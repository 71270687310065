import { SOURCES_IDS } from 'components/Layers/common'
import { FeatureLayer, getTitle } from 'components/MapPopup/utils'

export const getLabel = (feature: FeatureLayer) => {
  switch (feature.source) {
    case SOURCES_IDS.actionZone:
    case SOURCES_IDS.actionZoneLabel:
      return feature.properties?.libelle || 'NC'
    default:
      return feature.properties?.libelle || 'NC'
  }
}

export const featureReducer = (acc: Record<string, FeatureLayer[]>, f: FeatureLayer) => {
  const category = getTitle(f).name || 'NC'
  return {
    ...acc,
    [category]: [...(acc[category] || []), f],
  }
}

export default {
  getLabel, featureReducer,
}
