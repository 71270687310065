import { ReactElement } from 'react'
import './CreateZoneModal.scss'
import { Modal } from '@mui/material'
import { ChevronRight, Close } from '@mui/icons-material'
import terms from 'common/terms'
import { ZoneType } from 'store/types'

interface Props {
  open: boolean
  onClose: () => void
  handleClickOption: (option: ZoneType) => () => void
}

const CreateZoneModal = ({ open, onClose, handleClickOption }: Props): ReactElement => (
  <Modal
    open={open}
    onClose={onClose}
  >
    <div className="create-zone-modal">
      <div className="header">
        <h2>{terms.zoneEditor.createZone}</h2>
        <button type="button" className="close" onClick={onClose}>
          <Close />
        </button>
      </div>
      <div className="options">
        <button type="button" className="option" onClick={handleClickOption('poste')}>
          <span>{terms.zoneEditor.zone}</span>
          <strong>{terms.zoneEditor.poste}</strong>
          <ChevronRight />
        </button>
        <button type="button" className="option" onClick={handleClickOption('perimeter')}>
          <span>{terms.zoneEditor.define}</span>
          <strong>{terms.zoneEditor.perimeter}</strong>
          <ChevronRight />
        </button>
      </div>
    </div>
  </Modal>
)

export default CreateZoneModal
