import { ReactElement } from 'react'
import { Popup } from 'react-map-gl'
import { FeatureData } from 'views/Map/utils'
import CustomPopup from './CustomPopup'
import { FeatureLayer, getProperties, getTitle } from './utils'
import './MapPopup.scss'

interface Props {
  longitude: number | null
  latitude: number | null
  feature: FeatureLayer | null
  featureData: FeatureData
  showPopup: boolean
  handleClose: () => void
  handleClickPoste: (geom: GeoJSON.Geometry, zapId: string, isModifiable: boolean) => () => void
  handleHoverPoste: (zapId: string) => () => void
  handleClickZap: (zapId: string) => () => void
  handleHoverZap: (zapId: string) => () => void
  handleClickBal: (balId: string) => () => void
  handleHoverBal: (balId: string) => () => void
}

const MapPopup = ({
  longitude, latitude, feature, featureData, showPopup, handleClose, handleClickPoste, handleClickBal, handleClickZap,
  handleHoverPoste, handleHoverZap, handleHoverBal,
}: Props): ReactElement => (
  showPopup && feature && longitude && latitude ? (
    <Popup
      longitude={longitude}
      latitude={latitude}
      closeButton
      captureScroll
      captureDrag
      captureClick
      captureDoubleClick
      capturePointerMove
      onClose={handleClose}
      className="map-popup"
    >
      <CustomPopup
        title={getTitle(feature)}
        featureProperties={getProperties(feature, featureData)}
        featureId={String(feature.properties?.id)}
        handleClickPoste={handleClickPoste}
        handleHoverPoste={handleHoverPoste}
        source={feature.source}
        handleClickZap={handleClickZap}
        handleHoverZap={handleHoverZap}
        handleClickBal={handleClickBal}
        handleHoverBal={handleHoverBal}
      />
    </Popup>
  ) : <></>)

export default MapPopup
