export const LAYERS_IDS = {
  accesCircle: 'layer-acces-circle',
  accesLabel: 'layer-acces-label',
  accesHighlight: 'layer-acces-highlight',
  advCircle: 'layer-adv-circle',
  advLabel: 'layer-adv-label',
  advLine: 'layer-adv-line',
  advHighlight: 'layer-adv-highlight',
  cssSch: 'layer-css-sch',
  cssGeo: 'layer-css-geo',
  dbc: 'layer-dbc',
  jdz: 'layer-jdz',
  localisateurCircle: 'layer-localisateur-circle',
  localisateurLabel: 'layer-localisateur-label',
  localisateurHighlight: 'layer-localisateur-highlight',
  passageNiveauIcon: 'layer-passage-niveau-icon',
  passageNiveauLabel: 'layer-passage-niveau-label',
  pedaleCircle: 'layer-pedale-circle',
  pedaleLabel: 'layer-pedale-label',
  pedaleHighlight: 'layer-pedale-highlight',
  poste: 'layer-poste',
  posteCircle: 'layer-poste-circle',
  zoneEditorFill: 'layer-zone-editor-fill',
  zoneEditorOutline: 'layer-zone-editor-outline',
  signalCircle: 'layer-signal',
  signalMat: 'layer-signal-mat',
  signalPicto: 'layer-signal-picto',
  signalLabel: 'layer-signal-label',
  signalHighlight: 'layer-signal-highlight',
  sousStationSch: 'layer-sous-station-sch',
  sousStationGeo: 'layer-sous-station-geo',
  station: 'layer-station',
  stationLabel: 'layer-station-label',
  trackVP: 'layer-track-vp',
  trackNameVP: 'layer-track-name-vp',
  trackVS: 'layer-track-vs',
  trackNameVS: 'layer-track-name-vs',
  trackLineNumber: 'layer-track-line-number',
  zoneActionPoste: 'dex-zone-action-poste',
  zoneActionPosteLabel: 'dex-zone-action-poste-libelle',
  zoneActionPosteAmendee: 'dex-zone-action-poste-amendee',
  zoneActionPosteAmendeeLabel: 'dex-zone-action-poste-amendee-libelle',
  zoneActionPosteAmendeeStripes: 'dex-zone-action-poste-amendee-stripes',
  zoneActionPosteOutline: 'dex-zone-action-poste-outline',
  pri: 'layer-pri',
  priHighlight: 'layer-pri-highlight',
  priLabel: 'layer-pri-label',
  utm: 'layer-utm',
  utmHighlight: 'layer-utm-highlight',
  utmLabel: 'layer-utm-label',
  infrapole: 'layer-infrapole',
  infrapoleHighlight: 'layer-infrapole-highlight',
  infrapoleLabel: 'layer-infrapole-label',
  zp: 'layer-zp',
  zpHighlight: 'layer-zp-highlight',
  zpLabel: 'layer-zp-label',
  chantierPoint: 'layer-chantier-point',
  chantierPointHighlight: 'layer-chantier-point-highlight',
  chantierLine: 'layer-chantier-line',
  chantierLineHighlight: 'layer-chantier-line-highlight',
  zoneBAL: 'layer-zone-bal',
  zoneBALDotted: 'layer-zone-bal-dotted',
  zoneBALOutline: 'layer-zone-bal-outline',
  centerCircle: 'layer-center-circle',
  centerLabel: 'layer-center-label',
  centerHighlight: 'layer-center-highlight',
  ihmZoneOutline: 'layer-ihm-zone-outline',
  ihmZoneFill: 'layer-ihm-zone-fill',
  ihmZoneLabel: 'layer-ihm-zone-label',
  ihmZoneAmendeeOutline: 'layer-ihm-zone-amendee-outline',
  ihmZoneAmendeeFill: 'layer-ihm-zone-amendee-fill',
  ihmZoneAmendeeLabel: 'layer-ihm-zone-amendee-label',
  cdv: 'layer-cdv',
  cdvLabel: 'layer-cdv-label',
  exploitationMode: 'layer-exploitation-mode',
  bfCircle: 'layer-bf-circle',
  bfLabel: 'layer-bf-label',
  bfHighlight: 'layer-bf-highlight',
  rac: 'layer-rac',
  racLabel: 'layer-rac-label',
  centerArmenCircle: 'layer-center-armen-circle',
  centerArmenLabel: 'layer-center-armen-label',
  centerArmenHighlight: 'layer-center-armen-highlight',
  collectionStatus: 'layer-collection-status',
  collectionStatusCircle: 'layer-collection-status-circle',
} as const

export const INTERACTIVE_LAYERS_IDS = { sch: [
  LAYERS_IDS.accesCircle,
  LAYERS_IDS.accesLabel,
  LAYERS_IDS.accesHighlight,
  LAYERS_IDS.advCircle,
  LAYERS_IDS.advLabel,
  LAYERS_IDS.advLine,
  LAYERS_IDS.advHighlight,
  LAYERS_IDS.cssSch,
  LAYERS_IDS.cssGeo,
  LAYERS_IDS.dbc,
  LAYERS_IDS.jdz,
  LAYERS_IDS.localisateurCircle,
  LAYERS_IDS.localisateurLabel,
  LAYERS_IDS.localisateurHighlight,
  LAYERS_IDS.passageNiveauIcon,
  LAYERS_IDS.passageNiveauLabel,
  LAYERS_IDS.pedaleCircle,
  LAYERS_IDS.pedaleLabel,
  LAYERS_IDS.pedaleHighlight,
  LAYERS_IDS.poste,
  LAYERS_IDS.posteCircle,
  LAYERS_IDS.signalCircle,
  LAYERS_IDS.signalMat,
  LAYERS_IDS.signalPicto,
  LAYERS_IDS.signalLabel,
  LAYERS_IDS.signalHighlight,
  LAYERS_IDS.sousStationSch,
  LAYERS_IDS.sousStationGeo,
  LAYERS_IDS.station,
  LAYERS_IDS.stationLabel,
  LAYERS_IDS.zoneActionPoste,
  LAYERS_IDS.zoneActionPosteAmendee,
  LAYERS_IDS.zoneActionPosteLabel,
  LAYERS_IDS.zoneActionPosteAmendeeLabel,
  LAYERS_IDS.zpLabel,
  LAYERS_IDS.priLabel,
  LAYERS_IDS.utmLabel,
  LAYERS_IDS.infrapoleLabel,
  LAYERS_IDS.chantierPoint,
  LAYERS_IDS.chantierPointHighlight,
  LAYERS_IDS.chantierLine,
  LAYERS_IDS.chantierLineHighlight,
  LAYERS_IDS.zoneBAL,
  LAYERS_IDS.centerCircle,
  LAYERS_IDS.centerLabel,
  LAYERS_IDS.centerHighlight,
  LAYERS_IDS.ihmZoneOutline,
  LAYERS_IDS.ihmZoneFill,
  LAYERS_IDS.ihmZoneLabel,
  LAYERS_IDS.ihmZoneAmendeeOutline,
  LAYERS_IDS.ihmZoneAmendeeFill,
  LAYERS_IDS.ihmZoneAmendeeLabel,
  LAYERS_IDS.cdv,
  LAYERS_IDS.cdvLabel,
  LAYERS_IDS.bfCircle,
  LAYERS_IDS.bfLabel,
  LAYERS_IDS.bfHighlight,
  LAYERS_IDS.rac,
  LAYERS_IDS.racLabel,
  LAYERS_IDS.centerArmenCircle,
  LAYERS_IDS.centerArmenLabel,
  LAYERS_IDS.centerArmenHighlight,
  LAYERS_IDS.collectionStatusCircle,
],
geo: [
  LAYERS_IDS.accesCircle,
  LAYERS_IDS.accesLabel,
  LAYERS_IDS.accesHighlight,
  LAYERS_IDS.advCircle,
  LAYERS_IDS.advLabel,
  LAYERS_IDS.advLine,
  LAYERS_IDS.advHighlight,
  LAYERS_IDS.cssSch,
  LAYERS_IDS.cssGeo,
  LAYERS_IDS.dbc,
  LAYERS_IDS.jdz,
  LAYERS_IDS.localisateurCircle,
  LAYERS_IDS.localisateurLabel,
  LAYERS_IDS.localisateurHighlight,
  LAYERS_IDS.passageNiveauIcon,
  LAYERS_IDS.passageNiveauLabel,
  LAYERS_IDS.pedaleCircle,
  LAYERS_IDS.pedaleLabel,
  LAYERS_IDS.pedaleHighlight,
  LAYERS_IDS.poste,
  LAYERS_IDS.posteCircle,
  LAYERS_IDS.signalCircle,
  LAYERS_IDS.signalMat,
  LAYERS_IDS.signalPicto,
  LAYERS_IDS.signalLabel,
  LAYERS_IDS.signalHighlight,
  LAYERS_IDS.sousStationSch,
  LAYERS_IDS.sousStationGeo,
  LAYERS_IDS.station,
  LAYERS_IDS.stationLabel,
  LAYERS_IDS.zoneActionPoste,
  LAYERS_IDS.zoneActionPosteAmendee,
  LAYERS_IDS.zoneActionPosteLabel,
  LAYERS_IDS.zoneActionPosteAmendeeLabel,
  LAYERS_IDS.chantierPoint,
  LAYERS_IDS.chantierPointHighlight,
  LAYERS_IDS.chantierLine,
  LAYERS_IDS.chantierLineHighlight,
  LAYERS_IDS.zoneBAL,
  LAYERS_IDS.centerCircle,
  LAYERS_IDS.centerLabel,
  LAYERS_IDS.centerHighlight,
  LAYERS_IDS.ihmZoneOutline,
  LAYERS_IDS.ihmZoneFill,
  LAYERS_IDS.ihmZoneLabel,
  LAYERS_IDS.ihmZoneAmendeeOutline,
  LAYERS_IDS.ihmZoneAmendeeFill,
  LAYERS_IDS.ihmZoneAmendeeLabel,
  LAYERS_IDS.cdv,
  LAYERS_IDS.cdvLabel,
  LAYERS_IDS.bfCircle,
  LAYERS_IDS.bfLabel,
  LAYERS_IDS.bfHighlight,
  LAYERS_IDS.rac,
  LAYERS_IDS.racLabel,
  LAYERS_IDS.centerArmenCircle,
  LAYERS_IDS.centerArmenLabel,
  LAYERS_IDS.centerArmenHighlight,
  LAYERS_IDS.collectionStatusCircle,
],
schPerimeter: [
  LAYERS_IDS.accesCircle,
  LAYERS_IDS.accesLabel,
  LAYERS_IDS.accesHighlight,
  LAYERS_IDS.advCircle,
  LAYERS_IDS.advLabel,
  LAYERS_IDS.advLine,
  LAYERS_IDS.advHighlight,
  LAYERS_IDS.cssSch,
  LAYERS_IDS.cssGeo,
  LAYERS_IDS.dbc,
  LAYERS_IDS.jdz,
  LAYERS_IDS.localisateurCircle,
  LAYERS_IDS.localisateurLabel,
  LAYERS_IDS.localisateurHighlight,
  LAYERS_IDS.passageNiveauIcon,
  LAYERS_IDS.passageNiveauLabel,
  LAYERS_IDS.pedaleCircle,
  LAYERS_IDS.pedaleLabel,
  LAYERS_IDS.pedaleHighlight,
  LAYERS_IDS.poste,
  LAYERS_IDS.posteCircle,
  LAYERS_IDS.signalCircle,
  LAYERS_IDS.signalMat,
  LAYERS_IDS.signalPicto,
  LAYERS_IDS.signalLabel,
  LAYERS_IDS.signalHighlight,
  LAYERS_IDS.sousStationSch,
  LAYERS_IDS.sousStationGeo,
  LAYERS_IDS.station,
  LAYERS_IDS.stationLabel,
  LAYERS_IDS.zoneActionPoste,
  LAYERS_IDS.zoneActionPosteAmendee,
  LAYERS_IDS.zoneActionPosteLabel,
  LAYERS_IDS.zoneActionPosteAmendeeLabel,
  LAYERS_IDS.chantierPoint,
  LAYERS_IDS.chantierPointHighlight,
  LAYERS_IDS.chantierLine,
  LAYERS_IDS.chantierLineHighlight,
  LAYERS_IDS.zoneBAL,
  LAYERS_IDS.centerCircle,
  LAYERS_IDS.centerLabel,
  LAYERS_IDS.centerHighlight,
  LAYERS_IDS.ihmZoneOutline,
  LAYERS_IDS.ihmZoneFill,
  LAYERS_IDS.ihmZoneLabel,
  LAYERS_IDS.ihmZoneAmendeeOutline,
  LAYERS_IDS.ihmZoneAmendeeFill,
  LAYERS_IDS.ihmZoneAmendeeLabel,
  LAYERS_IDS.cdv,
  LAYERS_IDS.cdvLabel,
  LAYERS_IDS.bfCircle,
  LAYERS_IDS.bfLabel,
  LAYERS_IDS.bfHighlight,
  LAYERS_IDS.rac,
  LAYERS_IDS.racLabel,
  LAYERS_IDS.centerArmenCircle,
  LAYERS_IDS.centerArmenLabel,
  LAYERS_IDS.centerArmenHighlight,
  LAYERS_IDS.collectionStatusCircle,
] }

export const CONTEXT_MENU_LAYERS_IDS = [
  LAYERS_IDS.zoneActionPosteAmendee,
]
