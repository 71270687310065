import { signal } from '@preact/signals-react'
import { FeatureCollection } from 'geojson'
import { Layer, Source } from 'react-map-gl'

export const GeoJSONLayerDataSignal = signal<FeatureCollection>({
  type: 'FeatureCollection',
  features: [],
})

const GeoJSONLayer = () => (
  <Source
    id="geojson-layer"
    type="geojson"
    data={GeoJSONLayerDataSignal.value}
  >
    <Layer
      id="geojson-point-layer"
      type="circle"
      paint={{
        'circle-radius': 6,
        'circle-color': ['case', ['get', 'old'], '#B42222', '#009f00'],
      }}
      source="geojson-layer"
      filter={['any', ['==', ['geometry-type'], 'Point'], ['==', ['geometry-type'], 'MultiPoint']]}
    />
    <Layer
      id="geojson-line-layer"
      type="line"
      paint={{
        'line-color': ['case', ['get', 'old'], '#B42222', '#009f00'],
        'line-width': 2,
        'line-dasharray': [2, 2],
      }}
      source="geojson-layer"
    />
  </Source>
)

export default GeoJSONLayer
