import { ReactElement } from 'react'
import './PosteSelectionPopup.scss'
import terms from 'common/terms'
import { PosteProperties } from 'store/types'
import { Close } from '@mui/icons-material'

interface Props {
  poste: PosteProperties | null
  showPopup: boolean
  handleClose: () => void
}

const PosteSelectionPopup = ({ poste, showPopup, handleClose }: Props): ReactElement => {
  const fieldLabels = terms.zoneEditor.postePopup.fields
  const fields = [
    { id: 'libelle', label: fieldLabels.libelle, value: poste?.libelle || undefined },
    { id: 'gare_libelle', label: fieldLabels.gare, value: poste?.gare_libelle || undefined },
    { id: 'ligne', label: fieldLabels.ligne, value: poste?.lrs_ligne || undefined },
    { id: 'pk', label: fieldLabels.pk, value: poste?.lrs_pk || undefined },
    {
      id: 'type',
      label: fieldLabels.installationType,
      value: poste?.type_installation_fixe_id_mnemo || undefined,
    },
  ]

  return showPopup ? (
    <div className="poste-selection-popup">
      <div className="header">
        <span className="no-selection">
          {poste ? terms.zoneEditor.postePopup.title : terms.zoneEditor.postePopup.noSelection}
        </span>
        <button type="button" className="close" onClick={handleClose}>
          <Close fontSize="inherit" />
        </button>
      </div>
      {poste && fields.filter(field => field?.value).map(field => (
        <div key={field.id} className="field">
          <span className="label">{field.label}</span>
          <span className="value">{field.value}</span>
        </div>
      ))}
    </div>
  ) : <></>
}

export default PosteSelectionPopup
