/*
valeur,valeur_libelle
45bd684e-6665-11e3-afff-01f464e0362d,Installations permanentes de contre sens
45bf8bce-6665-11e3-afff-01f464e0362d,Voie banalisée
45bd6dac-6665-11e3-afff-01f464e0362d,Voie banalisée
45bf7922-6665-11e3-afff-01f464e0362d,Voie de Service
e0c0421a-19b6-11e5-80ff-01fc64e0362d,Voie de Service
45c0be0c-6665-11e3-afff-01f464e0362d,voie non Exploitée
45c26bc4-6665-11e3-afff-01f464e0362d,Voie unique
45c48a46-6665-11e3-afff-01f464e0362d,Voie unique à signalisation simplifiée
45bd7314-6665-11e3-afff-01f464e0362d,Voie unique à trafic restreint (consigne de ligne)
45bbeebe-6665-11e3-afff-01f464e0362d,Voie unique temporaire à caractère permanent
*/

export const EM_UNIQUE_IDS = ['45c26bc4-6665-11e3-afff-01f464e0362d', '45c48a46-6665-11e3-afff-01f464e0362d',
  '45bd7314-6665-11e3-afff-01f464e0362d', '45bbeebe-6665-11e3-afff-01f464e0362d']

export const EM_BANALISEE_IDS = ['45bf8bce-6665-11e3-afff-01f464e0362d', '45bd6dac-6665-11e3-afff-01f464e0362d']

export const EM_IPCS_IDS = ['45bd684e-6665-11e3-afff-01f464e0362d']

export const EXPLOITATION_MODES = [...EM_UNIQUE_IDS, ...EM_BANALISEE_IDS, ...EM_IPCS_IDS]
