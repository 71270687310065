import { perimeterActiveLayersSignal, perimeterHighlightedIdSignal } from 'views/Perimeter/PerimeterView/PerimeterView'
import { UserStudyPerimeterDetailsWithId } from 'store/userFavorite/types'
import { usePostesFeatures } from 'components/hooks'
import { useSelector } from 'react-redux'
import { RootState } from 'store'
import { MapTheme } from 'components/Toolbar/ThemeMenu/const'
import { schPerimeterBboxSignal, schPerimeterViewportSignal } from 'views/Perimeter/PerimeterView/PerimeterSchMap'
import {
  DexCartoSourceKey, DexcartoLayerKey, LAYERS_KEYS, OBJECTS_LAYERS, POSTES_LAYER_KEYS,
  SCHEMATIC_VIEWS, SIGNAL_LAYERS_KEYS, SOURCES_IDS,
} from './common'
import { getChantierFilter, getCollectionStatusProps, getPosteFilter, getSignalFilter } from './utils'
import {
  ADVLayer, ADVLineLayer, AccesLayer, ActionZonePosteLayer, BALZoneLayer, BFLayer, CDVLayer, CenterLayer,
  ChantierLayer, DBCLayer, IHMZoneLayer, JDZLayer, LocalisateurLayer, PassageNiveauLayer, PedaleLayer, PosteLayer,
  RacLayer, SignalLayer, SousStationLayer, StationLayer, TrackLabelLayer, TrackLayer, CentraleSousStationLayer,
  ActionZonePosteLibelleLayer, IHMZoneLabelLayer, ExploitationModeLayer, EditorLayer, PosteSelectionLayer,
  CenterArmenLayer,
} from '.'
import EditorOverlay from './Editor/EditorOverlay'

const getView = (sourceId: DexCartoSourceKey) => SCHEMATIC_VIEWS[sourceId] ?? ''

interface Props {
  activePerimeter: UserStudyPerimeterDetailsWithId
}

const PerimeterSchLayers = ({ activePerimeter }: Props) => {
  const { isSelectingPoste } = useSelector((state: RootState) => state.zoneEditor)
  const posteFeatures = usePostesFeatures(
    isSelectingPoste,
    MapTheme.schematic,
    schPerimeterViewportSignal,
    schPerimeterBboxSignal,
    undefined,
    undefined,
    activePerimeter.geometry,
  )

  const getVisibility = (layerKey: DexcartoLayerKey) => perimeterActiveLayersSignal.value.includes(layerKey)

  const isLayerActive = (layer: string) => perimeterActiveLayersSignal.value.includes(layer)
  const signalFilter = getSignalFilter(isLayerActive)
  const posteFilter = getPosteFilter(isLayerActive)
  const chantierFilter = getChantierFilter(isLayerActive)
  const collectionStatusProps = getCollectionStatusProps(isLayerActive)

  const bpolygonParam = { bpolygon: JSON.stringify(activePerimeter.geometry) }

  return (
    <>
      <EditorOverlay posteFeatures={posteFeatures} />
      <EditorLayer />
      <ExploitationModeLayer
        activeLayers={perimeterActiveLayersSignal.value}
        view={getView(SOURCES_IDS.exploitationMode)}
        params={bpolygonParam}
      />
      <TrackLayer
        sourceLayer={OBJECTS_LAYERS.trackSch}
        params={bpolygonParam}
      />
      <JDZLayer
        hoveredObjectsIds={perimeterHighlightedIdSignal.value}
        view={getView(SOURCES_IDS.jdz)}
        params={bpolygonParam}
        angle="angle_rgi_track_sch_flat"
        visibility={getVisibility(LAYERS_KEYS.jdz)}
      />
      <BALZoneLayer
        hoveredObjectsIds={perimeterHighlightedIdSignal.value}
        view={getView(SOURCES_IDS.balZone)}
        params={bpolygonParam}
        visibility={getVisibility(LAYERS_KEYS.balZone)}
      />
      <ActionZonePosteLayer
        hoveredObjectsIds={perimeterHighlightedIdSignal.value}
        view={getView(SOURCES_IDS.actionZone)}
        params={bpolygonParam}
        visibility={{
          zoneActionPoste: getVisibility(LAYERS_KEYS.zoneActionPoste),
          zoneActionPosteAmendee: getVisibility(LAYERS_KEYS.zoneActionPosteAmendee),
        }}
      />
      <IHMZoneLayer
        hoveredObjectsIds={perimeterHighlightedIdSignal.value}
        view={getView(SOURCES_IDS.ihmZone)}
        params={bpolygonParam}
        visibility={{
          ihmZone: getVisibility(LAYERS_KEYS.ihmZone),
          ihmZoneAmendee: getVisibility(LAYERS_KEYS.ihmZoneAmendee),
        }}
      />
      <ChantierLayer
        view={getView(SOURCES_IDS.chantier)}
        hoveredObjectsIds={perimeterHighlightedIdSignal.value}
        params={bpolygonParam}
        chantierFilter={chantierFilter}
      />
      {/* TODO No Border layer */}
      <ADVLineLayer
        hoveredObjectsIds={perimeterHighlightedIdSignal.value}
        view={getView(SOURCES_IDS.advLine)}
        params={bpolygonParam}
        visibility={getVisibility(LAYERS_KEYS.adv)}
      />
      <TrackLabelLayer sourceLayer={OBJECTS_LAYERS.trackSch} />
      <RacLayer
        hoveredObjectsIds={perimeterHighlightedIdSignal.value}
        view={getView(SOURCES_IDS.rac)}
        params={bpolygonParam}
        visibility={getVisibility(LAYERS_KEYS.rac)}
      />
      <ADVLayer
        hoveredObjectsIds={perimeterHighlightedIdSignal.value}
        view={getView(SOURCES_IDS.adv)}
        params={bpolygonParam}
        visibility={getVisibility(LAYERS_KEYS.adv)}
      />
      <CDVLayer
        hoveredObjectsIds={perimeterHighlightedIdSignal.value}
        view={getView(SOURCES_IDS.cdv)}
        params={bpolygonParam}
        visibility={getVisibility(LAYERS_KEYS.cdv)}
      />
      <SignalLayer
        hoveredObjectsIds={perimeterHighlightedIdSignal.value}
        view={getView(SOURCES_IDS.signal)}
        params={bpolygonParam}
        angle="angle_rgi_track_sch_flat"
        signalFilter={signalFilter}
        visibility={SIGNAL_LAYERS_KEYS.some(key => getVisibility(key))}
      />
      <StationLayer
        hoveredObjectsIds={perimeterHighlightedIdSignal.value}
        view={getView(SOURCES_IDS.station)}
        params={bpolygonParam}
        visibility={getVisibility(LAYERS_KEYS.station)}
      />
      <PosteSelectionLayer />
      <PosteLayer
        hoveredObjectsIds={perimeterHighlightedIdSignal.value}
        view={getView(SOURCES_IDS.poste)}
        params={bpolygonParam}
        posteFilter={posteFilter}
        posteVisibility={Object.values(POSTES_LAYER_KEYS).some(key => getVisibility(key))}
      />

      <AccesLayer
        hoveredObjectsIds={perimeterHighlightedIdSignal.value}
        view={getView(SOURCES_IDS.acces)}
        params={bpolygonParam}
        visibility={getVisibility(LAYERS_KEYS.acces)}
      />
      <BFLayer
        hoveredObjectsIds={perimeterHighlightedIdSignal.value}
        view={getView(SOURCES_IDS.bf)}
        params={{ ...bpolygonParam, code_ch: 'BF' }}
        visibility={getVisibility(LAYERS_KEYS.bf)}
      />
      <SousStationLayer
        view={getView(SOURCES_IDS.sousStation)}
        params={bpolygonParam}
        visibility={getVisibility(LAYERS_KEYS.sousStation)}
      />
      <CentraleSousStationLayer
        view={getView(SOURCES_IDS.css)}
        params={bpolygonParam}
        visibility={getVisibility(LAYERS_KEYS.css)}
      />
      <LocalisateurLayer
        hoveredObjectsIds={perimeterHighlightedIdSignal.value}
        view={getView(SOURCES_IDS.localisateur)}
        params={bpolygonParam}
        visibility={getVisibility(LAYERS_KEYS.localisateur)}
      />
      <DBCLayer
        hoveredObjectsIds={perimeterHighlightedIdSignal.value}
        view={getView(SOURCES_IDS.dbc)}
        params={bpolygonParam}
        visibility={getVisibility(LAYERS_KEYS.dbc)}
        angle="angle_rgi_track_sch_flat"
      />
      <PedaleLayer
        hoveredObjectsIds={perimeterHighlightedIdSignal.value}
        view={getView(SOURCES_IDS.pedale)}
        params={bpolygonParam}
        visibility={getVisibility(LAYERS_KEYS.pedale)}
      />
      <PassageNiveauLayer
        hoveredObjectsIds={perimeterHighlightedIdSignal.value}
        view={getView(SOURCES_IDS.passageNiveau)}
        params={bpolygonParam}
        visibility={getVisibility(LAYERS_KEYS.passageNiveau)}
        angle="angle_rgi_track_sch_flat"
      />
      <CenterLayer
        hoveredObjectsIds={perimeterHighlightedIdSignal.value}
        view={getView(SOURCES_IDS.center)}
        params={bpolygonParam}
        visibility={getVisibility(LAYERS_KEYS.center)}
      />
      <CenterArmenLayer
        hoveredObjectsIds={perimeterHighlightedIdSignal.value}
        view={getView(SOURCES_IDS.centerArmen)}
        params={bpolygonParam}
        visibility={getVisibility(LAYERS_KEYS.centerArmen)}
      />
      <ActionZonePosteLibelleLayer
        hoveredObjectsIds={perimeterHighlightedIdSignal.value}
        view={getView(SOURCES_IDS.actionZoneLabel)}
        params={{ geom_rgi_track_sch_flat__bpolygon: JSON.stringify(activePerimeter.geometry) }}
        visibility={{
          zoneActionPoste: getVisibility(LAYERS_KEYS.zoneActionPoste),
          zoneActionPosteAmendee: getVisibility(LAYERS_KEYS.zoneActionPosteAmendee),
        }}
        collectionStatusProps={collectionStatusProps}
      />
      <IHMZoneLabelLayer
        hoveredObjectsIds={perimeterHighlightedIdSignal.value}
        view={getView(SOURCES_IDS.ihmZoneLabel)}
        params={{ geom_rgi_track_sch_flat__bpolygon: JSON.stringify(activePerimeter.geometry) }}
        visibility={{
          ihmZone: getVisibility(LAYERS_KEYS.ihmZone),
          ihmZoneAmendee: getVisibility(LAYERS_KEYS.ihmZoneAmendee),
        }}
      />
    </>
  )
}

export default PerimeterSchLayers
