/* eslint-disable camelcase */
import { MutableRefObject, RefObject } from 'react'
import { MapRef, ViewportProps } from 'react-map-gl'
import rectangleSimple from 'assets/icons/rectangle_simple.png'
import rectangleArrondi from 'assets/icons/rectangle_arrondi.png'
import station from 'assets/icons/station.png'
import stationRed from 'assets/icons/station_red.png'
import jdzBlue from 'assets/icons/jdz_blue.png'
import jdzRed from 'assets/icons/jdz_red.png'
import stripes from 'assets/icons/stripes.png'
import dot from 'assets/icons/dot.png'
import ihmLine from 'assets/icons/ihm_line.png'
import ihmLineRed from 'assets/icons/ihm_line_red.png'
import { store } from 'store'
import { MapState } from 'store/types'
import { removeSourcesToUpdate } from 'store/map'
import MAIN_API from 'config/config'
import { GEOGRAPHIC_VIEWS, OBJECTS_LAYERS, SCHEMATIC_VIEWS, SOURCES_IDS } from 'components/Layers/common'
import { get, post } from '@osrdata/app_core/dist/requests'
import { FeatureCollection, Geometry } from 'geojson'
import { FeatureLayer } from 'components/MapPopup/utils'
import { signal } from '@preact/signals-react'
import { MapTheme } from 'components/Toolbar/ThemeMenu/const'
import { DetailsField, ZapModification, ZapModificationDetails } from 'components/Toolbar/HistoryMenu/types'

const hexToRgb = (hex:string) => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
  return result ? {
    r: parseInt(result[1], 16),
    g: parseInt(result[2], 16),
    b: parseInt(result[3], 16),
  } : null
}

const generateColorImage = (color: string, image: ImageBitmap): ImageData | undefined => {
  const rgbColor = hexToRgb(color)
  if (!rgbColor) return undefined

  const canvas = document.createElement('canvas')
  canvas.width = image.width
  canvas.height = image.height
  const ctx = canvas.getContext('2d')
  if (!ctx) return undefined
  ctx.drawImage(image, 0, 0)
  const imgData = ctx.getImageData(0, 0, image.width, image.height)
  const newImageData = new ImageData(imgData.width, imgData.height)
  for (let x = 0; x < imgData.width; x += 1) {
    for (let y = 0; y < imgData.height; y += 1) {
      const offset = (y * imgData.width + x) * 4
      newImageData.data[offset + 0] = rgbColor.r // red
      newImageData.data[offset + 1] = rgbColor.g // green
      newImageData.data[offset + 2] = rgbColor.b // blue
      newImageData.data[offset + 3] = imgData.data[offset + 3] // alpha
    }
  }
  return newImageData
}

export const addedImagesSignal = signal<Set<string>>(new Set<string>())

export const loadMissingImage = (mapRef: MutableRefObject<MapRef | undefined> | undefined, imageId: string) => {
  const currentMap = mapRef?.current?.getMap()

  if (imageId.includes('rectanglearrondi-')) {
    const color = imageId.trim().split('-')?.[1]
    currentMap.loadImage(rectangleArrondi, (error: Error, image: ImageBitmap) => {
      if (error) return
      const generatedImage = generateColorImage(color, image)
      if (generatedImage && !addedImagesSignal.value.has(imageId)) {
        currentMap.addImage(imageId, generatedImage, {
          stretchX: [[40, 88]], stretchY: [[31, 32]], pixelRatio: 3, content: [20, 10, 108, 54],
        })
        addedImagesSignal.value.add(imageId)
      }
    })
  }
}

export const loadStaticImages = (mapRef: MutableRefObject<MapRef | undefined> | undefined) => {
  const currentMap = mapRef?.current?.getMap()
  const addImageToMap = (newImage: string, id: string) => {
    if (!currentMap || !newImage || !id) return
    currentMap.loadImage(newImage, (error: Error, image: ImageBitmap) => {
      if (error) return
      currentMap.addImage(id, image, { sdf: false })
    })
  }
  addImageToMap(rectangleSimple, 'rectanglesimple')
  addImageToMap(station, 'station')
  addImageToMap(stationRed, 'station_red')
  addImageToMap(jdzBlue, 'jdz_blue')
  addImageToMap(jdzRed, 'jdz_red')
  addImageToMap(stripes, 'stripes')
  addImageToMap(dot, 'dot')
  addImageToMap(ihmLine, 'ihm_line')
  addImageToMap(ihmLineRed, 'ihm_line_red')
}

export const resetSource = (sourceId: string, mapRef: RefObject<MapRef | undefined>): void => {
  const mapInstance = mapRef?.current?.getMap()
  // Remove the tiles for a particular source
  mapInstance.style.sourceCaches[sourceId].clearTiles()

  // Load the new tiles for the current viewport (mapInstance.transform -> viewport)
  mapInstance.style.sourceCaches[sourceId].update(mapInstance.transform)

  // Force a repaint, so that the map will be repainted without you having to touch the map
  mapInstance.triggerRepaint()
}

export const refreshTiles = (mapRef: RefObject<MapRef | undefined>): void => {
  const { sourcesToUpdate } = store.getState().map as MapState

  if (mapRef.current && sourcesToUpdate.length !== 0) {
    const mapInstance = mapRef.current.getMap()
    sourcesToUpdate.forEach(source => {
      // Define base tiles URL
      // Add Date to force cache reset
      const params = source.params ? `&${new URLSearchParams(source.params)}` : ''

      // eslint-disable-next-line max-len
      const tempCacheUrl = `${MAIN_API.proxy}/chartis/v2/layer/${source.name}/mvt_tile/${source.type}/?x={x}&y={y}&z={z}${params}&#${Date.now()}`
      try {
        mapInstance.getSource(source.sourceId).tiles = [tempCacheUrl]
        resetSource(source.sourceId, mapRef)
      } catch (err) {
        // console.log(err)
      }
    })
    store.dispatch(removeSourcesToUpdate(sourcesToUpdate))
  }
}

export type CollectionSubfamily = {
    categorie: string
    indice_gep: string
    objet: string
}

export type GeoReference = {
  jiraId: string
  referenceGeographique: string
  fiches?: {
    [ficheType: string]: {
      fiche_key: string
      fiche_status_name?: string
      fiche_summary?: string
      indice_gep?: string
    }[]
  }
}

export type IDFIndices = {
  ['Collection_DEX[EtatDeLaCollection]']?: string
  ['Collection_DEX[Indice]']?: string
  ['Collection_DEX[LibelleOperation]']?: string
  ['Collection_DEX[DateEditionTravaux]']?: string
}

export type IDFReference = {
  [subfamily: string]: {
    indices: IDFIndices[]
    nombre_dc_bloque: number
    nombre_dc_non_commence: number
    nombre_dc_non_termine: number
    nombre_en_attente_rc: number
    nombre_etude_en_cours: number
  }
}

export type CollectionReference = {
  [key: string]: {
    ligne_ids: string[],
    referenceGeographiques: GeoReference[]
    IDF?: IDFReference
  } & {
    [subfamily: string]: {
      categorie: string
      indice_gep: string
    }[]
  }
}

export type NeighbouringZones = {
  zone_action_poste: { id: string, libelle: string }[]
  zone_bal: { id: string, libelle: string }[]
}

type FeatureActionZoneData = {
  postes: {
    posteName: string
    zapGeometry: Geometry
    zapId: string
    isModifiable: boolean
  }[]
  collections: CollectionReference
  history: ZapModificationDetails[]
  neighbouringZones: NeighbouringZones
  rcData: {
    nombre_dc_bloque?: number | null
    nombre_dc_non_commence?: number | null
    nombre_dc_non_termine?: number | null
    nombre_en_attente_rc?: number | null
    nombre_etude_en_cours?: number | null
  }
}

const getURLData = (feature: FeatureLayer, geometry: Geometry, theme: MapTheme) => {
  switch (feature.source) {
    case SOURCES_IDS.poste:
      return [{
        layer: OBJECTS_LAYERS.actionZonePoste,
        view: theme === MapTheme.schematic
          ? SCHEMATIC_VIEWS[SOURCES_IDS.actionZone] : GEOGRAPHIC_VIEWS[SOURCES_IDS.actionZone],
        params: {
          poste_gaia_id: feature.properties?.id,
          modifiable: true,
        },
      }]
    case SOURCES_IDS.actionZone:
    case SOURCES_IDS.actionZoneLabel:
      return [{
        layer: OBJECTS_LAYERS.actionZonePoste,
        view: theme === MapTheme.schematic
          ? SCHEMATIC_VIEWS[SOURCES_IDS.actionZone] : GEOGRAPHIC_VIEWS[SOURCES_IDS.actionZone],
        params: {
          id: feature.properties?.id,
          modifiable: feature.properties?.modifiable,
        },
      }]
    case SOURCES_IDS.balZone:
      return [{
        layer: OBJECTS_LAYERS.balZone,
        view: theme === MapTheme.schematic
          ? SCHEMATIC_VIEWS[SOURCES_IDS.balZone] : GEOGRAPHIC_VIEWS[SOURCES_IDS.balZone],
        params: {
          id: feature.properties?.id,
        },
      }]
    default:
      return [
        {
          layer: OBJECTS_LAYERS.actionZonePoste,
          view: theme === MapTheme.schematic
            ? SCHEMATIC_VIEWS[SOURCES_IDS.actionZone] : GEOGRAPHIC_VIEWS[SOURCES_IDS.actionZone],
          params: {
            bpolygon: geometry,
            modifiable: true,
          },
        },
        {
          layer: OBJECTS_LAYERS.balZone,
          view: theme === MapTheme.schematic
            ? SCHEMATIC_VIEWS[SOURCES_IDS.balZone] : GEOGRAPHIC_VIEWS[SOURCES_IDS.balZone],
          params: {
            bpolygon: geometry,
          },
        },
      ]
  }
}
const getActionZonePoste = async (feature: FeatureLayer, theme: MapTheme): Promise<FeatureActionZoneData> => {
  const featureGeometry = feature.geometry
  const urlDataList = getURLData(feature, featureGeometry, theme)
  const featureCollections = await Promise.all(urlDataList.map(async urlData => {
    const url = `/chartis/v2/layer/${urlData.layer}/geojson/${urlData.view}/`
    const response: FeatureCollection = await post(url, {
      // eslint-disable-next-line max-len
      ...Object.fromEntries(Object.entries(urlData.params).map(([key, value]) => [key, typeof value === 'string' ? value : JSON.stringify(value)])),
    })
    return response.features
  }))

  let postes: FeatureActionZoneData['postes'] = []
  let collections = {}
  featureCollections.forEach(features => {
    // Only ZAP layer should have poste_libelle property
    const posteList = features.filter(f => f.properties?.poste_libelle)
      .map(f => ({
        posteName: f.properties?.libelle,
        zapGeometry: f.geometry,
        zapId: f.properties?.id,
        isModifiable: f.properties?.modifiable,
      }))
    postes = [...postes, ...posteList]
    // ZAP and BAL layers have documents property
    const collectionReferences = features.filter(f => f.properties?.documents)
      .reduce((acc, f) => ({
        ...acc,
        ...f.properties?.documents as CollectionReference,
      }), {})
    collections = { ...collections, ...collectionReferences }
  })

  let history: ZapModificationDetails[] = []
  if ((feature.source === SOURCES_IDS.actionZone || feature.source === SOURCES_IDS.actionZoneLabel)
    && feature.properties?.modifiable && feature.properties?.id) {
    const response = await get(`/dexcarto/zone-action-poste/${feature.properties.id}/modifications`, { limit: 5 })
    const GEOM_FIELDS = ['geom_rgi_track_sch_flat', 'geom_rgi_track_geo',
      'libelle_geom_rgi_track_sch_flat', 'libelle_geom_rgi_track_geo']

    const zapDetails: ZapModificationDetails[] = (response.results as ZapModification[]).map(modif => {
      const modifiedFields: DetailsField[] = modif.modified_fields.map(field => {
        if (GEOM_FIELDS.includes(field.field)) {
          return {
            field: field.field,
            isGeom: true,
            shown_old: false,
            shown_new: false,
            old_value: field.old_value,
            new_value: field.new_value,
          }
        }
        return { field: field.field, isGeom: false, old_value: field.old_value, new_value: field.new_value }
      })
      return { ...modif, modified_fields: modifiedFields }
    })
    history = zapDetails
  }

  let neighbouringZones: NeighbouringZones = { zone_action_poste: [], zone_bal: [] }
  if (feature.source === SOURCES_IDS.actionZone || feature.source === SOURCES_IDS.actionZoneLabel) {
    const response = await get(`/dexcarto/zone-action-poste/${feature.properties?.id}/objet-encadrant/`)
    neighbouringZones = response
  } else if (feature.source === SOURCES_IDS.balZone) {
    const response = await get(`/dexcarto/zone-bal/${feature.properties?.id}/objet-encadrant/`)
    neighbouringZones = response
  }

  return {
    postes,
    collections,
    history,
    neighbouringZones,
    rcData: {},
  }
}

type ZAP = {
  id: string
  // eslint-disable-next-line camelcase
  poste_libelle: string

}

export type FeatureData = FeatureActionZoneData

export const getFeatureData = async (feature: FeatureLayer, theme: MapTheme): Promise<FeatureActionZoneData> => {
  if (feature.source === SOURCES_IDS.ihmZone || feature.source === SOURCES_IDS.ihmZoneLabel) {
    const ihmData = await get(`/dexcarto/zone-ihm/${feature.properties?.id}/`)
    const zaps = ihmData.zaps as ZAP[]
    const view = theme === MapTheme.schematic
      ? SCHEMATIC_VIEWS[SOURCES_IDS.actionZone] : GEOGRAPHIC_VIEWS[SOURCES_IDS.actionZone]
    // eslint-disable-next-line max-len
    const posteData: FeatureCollection = await get(`/chartis/v2/layer/${OBJECTS_LAYERS.actionZonePoste}/geojson/${view}/`, {
      id__in: zaps.map(zap => zap.id).join(','),
    })
    return {
      postes: posteData.features.map(f => ({
        posteName: f.properties?.libelle,
        zapGeometry: f.geometry,
        zapId: f.properties?.id,
        isModifiable: f.properties?.modifiable,
      })),
      collections: {},
      history: [],
      neighbouringZones: { zone_action_poste: [], zone_bal: [] },
      rcData: {},
    }
  }
  const actionZoneData = await getActionZonePoste(feature, theme)
  return actionZoneData
}

export const formatLocationURL = (viewport: ViewportProps, view: MapTheme) => {
  const { latitude, longitude, zoom, bearing, pitch } = viewport
  const viewSlug = view === MapTheme.schematic ? 'sch' : 'geo'
  if (!latitude || !longitude || !zoom || bearing === undefined || pitch === undefined) return ''
  // eslint-disable-next-line max-len
  return `${viewSlug}@${longitude.toPrecision(8)},${latitude.toPrecision(8)},${zoom.toPrecision(3)}z,${Math.floor(bearing)}b,${Math.floor(pitch)}p`
}

export const parseLocationURL = (location: string): {view: string, viewport: ViewportProps} | null => {
  // const match = location.match(/@(-?\d+\.\d+),(-?\d+\.\d+),(\d+\.\d+)z,(-?\d+)b,(\d+)p/)
  const match = location.match(/(geo|sch)@(-?\d+\.\d+),(-?\d+\.\d+),(\d+\.\d+)z,(-?\d+)b,(-?\d+)p/)
  if (!match) return null
  const [, view, longitude, latitude, zoom, bearing, pitch] = match
  return {
    view,
    viewport: {
      latitude: parseFloat(latitude),
      longitude: parseFloat(longitude),
      zoom: parseFloat(zoom),
      bearing: parseInt(bearing, 10),
      pitch: parseInt(pitch, 10),
    },

  }
}

export default { loadStaticImages, loadMissingImage, refreshTiles }
