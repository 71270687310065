import { Box, Modal } from '@mui/material'
import { useState } from 'react'
import terms from 'common/terms'
import DataInformation from './DataInformation/DataInformation'
import UserFeedBack from './UserFeedback/UserFeedback'
import './UserFeedbackModal.scss'

const style = {
  position: 'absolute' as const,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 700,
  maxHeight: '90vh',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '4px',
}

interface Props {
  handleClose: () => void
  openModal: boolean
}

const BugModal = ({ handleClose, openModal }: Props) => {
  const [inputObject, setInputObject] = useState('')
  const [inputContent, setInputContent] = useState('')
  const [inputCheckbox, setInputCheckox] = useState(false)

  const browser = window.navigator

  const clickClose = () => {
    setInputObject('')
    setInputContent('')
    setInputCheckox(false)
    handleClose()
  }

  const bodyMail = () => {
    if (inputCheckbox) {
      return `${
        inputContent.replace(/\n/g, '%0D%0A')
      }%0D%0A%0D%0A-------------------
        %0D%0A%0D%0ANavigateurs installés : ${
  browser.userAgent.replace(/\n/g, '%0D%0A')
}%0D%0ASystème d'exploitation : ${
  browser.platform
}%0D%0AURL : ${
  window.location.href
}%0D%0ALargeur de l'écran : ${
  window.screen.width
}%0D%0AHauteur de l'écran : ${
  window.screen.height
}`
    }
    return `${
      inputContent.replace(/\n/g, '%0D%0A')
    }`
  }

  const handleSendMail = () => {
    window.location.href = `mailto:dexcarto.moe@dgexsol.fr?subject=[DEX CARTO BUG]
 - ${inputObject}&body=${bodyMail()}`
    clickClose()
  }

  const isDisabled = !inputObject
  || !inputContent
  || inputContent.length > parseInt('1500', 10)

  return (
    <Modal open={openModal} onClose={handleClose}>
      <Box sx={style} className="user-feedback-modal">
        <h2>{terms.UserFeedback.bugTitle}</h2>
        <UserFeedBack
          typeDescription={terms.UserFeedback.bugType}
          description={terms.UserFeedback.bugDesc}
          inputContent={inputContent}
          setInputContent={setInputContent}
          inputObject={inputObject}
          setInputObject={setInputObject}
        />
        <DataInformation
          inputCheckbox={inputCheckbox}
          setInputCheckox={setInputCheckox}
        />
        <button className="validate" type="button" onClick={handleSendMail} disabled={isDisabled}>
          {terms.UserFeedback.sendMail}
        </button>
      </Box>
    </Modal>
  )
}

export default BugModal
