import {
  DexcartoLayerKey, GEOGRAPHIC_VIEWS, OBJECTS_LAYERS, SCHEMATIC_VIEWS,
  SIGNAL_LAYERS_MNEMO,
  SOURCES_IDS,
} from 'components/Layers/common'
import { MapTheme } from 'components/Toolbar/ThemeMenu/const'
import { Polygon } from 'geojson'
import { get } from '@osrdata/app_core/dist/requests'
import { SearchLayers } from './const'
import { CassiniWithTypeSearchResult, SearchParams } from './types'
import { computeSearchResultsWithType, getCassiniParams } from './cassini'

// eslint-disable-next-line import/prefer-default-export
export const getSignalObjects = async (
  activeLayers: DexcartoLayerKey[], layers: SearchLayers[], theme: MapTheme, bbox: Polygon, query: string,
  params: SearchParams,
) => {
  if (!layers.includes(SearchLayers.signal)) return null
  const signalFamilies = Object.entries(SIGNAL_LAYERS_MNEMO)
    .filter(([key]) => activeLayers.includes(key))
    .flatMap(([, value]) => value)

  const layer = OBJECTS_LAYERS.signal
  const view = theme === MapTheme.geographic
    ? GEOGRAPHIC_VIEWS[SOURCES_IDS.signal] : SCHEMATIC_VIEWS[SOURCES_IDS.signal]
  const bboxParam = theme === MapTheme.perimeter ? 'geom_rgi_track_sch_flat__bpolygon' : 'bbox'
  const cassiniParams = getCassiniParams(params)
  const requestParams = {
    // eslint-disable-next-line max-len
    columns: 'id,libelle,lrs_pk,lrs_voie,lrs_ligne,type_installation_fixe_id_mnemo,geom_rgi_track_sch_flat,geom_rgi_track_geo',
    [bboxParam]: bbox,
    size_per_layer: 100,
    libelle__ilike: `%${query.trim()}%`,
    type_installation_fixe_id_mnemo__in: signalFamilies.join(','),
    ...cassiniParams,
  }
  const cassiniResponse: CassiniWithTypeSearchResult = await get(`/chartis/v2/layer/${layer}/search/${view}/`,
    requestParams)
  return computeSearchResultsWithType(cassiniResponse)
}
