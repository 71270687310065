import { Layer } from 'react-map-gl'
import { SOURCES_IDS } from '../common'
import TrackLayerProps from './TrackLayerProps'

interface Props {
  sourceLayer: string
  trackFilter?: mapboxgl.Expression
}

const TrackLabelLayer = ({ sourceLayer, trackFilter }: Props) => {
  const { trackNameVS, trackNameVP, lineNumber } = TrackLayerProps

  const filterVP = trackFilter ? ['all', trackNameVP.filter, trackFilter] : trackNameVP.filter
  const filterVS = trackFilter ? ['all', trackNameVS.filter, trackFilter] : trackNameVS.filter

  return (
    <>
      <Layer
        {...trackNameVP}
        source={SOURCES_IDS.track}
        source-layer={sourceLayer}
        filter={filterVP}
      />
      <Layer
        {...trackNameVS}
        source={SOURCES_IDS.track}
        source-layer={sourceLayer}
        filter={filterVS}
      />
      <Layer
        {...lineNumber}
        source={SOURCES_IDS.track}
        source-layer={sourceLayer}
        {...(trackFilter ? { filter: trackFilter } : {})}
      />
    </>
  )
}

TrackLabelLayer.defaultProps = {
  trackFilter: undefined,
}

export default TrackLabelLayer
