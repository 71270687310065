import {
  CHANTIER_LAYERS_KEYS,
  DexCartoObjectLayerKey, DexcartoLayerKey, LAYERS_KEYS, OBJECTS_LAYERS,
  POSTES_LAYER_KEYS, SIGNAL_LAYERS_KEYS,
} from 'components/Layers/common'

export enum SearchLayers {
  zap = 'zap', // need filter modifiable
  zapAmendees = 'zapAmendees', // need filter modifiable
  bal = 'bal',
  ihm = 'ihm', // need filter modifiable
  ihmAmendees = 'ihmAmendees', // need filter modifiable
  signal = 'signal', // need filter date and type
  adv = 'adv', // need filter date
  cdv = 'cdv', // need filter date
  jdz = 'jdz', // need filter date
  dbc = 'dbc', // need filter date
  localisateur = 'localisateur', // need filter date
  passageNiveau = 'passageNiveau', // need filter date
  pedale = 'pedale', // need filter date
  bif = 'bif', // need filter date and code_ch
  rac = 'rac', // need filter type_ligne
  station = 'station', // need filter date
  acces = 'acces', // need filter date
  css = 'css', // need filter date
  poste = 'poste', // need filter date and type
  center = 'center',
  centerArmen = 'centerArmen',
  sousStation = 'sousStation', // need filter date
  chantier = 'chantier',
}

export const LAYERS_SOURCES_MAP: {
  [key in SearchLayers]: readonly DexcartoLayerKey[];
} = {
  [SearchLayers.zap]: [LAYERS_KEYS.zoneActionPoste],
  [SearchLayers.zapAmendees]: [LAYERS_KEYS.zoneActionPosteAmendee],
  [SearchLayers.bal]: [LAYERS_KEYS.balZone],
  [SearchLayers.ihm]: [LAYERS_KEYS.ihmZone],
  [SearchLayers.ihmAmendees]: [LAYERS_KEYS.ihmZoneAmendee],
  [SearchLayers.signal]: SIGNAL_LAYERS_KEYS,
  [SearchLayers.adv]: [LAYERS_KEYS.adv],
  [SearchLayers.cdv]: [LAYERS_KEYS.cdv],
  [SearchLayers.jdz]: [LAYERS_KEYS.jdz],
  [SearchLayers.dbc]: [LAYERS_KEYS.dbc],
  [SearchLayers.localisateur]: [LAYERS_KEYS.localisateur],
  [SearchLayers.passageNiveau]: [LAYERS_KEYS.passageNiveau],
  [SearchLayers.pedale]: [LAYERS_KEYS.pedale],
  [SearchLayers.bif]: [LAYERS_KEYS.bf],
  [SearchLayers.rac]: [LAYERS_KEYS.rac],
  [SearchLayers.station]: [LAYERS_KEYS.station],
  [SearchLayers.acces]: [LAYERS_KEYS.acces],
  [SearchLayers.css]: [LAYERS_KEYS.css],
  [SearchLayers.poste]: Object.values(POSTES_LAYER_KEYS),
  [SearchLayers.center]: [LAYERS_KEYS.center],
  [SearchLayers.centerArmen]: [LAYERS_KEYS.centerArmen],
  [SearchLayers.sousStation]: [LAYERS_KEYS.sousStation],
  [SearchLayers.chantier]: CHANTIER_LAYERS_KEYS,
}

export const SEARCH_LAYER_SOURCES: {
  [key in SearchLayers]: DexCartoObjectLayerKey;
} = {
  [SearchLayers.zap]: OBJECTS_LAYERS.actionZonePoste,
  [SearchLayers.zapAmendees]: OBJECTS_LAYERS.actionZonePoste,
  [SearchLayers.bal]: OBJECTS_LAYERS.balZone,
  [SearchLayers.ihm]: OBJECTS_LAYERS.ihmZone,
  [SearchLayers.ihmAmendees]: OBJECTS_LAYERS.ihmZone,
  [SearchLayers.signal]: OBJECTS_LAYERS.signal,
  [SearchLayers.adv]: OBJECTS_LAYERS.adv,
  [SearchLayers.cdv]: OBJECTS_LAYERS.cdv,
  [SearchLayers.jdz]: OBJECTS_LAYERS.jdz,
  [SearchLayers.dbc]: OBJECTS_LAYERS.dbc,
  [SearchLayers.localisateur]: OBJECTS_LAYERS.localisateur,
  [SearchLayers.passageNiveau]: OBJECTS_LAYERS.passageNiveau,
  [SearchLayers.pedale]: OBJECTS_LAYERS.pedale,
  [SearchLayers.bif]: OBJECTS_LAYERS.bf,
  [SearchLayers.rac]: OBJECTS_LAYERS.rac,
  [SearchLayers.station]: OBJECTS_LAYERS.station,
  [SearchLayers.acces]: OBJECTS_LAYERS.acces,
  [SearchLayers.css]: OBJECTS_LAYERS.centraleSousStation,
  [SearchLayers.poste]: OBJECTS_LAYERS.poste,
  [SearchLayers.center]: OBJECTS_LAYERS.center,
  [SearchLayers.centerArmen]: OBJECTS_LAYERS.centerArmen,
  [SearchLayers.sousStation]: OBJECTS_LAYERS.sousStation,
  [SearchLayers.chantier]: OBJECTS_LAYERS.chantier,
}
