import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import terms from 'common/terms'
import { FeedbackState } from './types'
import { deletePosteZone, patchPosteZone, postPosteZone } from './zoneEditor/zoneEditor.thunk'
import {
  deleteUserFavoriteObject, deleteUserStudyPerimeter, deleteUserView, postObjectComment, postUserFavoriteObject,
  postUserStudyPerimeter, postUserView,
} from './userFavorite/userFavorite.thunk'

const initialState: FeedbackState = {
  snackbarDisplay: false,
  snackbarMessage: '',
  snackbarSeverity: 'success',
}

export const feedbackSlice = createSlice({
  name: 'feedback',
  initialState,
  reducers: {
    hideSnackbar: () => initialState,
    sendError: (state, action: PayloadAction<string>) => {
      state.snackbarDisplay = true
      state.snackbarMessage = action.payload
      state.snackbarSeverity = 'error'
    },
  },
  extraReducers: builder => {
    builder.addCase(postPosteZone.fulfilled, state => {
      state.snackbarDisplay = true
      state.snackbarMessage = terms.zoneEditor.postZone.success
      state.snackbarSeverity = 'success'
    })
    builder.addCase(postPosteZone.rejected, state => {
      state.snackbarDisplay = true
      state.snackbarMessage = terms.zoneEditor.postZone.error
      state.snackbarSeverity = 'error'
    })
    builder.addCase(deletePosteZone.fulfilled, state => {
      state.snackbarDisplay = true
      state.snackbarMessage = terms.zoneEditor.deleteZone.success
      state.snackbarSeverity = 'success'
    })
    builder.addCase(deletePosteZone.rejected, state => {
      state.snackbarDisplay = true
      state.snackbarMessage = terms.zoneEditor.deleteZone.error
      state.snackbarSeverity = 'error'
    })
    builder.addCase(patchPosteZone.fulfilled, state => {
      state.snackbarDisplay = true
      state.snackbarMessage = terms.zoneEditor.patchZone.success
      state.snackbarSeverity = 'success'
    })
    builder.addCase(patchPosteZone.rejected, state => {
      state.snackbarDisplay = true
      state.snackbarMessage = terms.zoneEditor.patchZone.error
      state.snackbarSeverity = 'error'
    })
    builder.addCase(postUserView.fulfilled, state => {
      state.snackbarDisplay = true
      state.snackbarMessage = terms.CreateUserView.postSuccess
      state.snackbarSeverity = 'success'
    })
    builder.addCase(postUserView.rejected, state => {
      state.snackbarDisplay = true
      state.snackbarMessage = terms.CreateUserView.postError
      state.snackbarSeverity = 'error'
    })
    builder.addCase(deleteUserView.fulfilled, state => {
      state.snackbarDisplay = true
      state.snackbarMessage = terms.CreateUserView.deleteSuccess
      state.snackbarSeverity = 'success'
    })
    builder.addCase(deleteUserView.rejected, state => {
      state.snackbarDisplay = true
      state.snackbarMessage = terms.CreateUserView.deleteError
      state.snackbarSeverity = 'error'
    })
    builder.addCase(postUserFavoriteObject.fulfilled, state => {
      state.snackbarDisplay = true
      state.snackbarMessage = terms.UserFavoriteObject.postSucess
      state.snackbarSeverity = 'success'
    })
    builder.addCase(postUserFavoriteObject.rejected, state => {
      state.snackbarDisplay = true
      state.snackbarMessage = terms.UserFavoriteObject.postError
      state.snackbarSeverity = 'error'
    })
    builder.addCase(deleteUserFavoriteObject.fulfilled, state => {
      state.snackbarDisplay = true
      state.snackbarMessage = terms.UserFavoriteObject.deleteSuccess
      state.snackbarSeverity = 'success'
    })
    builder.addCase(deleteUserFavoriteObject.rejected, state => {
      state.snackbarDisplay = true
      state.snackbarMessage = terms.UserFavoriteObject.deleteError
      state.snackbarSeverity = 'error'
    })
    builder.addCase(postUserStudyPerimeter.fulfilled, state => {
      state.snackbarDisplay = true
      state.snackbarMessage = terms.CreateUserPerimeter.postSuccess
      state.snackbarSeverity = 'success'
    })
    builder.addCase(postUserStudyPerimeter.rejected, state => {
      state.snackbarDisplay = true
      state.snackbarMessage = terms.CreateUserPerimeter.postError
      state.snackbarSeverity = 'error'
    })
    builder.addCase(deleteUserStudyPerimeter.fulfilled, state => {
      state.snackbarDisplay = true
      state.snackbarMessage = terms.CreateUserPerimeter.deleteSuccess
      state.snackbarSeverity = 'success'
    })
    builder.addCase(deleteUserStudyPerimeter.rejected, state => {
      state.snackbarDisplay = true
      state.snackbarMessage = terms.CreateUserPerimeter.deleteError
      state.snackbarSeverity = 'error'
    })
    builder.addCase(postObjectComment.fulfilled, state => {
      state.snackbarDisplay = true
      state.snackbarMessage = terms.ObjectCommentModal.postSuccess
      state.snackbarSeverity = 'success'
    })
    builder.addCase(postObjectComment.rejected, state => {
      state.snackbarDisplay = true
      state.snackbarMessage = terms.ObjectCommentModal.postError
      state.snackbarSeverity = 'error'
    })
  },
})

export const { hideSnackbar, sendError } = feedbackSlice.actions

export default feedbackSlice.reducer
