import { ReactElement } from 'react'
import { Source, Layer } from 'react-map-gl'
import TrackLayerProps from './TrackLayerProps'
import { SOURCES_IDS } from '../common'
import { MapURLParams, useMapURL } from '../hooks'

interface Props {
  sourceLayer: string
  opacity?: number
  lineFilter?: string
  params?: MapURLParams
  trackFilter?: mapboxgl.Expression
}

const TrackLayer = ({
  sourceLayer, opacity, lineFilter, params, trackFilter,
}: Props): ReactElement => {
  const { vp, vs } = TrackLayerProps
  const url = useMapURL(sourceLayer, 'full', SOURCES_IDS.track, params)

  const filterVP = trackFilter ? ['all', vp.filter, trackFilter] : vp.filter
  const filterVS = trackFilter ? ['all', vs.filter, trackFilter] : vs.filter

  return (
    <Source
      id={SOURCES_IDS.track}
      type="vector"
      url={url}
    >
      <Layer
        {...vp}
        paint={{
          ...vp.paint,
          'line-opacity': ['case', ['in', lineFilter, ['get', 'ligne']], 1, opacity],
        }}
        source-layer={sourceLayer}
        filter={filterVP}
      />
      <Layer
        {...vs}
        paint={{
          ...vs.paint,
          'line-opacity': ['case', ['in', lineFilter, ['get', 'ligne']], 1, opacity],
        }}
        source-layer={sourceLayer}
        filter={filterVS}
      />
    </Source>
  )
}

TrackLayer.defaultProps = {
  opacity: 1,
  lineFilter: '',
  params: {},
  trackFilter: undefined,
}

export default TrackLayer
