import { ExpandLess, ExpandMore } from '@mui/icons-material'
import { _useMapControl as useMapControl } from 'react-map-gl'
import './ObjectInfos.scss'
import { CircularProgress, Tooltip } from '@mui/material'
import { mapViewportSignal } from 'views/Map'

interface Props<FeatureType> {
  features: FeatureType[],
  loading: boolean
  visible: boolean
  tooltip: string
  title: string
  getLabel: (feature: FeatureType) => string
  getId: (feature: FeatureType) => string | undefined
  getFeatureTooltip: (feature: FeatureType) => string
  expanded: boolean
  setExpanded: (expanded: boolean) => void
  handleClick: (feature: FeatureType) => () => void
  handleHover: (feature: FeatureType) => () => void
  windowTooltip?: string
  disableFeature?: (feature: FeatureType) => boolean
}

const MIN_ZOOM = 10

function ObjectInfos<T>({
  features, loading, visible, tooltip, title, getLabel, getId, getFeatureTooltip, disableFeature,
  expanded, setExpanded, handleClick, handleHover, windowTooltip,
}: Props<T>) {
  const { containerRef } = useMapControl({
    captureDrag: true, captureClick: true, captureDoubleClick: true, captureScroll: true, capturePointerMove: true,
  })

  const getTitle = () => {
    if (!expanded) return title
    if (loading && features.length === 0) return title
    if (features.length >= 100) return `${title} (100+)`
    return `${title} (${features.length})`
  }

  return (
    <div ref={containerRef}>
      {visible && mapViewportSignal.value.zoom && mapViewportSignal.value.zoom >= MIN_ZOOM && (
        <div className={`objects-infos ${expanded ? 'expanded' : ''}`}>
          <Tooltip title={!expanded && tooltip} placement="right">
            <div className="header">
              <button type="button" onClick={() => setExpanded(!expanded)}>
                {expanded ? <ExpandMore /> : <ExpandLess />}
              </button>
              <h3>{getTitle()}</h3>
              {loading && (
                <CircularProgress size={20} />
              )}
            </div>
          </Tooltip>
          <div className="item-list">
            {features.map(feature => (
              <button
                type="button"
                key={getId(feature)}
                className="item"
                onMouseOverCapture={handleHover(feature)}
                onClick={handleClick(feature)}
                disabled={disableFeature && disableFeature(feature)}
              >
                <Tooltip
                  title={<div style={{ whiteSpace: 'pre-line' }}>{getFeatureTooltip(feature)}</div>}
                  placement="right"
                >
                  <span>
                    {getLabel(feature)}
                  </span>
                </Tooltip>
              </button>
            ))}
          </div>
          {windowTooltip && (
            <span className="window-tooltip">{windowTooltip}</span>
          )}
        </div>
      )}
    </div>
  )
}

ObjectInfos.defaultProps = {
  windowTooltip: undefined,
  disableFeature: undefined,
}

export default ObjectInfos
