export const terms = {
  menu: {
    categories: {
      infra: 'Infrastructures',
      building: 'Bâtiments',
      signals: 'Signalisation',
      posteActionZone: 'Zones d\'action DEX',
      rct: 'Energie',
      borders: 'Frontières métiers',
      chantier: 'Travaux',
      exploitationMode: 'Voies et exploitation',
      collectionStatus: 'Statut des collections DEX',
    },
    layers: {
      chantiers: {
        selectAll: 'Tout sélectionner',
        unselectAll: 'Tout désélectionner',
        chantierFamille: 'Par famille',
        chantierAnnée: 'Par année de travaux',
        chantierBudget: 'Par budget',
        chantierPhase: 'Par phase',
        chantierADV: 'Appareils de voie',
        chantierDev: 'Développement',
        chantierGC: 'Gare & Connexions',
        chantierIFTE: 'Installations fixes de traction électrique',
        chantierMNE: 'Mise aux normes environnementales',
        chantierOA: 'Ouvrages d\'art',
        chantierOGE: 'Opération de Grand Entretien',
        chantierOT: 'Ouvrages en terre',
        chantierPMRPN: 'Personnes à mobilité réduite et passage à niveau',
        chantierSignal: 'Signalisation',
        chantierSurete: 'Sûreté',
        chantierSecurite: 'Sécurité',
        chantierTelecom: 'Télécom',
        chantierVoie: 'Voie',
        chantier2024: '2024',
        chantier2025: '2025',
        chantier2026: '2026',
        chantier2027: '2027',
        chantier2028: '2028',
        chantier2029: '2029',
        chantier2030Plus: '2030 et plus',
        chantier0M: 'Moins de 1M€',
        chantier1M: 'Entre 1M€ et 10M€',
        chantier10M: 'Plus de 10M€',
        chantierPhaseREA: 'REA',
        chantierPhaseTVX: 'TVX',
        chantierPhaseTVXP: 'TVXP',
        chantierPhaseREA_TVXP: 'REA_TVXP',
        chantierPhaseREA_TVX: 'REA_TVX',
        chantierPhaseAPO_REA: 'APO_REA',
        chantierPhaseAPO_TVX: 'APO_TVX',
        chantierPhaseAPO_REA_TVX: 'APO_REA_TVX',
        chantierPhasePRO_REA: 'PRO_REA',
        chantierFilter: 'Filtre par type d\'identifiant',
        chantierASTRE: 'ASTRE',
        chantierSEISM: 'SEISM',
      },
      labelsChantier: {
        chantierASTRE: 'Afficher uniquement les travaux ayant un code ASTRE',
        chantierSEISM: 'Afficher uniquement les travaux ayant un code SEISM',
      },
      signals: {
        names: 'Signaux',
        signalProtection: 'Signal de Protection et Signal de cantonnement',
        signalAA: 'Signaux d\'annonce d\'arrêt',
        signalLDV: 'Signaux de limitation de vitesse',
        signalManoeuvre: 'Signaux de manœuvre',
        signalID: 'Signal indicateur de direction',
        signalDT: 'Signal de départ de train',
        signalGVC: 'Signal de sortie de groupes de voies convergentes',
        signalTGV: 'Signal TGV',
        signalTE: 'Signal de traction électrique',
        signalTP: 'Tableaux et Pancartes à inscriptions diverses',
      },
      station: 'Gares',
      jdz: 'Joints de zone',
      acces: 'Accès',
      sousStation: 'Sous-stations',
      centraleSousStation: 'Centrales sous-stations',
      pr: 'Bâtiments voyageurs',
      localisateur: 'Localisateurs',
      dbc: 'Détecteurs de boîte chaude',
      pedale: 'Pédales',
      passageNiveau: 'Passages à niveau',
      adv: 'Appareils de voie',
      posteActionZone: 'Zones d\'action des postes',
      editablePosteActionZone: 'Zones d\'action des postes (éditables)',
      pri: 'Régions PRI',
      infrapole: 'Infrapôles',
      utm: 'Unités de maintenance',
      zp: 'Zones de production',
      postes: {
        names: 'Postes majeurs',
        categoryInfo: 'Postes VP - informatisé',
        categoryRelais: 'Postes VP - à relais',
        categoryElec: 'Postes VP - électrique',
        categoryMeca: 'Postes VP - mécanique',
        categoryPVS: 'Postes VS (service)',
        categoryOther: 'Postes autres',
        argos: 'ARGOS',
        pai: 'PAI',
        prci: 'PRCI',
        prs: 'PRS',
        prg: 'PRG',
        prmi: 'PRMI',
        pstat: 'P-STAT',
        cc: 'Commandes centralisées',
        plit: 'PLIT',
        peli: 'PELI',
        pelir: 'PELI-R',
        pml: 'PML',
        emu: 'EMU',
        mu45: 'MU45',
        mrsaxby: 'MR-SAXBY',
        mrlevdr: 'MR-LEV-DR',
        mrvignty: 'MR-VIGNTY',
        mrexal: 'MR-EX-AL',
        ssdv: 'SS-DV',
        ssvuvb: 'SS-VUVB',
        pvstri: 'PVS-TRI',
        pvselec: 'PVS-ELEC',
        pvsei: 'PVS-EI',
        pvsci: 'PVS-CI',
        pvsmec: 'PVS-MEC',
        point: 'POINT',
        levier: 'LEVIER',
        p10: 'P-10',
        pipc: 'PIPC',
        autres: 'Autres',
      },
      labelPostes: {
        argos: 'Poste de VP électrique à enclenchement informatique de type ARGOS',
        pai: 'Poste de VP électrique à enclenchement informatique',
        prci: 'Poste de VP électrique à relais à commande informatique',
        prs: 'Poste de VP électrique tout relais à transit souple',
        prg: 'Poste de VP électrique tout relais à cablage géographique',
        prmi: 'Poste de VP électrique tout relais à manettes individuelles',
        pstat: 'Poste de VP électrique statique',
        cc: 'Poste de VP électrique commande centralisée',
        plit: 'Poste de VP électrique à leviers d\'itinéraire',
        peli: 'Poste de VP électrique à leviers individuels unifiés',
        pelir: 'Poste de VP électrique à leviers individuels régionaux ou constructeur',
        pml: 'Poste de VP électrique à manettes libres',
        emu: 'Poste de VP mécanique électromécanique unifié',
        mu45: 'Poste de VP mécanique électromécanique type 45',
        mrsaxby: 'Poste de VP mécanique régional type SAXBY',
        mrlevdr: 'Poste de VP mécanique régional à levier à demi-révolution SAXBY ou POTRON',
        mrvignty: 'Poste de VP mécanique régional type VIGNIER ou TYER',
        mrexal: 'Poste de VP mécanique régional ex-AL',
        ssdv: 'Poste de VP mécanique à serrures S de double voie',
        ssvuvb: 'Poste de VP mécanique à serrures S de voie unique ou voie banalisée',
        pvstri: 'Poste de VS de triage à commande automatique',
        pvselec: 'Poste de VS électrique',
        pvsei: 'Poste de VS à enclenchement informatique',
        pvsci: 'Poste de VS à commande informatique',
        pvsmec: 'Poste de VS mécanique non enclenché',
        point: 'Poste divers point de manoeuvre',
        levier: 'Poste divers levier isolé',
        p10: 'Poste divers de moins de 10 leviers',
        pipc: 'Poste de VP électrique à enclenchement informatique à base de technologie PC',
        autres: 'Autres',
      },
      balZone: 'Zones de tronçons (BAL, etc.)',
      center: 'Centres d\'appareillage (Ouranos)',
      centerArmen: 'Centres d\'appareillage (ARMEN)',
      ihmZone: 'Zones IHM',
      ihmZoneAmendee: 'Zones IHM (éditables)',
      cdv: 'Circuits de voie',
      emUnique: 'Voie unique',
      emBanalisee: 'Voie banalisée',
      emIpcs: 'Installations permanentes de contre sens',
      bf: 'Bifurcations',
      rac: 'Raccordements',
      collectionStatus: {
        study: 'Etudes en cours',
        waiting: 'RC en attente',
        received: 'RC reçus, non conformes',
        notStarted: 'DC réalisables non lancés',
        blocked: 'DC bloqués par un indice',
        notFinished: 'DC en cours',
      },
    },
    editorMenu: {
      selectedPoints: 'points sélectionné(s)',
      noSelection: 'Aucun point sélectionné',
      deleteTooltip: 'Supprimer les points sélectionnés',
    },
  },
  featuresPopup: {
    title: {
      signal: 'Signal',
      station: 'Gare',
      poste: 'Poste',
      jdz: 'Joint de zone',
      acces: 'Accès',
      sousStation: 'Sous-station',
      centraleSousStation: 'Centrale sous-station',
      pr: 'Point remarquable',
      localisateur: 'Localisateur',
      dbc: 'Détecteur de boîte chaude',
      signalLV: 'Signal limitation de vitesse',
      signalAA: 'Signal arrêt annonce',
      pedale: 'Pédale',
      passageNiveau: 'Passage à niveau',
      zone: 'Zone d\'action de poste',
      zoneAmendee: 'Zone d\'action de poste (éditable)',
      adv: 'Appareil de voie',
      chantier: 'Travaux',
      balZone: 'Zone de tronçon',
      center: 'Centre d\'appareillage (Ouranos)',
      centerArmen: 'Centre d\'appareillage (ARMEN)',
      ihmZone: 'Zone IHM',
      ihmZoneAmendee: 'Zone IHM (éditable)',
      cdv: 'Circuit de voie',
      bf: 'Bifurcation',
      rac: 'Raccordement',
    },
    properties: {
      libelle: 'Libellé',
      lrs_ligne: 'Ligne',
      lrs_voie: 'Voie',
      lrs_pk: 'PK',
      postes: 'Postes',
      noData: 'NC',
      collections: {
        nomDossier: 'Nom de collection GEP',
        familles: 'Famille',
        sousFamilles: 'Sous-famille',
        categorie: 'Catégorie',
      },
      gare: 'Gare',
      techno_poste: 'Technologie du poste',
      type: 'Type',
      chantier: {
        key: 'Clé banque travaux',
        label: 'Libellé',
        family: 'Famille',
        program: 'Programme',
        infrapole: 'Infrapôle',
        year: 'Année',
        line: 'Ligne',
        pk: 'PK',
        date: 'Date de mise en service',
        account: 'Num compte GEREMI',
        reference: 'Référence EMERGENCE',
        jv: 'JV de l\'Emergence',
        budget: 'Budget',
        astre: 'Numéro ASTRE',
        seism: 'ID projet SEISM',
        phase: 'Phase',
      },
      center: {
        type: 'Type de centre',
        nomChassis: 'Nom du chassis',
      },
      zap: 'Zones d\'action de poste',
      linkedIHM: 'Zone(s) IHM associée(s)',
      rcData: {
        rcReceived: 'RC reçus, non conformes',
        nombre_dc_bloque: 'DC bloqués par indice',
        nombre_dc_non_commence: 'DC réalisables non lancés',
        nombre_dc_non_termine: 'DC en cours',
        nombre_en_attente_rc: 'RC en attente',
        nombre_etude_en_cours: 'Etudes en cours',
      },
    },
    collections: {
      reference: 'Référence GEP :',
      familly: 'Famille : DEX ',
      index: 'Indice :',
      status: 'Statut :',
      geoRef: 'Référence géographique :',
      jiraId: 'Jira ID :',
      ficheKey: 'Clé :',
      ficheSummary: 'Résumé :',
      ficheStatus: 'Statut :',
      lines: 'Lignes :',
    },
    addFavorite: 'Ajouter aux favoris',
    removeFavorite: 'Retirer des favoris',
    flag: 'Signaler une Erreur/Faire un commentaire',
    modification: 'Modification',
    modifiedField: 'Champ modifié',
    oldValue: 'Ancienne valeur',
    newValue: 'Nouvelle valeur',
    geometry: 'Géométrie',
    neighbouringZones: 'Zones encadrantes',
  },
  zoneEditor: {
    createZone: 'Créer une nouvelle zone',
    zone: 'Zone d\'action',
    poste: 'Poste',
    ihm: 'IHM',
    define: 'Définir un nouveau',
    perimeter: 'Périmètre d\'étude',
    validate: 'J\'ai terminé',
    cancel: 'Je quitte',
    drawPolygon: 'Veuillez dessiner le polygone de votre zone d’action',
    hintDrawPolygon: 'Cliquez sur un point adjacent pour créer le polygone',
    hintEditPolygon: 'Sélectionnez le polygone pour le modifier',
    selectPoste: 'Veuillez sélectionner un poste',
    hintSelectPoste: 'Cliquez sur un poste pour voir ses informations',
    patchPolygon: 'Veuillez modifier le polygone de votre zone d’action',
    hintPatchPolygon: 'Cliquez sur un côté pour y rajouter un point',
    editZAPProperties: 'Choisissez un nouveau poste associé à cette zone',
    hintEditZAPProperties: 'Cliquez sur un poste pour le sélectionner',
    createPerimeter: 'Veuillez dessiner la délimitation de nouveau périmètre',
    editPerimeter: 'Sélectionner la délimitation du périmètre pour la modifier',
    postePopup: {
      fields: {
        id: 'ID Gaia',
        libelle: 'Libellé du poste',
        ligne: 'Numéro de ligne(s)',
        pk: 'Point(s) kilométrique(s)',
        region: 'Nom de région SNCF',
        gare: 'Gare',
        installationType: 'Type de poste',
        modeType: 'Mode de fonctionnement',
      },
      title: 'Informations du poste',
      noSelection: 'Aucun poste sélectionné',
    },
    postZone: {
      success: 'Zone d\'action de poste créée avec succès',
      error: 'Une erreur est survenue lors de la création de la zone d\'action de poste',
    },
    deleteZone: {
      success: 'Zone d\'action de poste supprimée avec succès',
      error: 'Une erreur est survenue lors de la suppression de la zone d\'action de poste',
    },
    patchZone: {
      success: 'Zone d\'action de poste modifiée avec succès',
      error: 'Une erreur est survenue lors de la modification de la zone d\'action de poste',
      fields: {
        label: 'Libellé du poste',
        region: 'Nom de région SNCF',
        gare: 'Gare',
        ligne: 'Numéro de ligne(s)',
        pk: 'Point(s) kilométrique(s)',
        installationType: 'Type de poste',
        modeType: 'Mode de fonctionnement',
      },
      title: 'Modifier les informations du poste',
      validate: 'Valider',
    },
  },
  confirmationModal: {
    confirmation: 'Confirmation',
    confirm: 'Confirmer',
    cancel: 'Annuler',
  },
  contextMenu: {
    editProperties: 'Modifier les attributs',
    editGeometry: 'Modifier la délimitation',
    delete: 'Supprimer',
    deleteConfirmation: 'Êtes-vous sûr de vouloir supprimer cet objet ?',
  },
  bordersInfos: {
    zp: 'Zone(s) de production:',
    infrapole: 'Infrapôle(s):',
    pri: 'Région(s) PRI:',
  },
  objectsInfos: {
    postes: 'Poste(s)',
    chantiers: 'Travaux',
    balZone: 'Zones de tronçons',
    unknownStation: 'Gare inconnue',
    unknownPoste: 'Poste inconnu',
    posteTooltip: '(*) : Postes sans zone d’action ',
  },
  objectSearch: {
    placeholder: 'Rechercher un objet...',
    noResult: 'Aucun résultat',
    atLeast: 'Au moins',
    moreResults: 'résultats supplémentaires',
    error: 'Une erreur est survenue lors de la recherche',
    tooManyLayers: 'Trop de couches sélectionnées',
    selectLayer: 'Veuillez sélectionner une couche',
    hint: 'Les modes d\'exploitation et les frontières ne sont pas disponibles dans la recherche',
    searchInfos: 'La zone de recherche correspond à la zone affichée sur l\'écran',
    layers: {
      cassini_v2_gaia_signal: 'Signaux',
      dexcarto_gaia_poste: 'Postes',
      cassini_v2_gaia_gare: 'Gares',
      cassini_v2_gaia_joint_de_zone: 'Joints de zone',
      cassini_v2_gaia_appareil_de_voie: 'Appareils de voie',
      cassini_v2_gaia_acces: 'Accès',
      cassini_v2_gaia_sous_station: 'Sous-stations',
      cassini_v2_gaia_central_sous_station: 'Centrales sous-stations',
      cassini_v2_gaia_point_remarquable: 'Bifurcations',
      cassini_v2_gaia_localisateur: 'Localisateurs',
      cassini_v2_gaia_detecteur_boite_chaude: 'Détecteurs de boîtes chaudes',
      cassini_v2_gaia_signal_limitation_de_vitesse: 'Signaux de limitation de vitesse',
      cassini_v2_gaia_signal_arret_annonce: 'Signaux d\'annonce d\'arrêt',
      cassini_v2_gaia_pedale: 'Pédales',
      cassini_v2_gaia_passage_a_niveau: 'Passages à niveau',
      dexcarto_chantier: 'Travaux',
      cassini_v2_gaia_circuit_de_voie: 'Circuits de voie',
      zap: 'Zones d\'action de poste',
      zapAmendees: 'Zones d\'action de poste (éditables)',
      dexcarto_zone_bal: 'Zones de tronçons',
      ihm: 'Zones IHM',
      ihmAmendees: 'Zones IHM (éditables)',
      dexcarto_centre_technique: 'Centres d\'appareillage (Ouranos)',
      dexcarto_centre_technique_armen: 'Centres d\'appareillage (ARMEN)',
      unknown: 'Source Inconnue',
    },
    lineSearch: {
      filterLine: 'Filtrer sur une ligne',
      loading: 'Recherche en cours...',
      noResult: 'Aucune ligne trouvée',
      tooSmall: 'Veuillez entrer au moins 3 caractères',
      line: 'Ligne',
      pkFrom: 'PK',
      pkTo: 'à PK',
    },
  },
  tooltips: {
    zoomOut: 'Revenir à l\'échelle nationale',
    zoomOutPerimeter: 'Revenir à l\'échelle du périmètre',
    resetNorth: 'Réinitialiser l\'orientation de la carte vers le nord',
    searchObject: 'Rechercher un objet affiché sur la carte',
    posteList: 'Dérouler pour visualiser les postes présents sur l\'écran de visualisation',
    chantierList: 'Dérouler pour visualiser les travaux présents sur l\'écran de visualisation',
    balZoneList: 'Dérouler pour visualiser les zones de tronçons présentes sur l\'écran de visualisation',
    menuLayers: 'Couches d\'infrastructure',
    menuChantier: 'Opérations travaux',
    menuViewmode: 'Changer de vue',
    menuHistory: 'Historique des modifications',
    menuPerimeterView: 'Sélectionner les vues',
    createView: 'Sauvegarder la vue',
    syncViews: 'Synchroniser avec la vue opposée',
  },
  feedback: {
    noZap1: 'Le poste',
    noZap2: 'n\'a pas de zone d\'action associée',
  },
  dateRangeFilter: {
    cancel: 'Annuler',
    validate: 'Valider',
    clear: 'Effacer',
  },
  EditorPosteSelection: {
    title: 'Sélectionner un poste',
    searchPlaceholder: 'Filtrer les postes',
    noMatch: 'Aucun poste trouvé',
  },
  CreateUserView: {
    title: 'Sauvegarder la vue',
    placeholder: 'Nom de la vue',
    save: 'Sauvegarder',
    cancel: 'Abandonner',
    postSuccess: 'La vue a été sauvegardée avec succès. Vous pouvez retrouvez vos vues dans le menu en haut à droite',
    postError: 'Une erreur est survenue lors de la sauvegarde de la vue',
    deleteSuccess: 'La vue a été supprimée avec succès',
    deleteError: 'Une erreur est survenue lors de la suppression de la vue',
  },
  CreateUserPerimeter: {
    title: 'Créer un périmètre d\'étude',
    addedTitle: 'Périmètre ajouté',
    placeholder: 'Nom du périmètre',
    save: 'Sauvegarder',
    close: 'Fermer',
    viewPerimeter: 'Voir le périmètre',
    cancel: 'Annuler',
    postSuccess: 'Le périmètre a été sauvegardé avec succès',
    postError: 'Une erreur est survenue lors de la sauvegarde du périmètre',
    deleteSuccess: 'Le périmètre a été supprimé avec succès',
    deleteError: 'Une erreur est survenue lors de la suppression du périmètre',
    line: 'Ligne',
    track: 'Voie',
    pk: 'PK',
  },
  FavoriteModal: {
    favorites: 'Favoris',
    noFavoriteObject: 'Aucun objet favori',
    noFavoriteView: 'Aucune vue favorite',
    noPerimeters: 'Aucun périmètre d\'étude',
    layers: 'Couches : ',
    geoView: 'Vue géographique',
    schView: 'Vue schématique',
    see: 'Voir',
    delete: 'Supprimer',
  },
  UserFavoriteObject: {
    postSucess: 'L\'objet a été ajouté aux favoris avec succès',
    postError: 'Une erreur est survenue lors de l\'ajout de l\'objet aux favoris',
    deleteSuccess: 'L\'objet a été supprimé des favoris avec succès',
    deleteError: 'Une erreur est survenue lors de la suppression de l\'objet des favoris',
  },
  HistoryMenu: {
    title: 'Historique des modifications',
    refresh: 'Rafraîchir',
    back: 'Retour',
    search: 'Rechercher',
    details: 'Détails',
    modification: {
      date: 'Date',
      agentName: 'Nom de l\'agent',
      modifiedObject: 'Objet modifié',
      details: 'Détails',
      goToZone: 'Aller à la zone',
      fieldName: 'Champ',
      oldValue: 'Ancienne valeur',
      newValue: 'Nouvelle valeur',
      hideGeometry: 'Cacher la géométrie',
      showGeometry: 'Voir la géométrie',
    },
    errorGetHistory: 'Une erreur est survenue lors du chargement de l\'historique',
    errorGetDetails: 'Une erreur est survenue lors du chargement des détails de la zone',
    errorShowGeometry: 'Une erreur est survenue lors de l\'affichage de la géométrie',
    errorGoToZone: 'Une erreur est survenue lors du chargement de la zone',
    unknownUser: 'Auteur inconnu',
    noData: 'Aucun objet modifié',
  },
  PerimeterView: {
    exit: 'Quitter le périmètre d\'étude',
    noViewSelected: 'Aucune vue sélectionnée',
    noView: 'Aucune vue disponible',
    loadingPerimeter: 'Chargement du périmètre',
    perimeterNotFound: 'Périmètre introuvable',
    perimeter: 'Périmètre',
    close: 'Fermer',
    info: 'Afficher la ferrolocalisation du périmètre',
    schView: 'Vue schématique',
    geoView: 'Vue géographique',
    tableView: 'Vue tableau des collections',
  },
  CollectionsView: {
    title: 'Toutes les collections',
    quit: 'Quitter',
    searchPlaceholder: 'Rechercher',
    noCollection: 'Aucune collection trouvée',
    page: 'Page',
    loading: 'Chargement des collections',
    name: 'Nom Collection',
    poste: 'Poste',
    type: 'Type',
    technology: 'Technologie',
    station: 'Gare',
    ferroloc: 'Ferrolocalisation',
    adjacentCollections: 'Collections Adjacentes',
    status: 'Synthèse Statut',
    seeDetails: 'Voir fiche',
    back: 'Retour',
    details: {
      loading: 'Chargement des détails de la collection',
      generalInfos: 'Informations générales',
      labelPoste: 'Nom du poste',
      labelType: 'Type',
      labelStation: 'Gare',
      labelTechnology: 'Technologie',
      labelLoc: 'Ferroloc',
      unknown: 'Inconnu',
      borders: 'Frontières',
      labelIHM: 'Zone IHM associée',
      labelCollections: 'Collections encadrantes',
      tableTitle: 'Statuts / Indices',
      indice: 'Indice',
      status: 'Statut',
      work: 'Projet/Travaux',
      fmr: 'FMR',
      fa: 'FA',
      fdmde: 'FDMDE',
      rc: 'RC',
      ficheTitle: 'Fiches',
      ficheType: 'Type',
      ficheStatus: 'Statut',
      ficheKey: 'Clé',
      ficheSummary: 'Résumé',
    },
  },
  ObjectCommentModal: {
    title: 'Signaler une erreur / Faire un commentaire',
    type: 'Type de commentaire',
    content: 'Commentaire additionnel',
    placeholder: 'Entrez votre commentaire',
    postSuccess: 'Le commentaire a été ajouté avec succès',
    postError: 'Une erreur est survenue lors de l\'ajout du commentaire',
    close: 'Fermer',
    validate: 'Valider',
  },
  UserFeedback: {
    suggestionTitle: 'Suggérer une amélioration',
    suggestionType: 'Type d\'amélioration (couleur, donnée, fonctionnalité, autre)',
    suggestionDesc: 'Veuillez décrire votre suggestion ci-dessous :',
    bugTitle: 'Signaler un bug',
    bugType: 'Type de bug (crash, erreur fonctionnelle, problème d\'interface, autre)',
    bugDesc: 'Veuillez décrire le problème rencontré ci-dessous :',
    maxChar: '1500 caractères',
    sendMail: 'Ouvrir votre messagerie',
    DataInformation: {
      // eslint-disable-next-line max-len
      checkLabel: 'Inclure des données sur votre environnement actuel, comme le navigateur et l\'URL de la page. Cela nous permet de mieux comprendre votre commentaire',
      button: 'Quelles informations sont incluses dans les données relatives à mon environnement actuel ?',
      browser: 'Navigateurs installés :',
      os: 'Système d\'exploitation :',
      screenWidth: 'Largeur de l\'écran :',
      screenHeight: 'Hauteur de l\'écran :',
      url: 'URL :',
    },
  },
} as const

export default terms
