import { Delete, Visibility } from '@mui/icons-material'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'store'
import './ViewTab.scss'
import { Tooltip } from '@mui/material'
import { deleteUserView } from 'store/userFavorite/userFavorite.thunk'
import { UserView } from 'store/userFavorite/types'
import { mapViewportSignal } from 'views/Map'
import { setActiveLayers, updateTheme } from 'store/map'
import { closeFavoriteModal } from 'store/userFavorite/userFavorite'
import terms from 'common/terms'
import { MapTheme } from 'components/Toolbar/ThemeMenu/const'
import { getLayerList, getViewTypeLabel } from './utils'

const ViewTab = () => {
  const dispatch = useDispatch()
  const { views } = useSelector((state: RootState) => state.userFavorite)
  const { currentOsmTheme } = useSelector((state: RootState) => state.map)

  const handleDeleteView = (id: number) => () => {
    dispatch(deleteUserView(id))
  }

  const handleSelectView = (view: UserView) => () => {
    mapViewportSignal.value = {
      longitude: view.x,
      latitude: view.y,
      bearing: view.bearing,
      pitch: view.pitch,
      zoom: view.zoom,
      transitionDuration: 0,
    }
    if (view.view && Object.values(MapTheme).includes(view.view) && view.view !== currentOsmTheme) {
      dispatch(updateTheme(view.view))
    }
    dispatch(setActiveLayers(view.displayed_layers))
    dispatch(closeFavoriteModal())
  }

  return (
    <div className="favorite-view-tab">
      {views.map(view => (
        <div key={view.id} className="view-details">
          <div className="infos">
            <strong className="title">{`Vue : ${view.libelle}`}</strong>
            {view.view && (
            <div className="description">
              <strong>
                {getViewTypeLabel(view.view)}
              </strong>
            </div>
            )}
            <div className="description">
              <strong>{terms.FavoriteModal.layers}</strong>
              <span className="layer-list">{getLayerList(view.displayed_layers)}</span>
            </div>
          </div>
          <div className="actions">
            <Tooltip title={terms.FavoriteModal.see}>
              <button type="button" className="view" onClick={handleSelectView(view)}>
                <Visibility />
              </button>
            </Tooltip>
            <Tooltip title={terms.FavoriteModal.delete}>
              <button type="button" className="delete" onClick={handleDeleteView(view.id)}>
                <Delete />
              </button>
            </Tooltip>
          </div>
        </div>
      ))}
      {views.length === 0 && (
        <div className="no-views">
          {terms.FavoriteModal.noFavoriteView}
        </div>
      )}
    </div>
  )
}

export default ViewTab
