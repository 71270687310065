import terms from 'common/terms'
import './BordersInfos.scss'

interface Props {
  zp: string | null
  infra: string | null
  pri: string | null
}

const BordersInfos = ({ zp, infra, pri }: Props) => {
  if (!zp && !infra && !pri) return null
  return (
    <div className="borders-infos">
      {zp && (
        <div className="item">
          <span className="label">{terms.bordersInfos.zp}</span>
          <span className="value" title={zp}>{zp}</span>
        </div>
      )}
      {infra && (
        <div className="item">
          <span className="label">{terms.bordersInfos.infrapole}</span>
          <span className="value" title={infra}>{infra}</span>
        </div>
      )}
      {pri && (
        <div className="item">
          <span className="label">{terms.bordersInfos.pri}</span>
          <span className="value" title={pri}>{pri}</span>
        </div>
      )}
    </div>
  )
}

export default BordersInfos
