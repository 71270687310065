import { MapTheme } from 'components/Toolbar/ThemeMenu/const'
import { Polygon } from 'geojson'
import {
  DexcartoLayerKey, GEOGRAPHIC_VIEWS, OBJECTS_LAYERS, POSTES_LAYER_KEYS,
  SCHEMATIC_VIEWS, SOURCES_IDS,
} from 'components/Layers/common'
import { get } from '@osrdata/app_core/dist/requests'
import { SearchLayers } from './const'
import { PosteSearchResult, SearchParams } from './types'
import { computeSearchResultsWithType, getCassiniParams } from './cassini'

// eslint-disable-next-line import/prefer-default-export
export const getPosteObjects = async (
  activeLayers: DexcartoLayerKey[], layers: SearchLayers[], theme: MapTheme, bbox: Polygon, query: string,
  params: SearchParams,
) => {
  if (!layers.includes(SearchLayers.poste)) return null
  const posteFamilies = Object.entries(POSTES_LAYER_KEYS)
    .filter(([, value]) => activeLayers.includes(value))
    .map(([key]) => key)

  const layer = OBJECTS_LAYERS.poste
  const view = theme === MapTheme.geographic ? GEOGRAPHIC_VIEWS[SOURCES_IDS.poste] : SCHEMATIC_VIEWS[SOURCES_IDS.poste]
  const bboxParam = theme === MapTheme.perimeter ? 'geom_rgi_track_sch_flat_centroid__bpolygon' : 'bbox'
  const cassiniParams = getCassiniParams(params)
  const requestParams = {
    // eslint-disable-next-line max-len
    columns: 'id,libelle,lrs_pk,lrs_voie,lrs_ligne,type_installation_fixe_id_mnemo,geom_rgi_track_sch_flat_centroid,geom_rgi_track_geo_centroid',
    [bboxParam]: bbox,
    size_per_layer: 100,
    libelle__ilike: `%${query.trim()}%`,
    type_installation_fixe_id_mnemo__in: posteFamilies.join(','),
    ...cassiniParams,
  }
  const cassiniResponse: PosteSearchResult = await get(`/chartis/v2/layer/${layer}/search/${view}/`,
    requestParams)
  const formattedResponse = {
    ...cassiniResponse,
    features: cassiniResponse.features.map(feature => ({
      type: feature.type,
      geometry: feature.geometry,
      properties: {
        ...feature.properties,
        geom_rgi_track_sch_flat: feature.properties.geom_rgi_track_sch_flat_centroid,
        geom_rgi_track_geo: feature.properties.geom_rgi_track_geo_centroid,
      },
    })),
  }
  return computeSearchResultsWithType(formattedResponse)
}
