import { MapTheme } from 'components/Toolbar/ThemeMenu/const'
import { get } from '@osrdata/app_core/dist/requests'
import { GEOGRAPHIC_VIEWS, OBJECTS_LAYERS, SCHEMATIC_VIEWS, SOURCES_IDS } from 'components/Layers/common'
import { Polygon } from 'geojson'
import { SearchLayers } from './const'
import { SearchResults, ZapSearchResult } from './types'

// eslint-disable-next-line import/prefer-default-export
export const getZapObjects = async (
  layers: SearchLayers[], theme: MapTheme, bbox: Polygon, query: string,
) => {
  const searchModifiable = layers.includes(SearchLayers.zapAmendees)
  const searchNonModifiable = layers.includes(SearchLayers.zap)

  if (searchModifiable || searchNonModifiable) {
    const view = theme === MapTheme.geographic
      ? GEOGRAPHIC_VIEWS[SOURCES_IDS.actionZone] : SCHEMATIC_VIEWS[SOURCES_IDS.actionZone]
    const bboxParam = theme === MapTheme.perimeter ? 'geom_rgi_track_sch_flat__bpolygon' : 'bbox'
    const params = {
      columns: 'id,poste_libelle,gare_libelle,modifiable,geom_rgi_track_sch_flat,geom_rgi_track_geo',
      [bboxParam]: bbox,
      size_per_layer: 100,
      libelle__ilike: `%${query.trim()}%`,
      ...(searchModifiable && !searchNonModifiable && { modifiable: true }),
      ...(searchNonModifiable && !searchModifiable && { modifiable: false }),
    }
    // eslint-disable-next-line max-len
    const zapResponse: ZapSearchResult = await get(`/chartis/v2/layer/${OBJECTS_LAYERS.actionZonePoste}/search/${view}/`, params)
    return zapResponse.features.reduce((acc: SearchResults, feature) => {
      const layerName = feature.properties.modifiable ? SearchLayers.zapAmendees : SearchLayers.zap
      const layer = acc[layerName] || []
      return {
        ...acc,
        [layerName]: [
          ...layer,
          {
            type: 'zap' as const,
            id: feature.properties.id,
            poste_libelle: feature.properties.poste_libelle,
            gare_libelle: feature.properties.gare_libelle,
            modifiable: feature.properties.modifiable,
            schGeom: feature.properties.geom_rgi_track_sch_flat,
            geoGeom: feature.properties.geom_rgi_track_geo,
          },
        ],
      }
    }, {})
  }
  return null
}
