import { get } from '@osrdata/app_core/dist/requests'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { searchObjects } from 'utils'
import { SearchAllObjectsParams } from 'utils/search/types'
import { GaiaLine } from './types'

const searchAllObjects = createAsyncThunk(
  'get/searchAllObjects',
  async (params: SearchAllObjectsParams, thunkApi) => {
    try {
      const response = await searchObjects(
        params.activeLayers, params.query, params.bbox, params.params, params.theme,
      )
      return response
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const searchLineObjects = createAsyncThunk(
  'get/searchLine',
  async (query: string, thunkApi) => {
    try {
      const response = await get('/chartis/v2/layer/dexcarto_gaia_ligne/json/full', {
        code__ilike: `%${query.trim()}%`,
        page_size: 25,
      })
      return response.results as GaiaLine[]
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

// eslint-disable-next-line import/prefer-default-export
export { searchLineObjects, searchAllObjects }
