import terms from 'common/terms'
import nextId from 'react-id-generator'
import moment from 'moment'
import { HistoryItem } from './types'

interface Props {
  handleGoToZone: (zoneId: number) => () => void
  handleGoToDetails: (item: HistoryItem) => () => void
  items: HistoryItem[]
}

const HistoryTable = ({ handleGoToZone, handleGoToDetails, items }: Props) => (
  <table className="history-table">
    <thead>
      <tr>
        <th>{terms.HistoryMenu.modification.date}</th>
        <th>{terms.HistoryMenu.modification.agentName}</th>
        <th>{terms.HistoryMenu.modification.modifiedObject}</th>
      </tr>
    </thead>
    <tbody>
      {items.map(item => (
        <tr key={nextId()}>
          <td>
            {moment(item.modification_date).format('DD/MM/YYYY')}
          </td>
          <td>{item.user_sub}</td>
          <td>
            <div className="zap-label">
              <span>{item.zap_libelle}</span>
              <div className="actions">
                <button type="button" onClick={handleGoToDetails(item)} className="go-to-details">
                  {terms.HistoryMenu.modification.details}
                </button>
                <button type="button" onClick={handleGoToZone(item.zap)} className="go-to-zone">
                  {terms.HistoryMenu.modification.goToZone}
                </button>
              </div>
            </div>
          </td>
        </tr>
      ))}
      {items.length === 0 && (
        <tr>
          <td colSpan={3}>
            <span className="no-data">{terms.HistoryMenu.noData}</span>
          </td>
        </tr>
      )}
    </tbody>
  </table>
)

export default HistoryTable
