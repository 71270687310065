const LAYERS_KEYS = {
  acces: 'acces',
  adv: 'adv',
  css: 'css',
  dbc: 'dbc',
  jdz: 'jdz',
  localisateur: 'localisateur',
  passageNiveau: 'passageNiveau',
  pedale: 'pedale',
  argosPoste: 'poste-argos',
  paiPoste: 'poste-pai',
  prciPoste: 'poste-prci',
  prsPoste: 'poste-prs',
  prgPoste: 'poste-prg',
  prmiPoste: 'poste-prmi',
  ccPoste: 'poste-cc',
  plitPoste: 'poste-plit',
  peliPoste: 'poste-peli',
  pelirPoste: 'poste-pelir',
  pmlPoste: 'poste-pml',
  emuPoste: 'poste-emu',
  mu45Poste: 'poste-mu45',
  mrsaxbyPoste: 'poste-mrsaxby',
  mrlevdrPoste: 'poste-mrlevdr',
  mrvigntyPoste: 'poste-mrvignty',
  mrexalPoste: 'poste-mrexal',
  ssdvPoste: 'poste-ssdv',
  ssvuvbPoste: 'poste-ssvuvb',
  pvstriPoste: 'poste-pvstri',
  pvselecPoste: 'poste-pvselec',
  pvseiPoste: 'poste-pvsei',
  pvsciPoste: 'poste-pvsci',
  pvsmecPoste: 'poste-pvsmec',
  pointPoste: 'poste-point',
  levierPoste: 'poste-levier',
  p10Poste: 'poste-p10',
  pipcPoste: 'poste-pipc',
  autresPoste: 'poste-autres',
  signalProtection: 'signalProtection',
  signalAA: 'signalArretAnnonce',
  signalLDV: 'signalLimitationDeVitesse',
  signalManoeuvre: 'signalManoeuvre',
  signalID: 'signalIndicateurDirection',
  signalDT: 'signalDepartTrain',
  signalGVC: 'signalSortieGroupesVoiesConvergentes',
  signalTGV: 'signalTGV',
  signalTE: 'signalTractionElectrique',
  signalTP: 'signalTableauPancarte',
  station: 'station',
  sousStation: 'sousStation',
  track: 'track',
  zoneActionPoste: 'zoneActionPoste',
  zoneActionPosteAmendee: 'zoneActionPosteAmendee',
  pri: 'pri',
  infrapole: 'infrapole',
  utm: 'utm',
  zp: 'zp',
  chantier2024: 'chantier-2024',
  chantier2025: 'chantier-2025',
  chantier2026: 'chantier-2026',
  chantier2027: 'chantier-2027',
  chantier2028: 'chantier-2028',
  chantier2029: 'chantier-2029',
  chantier2030Plus: 'chantier-2030-plus',
  chantierPhaseREA: 'chantier-phase-rea',
  chantierPhaseTVX: 'chantier-phase-tvx',
  chantierPhaseTVXP: 'chantier-phase-tvxp',
  chantierPhaseREA_TVXP: 'chantier-phase-rea-tvxp',
  chantierPhaseREA_TVX: 'chantier-phase-rea-tvx',
  chantierPhaseAPO_REA: 'chantier-phase-apo-rea',
  chantierPhaseAPO_TVX: 'chantier-phase-apo-tvx',
  chantierPhaseAPO_REA_TVX: 'chantier-phase-apo-rea-tvx',
  chantierPhasePRO_REA: 'chantier-phase-pro-rea',
  chantierASTRE: 'chantier-astre',
  chantierSEISM: 'chantier-seism',
  balZone: 'bal-zone',
  center: 'center',
  centerArmen: 'center-armen',
  ihmZone: 'ihm-zone',
  ihmZoneAmendee: 'ihm-zone-amendee',
  cdv: 'cdv',
  emUnique: 'exploitation-mode-unique',
  emBanalisee: 'exploitation-mode-banalisee',
  emIPCS: 'exploitation-mode-ipcs',
  bf: 'bf',
  rac: 'rac',
  collectionStatusStudy: 'collection-status-study',
  collectionStatusWaiting: 'collection-status-waiting',
  collectionStatusNotStarted: 'collection-status-not-started',
  collectionStatusBlocked: 'collection-status-blocked',
  collectionStatusNotDone: 'collection-status-done',
}

export const SCH_LAYER_KEYS = {
  ...LAYERS_KEYS,
}

export const GEO_LAYER_KEYS = {
  acces: 'acces',
  adv: 'adv',
  css: 'css',
  dbc: 'dbc',
  jdz: 'jdz',
  localisateur: 'localisateur',
  passageNiveau: 'passageNiveau',
  pedale: 'pedale',
  signalProtection: 'signalProtection',
  signalAA: 'signalArretAnnonce',
  signalLDV: 'signalLimitationDeVitesse',
  signalManoeuvre: 'signalManoeuvre',
  signalID: 'signalIndicateurDirection',
  signalDT: 'signalDepartTrain',
  signalGVC: 'signalSortieGroupesVoiesConvergentes',
  signalTGV: 'signalTGV',
  signalTE: 'signalTractionElectrique',
  signalTP: 'signalTableauPancarte',
  station: 'station',
  sousStation: 'sousStation',
  track: 'track',
  zoneActionPoste: 'zoneActionPoste',
  zoneActionPosteAmendee: 'zoneActionPosteAmendee',
  cdv: 'cdv',
  emUnique: 'exploitation-mode-unique',
  emBanalisee: 'exploitation-mode-banalisee',
  emIPCS: 'exploitation-mode-ipcs',
  bf: 'bf',
  rac: 'rac',
  argosPoste: 'poste-argos',
  paiPoste: 'poste-pai',
  prciPoste: 'poste-prci',
  prsPoste: 'poste-prs',
  prgPoste: 'poste-prg',
  prmiPoste: 'poste-prmi',
  ccPoste: 'poste-cc',
  plitPoste: 'poste-plit',
  peliPoste: 'poste-peli',
  pelirPoste: 'poste-pelir',
  pmlPoste: 'poste-pml',
  emuPoste: 'poste-emu',
  mu45Poste: 'poste-mu45',
  mrsaxbyPoste: 'poste-mrsaxby',
  mrlevdrPoste: 'poste-mrlevdr',
  mrvigntyPoste: 'poste-mrvignty',
  mrexalPoste: 'poste-mrexal',
  ssdvPoste: 'poste-ssdv',
  ssvuvbPoste: 'poste-ssvuvb',
  pvstriPoste: 'poste-pvstri',
  pvselecPoste: 'poste-pvselec',
  pvseiPoste: 'poste-pvsei',
  pvsciPoste: 'poste-pvsci',
  pvsmecPoste: 'poste-pvsmec',
  pointPoste: 'poste-point',
  levierPoste: 'poste-levier',
  p10Poste: 'poste-p10',
  pipcPoste: 'poste-pipc',
  autresPoste: 'poste-autres',
  chantierADV: 'chantier-adv',
  chantierDev: 'chantier-dev',
  chantierGC: 'chantier-gc',
  chantierIFTE: 'chantier-ifte',
  chantierMNE: 'chantier-mne',
  chantierOA: 'chantier-oa',
  chantierOGE: 'chantier-oge',
  chantierOT: 'chantier-ot',
  chantierPMRPN: 'chantier-pmrpn',
  chantierSignal: 'chantier-signal',
  chantierSurete: 'chantier-surete',
  chantierSecurite: 'chantier-securite',
  chantierTelecom: 'chantier-telecom',
  chantierVoie: 'chantier-voie',
  chantier2024: 'chantier-2024',
  chantier2025: 'chantier-2025',
  chantier2026: 'chantier-2026',
  chantier2027: 'chantier-2027',
  chantier2028: 'chantier-2028',
  chantier2029: 'chantier-2029',
  chantier2030Plus: 'chantier-2030-plus',
  chantier0M: 'chantier-0m',
  chantier1M: 'chantier-1m',
  chantier10M: 'chantier-10m',
  chantierPhaseEP: 'chantier-phase-ep',
  chantierPhaseAVP: 'chantier-phase-avp',
  chantierPhaseAPO: 'chantier-phase-apo',
  chantierPhasePRO: 'chantier-phase-pro',
  chantierPhaseDCE: 'chantier-phase-dce',
  chantierPhaseREA: 'chantier-phase-rea',
  chantierPhaseOther: 'chantier-phase-other',
  balZone: 'bal-zone',
  center: 'center',
  ihmZone: 'ihm-zone',
  ihmZoneAmendee: 'ihm-zone-amendee',
  collectionStatusStudy: 'collection-status-study',
  collectionStatusWaiting: 'collection-status-waiting',
  collectionStatusNotStarted: 'collection-status-not-started',
  collectionStatusBlocked: 'collection-status-blocked',
  collectionStatusNotDone: 'collection-status-done',
}

export default LAYERS_KEYS
