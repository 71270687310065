import { Layer, Source } from 'react-map-gl'
import { useEffect, useState } from 'react'
import { LAYERS_IDS, LAYERS_KEYS, OBJECTS_LAYERS, SOURCES_IDS } from '../common'
import { MapURLParams, useMapURL } from '../hooks'
import { EM_BANALISEE_IDS, EM_IPCS_IDS, EM_UNIQUE_IDS, EXPLOITATION_MODES } from './utils'

interface Props {
  activeLayers: string[]
  view: string
  params?: MapURLParams
}

const ExploitationModeLayer = ({ activeLayers, params, view }:Props) => {
  const url = useMapURL(
    OBJECTS_LAYERS.exploitationMode,
    view,
    SOURCES_IDS.exploitationMode,
    { ...params, valeur__in: EXPLOITATION_MODES.join(',') },
  )
  const [displayUnique, setDisplayUnique] = useState(false)
  const [displayBanalisee, setDisplayBanalisee] = useState(false)
  const [displayIPCS, setDisplayIPCS] = useState(false)
  const [filter, setFilter] = useState<string[]>([])

  useEffect(() => {
    const unique = activeLayers.includes(LAYERS_KEYS.emUnique)
    const banalisee = activeLayers.includes(LAYERS_KEYS.emBanalisee)
    const ipcs = activeLayers.includes(LAYERS_KEYS.emIPCS)
    setDisplayUnique(unique)
    setDisplayBanalisee(banalisee)
    setDisplayIPCS(ipcs)
    setFilter([
      ...(unique ? [...EM_UNIQUE_IDS] : []),
      ...(banalisee ? [...EM_BANALISEE_IDS] : []),
      ...(ipcs ? [...EM_IPCS_IDS] : []),
    ])
  }, [activeLayers])

  return (
    <Source
      id={SOURCES_IDS.exploitationMode}
      type="vector"
      url={url}
    >
      <Layer
        id={LAYERS_IDS.exploitationMode}
        type="symbol"
        source-layer={OBJECTS_LAYERS.exploitationMode}
        paint={{}}
        layout={{
          'icon-image': ['case',
            ['in', ['get', 'valeur'], ['literal', EM_UNIQUE_IDS]], 'voie_unique_no_line',
            ['in', ['get', 'valeur'], ['literal', EM_BANALISEE_IDS]], 'voie_banalisee_no_line',
            ['in', ['get', 'valeur'], ['literal', EM_IPCS_IDS]], 'ipcs_no_line',
            ''],
          'icon-size': 0.8,
          'symbol-placement': 'line-center',
          'icon-allow-overlap': true,
          'symbol-spacing': 500,
          visibility: (displayUnique || displayBanalisee || displayIPCS) ? 'visible' : 'none',
        }}
        filter={['in', ['get', 'valeur'], ['literal', filter]]}
        minzoom={12}
      />
    </Source>
  )
}

ExploitationModeLayer.defaultProps = {
  params: {},
}

export default ExploitationModeLayer
