import { RefObject } from 'react'
import { Layer, Source, MapRef } from 'react-map-gl'
import { mapViewportSignal } from 'views/Map'
import { LAYERS_IDS, LAYERS_KEYS, SOURCES_IDS } from '../common'
import { getDistinctUTMCentroid } from './utils'
import { useCalculateLabelPositions } from './hooks'

interface Props {
  visible: boolean
  mapRef: RefObject<MapRef | undefined>
  principalLayer: string | null
}

const UTMLabelLayer = ({ visible, mapRef, principalLayer }: Props) => {
  const labelPositions = useCalculateLabelPositions(
    mapRef, visible, LAYERS_IDS.utm, getDistinctUTMCentroid, mapViewportSignal.value, 8,
  )
  return (
    <Source
      id={SOURCES_IDS.utmLabel}
      type="geojson"
      data={labelPositions}
    >
      <Layer
        type="symbol"
        id={LAYERS_IDS.utmLabel}
        source={SOURCES_IDS.utmLabel}
        layout={{
          'text-font': ['Open Sans Regular'],
          'text-field': '{structure_libelle}',
          'text-size': 14,
          'text-variable-anchor': ['top', 'bottom', 'left', 'right'],
          'text-offset': [0, 1],
          visibility: visible ? 'visible' : 'none',
        }}
        paint={{
          'text-color': ['get', 'color'],
        }}
        minzoom={8}
        maxzoom={principalLayer === LAYERS_KEYS.utm ? 24 : 12}
      />
    </Source>
  )
}

export default UTMLabelLayer
