import { CircularProgress } from '@mui/material'
import { get } from '@osrdata/app_core/dist/requests'
import { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import terms from 'common/terms'
import { UserStudyPerimeterDetailsWithId } from 'store/userFavorite/types'
import { useDispatch } from 'react-redux'
import { getObjectComments, getUserFavoriteObjects } from 'store/userFavorite/userFavorite.thunk'
import PerimeterView from './PerimeterView/PerimeterView'
import './index.scss'

const Perimeter = () => {
  const dispatch = useDispatch()
  const params = useParams<{id: string}>()
  const history = useHistory()
  const [loadingPerimeter, setLoadingPerimeter] = useState(true)
  const [validPerimeter, setValidPerimeter] = useState<UserStudyPerimeterDetailsWithId | null>(null)
  const [perimeterId, setPerimeterId] = useState<string | undefined>(undefined)

  useEffect(() => {
    dispatch(getUserFavoriteObjects())
    dispatch(getObjectComments())
  }, [])

  useEffect(() => {
    const { id } = params
    if (!id) {
      history.push('/')
    }
    if (perimeterId === id) return
    setPerimeterId(id)
    get(`/dexcarto/user-study-perimeter/${id}/`)
      .then(response => {
        setLoadingPerimeter(false)
        setValidPerimeter(response)
      })
      .catch(() => {
        setLoadingPerimeter(false)
        setValidPerimeter(null)
      })
  }, [params])
  return (
    <div className="perimeter-wrapper">
      {loadingPerimeter && (
        <div className="perimeter-loader">
          <CircularProgress />
          <span>{terms.PerimeterView.loadingPerimeter}</span>
        </div>
      )}
      {!loadingPerimeter && !validPerimeter && (
        <div className="perimeter-error">
          <span>{terms.PerimeterView.perimeterNotFound}</span>
          <button type="button" onClick={() => history.push('/')}>Retour à la carte</button>
        </div>
      )}
      {!loadingPerimeter && validPerimeter && (
        <PerimeterView activePerimeter={validPerimeter} />
      )}
    </div>
  )
}

export default Perimeter
