import { Checkbox, FormControlLabel, Typography } from '@mui/material'
import terms from 'common/terms'
import { perimeterActiveViewsSignal } from 'views/Perimeter/PerimeterView/PerimeterView'

const PerimeterViewMenu = () => {
  const handleClickSch = () => {
    perimeterActiveViewsSignal.value = {
      ...perimeterActiveViewsSignal.value,
      sch: !perimeterActiveViewsSignal.value.sch,
    }
  }

  const handleClickGeo = () => {
    perimeterActiveViewsSignal.value = {
      ...perimeterActiveViewsSignal.value,
      geo: !perimeterActiveViewsSignal.value.geo,
    }
  }

  const handleClickTable = () => {
    perimeterActiveViewsSignal.value = {
      ...perimeterActiveViewsSignal.value,
      table: !perimeterActiveViewsSignal.value.table,
    }
  }

  return (
    <div className="perimeter-view-menu">
      <FormControlLabel
        label={(
          <Typography>
            {terms.PerimeterView.schView}
          </Typography>
        )}
        sx={{ '&.Mui-disabled': { color: 'rgba(0, 0, 0, 0.26)' } }}
        disabled={perimeterActiveViewsSignal.value.geo && perimeterActiveViewsSignal.value.table}
        control={(
          <Checkbox
            checked={perimeterActiveViewsSignal.value.sch}
            onClick={handleClickSch}
          />
        )}
      />
      <FormControlLabel
        label={(
          <Typography>
            {terms.PerimeterView.geoView}
          </Typography>
        )}
        sx={{ '&.Mui-disabled': { color: 'rgba(0, 0, 0, 0.26)' } }}
        disabled={perimeterActiveViewsSignal.value.sch && perimeterActiveViewsSignal.value.table}
        control={(
          <Checkbox
            checked={perimeterActiveViewsSignal.value.geo}
            onClick={handleClickGeo}
          />
        )}
      />
      <FormControlLabel
        label={(
          <Typography>
            {terms.PerimeterView.tableView}
          </Typography>
        )}
        sx={{ '&.Mui-disabled': { color: 'rgba(0, 0, 0, 0.26)' } }}
        disabled={perimeterActiveViewsSignal.value.sch && perimeterActiveViewsSignal.value.geo}
        control={(
          <Checkbox
            checked={perimeterActiveViewsSignal.value.table}
            onClick={handleClickTable}
          />
        )}
      />
    </div>
  )
}

export default PerimeterViewMenu
