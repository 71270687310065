import terms from 'common/terms'
import { LAYERS_KEYS } from 'components/Layers/common'
import { HideableLayers } from '../types'
import { MapTheme } from '../ThemeMenu/const'

const CHANTIER_LAYERS: HideableLayers[] = [
  {
    category: terms.menu.categories.chantier,
    expanded: true,
    layers: [
      {
        subcategory: true,
        expanded: true,
        name: terms.menu.layers.chantiers.chantierAnnée,
        themes: [MapTheme.schematic, MapTheme.geographic, MapTheme.perimeter],
        layers: [
          {
            subcategory: false,
            name: terms.menu.layers.chantiers.chantier2024,
            layer: LAYERS_KEYS.chantier2024,
            themes: [MapTheme.schematic, MapTheme.geographic, MapTheme.perimeter],
          },
          {
            subcategory: false,
            name: terms.menu.layers.chantiers.chantier2025,
            layer: LAYERS_KEYS.chantier2025,
            themes: [MapTheme.schematic, MapTheme.geographic, MapTheme.perimeter],
          },
          {
            subcategory: false,
            name: terms.menu.layers.chantiers.chantier2026,
            layer: LAYERS_KEYS.chantier2026,
            themes: [MapTheme.schematic, MapTheme.geographic, MapTheme.perimeter],
          },
          {
            subcategory: false,
            name: terms.menu.layers.chantiers.chantier2027,
            layer: LAYERS_KEYS.chantier2027,
            themes: [MapTheme.schematic, MapTheme.geographic, MapTheme.perimeter],
          },
          {
            subcategory: false,
            name: terms.menu.layers.chantiers.chantier2028,
            layer: LAYERS_KEYS.chantier2028,
            themes: [MapTheme.schematic, MapTheme.geographic, MapTheme.perimeter],
          },
          {
            subcategory: false,
            name: terms.menu.layers.chantiers.chantier2029,
            layer: LAYERS_KEYS.chantier2029,
            themes: [MapTheme.schematic, MapTheme.geographic, MapTheme.perimeter],
          },
          {
            subcategory: false,
            name: terms.menu.layers.chantiers.chantier2030Plus,
            layer: LAYERS_KEYS.chantier2030Plus,
            themes: [MapTheme.schematic, MapTheme.geographic, MapTheme.perimeter],
          },
        ],
      },
      {
        subcategory: true,
        expanded: true,
        name: terms.menu.layers.chantiers.chantierPhase,
        themes: [MapTheme.schematic, MapTheme.geographic, MapTheme.perimeter],
        layers: [
          {
            subcategory: false,
            name: terms.menu.layers.chantiers.chantierPhaseREA,
            layer: LAYERS_KEYS.chantierPhaseREA,
            themes: [MapTheme.schematic, MapTheme.geographic, MapTheme.perimeter],
          },
          {
            subcategory: false,
            name: terms.menu.layers.chantiers.chantierPhaseTVX,
            layer: LAYERS_KEYS.chantierPhaseTVX,
            themes: [MapTheme.schematic, MapTheme.geographic, MapTheme.perimeter],
          },
          {
            subcategory: false,
            name: terms.menu.layers.chantiers.chantierPhaseTVXP,
            layer: LAYERS_KEYS.chantierPhaseTVXP,
            themes: [MapTheme.schematic, MapTheme.geographic, MapTheme.perimeter],
          },
          {
            subcategory: false,
            name: terms.menu.layers.chantiers.chantierPhaseREA_TVXP,
            layer: LAYERS_KEYS.chantierPhaseREA_TVXP,
            themes: [MapTheme.schematic, MapTheme.geographic, MapTheme.perimeter],
          },
          {
            subcategory: false,
            name: terms.menu.layers.chantiers.chantierPhaseREA_TVX,
            layer: LAYERS_KEYS.chantierPhaseREA_TVX,
            themes: [MapTheme.schematic, MapTheme.geographic, MapTheme.perimeter],
          },
          {
            subcategory: false,
            name: terms.menu.layers.chantiers.chantierPhaseAPO_REA,
            layer: LAYERS_KEYS.chantierPhaseAPO_REA,
            themes: [MapTheme.schematic, MapTheme.geographic, MapTheme.perimeter],
          },
          {
            subcategory: false,
            name: terms.menu.layers.chantiers.chantierPhaseAPO_TVX,
            layer: LAYERS_KEYS.chantierPhaseAPO_TVX,
            themes: [MapTheme.schematic, MapTheme.geographic, MapTheme.perimeter],
          },
          {
            subcategory: false,
            name: terms.menu.layers.chantiers.chantierPhaseAPO_REA_TVX,
            layer: LAYERS_KEYS.chantierPhaseAPO_REA_TVX,
            themes: [MapTheme.schematic, MapTheme.geographic, MapTheme.perimeter],
          },
          {
            subcategory: false,
            name: terms.menu.layers.chantiers.chantierPhasePRO_REA,
            layer: LAYERS_KEYS.chantierPhasePRO_REA,
            themes: [MapTheme.schematic, MapTheme.geographic, MapTheme.perimeter],
          },
        ],
      },
      {
        subcategory: true,
        expanded: true,
        name: terms.menu.layers.chantiers.chantierFilter,
        themes: [MapTheme.schematic, MapTheme.geographic, MapTheme.perimeter],
        layers: [
          {
            subcategory: false,
            name: terms.menu.layers.chantiers.chantierASTRE,
            label: terms.menu.layers.labelsChantier.chantierASTRE,
            layer: LAYERS_KEYS.chantierASTRE,
            themes: [MapTheme.schematic, MapTheme.geographic, MapTheme.perimeter],
          },
          {
            subcategory: false,
            name: terms.menu.layers.chantiers.chantierSEISM,
            label: terms.menu.layers.labelsChantier.chantierSEISM,
            layer: LAYERS_KEYS.chantierSEISM,
            themes: [MapTheme.schematic, MapTheme.geographic, MapTheme.perimeter],
          },
        ],
      },
    ],
  },
]

export default CHANTIER_LAYERS
