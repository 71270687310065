import { ReactElement } from 'react'
import { Source, Layer } from 'react-map-gl'
import { LAYERS_IDS, OBJECTS_LAYERS, SOURCES_IDS, hoverColor } from '../common'
import { MapURLParams, useMapURL } from '../hooks'

interface Props {
  hoveredObjectsIds: string[]
  view: string
  params?: MapURLParams
  opacity?: number
  lineFilter?: string
  visibility: boolean
}

/*
  "type_ligne_id","type_ligne_id_libelle"
  "467e6d22-6665-11e3-afff-01f464e0362d","Voie-mère d'embranchement"
  "467e6e52-6665-11e3-afff-01f464e0362d","Voie de desserte de voies ferrées de port"
  "467e67fa-6665-11e3-afff-01f464e0362d","Ligne proprement dite"
  "467e6b4c-6665-11e3-afff-01f464e0362d","Raccordement"
*/

const RacLayer = ({ hoveredObjectsIds, view, opacity, lineFilter, params, visibility }: Props): ReactElement => {
  const url = useMapURL(
    OBJECTS_LAYERS.rac,
    view,
    SOURCES_IDS.rac,
    { ...params, type_ligne_id: '467e6b4c-6665-11e3-afff-01f464e0362d' },
  )

  return (
    <Source
      id={SOURCES_IDS.rac}
      type="vector"
      url={url}
    >
      <Layer
        type="line"
        paint={{
          'line-width': 3,
          'line-color': hoverColor('#9d15cf', hoveredObjectsIds),
          'line-opacity': ['case',
            ['in', lineFilter, ['get', 'lrs_ligne']], 1,
            ['in', ['get', 'id'], ['literal', hoveredObjectsIds]], 1,
            opacity],
        }}
        id={LAYERS_IDS.rac}
        source-layer={OBJECTS_LAYERS.rac}
        layout={{ visibility: visibility ? 'visible' : 'none' }}
      />
      <Layer
        type="symbol"
        id={LAYERS_IDS.racLabel}
        source-layer={OBJECTS_LAYERS.rac}
        paint={{
          'text-color': '#ffffff',
          'text-halo-color': hoverColor('#9d15cf', hoveredObjectsIds),
          'text-halo-width': 1.5,
        }}
        layout={{
          'text-font': ['Open Sans Regular'],
          'symbol-placement': 'line',
          'symbol-spacing': 500,
          'text-size': 14,
          'text-allow-overlap': false,
          'text-field': '{libelle}',
          visibility: visibility ? 'visible' : 'none',
        }}
        minzoom={12}
      />
    </Source>
  )
}

RacLayer.defaultProps = {
  params: {},
  opacity: 1,
  lineFilter: '',
}

export default RacLayer
