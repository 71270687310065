import { useCallback, useEffect, useState } from 'react'
import './Resizer.scss'

type Props = {
  leftElementRef: React.RefObject<HTMLElement>
  rightElementRef: React.RefObject<HTMLElement>
  minWidth: number
  splitRatio: number
  setSplitRatio: (ratio: number) => void
}

export default function Resizer({ leftElementRef, rightElementRef, minWidth, splitRatio, setSplitRatio }: Props) {
  const [resizing, setResizing] = useState<boolean>(false)
  const handleStartResize = (event: MouseEvent) => {
    if (leftElementRef.current && rightElementRef.current && event.target === document.getElementById('handle')) {
      event.preventDefault()
      setResizing(true)
    }
  }

  const handleEndResize = (event: MouseEvent) => {
    if (leftElementRef.current && rightElementRef.current && event.target === document.getElementById('handle')) {
      event.preventDefault()
      setResizing(false)
    }
  }

  const handleResize = useCallback((event: MouseEvent) => {
    const { current: leftCurrent } = leftElementRef
    const { current: rightCurrent } = rightElementRef
    const { clientX } = event
    const { innerWidth } = window

    if (resizing && leftCurrent && rightCurrent) {
      if (clientX > minWidth && clientX < innerWidth - minWidth) {
        leftCurrent.style.flexBasis = `${clientX}px`
        leftCurrent.style.minWidth = `${clientX}px`
        rightCurrent.style.flexBasis = `${innerWidth - clientX}px`
        rightCurrent.style.minWidth = `${innerWidth - clientX}px`
      } else if (clientX < minWidth) {
        leftCurrent.style.flexBasis = `${minWidth}px`
        leftCurrent.style.minWidth = `${minWidth}px`
        rightCurrent.style.flexBasis = `${innerWidth - minWidth}px`
        rightCurrent.style.minWidth = `${innerWidth - minWidth}px`
        setResizing(false)
      } else if (clientX > innerWidth - minWidth + 10) {
        leftCurrent.style.flexBasis = `${innerWidth - minWidth}px`
        leftCurrent.style.minWidth = `${innerWidth - minWidth}px`
        rightCurrent.style.flexBasis = `${minWidth}px`
        rightCurrent.style.minWidth = `${minWidth}px`
        setResizing(false)
      }
      setSplitRatio(clientX / innerWidth)
    } else {
      setResizing(false)
    }
  }, [resizing, leftElementRef, rightElementRef, minWidth])

  const handleDoubleClick = (ratio: number) => {
    const { innerWidth } = window
    if (leftElementRef.current && rightElementRef.current) {
      leftElementRef.current.style.flexBasis = `${Math.floor(innerWidth * ratio)}px`
      leftElementRef.current.style.minWidth = `${Math.floor(innerWidth * ratio)}px`
      rightElementRef.current.style.flexBasis = `${Math.floor(innerWidth * (1 - ratio))}px`
      rightElementRef.current.style.minWidth = `${Math.floor(innerWidth * (1 - ratio))}px`
    }
  }

  useEffect(() => {
    handleDoubleClick(splitRatio)
  }, [])

  useEffect(() => {
    if (leftElementRef.current && rightElementRef.current) {
      document.addEventListener('mousedown', handleStartResize)
      document.addEventListener('mouseup', handleEndResize)
      document.addEventListener('mousemove', handleResize)
    }

    return () => {
      document.removeEventListener('mousedown', handleStartResize)
      document.removeEventListener('mouseup', handleEndResize)
      document.removeEventListener('mousemove', handleResize)
    }
  }, [leftElementRef.current, rightElementRef.current, handleResize])

  return (
    <div id="handle" className="flex-center" onDoubleClick={() => handleDoubleClick(0.5)}>
      <div />
      <div />
    </div>
  )
}
