import { ReactElement } from 'react'
import { Source, Layer } from 'react-map-gl'
import { LAYERS_IDS, OBJECTS_LAYERS, SOURCES_IDS } from '../common'
import { MapURLParams, useMapURL } from '../hooks'

interface Props {
  hoveredObjectsIds: string[]
  view: string
  params: MapURLParams
  opacity?: number
  lineFilter?: string
  visibility?: boolean
  angle: string
}

const JDZLayer = ({
  hoveredObjectsIds, view, params, opacity, lineFilter, visibility, angle,
}: Props): ReactElement => {
  const url = useMapURL(
    OBJECTS_LAYERS.jdz,
    view,
    SOURCES_IDS.jdz,
    params,
  )

  return (
    <Source
      id={SOURCES_IDS.jdz}
      type="vector"
      url={url}
    >
      <Layer
        type="symbol"
        id={LAYERS_IDS.jdz}
        source-layer={OBJECTS_LAYERS.jdz}
        paint={{
          'icon-opacity': ['case',
            ['in', lineFilter, ['get', 'lrs_ligne']], 1,
            ['in', ['get', 'id'], ['literal', hoveredObjectsIds]], 1,
            opacity],
        }}
        layout={{
          'icon-image': ['case',
            ['in', ['get', 'id'], ['literal', hoveredObjectsIds]], 'jdz_red',
            'jdz_blue'],
          'icon-rotation-alignment': 'map',
          'icon-allow-overlap': true,
          'icon-size': 0.3,
          'icon-rotate': ['coalesce', ['get', angle], 0],
          visibility: visibility ? 'visible' : 'none',
        }}
        minzoom={12}
      />
    </Source>
  )
}

JDZLayer.defaultProps = {
  opacity: 1,
  lineFilter: '',
  visibility: true,
}

export default JDZLayer
