import { ReactElement } from 'react'
import { Source, Layer } from 'react-map-gl'
import { OBJECTS_LAYERS, SOURCES_IDS } from '../common'
import CentraleSousStationLayerProps from './CentraleSousStationLayerProps'
import { MapURLParams, useMapURL } from '../hooks'

interface Props {
  view: string
  params: MapURLParams
  opacity?: number
  lineFilter?: string
  visibility: boolean
}

const CentraleSousStationLayer = ({ view, params, opacity, lineFilter, visibility }: Props): ReactElement => {
  const url = useMapURL(
    OBJECTS_LAYERS.centraleSousStation,
    view,
    SOURCES_IDS.css,
    params,
  )

  const { sch, geo } = CentraleSousStationLayerProps
  return (
    <Source
      id={SOURCES_IDS.css}
      type="vector"
      url={url}
    >
      <Layer
        {...sch}
        source-layer={OBJECTS_LAYERS.centraleSousStation}
        minzoom={12}
        layout={{
          ...sch.layout,
          visibility:
          visibility ? 'visible' : 'none',
        }}
        paint={{
          ...sch.paint,
          'icon-opacity': ['case',
            ['in', lineFilter, ['get', 'lrs_ligne']], 1,
            opacity],
        }}
      />
      <Layer
        {...geo}
        source-layer={OBJECTS_LAYERS.centraleSousStation}
        maxzoom={12}
        layout={{
          visibility: visibility ? 'visible' : 'none',
        }}
        paint={{
          ...geo.paint,
          'circle-opacity': ['case',
            ['in', lineFilter, ['get', 'lrs_ligne']], 1,
            opacity],
          'circle-stroke-opacity': ['case',
            ['in', lineFilter, ['get', 'lrs_ligne']], 1,
            opacity],
        }}
      />
    </Source>
  )
}

CentraleSousStationLayer.defaultProps = {
  opacity: 1,
  lineFilter: '',
}

export default CentraleSousStationLayer
