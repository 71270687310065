import { Feature, FeatureCollection, Point } from '@turf/helpers'
import { throttle } from 'lodash'
import { RefObject, useCallback, useEffect, useState } from 'react'
import { MapRef, ViewportProps } from 'react-map-gl'

export const useCalculateLabelPositions = (
  mapRef: RefObject<MapRef | undefined>,
  visible: boolean,
  layerId: string,
  functionToCall: (features: Feature[]) => FeatureCollection<Point>,
  viewport: ViewportProps,
  minzoom?: number,
) => {
  const [labelPositions, setLabelPositions] = useState<FeatureCollection<Point>>(
    { type: 'FeatureCollection', features: [] },
  )
  const throttledCalculatePositions = useCallback(throttle(() => {
    if (minzoom && viewport.zoom && viewport.zoom < minzoom) functionToCall([])
    const lines = mapRef?.current?.getMap()?.queryRenderedFeatures({ layers: [layerId] }) as Feature[] || []
    return functionToCall(lines)
  }, 200), [])

  useEffect(() => {
    if (!visible) {
      setLabelPositions({ ...labelPositions, features: [] })
      return
    }
    const positions = throttledCalculatePositions()
    if (positions) {
      setLabelPositions(positions)
    }
  }, [viewport])

  // Force label calculation on first render
  useEffect(() => {
    const currentMap = mapRef?.current?.getMap()
    const onIdle = () => {
      if (visible && labelPositions.features.length === 0) {
        const positions = throttledCalculatePositions()
        if (positions) {
          setLabelPositions(positions)
        }
      } else if (!visible) {
        setLabelPositions({ ...labelPositions, features: [] })
      }
    }
    currentMap?.on('idle', onIdle)

    return () => {
      currentMap?.off('idle', onIdle)
    }
  }, [visible])

  return labelPositions
}

export default { useCalculateLabelPositions }
