import { ArrowBack } from '@mui/icons-material'
import { Tooltip } from '@mui/material'
import terms from 'common/terms'
import { Fragment } from 'react'
import moment from 'moment'
import { DetailsField, ZapModificationDetails } from './types'

interface Props {
  handleGoBack: () => void
  itemLabel: string
  details: ZapModificationDetails[]
  handleShowGeometry: (field: DetailsField, modifId: number, old: boolean) => () => void
}

const HistoryDetails = ({
  handleGoBack, itemLabel, details, handleShowGeometry,
}: Props) => (
  <div className="history-details">
    <div className="header">
      <Tooltip title={terms.HistoryMenu.back}>
        <button type="button" onClick={handleGoBack}>
          <ArrowBack />
        </button>
      </Tooltip>
      <span>{`${terms.HistoryMenu.details} - ${itemLabel}`}</span>
    </div>
    <table className="history-table">
      <thead>
        <tr>
          <th>{terms.HistoryMenu.modification.fieldName}</th>
          <th>{terms.HistoryMenu.modification.oldValue}</th>
          <th>{terms.HistoryMenu.modification.newValue}</th>
        </tr>
      </thead>
      <tbody>
        {details.map(detail => (
          <Fragment key={detail.id}>
            <tr>
              <td colSpan={3} className="modif-date">
                {`Modification: ${moment(detail.modification_date).format('DD/MM/YYYY à HH:mm:ss')}`}
              </td>
            </tr>
            {detail.modified_fields.map(field => {
              if (field.isGeom) {
                return (
                  <tr key={field.field}>
                    <td>{field.field}</td>
                    <td>
                      <button
                        type="button"
                        className="show-geometry"
                        onClick={handleShowGeometry(field, detail.id, true)}
                      >
                        {field.shown_old ? terms.HistoryMenu.modification.hideGeometry
                          : terms.HistoryMenu.modification.showGeometry}
                      </button>
                    </td>
                    <td>
                      <button
                        type="button"
                        className="show-geometry"
                        onClick={handleShowGeometry(field, detail.id, false)}
                      >
                        {field.shown_new ? terms.HistoryMenu.modification.hideGeometry
                          : terms.HistoryMenu.modification.showGeometry}
                      </button>
                    </td>
                  </tr>
                )
              }
              return (
                <tr key={field.field}>
                  <td>{field.field}</td>
                  <td>{field.old_value}</td>
                  <td>{field.new_value}</td>
                </tr>
              )
            })}
          </Fragment>
        ))}
      </tbody>
    </table>
  </div>
)

export default HistoryDetails
