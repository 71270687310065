import { List, ListItemText, Paper, Tooltip } from '@mui/material'
import { FeatureLayer } from 'components/MapPopup/utils'
import nextId from 'react-id-generator'
import { Popup, _useMapControl as useMapControl } from 'react-map-gl'
import { featureReducer, getLabel } from './utils'
import './MultiFeaturesPopup.scss'

interface Props {
  showPopup: boolean
  features: FeatureLayer[]
  longitude: number | null
  latitude: number | null
  handleClose: () => void
  setHoveredId: (id: string[]) => void
  handleClickFeature: (feature: FeatureLayer) => void
}

const MultiFeaturesPopup = ({
  features, showPopup, handleClose, longitude, latitude, handleClickFeature, setHoveredId,
}: Props) => {
  const { containerRef } = useMapControl({
    captureDrag: true, captureClick: true, captureDoubleClick: true, captureScroll: true, capturePointerMove: true,
  })
  const handleMenuClick = async (feature: FeatureLayer) => {
    setTimeout(async () => {
      await handleClickFeature(feature)
      handleClose()
    }, 300)
  }
  if (!showPopup || features.length <= 1) return null
  if (!longitude || !latitude) return null

  return (
    <Popup
      longitude={longitude}
      latitude={latitude}
      closeButton={false}
      className="context-menu-popup"
      tipSize={0}
      anchor="top-left"
      captureScroll
      captureClick
      capturePointerMove
      onClose={() => handleClose()}
    >
      <Paper className="multi-feature-popup">
        <div ref={containerRef} onMouseEnter={() => setHoveredId([])}>
          <List className="list">
            {Object.entries(features.reduce(featureReducer, {})).map(([category, featuresList]) => (
              <div key={nextId()} className="category-wrapper">
                <span className="category">{category}</span>
                <div className="feature-list">
                  {featuresList.map(feature => (
                    <button
                      key={nextId()}
                      className="feature-button"
                      type="button"
                      onClick={() => handleMenuClick(feature)}
                      onMouseEnter={() => setHoveredId([feature.properties?.id || ''])}
                    >
                      <Tooltip title={getLabel(feature)} placement="top">
                        <ListItemText>
                          <div className="label">{getLabel(feature)}</div>
                        </ListItemText>
                      </Tooltip>
                    </button>
                  ))}
                </div>
              </div>
            ))}
          </List>
        </div>
      </Paper>
    </Popup>
  )
}

export default MultiFeaturesPopup
