import { CircularProgress } from '@mui/material'
import terms from 'common/terms'
import { SearchResults as SearchResultsType, SearchResult } from 'utils/search/types'

interface Props {
  results: SearchResultsType
  displayResults: boolean
  handleHover: (id: string) => void
  pending: boolean
  handleClick: (result: SearchResult) => () => void
  // eslint-disable-next-line react/require-default-props
  error?: boolean
}

const SearchResults = ({ results, displayResults, handleHover, pending, handleClick, error = undefined }: Props) => {
  if (!displayResults) return null
  if (error) {
    return (
      <div className="results error">
        <span>
          {terms.objectSearch.error}
        </span>
      </div>
    )
  }
  if (pending) {
    return (
      <div className="results pending">
        <CircularProgress />
      </div>
    )
  }
  return (
    <div className="results">
      {Object.entries(results).map(([layer, resultList]) => (
        <div key={layer} className="category">
          <h4 className="category-name">
            {{ ...terms.objectSearch.layers }[layer] || terms.objectSearch.layers.unknown}
          </h4>
          {resultList.map(result => (
            <button
              type="button"
              className="result"
              key={`${result.id}-${layer}`}
              onClick={handleClick(result)}
              onMouseEnter={() => handleHover(result.id)}
            >
              {result.type === 'cassini' && (
                <>
                  <h4>
                    {result.libelle}
                  </h4>
                  <span>
                    {`${result.lineNumber} ${result.trackName} ${result.pk}`}
                  </span>
                </>
              )}
              {result.type === 'cassiniWithType' && (
                <>
                  <h4>
                    {result.libelle}
                  </h4>
                  <span>
                    {`${result.type_installation_fixe_id_mnemo} -
                     ${result.lineNumber} ${result.trackName} ${result.pk}`}
                  </span>
                </>
              )}
              {result.type === 'zap' && (
                <>
                  <h4 className="zap">
                    {`${result.poste_libelle} - ${result.gare_libelle}`}
                  </h4>
                </>
              )}
              {result.type === 'ihm' && (
                <>
                  <h4>
                    {result.libelle}
                  </h4>
                  <span>
                    {result.zaps_libelles}
                  </span>
                </>
              )}
              {result.type === 'center' && (
                <>
                  <h4 className="zap">
                    {result.libelle}
                  </h4>
                </>
              )}
            </button>
          ))}
        </div>
      ))}
      {Object.keys(results).length === 0 && (
        <div className="no-result">
          <span>
            {terms.objectSearch.noResult}
          </span>
        </div>
      )}
    </div>
  )
}

export default SearchResults
