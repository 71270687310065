import { MapRequest } from 'react-map-gl'
import MAIN_API from 'config/config'

const isTileserverResource = (type: string) => (type === 'Source' || type === 'Tile')

const replaceUrl = (url: string): string => {
  if (!MAIN_API.proxy.includes('.dev.')) {
    const newEnv = MAIN_API.proxy.includes('staging') ? 'staging.dgexsol.' : 'dgexsol.'
    return url.replace('dev.dgexsol.', newEnv)
  }
  return url
}

// eslint-disable-next-line import/prefer-default-export
export const transformRequest = (url: string | undefined, resourceType: string | undefined): MapRequest => {
  if (url === undefined) return { url: '' }
  if ((isTileserverResource(resourceType as string))) {
    const newUrl = url.startsWith(MAIN_API.proxy) ? url : replaceUrl(url)
    return {
      url: newUrl,
      headers: { Authorization: `Bearer ${localStorage.getItem('access_token')}` },
    }
  }

  if (resourceType === 'Glyphs' || resourceType?.includes('Sprite')) {
    return { url: replaceUrl(url) }
  }
  return { url }
}
