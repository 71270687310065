import { MapTheme } from 'components/Toolbar/ThemeMenu/const'
import { Feature, Polygon } from 'geojson'

/* eslint-disable camelcase */
export type UserView = {
  libelle: string
  x: number
  y: number
  bearing: number
  pitch: number
  zoom: number
  displayed_layers: string[]
  view: MapTheme | null
}

export type UserViewWithId = UserView & {
  id: number
}

export type UserFavoriteObject = {
  object_id: string
  layer_id_field: string
  layer_slug: string
}

export type UserFavoriteObjectWithId = UserFavoriteObject & {
  id: number
}

export type UserFavoriteObjectDetails = UserFavoriteObjectWithId & {
  properties: Feature['properties']
  geometry: Feature['geometry']
}

export type UserStudyPerimeterSimple = {
  libelle: string
  id: number
}

export type UserStudyPerimeterProperties = {
  libelle: string
  lvp_perimeter?: {
    line_code: string
    track_name: string
    start_pk: string
    end_pk: string
  }[]
  bearing?: number | null
  pitch?: number | null
  zoom?: number | null
  displayed_layers?: string[] | null
  x?: number | null
  y?: number | null
  split_ratio: number
}

export type UserStudyPerimeterDetails = Feature<Polygon, UserStudyPerimeterProperties>

export type UserStudyPerimeterDetailsWithId = UserStudyPerimeterDetails & {
  id: number
}

export enum UserFavoriteObjectCommentType {
  Commentaire = 'Commentaire',
  DonnéesObsolètes = 'Données obsolètes',
  DonnéesManquantes = 'Données manquantes',
  DonnéesFausses = 'Données fausses',
}

export type UserFavoriteObjectComment = {
  id: number
  content: string
  type: UserFavoriteObjectCommentType
  object_id: string
  object_libelle: string
  layer_id_field: string
  layer_slug: string
}

export type userFavoriteState = {
  views: UserViewWithId[]
  favoriteObjects: UserFavoriteObjectWithId[]
  objectsComments: UserFavoriteObjectComment[]
  createViewModal: boolean
  favoriteModal: boolean
  favoriteDetails: UserFavoriteObjectDetails[]
  detailsLoading: boolean
  perimeters: UserStudyPerimeterSimple[]
  addedPerimeter: UserStudyPerimeterDetails | null
}
