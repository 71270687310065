import { ReactElement } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'store'
import { Source, Layer } from 'react-map-gl'
import { LAYERS_IDS, SOURCES_IDS } from '../common'
import PosteLayerProps from './PosteLayerProps'

const PosteSelectionLayer = (): ReactElement => {
  const { zonePolygons, isSelectingPoste } = useSelector((state: RootState) => state.zoneEditor)

  return (
    <Source
      id={SOURCES_IDS.zoneEditorPolygon}
      type="geojson"
      data={{ type: 'FeatureCollection', features: zonePolygons }}
    >
      <Layer
        {...PosteLayerProps.selectionOutline}
        id={LAYERS_IDS.zoneEditorOutline}
        layout={{ visibility: isSelectingPoste ? 'visible' : 'none' }}
        source={SOURCES_IDS.zoneEditorPolygon}
      />
      <Layer
        {...PosteLayerProps.selectionFill}
        id={LAYERS_IDS.zoneEditorFill}
        layout={{ visibility: isSelectingPoste ? 'visible' : 'none' }}
        source={SOURCES_IDS.zoneEditorPolygon}
      />
    </Source>
  )
}

export default PosteSelectionLayer
