import terms from 'common/terms'
import { useDispatch, useSelector } from 'react-redux'
import { deleteUserStudyPerimeter } from 'store/userFavorite/userFavorite.thunk'
import { RootState } from 'store'
import { Tooltip } from '@mui/material'
import { Delete, Visibility } from '@mui/icons-material'
import './PerimeterTab.scss'
import { useHistory } from 'react-router-dom'

interface Props {
  handleClose: () => void,
}

const PerimeterTab = ({ handleClose }: Props) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { perimeters } = useSelector((state: RootState) => state.userFavorite)

  const handleDelete = (id: number) => () => {
    dispatch(deleteUserStudyPerimeter(id))
  }

  const handleSelect = (id: number) => () => {
    history.push(`/perimeter/${id}`)
    handleClose()
  }

  return (
    <div className="favorite-perimeter-tab">
      {perimeters.map(perimeter => (
        <div key={perimeter.id} className="perimeter-details">
          <div className="infos">
            <strong className="title">{`Périmètre : ${perimeter.libelle}`}</strong>
          </div>
          <div className="actions">
            <Tooltip title={terms.FavoriteModal.see}>
              <button type="button" className="see" onClick={handleSelect(perimeter.id)}>
                <Visibility />
              </button>
            </Tooltip>
            <Tooltip title={terms.FavoriteModal.delete}>
              <button type="button" className="delete" onClick={handleDelete(perimeter.id)}>
                <Delete />
              </button>
            </Tooltip>
          </div>
        </div>
      ))}
      {perimeters.length === 0 && (
        <div className="no-perimeters">
          {terms.FavoriteModal.noPerimeters}
        </div>
      )}
    </div>
  )
}

export default PerimeterTab
