import { FeatureOf, Polygon, Point } from '@nebula.gl/edit-modes'
import { ReactElement, useEffect, useState } from 'react'
import { DrawPolygonMode, EditingMode, Editor } from 'react-map-gl-draw'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'store'
import {
  closeModal, editPerimeterPolygon, editPolygon, resetEditor, updateLabelPosition, updateZonePolygons,
} from 'store/zoneEditor/zoneEditor'
import { closeCreatePerimeterModal } from 'store/userFavorite/userFavorite'
import centroid from '@turf/centroid'
import { AllGeoJSON } from '@turf/helpers'
import ConfirmationModal from 'components/Modal/ConfirmationModal/ConfirmationModal'
import nextId from 'react-id-generator'
import { CreatePerimeterModal } from 'components/Modal'
import EditorPolygonMarker from './EditorPolygonMarker'
import { EMPTY_ON_SELECT_EVENT, OnSelectEvent, editHandleStyle, editorFeatureStyle } from './utils'
import EditorMenu from './EditorMenu/EditorMenu'

const EditorLayer = (): ReactElement | null => {
  const dispatch = useDispatch()
  const {
    mode, confirmationModal, zonePolygons, labelPosition, selectedPoste, actionZonePatch, editorStep, addPerimeterModal,
  } = useSelector((state: RootState) => state.zoneEditor)
  const { addedPerimeter } = useSelector((state: RootState) => state.userFavorite)
  const [activeMode, setActiveMode] = useState<DrawPolygonMode | EditingMode | null>(null)
  const [polygons, setPolygons] = useState<FeatureOf<Polygon>[]>([])
  const [editorLabelPosition, setEditorLabelPosition] = useState<FeatureOf<Point> | undefined>(undefined)
  const [posteLibelle, setPosteLibelle] = useState<string | null>(null)
  const [labelColor, setLabelColor] = useState<string>('#000000')
  const [selectEvent, setSelectEvent] = useState<OnSelectEvent>(EMPTY_ON_SELECT_EVENT)
  const [editorKey, setEditorKey] = useState(nextId())

  useEffect(() => {
    if (selectedPoste) {
      setPosteLibelle(selectedPoste.libelle)
      setLabelColor(selectedPoste.color)
    } else {
      setPosteLibelle(null)
      setLabelColor('#000000')
    }
  }, [selectedPoste])

  useEffect(() => {
    if (actionZonePatch) {
      setPosteLibelle(actionZonePatch.label)
      setLabelColor(actionZonePatch.color)
    } else {
      setPosteLibelle(null)
      setLabelColor('#000000')
    }
  }, [actionZonePatch])

  useEffect(() => {
    switch (mode) {
      case 'create':
        setActiveMode(new DrawPolygonMode())
        setPolygons([])
        setEditorLabelPosition(undefined)
        setLabelColor('#000000')
        break
      case 'edit':
        setActiveMode(new EditingMode())
        break
      case 'patch':
        setActiveMode(new EditingMode())
        setPolygons(zonePolygons as FeatureOf<Polygon>[])
        setEditorLabelPosition(labelPosition as FeatureOf<Point>)
        setPosteLibelle(actionZonePatch?.label || '')
        setLabelColor(actionZonePatch?.color || '#000000')
        break
      default:
        setActiveMode(null)
    }
  }, [mode])

  const onUpdate = (payload : { data: FeatureOf<Polygon>[], editType: string }) => {
    setPolygons(payload.data)
    if (payload.data[0]) {
      const point = centroid(payload.data[0] as AllGeoJSON)
      point.properties = { color: labelColor }
      setEditorLabelPosition(point as FeatureOf<Point>)
      dispatch(updateLabelPosition(point))
    }
    if (payload.data.length > 0) {
      dispatch(updateZonePolygons(payload.data))
    }
    if (payload.editType === 'addFeature' && editorStep === 1) {
      dispatch(editPolygon())
    }
    if (payload.editType === 'addFeature' && editorStep === 10) {
      dispatch(editPerimeterPolygon())
    }
  }

  const handleDragEnd = (e: { lngLat: [number, number] }) => {
    const point = {
      type: 'Feature',
      properties: { color: labelColor },
      geometry: {
        type: 'Point',
        coordinates: e.lngLat,
      },
    } as FeatureOf<Point>
    setEditorLabelPosition(point)
    dispatch(updateLabelPosition(point))
  }

  const handleSelect = (e: OnSelectEvent) => setSelectEvent(e)

  const canDeletePoints = () => {
    if (selectEvent.selectedFeatureIndex === null) return false
    const numberOfPoints = polygons[selectEvent.selectedFeatureIndex]?.geometry.coordinates[0].length
    const numberOfSelectedPoints = selectEvent.selectedEditHandleIndexes.length
    return numberOfSelectedPoints > 0 && numberOfSelectedPoints < numberOfPoints - 3
  }

  const handleDelete = () => {
    const newPolygons = polygons.map((polygon, index) => {
      if (index !== selectEvent.selectedFeatureIndex) return polygon
      const coords = polygon.geometry.coordinates[0]
      const indicesToDelete = selectEvent.selectedEditHandleIndexes
      let newCoords = []
      if (indicesToDelete.includes(0) || indicesToDelete.includes(coords.length - 1)) {
        indicesToDelete.push(0)
        indicesToDelete.push(coords.length - 1)
        newCoords = [...coords.filter((_, i) => !indicesToDelete.includes(i))]
        newCoords.push(newCoords[0])
      } else {
        newCoords = [...coords.filter((_, i) => !indicesToDelete.includes(i))]
      }
      return {
        ...polygon,
        geometry: {
          ...polygon.geometry,
          coordinates: [newCoords],
        },
      }
    })
    setPolygons(newPolygons)
    setSelectEvent(EMPTY_ON_SELECT_EVENT)
    setEditorKey(nextId())
    dispatch(updateZonePolygons(newPolygons))
  }

  const handleClose = () => {
    dispatch(resetEditor())
    dispatch(closeCreatePerimeterModal())
  }

  return (
    <>
      {activeMode && (
        <>
          <Editor
            clickRadius={12}
            mode={activeMode}
            onUpdate={onUpdate}
            onSelect={handleSelect}
            features={polygons}
            featureStyle={editorFeatureStyle}
            editHandleShape="circle"
            editHandleStyle={editHandleStyle}
            key={editorKey}
          />
          <EditorMenu
            selectedPoints={selectEvent.selectedEditHandleIndexes.length}
            selectedPolygon={selectEvent.selectedFeatureIndex !== null}
            canDelete={canDeletePoints()}
            handleDelete={handleDelete}
          />
        </>
      )}
      {posteLibelle && (
        <EditorPolygonMarker
          posteLibelle={posteLibelle}
          labelPosition={editorLabelPosition}
          handleDragEnd={handleDragEnd}
          color={labelColor}
        />
      )}
      <ConfirmationModal
        onModal={confirmationModal.open}
        content={confirmationModal.message}
        handleClose={() => dispatch(closeModal())}
        handleConfirmClick={confirmationModal.action}
      />
      {!!editorStep && (
        <CreatePerimeterModal
          onModal={addPerimeterModal}
          handleClose={handleClose}
          addedPerimeter={addedPerimeter}
        />
      )}
    </>
  )
}

export default EditorLayer
