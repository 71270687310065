import { Box, Modal } from '@mui/material'
import { useState } from 'react'
import terms from 'common/terms'
import { useDispatch, useSelector } from 'react-redux'
import { postUserView } from 'store/userFavorite/userFavorite.thunk'
import { RootState } from 'store'
import { mapViewportSignal } from 'views/Map'
import './CreateViewModal.scss'

const style = {
  position: 'absolute' as const,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '4px',
}

interface Props {
  onModal: boolean,
  handleClose: () => void,
}

const CreateViewModal = ({
  onModal,
  handleClose,
}: Props) => {
  const dispatch = useDispatch()
  const { activeLayers, currentOsmTheme } = useSelector((state: RootState) => state.map)
  const [inputValue, setInputValue] = useState('')

  const handleValidate = () => {
    const { longitude, latitude, bearing, pitch, zoom } = mapViewportSignal.value
    if (!longitude || !latitude || bearing === undefined || pitch === undefined || !zoom) return
    dispatch(postUserView({
      libelle: inputValue,
      x: longitude,
      y: latitude,
      bearing,
      pitch,
      zoom,
      displayed_layers: activeLayers,
      view: currentOsmTheme,
    }))
  }
  return (
    <Modal
      open={onModal}
      onClose={handleClose}
    >
      {}
      <Box className="modal-create-view" sx={style}>
        <h2 className="title">
          {terms.CreateUserView.title}
        </h2>
        <input
          type="text"
          value={inputValue}
          onChange={e => setInputValue(e.target.value)}
          placeholder={terms.CreateUserView.placeholder}
        />
        <div className="actions">
          <button className="validate" type="button" onClick={handleValidate}>
            {terms.CreateUserView.save}
          </button>
          <button className="cancel" type="button" onClick={handleClose}>
            {terms.CreateUserView.cancel}
          </button>
        </div>
      </Box>
    </Modal>
  )
}

export default CreateViewModal
