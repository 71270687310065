import { debounce } from 'lodash'
import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'store'
import { getPerimeterCollections } from 'store/collections/collections.thunk'
import { UserStudyPerimeterDetailsWithId } from 'store/userFavorite/types'
import { collectionDetailsSignal } from 'views/Collections/details/CollectionDetails'
import CollectionsPage from 'views/Collections/page/CollectionsPage'
import { perimeterHoveredIdSignal } from './PerimeterView'

interface Props {
  activePerimeter: UserStudyPerimeterDetailsWithId
}

const PerimeterTable = ({ activePerimeter }:Props) => {
  const dispatch = useDispatch()
  const {
    perimeterCollections, perimeterLoading, perimeterTotalPage,
  } = useSelector((state: RootState) => state.collections)
  const [searchValue, setSearchValue] = useState('')
  const [searchPage, setSearchPage] = useState(1)
  const [orderFerroloc, setOrderFerroloc] = useState<'asc' | 'desc' | null>(null)
  const [selectedCollectionId, setSelectedCollectionId] = useState<number | null>(null)

  const debouncedSearch = useCallback(debounce((search: string, page: number, ordering: string) => {
    dispatch(getPerimeterCollections({ perimeterId: activePerimeter.id, search, page, ordering }))
  }, 500), [])

  useEffect(() => {
    let ordering = ''
    if (orderFerroloc === 'asc') ordering = 'ligne_id,kp'
    if (orderFerroloc === 'desc') ordering = '-ligne_id,-kp'
    debouncedSearch(searchValue, searchPage, ordering)
  }, [searchValue, searchPage, orderFerroloc])

  const handleSelectCollection = (collectionId: number) => () => {
    setSelectedCollectionId(collectionId)
  }

  const handleGoBack = () => {
    setSelectedCollectionId(null)
    collectionDetailsSignal.value = null
  }

  const handleHoverCollection = (collectionId: string | null) => () => {
    perimeterHoveredIdSignal.value = collectionId ? [collectionId] : []
  }

  return (
    <CollectionsPage
      collections={perimeterCollections}
      selectedCollectionId={selectedCollectionId}
      orderFerroloc={orderFerroloc}
      setOrderFerroloc={setOrderFerroloc}
      setSearchValue={setSearchValue}
      setSearchPage={setSearchPage}
      handleGoBack={handleGoBack}
      handleSelectCollection={handleSelectCollection}
      loading={perimeterLoading}
      searchValue={searchValue}
      searchPage={searchPage}
      totalPage={perimeterTotalPage}
      disableQuit
      handleHoverCollection={handleHoverCollection}
    />
  )
}

export default PerimeterTable
