import { LayerProperties, LAYERS_IDS } from '../common'

const TrackLayerProps: LayerProperties = {
  vp: {
    id: LAYERS_IDS.trackVP,
    type: 'line',
    paint: {
      'line-color': '#0088ce',
      'line-width': 2,
    },
    filter: ['any',
      ['==', ['get', 'type_voie'], 'VP'], ['==', ['get', 'type_voie'], 'VPL'], ['==', ['get', 'type_voie'], 'VPA'],
    ],
    layout: {
      visibility: 'visible',
    },
  },
  vs: {
    id: LAYERS_IDS.trackVS,
    type: 'line',
    paint: {
      'line-color': 'grey',
      'line-width': 1,
    },
    filter: ['==', ['get', 'type_voie'], 'VS'],
    layout: {
      visibility: 'visible',
    },
  },
  trackNameVS: {
    id: LAYERS_IDS.trackNameVS,
    type: 'symbol',
    layout: {
      'text-font': ['Open Sans Regular'],
      'symbol-placement': 'line',
      'symbol-spacing': 1000,
      'text-size': 12,
      'text-allow-overlap': false,
      'text-field': '{voie}',
      visibility: 'visible',
    },
    paint: {
      'text-color': '#555',
      'text-halo-width': 2,
      'text-halo-color': 'rgba(255,255,255,0.75)',
      'text-halo-blur': 1,

    },
    filter: ['==', ['get', 'type_voie'], 'VS'],
    minzoom: 12,
  },
  trackNameVP: {
    id: LAYERS_IDS.trackNameVP,
    type: 'symbol',
    layout: {
      'text-font': ['Open Sans Regular'],
      'symbol-placement': 'line',
      'text-size': 13,
      'text-allow-overlap': false,
      'symbol-spacing': 1000,
      'text-field': '{voie}',
      visibility: 'visible',
    },
    paint: {
      'text-color': 'black',
      'text-halo-width': 1.5,
      'text-halo-color': 'rgba(255,255,255,1)',
    },
    filter: ['any',
      ['==', ['get', 'type_voie'], 'VP'], ['==', ['get', 'type_voie'], 'VPL'], ['==', ['get', 'type_voie'], 'VPA'],
    ],
    minzoom: 12,
  },
  lineNumber: {
    id: LAYERS_IDS.trackLineNumber,
    type: 'symbol',
    layout: {
      'text-font': ['Open Sans Regular'],
      'symbol-placement': 'line',
      'symbol-spacing': 2000,
      'text-size': 13,
      'text-field': '{ligne}',
      visibility: 'visible',
    },
    paint: {
      'text-color': 'black',
      'text-halo-width': 1.5,
      'text-halo-color': 'rgba(255,255,255,1)',
    },
    minzoom: 12,
  },
}

export default TrackLayerProps
