import { ReactElement } from 'react'
import { Source, Layer } from 'react-map-gl'
import { LAYERS_IDS, OBJECTS_LAYERS, SOURCES_IDS } from '../common'
import layerProps from './ActionZonePosteLibelleLayerProps'
import { MapURLParams, useMapURL } from '../hooks'

interface Props {
  hoveredObjectsIds: string[]
  filterZapId?: string | null
  filterOutZapId?: string | null
  opacity?: number
  lineFilter?: string
  visibility: {
    zoneActionPoste: boolean
    zoneActionPosteAmendee: boolean
  }
  params?: MapURLParams
  view: string
  collectionStatusProps: {
    visibility: boolean,
    count: mapboxgl.Expression,
    filter: mapboxgl.Expression,
  }
}

const ActionZonePosteLibelleLayer = ({
  hoveredObjectsIds, filterZapId, filterOutZapId, opacity, lineFilter, visibility, params, view,
  collectionStatusProps,
}: Props): ReactElement => {
  const url = useMapURL(
    OBJECTS_LAYERS.actionZonePoste,
    view,
    SOURCES_IDS.actionZoneLabel,
    {
      ...params,
      ...(filterZapId && { id: filterZapId }),
    },
  )

  return (
    <Source
      id={SOURCES_IDS.actionZoneLabel}
      type="vector"
      url={url}
    >
      <Layer
        {...layerProps}
        id={LAYERS_IDS.zoneActionPosteLabel}
        layout={{
          ...layerProps.layout,
          visibility: visibility.zoneActionPoste ? 'visible' : 'none',
        }}
        paint={{
          ...layerProps.paint,
          'text-opacity': ['case',
            ['in', lineFilter, ['get', 'codes_ligne']], 1,
            ['in', ['get', 'id'], ['literal', hoveredObjectsIds]], 1,
            opacity],
          'icon-opacity': ['case',
            ['in', lineFilter, ['get', 'codes_ligne']], 1,
            ['in', ['get', 'id'], ['literal', hoveredObjectsIds]], 1,
            opacity],
        }}
        filter={['==', ['get', 'modifiable'], false]}
      />
      <Layer
        {...layerProps}
        id={LAYERS_IDS.zoneActionPosteAmendeeLabel}
        layout={{
          ...layerProps.layout,
          visibility: visibility.zoneActionPosteAmendee ? 'visible' : 'none',
        }}
        paint={{
          ...layerProps.paint,
          'text-opacity': ['case',
            ['in', lineFilter, ['get', 'codes_ligne']], 1,
            ['in', ['get', 'id'], ['literal', hoveredObjectsIds]], 1,
            opacity],
          'icon-opacity': ['case',
            ['in', lineFilter, ['get', 'codes_ligne']], 1,
            ['in', ['get', 'id'], ['literal', hoveredObjectsIds]], 1,
            opacity],
        }}
        filter={['all', ['==', ['get', 'modifiable'], true], ['!=', ['get', 'id'], filterOutZapId || '']]}
      />
      <Layer
        id={LAYERS_IDS.collectionStatusCircle}
        type="circle"
        source-layer={OBJECTS_LAYERS.actionZonePoste}
        layout={{
          visibility: collectionStatusProps.visibility ? 'visible' : 'none',
        }}
        paint={{
          'circle-radius': 10,
          'circle-color': [
            'case',
            ['==', collectionStatusProps.count, 0], '#43CF7B',
            ['<=', collectionStatusProps.count, 5], '#FBE55A',
            ['<=', collectionStatusProps.count, 10], '#FAB142',
            '#DA4238',
          ],
          'circle-stroke-color': 'white',
          'circle-stroke-width': 1,
          'circle-translate': [5, -14],
        }}
        filter={collectionStatusProps.filter}
        minzoom={8}
      />
      <Layer
        id={LAYERS_IDS.collectionStatus}
        type="symbol"
        source-layer={OBJECTS_LAYERS.actionZonePoste}
        layout={{
          'text-field': ['to-string', collectionStatusProps.count],
          'text-font': ['Open Sans Regular'],
          'text-allow-overlap': true,
          'text-offset': [0.4, -1.2],
          'text-size': 12,
          visibility: collectionStatusProps.visibility ? 'visible' : 'none',
        }}
        paint={{
          'text-opacity': 1,
        }}
        filter={collectionStatusProps.filter}
        minzoom={8}
      />
    </Source>
  )
}

ActionZonePosteLibelleLayer.defaultProps = {
  filterZapId: undefined,
  filterOutZapId: undefined,
  opacity: 1,
  lineFilter: '',
  params: {},
}

export default ActionZonePosteLibelleLayer
