/* eslint-disable camelcase */
import terms from 'common/terms'
import {
  DexcartoLayerKey, LAYERS_KEYS, MAIN_SOURCES,
  MAJOR_POSTES_LAYER_KEYS, MINOR_POSTES_LAYER_KEYS, SIGNAL_LAYERS_KEYS, SOURCES_IDS,
} from 'components/Layers/common'
import { MapTheme } from 'components/Toolbar/ThemeMenu/const'
import { UserFavoriteObjectDetails } from 'store/userFavorite/types'

const LAYER_NAMES: Record<DexcartoLayerKey, string> = {
  acces: 'Accès',
  adv: 'Appareils de voie',
  css: 'Centrales sous-station',
  dbc: 'Détecteurs de boîte chaude',
  jdz: 'Joints de Zone',
  localisateur: 'Localisateurs',
  passageNiveau: 'Passages à niveau',
  pedale: 'Pédales',
  'poste-argos': 'Postes ARGOS',
  'poste-pai': 'Postes PAI',
  'poste-prci': 'Postes PRCI',
  'poste-prs': 'Postes PRS',
  'poste-prg': 'Postes PRG',
  'poste-prmi': 'Postes PRMI',
  'poste-cc': 'Postes CC',
  'poste-plit': 'Postes PLIT',
  'poste-peli': 'Postes PELI',
  'poste-pelir': 'Postes PELIR',
  'poste-pml': 'Postes PML',
  'poste-emu': 'Postes EMU',
  'poste-mu45': 'Postes MU45',
  'poste-mrsaxby': 'Postes MRSAXBY',
  'poste-mrlevdr': 'Postes MRLEVDR',
  'poste-mrvignty': 'Postes MRVIGNTY',
  'poste-mrexal': 'Postes MREXAL',
  'poste-ssdv': 'Postes SSDV',
  'poste-ssvuvb': 'Postes SSVUVB',
  'poste-pvstri': 'Postes PVSTRI',
  'poste-pvselec': 'Postes PVSELEC',
  'poste-pvsei': 'Postes PVSEI',
  'poste-pvsci': 'Postes PVSCI',
  'poste-pvsmec': 'Postes PVSMEC',
  'poste-point': 'Postes POINT',
  'poste-levier': 'Postes LEVIER',
  'poste-p10': 'Postes P10',
  'poste-pipc': 'Postes PIPC',
  'poste-autres': 'Postes autres',
  signalProtection: 'Signaux de protection',
  signalArretAnnonce: 'Signaux d\'arrêt annonce',
  signalLimitationDeVitesse: 'Signaux de limitation de vitesse',
  signalManoeuvre: 'Signaux de manoeuvre',
  signalIndicateurDirection: 'Signaux indicateur de direction',
  signalDepartTrain: 'Signaux de départ de train',
  signalSortieGroupesVoiesConvergentes: 'Signaux de sortie de groupes de voies convergentes',
  signalTGV: 'Signaux TGV',
  signalTractionElectrique: 'Signaux de traction électrique',
  signalTableauPancarte: 'Signaux tableau pancarte',
  station: 'Gares',
  sousStation: 'Sous-stations',
  track: 'Voies',
  zoneActionPoste: 'Zones d\'action de poste',
  zoneActionPosteAmendee: 'Zones d\'action de poste (éditables)',
  pri: 'PRI',
  infrapole: 'Infrapôles',
  utm: 'UTM',
  zp: 'Zones de protection',
  'chantier-adv': 'Chantiers ADV',
  'chantier-dev': 'Chantiers DEV',
  'chantier-gc': 'Chantiers GC',
  'chantier-ifte': 'Chantiers IFTE',
  'chantier-mne': 'Chantiers MNE',
  'chantier-oa': 'Chantiers OA',
  'chantier-ot': 'Chantiers OT',
  'chantier-oge': 'Chantiers OGE',
  'chantier-pmrpn': 'Chantiers PMRPN',
  'chantier-signal': 'Chantiers signaux',
  'chantier-surete': 'Chantiers sûreté',
  'chantier-securite': 'Chantiers sécurité',
  'chantier-telecom': 'Chantiers télécom',
  'chantier-voie': 'Chantiers voie',
  'chantier-2024': 'Chantiers 2024',
  'chantier-2025': 'Chantiers 2025',
  'chantier-2026': 'Chantiers 2026',
  'chantier-2027': 'Chantiers 2027',
  'chantier-2028': 'Chantiers 2028',
  'chantier-2029': 'Chantiers 2029',
  'chantier-2030-plus': 'Chantiers 2030+',
  'chantier-0m': 'Chantiers moins de 1M',
  'chantier-1m': 'Chantiers moins de 10M',
  'chantier-10m': 'Chantiers plus de 10M',
  'chantier-phase-ep': 'Chantiers phase EP',
  'chantier-phase-avp': 'Chantiers phase AVP',
  'chantier-phase-apo': 'Chantiers phase APO',
  'chantier-phase-pro': 'Chantiers phase PRO',
  'chantier-phase-dce': 'Chantiers phase DCE',
  'chantier-phase-rea': 'Chantiers phase REA',
  'chantier-phase-other': 'Chantiers phase autre',
  'bal-zone': 'Zones de Tronçons',
  center: 'Centres d’appareillage',
  'ihm-zone': 'Zones IHM',
  'ihm-zone-amendee': 'Zones IHM (éditables)',
  cdv: 'Circuits de voie',
  'exploitation-mode-unique': 'Mode d\'exploitation unique',
  'exploitation-mode-banalisee': 'Mode d\'exploitation banalisée',
  'exploitation-mode-ipcs': 'Mode d\'exploitation IPCS',
} as const

export const getLayerList = (layers: string[]): string => {
  let layerList = Object.values(LAYERS_KEYS).filter(layer => layers.includes(layer))
  const categories = []
  const majorPostesLayerKeys: DexcartoLayerKey[] = Object.values(MAJOR_POSTES_LAYER_KEYS)
  if (majorPostesLayerKeys.every(layer => layerList.includes(layer))) {
    layerList = layerList.filter(layer => !majorPostesLayerKeys.includes(layer))
    categories.push(terms.menu.layers.postes.names)
  }

  const minorPostesLayerKeys: DexcartoLayerKey[] = Object.values(MINOR_POSTES_LAYER_KEYS)
  if (minorPostesLayerKeys.every(layer => layerList.includes(layer))) {
    layerList = layerList.filter(layer => !minorPostesLayerKeys.includes(layer))
    categories.push(terms.menu.layers.postes.categoryOther)
  }

  const signalLayerKeys: DexcartoLayerKey[] = Object.values(SIGNAL_LAYERS_KEYS)
  if (signalLayerKeys.every(layer => layerList.includes(layer))) {
    layerList = layerList.filter(layer => !signalLayerKeys.includes(layer))
    categories.push(terms.menu.layers.signals.names)
  }

  return [...categories, ...layerList.map(layer => LAYER_NAMES[layer])].join(', ')
}

const labels = {
  [SOURCES_IDS.signal]: terms.featuresPopup.title.signal,
  [SOURCES_IDS.station]: terms.featuresPopup.title.station,
  [SOURCES_IDS.poste]: terms.featuresPopup.title.poste,
  [SOURCES_IDS.jdz]: terms.featuresPopup.title.jdz,
  [SOURCES_IDS.acces]: terms.featuresPopup.title.acces,
  [SOURCES_IDS.sousStation]: terms.featuresPopup.title.sousStation,
  [SOURCES_IDS.css]: terms.featuresPopup.title.centraleSousStation,
  [SOURCES_IDS.localisateur]: terms.featuresPopup.title.localisateur,
  [SOURCES_IDS.dbc]: terms.featuresPopup.title.dbc,
  [SOURCES_IDS.pedale]: terms.featuresPopup.title.pedale,
  [SOURCES_IDS.passageNiveau]: terms.featuresPopup.title.passageNiveau,
  [SOURCES_IDS.actionZone]: terms.featuresPopup.title.zone,
  [SOURCES_IDS.adv]: terms.featuresPopup.title.adv,
  [SOURCES_IDS.advLine]: terms.featuresPopup.title.adv,
  [SOURCES_IDS.chantier]: terms.featuresPopup.title.chantier,
  [SOURCES_IDS.balZone]: terms.featuresPopup.title.balZone,
  [SOURCES_IDS.center]: terms.featuresPopup.title.center,
  [SOURCES_IDS.ihmZone]: terms.featuresPopup.title.ihmZone,
  [SOURCES_IDS.cdv]: terms.featuresPopup.title.cdv,
}

export const getObjectLabel = (object: UserFavoriteObjectDetails): string => {
  const objectSource = Object.entries(MAIN_SOURCES).find(([key]) => key === object.layer_slug)?.[1]
  const sourceLabel = Object.entries(labels).find(([key]) => key === objectSource)?.[1] || 'Objet'
  let label = object.properties?.libelle || 'NC'

  if (objectSource === SOURCES_IDS.actionZone) {
    const { poste_libelle, gare_libelle } = object.properties || {}
    if (poste_libelle && gare_libelle) label = `${poste_libelle} - ${gare_libelle}`
    else if (poste_libelle) label = poste_libelle
    else if (gare_libelle) label = gare_libelle
    else label = 'NC'
  }

  return `${sourceLabel} : ${label}`
}

export const getViewTypeLabel = (viewType: string): string => (viewType === MapTheme.schematic
  ? terms.FavoriteModal.schView : terms.FavoriteModal.geoView
)

export default {
  LAYER_NAMES, getLayerList,
}
