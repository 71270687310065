import { LAYERS_IDS, OBJECTS_LAYERS, LayerProperties } from '../common'

export const chantierLayerStyles: LayerProperties = {
  chantierLine: {
    type: 'line',
    id: LAYERS_IDS.chantierLine,
    'source-layer': OBJECTS_LAYERS.chantier,
    paint: {
      'line-color': '#000000',
      'line-width': 2,
    },
    layout: { visibility: 'visible' },
  },
  chantierLineHighlight: {
    type: 'line',
    id: LAYERS_IDS.chantierLineHighlight,
    'source-layer': OBJECTS_LAYERS.chantier,
    paint: {
      'line-color': '#ff0000',
      'line-width': 2.5,
    },
    layout: { visibility: 'visible' },
  },
  chantierPoint: {
    type: 'circle',
    id: LAYERS_IDS.chantierPoint,
    'source-layer': OBJECTS_LAYERS.chantier,
    paint: {
      'circle-color': '#000000',
      'circle-radius': 5,
      'circle-stroke-color': '#ffffff',
      'circle-stroke-width': 0.5,
    },
    layout: { visibility: 'visible' },
  },
  chantierPointHighlight: {
    type: 'circle',
    id: LAYERS_IDS.chantierPointHighlight,
    'source-layer': OBJECTS_LAYERS.chantier,
    paint: {
      'circle-color': '#ff0000',
      'circle-radius': 7,
      'circle-stroke-color': '#ff0000',
      'circle-stroke-width': 1,
    },
    layout: { visibility: 'visible' },
  },
}

export default {
  chantierLayerStyles,
}
