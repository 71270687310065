import { Checkbox, FormControlLabel } from '@mui/material'
import terms from 'common/terms'
import { ReactElement, useState } from 'react'

import './dataInformation.scss'

interface Props {
  inputCheckbox: boolean
  setInputCheckox: (value: boolean) => void
}

const DataInformation = ({ inputCheckbox, setInputCheckox }: Props): ReactElement => {
  const [displayInformation, setDisplayInformation] = useState(false)
  const browser = window.navigator
  return (
    <div className="data-information">
      <FormControlLabel
        control={(
          <Checkbox
            checked={inputCheckbox}
            onChange={() => setInputCheckox(!inputCheckbox)}
          />
        )}
        label={terms.UserFeedback.DataInformation.checkLabel}
      />
      <div className="button">
        <button
          type="button"
          className="button-information"
          onClick={() => setDisplayInformation(!displayInformation)}
        >
          {terms.UserFeedback.DataInformation.button}
        </button>
      </div>

      {
        displayInformation && (
          <div className="information-content">
            <div className="item">
              <span>
                {terms.UserFeedback.DataInformation.browser}
              </span>
              <span>
                {browser.userAgent}
              </span>
            </div>
            <div className="item">
              <span>
                {terms.UserFeedback.DataInformation.os}
              </span>
              <span>
                {browser.platform}
              </span>
            </div>
            <div className="item">
              <span>
                {terms.UserFeedback.DataInformation.screenWidth}
              </span>
              <span>
                {window.screen.width}
              </span>
            </div>
            <div className="item">
              <span>
                {terms.UserFeedback.DataInformation.screenHeight}
              </span>
              <span>
                {window.screen.height}
              </span>
            </div>
            <div className="item">
              <span>
                {terms.UserFeedback.DataInformation.url}
              </span>
              <span>
                {window.location.href}
              </span>
            </div>
          </div>
        )
      }
    </div>
  )
}

export default DataInformation
