import terms from 'common/terms'
import './CollectionsPage.scss'
import { ArrowBackIosNew, ArrowForwardIos, Search } from '@mui/icons-material'
import { Collection } from 'store/collections/types'
import { useHistory, useLocation } from 'react-router-dom'
import { useMemo } from 'react'
import CollectionDetails, { collectionDetailsSignal } from '../details/CollectionDetails'
import CollectionsTable from '../table/CollectionsTable'

interface Props {
  collections: Collection[]
  selectedCollectionId: number | null
  orderFerroloc: 'asc' | 'desc' | null
  setOrderFerroloc: (order: 'asc' | 'desc' | null) => void
  setSearchValue: (value: string) => void
  setSearchPage: (page: number) => void
  handleGoBack: () => void
  handleSelectCollection: (collectionId: number) => () => void
  loading: boolean
  searchValue: string
  searchPage: number
  totalPage: number
  disableQuit?: boolean
  handleHoverCollection?: (collectionId: string | null) => () => void
}

const CollectionsPage = ({
  orderFerroloc, setOrderFerroloc, handleGoBack, setSearchPage, setSearchValue, handleSelectCollection,
  selectedCollectionId, collections, loading, searchValue, searchPage, totalPage, disableQuit, handleHoverCollection,
}: Props) => {
  const location = useLocation()
  const history = useHistory()
  const handleChangeOrder = () => {
    if (orderFerroloc === 'asc') setOrderFerroloc('desc')
    else setOrderFerroloc('asc')
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value)
    setSearchPage(1)
  }

  const handleQuitCollections = useMemo(() => {
    if (location.state) {
      const { from } = location.state as { from: string }
      if (!from) return () => history.push('/')
      return () => history.push(from)
    }
    return () => history.push('/')
  }, [])

  return (
    <div className="collections-page">
      <div className="header">
        {!disableQuit && (
          <button type="button" onClick={handleQuitCollections} className="quit">
            {terms.CollectionsView.quit}
          </button>
        )}
        <div className="title">
          <button type="button" onClick={handleGoBack}>
            {terms.CollectionsView.title}
          </button>
          {collectionDetailsSignal.value && (
            <>
              <ArrowForwardIos />
              <span>
                {`Collection ${collectionDetailsSignal.value.libelle}`}
              </span>
            </>
          )}
        </div>
        {selectedCollectionId ? (
          <button type="button" className="back" onClick={handleGoBack}>
            {terms.CollectionsView.back}
          </button>
        ) : (
          <div className="search">
            <Search />
            <input
              type="text"
              placeholder={terms.CollectionsView.searchPlaceholder}
              value={searchValue}
              onChange={handleChange}
            />
          </div>
        )}
      </div>
      {selectedCollectionId ? (
        <CollectionDetails collectionId={selectedCollectionId} />
      ) : (
        <>
          <CollectionsTable
            collections={collections}
            loading={loading}
            handleSelectCollection={handleSelectCollection}
            ferrolocOrder={orderFerroloc}
            handleChangeOrder={handleChangeOrder}
            handleHoverCollection={handleHoverCollection}
          />
          <div className="pagination">
            <button
              type="button"
              onClick={() => setSearchPage(searchPage - 1)}
              disabled={searchPage === 1}
            >
              <ArrowBackIosNew />
            </button>
            <span>{`${terms.CollectionsView.page} ${searchPage} / ${totalPage}`}</span>
            <button
              type="button"
              onClick={() => setSearchPage(searchPage + 1)}
              disabled={searchPage >= totalPage}
            >
              <ArrowForwardIos />
            </button>
          </div>
        </>
      )}
    </div>
  )
}

CollectionsPage.defaultProps = {
  disableQuit: false,
  handleHoverCollection: undefined,
}

export default CollectionsPage
