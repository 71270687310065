import { LayerProperties } from './type'

export const CommonLayerProperties: LayerProperties = {
  circleLayer: {
    type: 'circle',
    paint: {
      'circle-radius': 5,
    },
  },
}

export const hoverColor = (color: string, ids: string[]): mapboxgl.Expression => (['case',
  ['in', ['get', 'id'], ['literal', ids]], '#cc0000',
  color,
])
