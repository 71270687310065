import { Layer, Source } from 'react-map-gl'
import { MapURLParams, useMapURL } from '../hooks'
import { LAYERS_IDS, OBJECTS_LAYERS, SOURCES_IDS, hoverColor } from '../common'

interface Props {
  hoveredObjectsIds: string[]
  visibility: {
    ihmZone: boolean
    ihmZoneAmendee: boolean
  }
  params?: MapURLParams
  view: string
}

const IHMZoneLabelLayer = ({ hoveredObjectsIds, visibility, params, view }:Props) => {
  const url = useMapURL(
    OBJECTS_LAYERS.ihmZone,
    view,
    SOURCES_IDS.ihmZoneLabel,
    params,
  )

  return (
    <Source
      id={SOURCES_IDS.ihmZoneLabel}
      type="vector"
      url={url}
    >
      <Layer
        id={LAYERS_IDS.ihmZoneLabel}
        type="symbol"
        paint={{ 'text-color': '#fff' }}
        layout={{
          'icon-image': ['concat', 'rectanglearrondi-', hoverColor('#0066FF', hoveredObjectsIds)],
          'icon-text-fit': 'both',
          'icon-allow-overlap': false,
          'text-font': ['Open Sans Bold'],
          'text-allow-overlap': false,
          'text-size': 12,
          'text-justify': 'left',
          'text-field': ['get', 'libelle'],
          'text-max-width': 1000,
          visibility: visibility.ihmZone ? 'visible' : 'none',
        }}
        source-layer={OBJECTS_LAYERS.ihmZone}
        minzoom={11}
        filter={['==', ['get', 'modifiable'], false]}
      />
      <Layer
        id={LAYERS_IDS.ihmZoneAmendeeLabel}
        type="symbol"
        paint={{ 'text-color': '#fff' }}
        layout={{
          'icon-image': ['concat', 'rectanglearrondi-', hoverColor('#0066FF', hoveredObjectsIds)],
          'icon-text-fit': 'both',
          'icon-allow-overlap': false,
          'text-font': ['Open Sans Bold'],
          'text-allow-overlap': false,
          'text-size': 12,
          'text-justify': 'left',
          'text-field': ['get', 'libelle'],
          'text-max-width': 1000,
          visibility: visibility.ihmZoneAmendee ? 'visible' : 'none',
        }}
        source-layer={OBJECTS_LAYERS.ihmZone}
        minzoom={11}
        filter={['==', ['get', 'modifiable'], true]}
      />
    </Source>
  )
}

IHMZoneLabelLayer.defaultProps = {
  params: {},
}

export default IHMZoneLabelLayer
