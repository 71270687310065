import thunk from 'redux-thunk'
import { persistStore, persistCombineReducers } from 'redux-persist'
import { configureStore } from '@reduxjs/toolkit'
import { createFilter } from 'redux-persist-transform-filter'
import storage from 'redux-persist/lib/storage' // defaults to localStorage

import { auth } from '@osrdata/app_core'
import appReducer from './app'
import mapReducer from './map'
import zoneEditorReducer from './zoneEditor/zoneEditor'
import feedbackReducer from './feedback'
import objectSearchReducer from './objectSearch/objectSearch'
import userFavoriteReducer from './userFavorite/userFavorite'
import collectionsReducer from './collections/collections'

const userReducer = auth.reducer

const userWhiteList = [
  'account',
]

const saveUserFilter = createFilter(
  'user',
  userWhiteList,
)

const persistConfig = {
  key: 'root',
  storage,
  transforms: [saveUserFilter],
  whitelist: ['user'],
}

const rootReducer = {
  user: userReducer,
  app: appReducer,
  map: mapReducer,
  zoneEditor: zoneEditorReducer,
  feedback: feedbackReducer,
  objectSearch: objectSearchReducer,
  userFavorite: userFavoriteReducer,
  collections: collectionsReducer,
}

const store = configureStore({
  reducer: persistCombineReducers(persistConfig, rootReducer) as unknown as typeof rootReducer,
  middleware: getDefaultMiddleware => getDefaultMiddleware({
    serializableCheck: false,
  }).concat(thunk),
  devTools: process.env.NODE_ENV !== 'production',
})

const persistor = persistStore(store)

export {
  store,
  persistor,
}

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
