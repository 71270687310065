export const getDateRange = (dateRangeStart: moment.Moment, dateRangeEnd: moment.Moment) => {
  const startDate = dateRangeStart.locale('fr').format('LL')
  const endDate = dateRangeEnd.locale('fr').format('LL')

  if (startDate === endDate) {
    return startDate
  }
  return `${startDate} - ${endDate}`
}

export default {
  getDateRange,
}
