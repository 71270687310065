import { Search } from '@mui/icons-material'
import { useDispatch, useSelector } from 'react-redux'
import { useCallback, useEffect, useRef, useState } from 'react'
import './ObjectSearch.scss'
import { searchAllObjects } from 'store/objectSearch/objectSearch.thunk'
import { RootState } from 'store'
import terms from 'common/terms'
import { resetSearchResults } from 'store/objectSearch/objectSearch'
import { Tooltip } from '@mui/material'
import { mapBboxSignal } from 'views/Map'
import { DexcartoLayerKey } from 'components/Layers/common'
import { debounce } from 'lodash'
import { Polygon } from 'geojson'
import { MapTheme } from 'components/Toolbar/ThemeMenu/const'
import { SearchResult } from 'utils/search/types'
import SearchResults from './SearchResults'
import LineSearch from './LineSearch'

interface Props {
  setHoveredObjectsIds: (ids: string[]) => void
  theme: MapTheme
  activeLayers: DexcartoLayerKey[]
  handleClickResult: (result: SearchResult) => () => void
}

const ObjectSearch = ({ setHoveredObjectsIds, theme, activeLayers, handleClickResult }:Props) => {
  const dispatch = useDispatch()
  const {
    searchResults, searchError, searchPending,
    dateRangeStart, dateRangeEnd, lineFilter, lineFilterActive,
  } = useSelector((state: RootState) => state.objectSearch)
  const [isExpanded, setIsExpanded] = useState<boolean>(false)
  const [searchValue, setSearchValue] = useState<string>('')
  const [displayResults, setDisplayResults] = useState(false)
  const inputRef = useRef<HTMLInputElement>(null)
  const componentRef = useRef<HTMLDivElement>(null)

  const debouncedSearch = useCallback(debounce((value: string, bbox: Polygon) => {
    dispatch(searchAllObjects({
      activeLayers: activeLayers as DexcartoLayerKey[],
      query: value,
      bbox,
      params: {
        dateRangeStart, dateRangeEnd, lineFilter, lineFilterActive,
      },
      theme,
    }))
  }, 500), [activeLayers, theme, dateRangeEnd, dateRangeStart, lineFilter, lineFilterActive])

  const handleClose = () => {
    setSearchValue('')
    setDisplayResults(false)
    setIsExpanded(false)
    dispatch(resetSearchResults())
  }

  useEffect(() => {
    if (isExpanded) {
      inputRef?.current?.focus()
    } else {
      handleClose()
    }
  }, [isExpanded])

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (componentRef.current && !componentRef.current.contains(event.target as Node)) {
        handleClose()
      }
    }
    document.addEventListener('mousedown', handleClickOutside)
    return () => document.removeEventListener('mousedown', handleClickOutside)
  }, [componentRef])

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value)
    if (e.target.value === '') {
      setDisplayResults(false)
    } else {
      setDisplayResults(true)
      const bbox = mapBboxSignal.value

      if (bbox) {
        debouncedSearch(e.target.value, bbox)
      }
    }
  }

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Escape' && isExpanded) {
      setIsExpanded(false)
    }
  }

  const getErrorMessage = () => {
    if (searchError) return terms.objectSearch.error
    return ''
  }

  const handleResultHover = (id: string) => {
    setHoveredObjectsIds([id])
  }

  const handleClick = (result: SearchResult) => () => {
    handleClickResult(result)()
    handleClose()
  }

  return (
    <div className={`object-search${isExpanded ? ' expanded' : ''}`} ref={componentRef}>
      <div className="input">
        <Tooltip title={!isExpanded && terms.tooltips.searchObject}>
          <button type="button" className="search-button" onClick={() => setIsExpanded(prev => !prev)}>
            <Search />
          </button>
        </Tooltip>
        <LineSearch />
        <input
          type="text"
          ref={inputRef}
          value={searchValue}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          placeholder={terms.objectSearch.placeholder}
          disabled={getErrorMessage() !== ''}
        />
      </div>

      {getErrorMessage() ? (
        <span className="errors">
          {getErrorMessage()}
        </span>
      ) : (
        <span className="hint">
          {terms.objectSearch.hint}
        </span>
      ) }
      { !displayResults && (
        <span className="hint">
          {terms.objectSearch.searchInfos}
        </span>
      )}
      <SearchResults
        results={searchResults}
        displayResults={displayResults}
        handleHover={handleResultHover}
        pending={searchPending}
        handleClick={handleClick}
      />
    </div>
  )
}

export default ObjectSearch
