import { ReactElement, useState } from 'react'
import { _useMapControl as useMapControl } from 'react-map-gl'
import { ReactComponent as LayersIcon } from 'assets/icons/layers.svg'
import { Tooltip } from '@mui/material'
import { Construction, Settings } from '@mui/icons-material'
import terms from 'common/terms'
import { perimeterActiveLayersSignal } from 'views/Perimeter/PerimeterView/PerimeterView'
import LayerMenu from './LayerMenu/LayerMenu'
import ChantierMenu from './ChantierMenu/ChantierMenu'
import { MapTheme } from './ThemeMenu/const'
import './PerimeterToolbar.scss'
import PerimeterViewMenu from './PerimeterViewMenu/PerimeterViewMenu'

enum MenuKeys {
  viewmode = 'viewmode',
  layers = 'layers',
  chantier = 'chantier',
}

const MENU_ITEMS = [
  {
    key: MenuKeys.viewmode,
    icon: <Settings />,
    label: terms.tooltips.menuPerimeterView,
  },
  {
    key: MenuKeys.layers,
    icon: <LayersIcon />,
    label: terms.tooltips.menuLayers,
  },
  {
    key: MenuKeys.chantier,
    icon: <Construction />,
    label: terms.tooltips.menuChantier,
  },
]

const PerimeterToolbar = (): ReactElement => {
  const { containerRef: menuRef } = useMapControl({
    captureDrag: true, captureClick: true, captureDoubleClick: true, captureScroll: true, capturePointerMove: true,
  })
  const { containerRef: tabsRef } = useMapControl({
    captureDrag: true, captureClick: true, captureDoubleClick: true, captureScroll: true, capturePointerMove: true,
  })
  const [visibleMenu, setVisibleMenu] = useState<MenuKeys>(MenuKeys.viewmode)
  const [expanded, setExpanded] = useState<boolean>(false)
  const toggleDisplayMenu = (key: MenuKeys) => {
    setVisibleMenu(key)
    setExpanded(key === visibleMenu ? !expanded : true)
  }

  const isLayerActive = (layer: string) => perimeterActiveLayersSignal.value.includes(layer)
  const updateLayerVisibility = (layer: string, checked: boolean) => {
    if (checked) {
      perimeterActiveLayersSignal.value = [...perimeterActiveLayersSignal.value, layer]
    } else {
      perimeterActiveLayersSignal.value = perimeterActiveLayersSignal.value.filter(l => l !== layer)
    }
  }

  return (
    <div className="perimeter-toolbar">
      <div className={`tabs ${expanded ? 'display-menu' : ''}`} ref={tabsRef}>
        {MENU_ITEMS.map(item => (
          <Tooltip key={item.key} title={item.label} placement="left">
            <button
              className="icon-wrapper"
              onClick={() => toggleDisplayMenu(item.key)}
              type="button"
              tabIndex={-1}
            >
              {item.icon}
            </button>
          </Tooltip>
        ))}
      </div>

      <div className={`content ${expanded ? 'visible' : 'hidden'}`} ref={menuRef}>

        {visibleMenu === MenuKeys.viewmode && expanded && (
          <PerimeterViewMenu />
        )}

        {visibleMenu === MenuKeys.layers && expanded && (
        <LayerMenu
          isLayerActive={isLayerActive}
          theme={MapTheme.perimeter}
          updateLayerVisibility={updateLayerVisibility}
        />
        )}

        {visibleMenu === MenuKeys.chantier && expanded && (
        <ChantierMenu
          isLayerActive={isLayerActive}
          theme={MapTheme.perimeter}
          updateLayerVisibility={updateLayerVisibility}
        />
        )}
      </div>
    </div>
  )
}

export default PerimeterToolbar
