import { Layer, Source } from 'react-map-gl'
import { LAYERS_IDS, LAYERS_KEYS, OBJECTS_LAYERS, SOURCES_IDS } from '../common'
import { useLineFilterOpacity, useMapURL } from '../hooks'

interface Props {
  visible: boolean
  principalLayer: string | null
  selectedBorderLabel: string
}

const UTMLayer = ({ visible, principalLayer, selectedBorderLabel }: Props) => {
  const url = useMapURL(
    OBJECTS_LAYERS.utm,
    'full_rgi_track_sch_flat',
    SOURCES_IDS.utm,
    { type_structure_libelle: 'Unité de production' },
  )
  const { opacity, lineFilter } = useLineFilterOpacity()

  return (
    <Source
      id={SOURCES_IDS.utm}
      type="vector"
      url={url}
    >
      <Layer
        type="line"
        paint={{
          'line-color': ['case',
            principalLayer === LAYERS_KEYS.utm, ['get', 'color'],
            'transparent',
          ],
          'line-opacity': [
            'case',
            ['in', lineFilter, ['get', 'code_ligne']], 1,
            opacity,
          ],
          'line-width': 2,
        }}
        id={LAYERS_IDS.utm}
        source-layer={OBJECTS_LAYERS.utm}
        layout={{
          visibility: visible ? 'visible' : 'none',
        }}
      />
      <Layer
        type="line"
        paint={{
          'line-color': ['get', 'color'],
          'line-opacity': 0.8,
          'line-width': 4,
        }}
        id={LAYERS_IDS.utmHighlight}
        source-layer={OBJECTS_LAYERS.utm}
        layout={{
          visibility: visible ? 'visible' : 'none',
        }}
        filter={['==', 'structure_libelle', selectedBorderLabel]}
      />
    </Source>
  )
}

export default UTMLayer
