import { ReactElement, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { MapEvent, Popup, _useMapControl as useMapControl } from 'react-map-gl'

import List from '@mui/material/List'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemText from '@mui/material/ListItemText'
import Paper from '@mui/material/Paper'

import { resetEditor } from 'store/zoneEditor/zoneEditor'
import CONTEXT_MENU_ITEMS from './contextMenuItems'
import './ContextMenu.scss'

type Props = {
  event: MapEvent;
  reset: () => void;
  hoveredEvent: MapEvent | undefined;
  setMapHover: (e: MapEvent | undefined) => void;
  setHoveredId: (id: string[]) => void;
}

export default function ContextMenu({
  event, reset, hoveredEvent, setMapHover, setHoveredId,
}: Props): ReactElement | null {
  const dispatch = useDispatch()
  const { containerRef } = useMapControl({
    captureDrag: true, captureClick: true, captureDoubleClick: true, captureScroll: true, capturePointerMove: true,
  })
  const [selectedItemId, setSelectedItemId] = useState<string | undefined>(undefined)
  const coords = event.lngLat

  const handleMenuClick = (next?: () => void) => {
    if (next !== undefined) next()
    setTimeout(reset, 300)
  }

  useEffect(() => {
    setSelectedItemId(undefined)
    dispatch(resetEditor())
  }, [hoveredEvent])

  if (!hoveredEvent) return null
  return (
    <Popup
      longitude={coords[0]}
      latitude={coords[1]}
      closeButton={false}
      className="context-menu-popup"
      tipSize={0}
      anchor="top-left"
      captureScroll
      captureClick
      capturePointerMove
      onPointerMove={() => setMapHover(undefined)}
      onClose={() => reset()}
    >
      <Paper className="popup">
        {hoveredEvent?.features?.length === 1 && (
          <List className="list">
            {CONTEXT_MENU_ITEMS(hoveredEvent.features[0])
              .filter(item => item.show)
              .map(item => (
                <ListItemButton key={item.key} onClick={() => handleMenuClick(item.onClick)} dense>
                  <ListItemText>
                    <div className="label">{item.label}</div>
                  </ListItemText>
                </ListItemButton>
              ))}
          </List>
        )}
        {hoveredEvent?.features?.length && hoveredEvent.features.length > 1 && (
          <List className="list">
            {hoveredEvent.features.map(feature => (
              <div key={feature.properties.id}>
                <ListItemButton
                  dense
                  onClick={() => setSelectedItemId(feature.properties.id)}
                  onMouseEnter={() => setHoveredId([feature.properties.id])}
                  onBlur={() => setHoveredId([])}
                  selected={selectedItemId === feature.properties.id}
                >
                  <ListItemText>
                    <div className="label">{feature.properties.poste_libelle}</div>
                  </ListItemText>
                </ListItemButton>
                <div className="sublist" ref={containerRef}>
                  {selectedItemId === feature.properties.id && (
                  <List className="list">
                    {CONTEXT_MENU_ITEMS(feature)
                      .filter(item => item.show)
                      .map(item => (
                        <ListItemButton key={item.key} onClick={() => handleMenuClick(item.onClick)} dense>
                          <ListItemText>
                            <div className="label">{item.label}</div>
                          </ListItemText>
                        </ListItemButton>
                      ))}
                  </List>
                  )}
                </div>
              </div>

            ))}
          </List>
        )}
      </Paper>
    </Popup>
  )
}
