import { ReactElement } from 'react'
import { Source, Layer } from 'react-map-gl'
import { LAYERS_IDS, OBJECTS_LAYERS, SOURCES_IDS } from '../common'
import ActionZonePosteLayerProps from './ActionZonePosteLayerProps'
import { MapURLParams, useMapURL } from '../hooks'

interface Props {
  hoveredObjectsIds: string[]
  filterZapId?: string | null
  filterOutZapId?: string | null
  opacity?: number
  lineFilter?: string
  visibility?: {
    zoneActionPoste: boolean
    zoneActionPosteAmendee: boolean
  }
  view: string
  params?: MapURLParams
}

const ActionZonePosteLayer = ({
  hoveredObjectsIds, filterZapId, filterOutZapId, opacity, lineFilter, visibility, params, view,
}: Props): ReactElement => {
  const url = useMapURL(
    OBJECTS_LAYERS.actionZonePoste,
    view,
    SOURCES_IDS.actionZone,
    {
      ...params,
      ...(filterZapId && { id: filterZapId }),
    },
  )

  return (
    <Source
      id={SOURCES_IDS.actionZone}
      type="vector"
      url={url}
    >
      <Layer
        {...ActionZonePosteLayerProps}
        id={LAYERS_IDS.zoneActionPoste}
        layout={{
          visibility: visibility?.zoneActionPoste ? 'visible' : 'none',
        }}
        paint={{
          ...ActionZonePosteLayerProps.paint,
          'fill-opacity': ['case',
            ['in', lineFilter, ['get', 'codes_ligne']], 0.25,
            ['in', ['get', 'id'], ['literal', hoveredObjectsIds]], 0.25,
            0.25 * (opacity || 1)],
        }}
        filter={['==', ['get', 'modifiable'], false]}
      />
      <Layer
        {...ActionZonePosteLayerProps}
        id={LAYERS_IDS.zoneActionPosteAmendee}
        layout={{
          visibility: visibility?.zoneActionPosteAmendee ? 'visible' : 'none',
        }}
        paint={{
          ...ActionZonePosteLayerProps.paint,
          'fill-opacity': ['case',
            ['in', lineFilter, ['get', 'codes_ligne']], 0.25,
            ['in', ['get', 'id'], ['literal', hoveredObjectsIds]], 0.25,
            0.25 * (opacity || 1)],
        }}
        filter={['all', ['==', ['get', 'modifiable'], true], ['!=', ['get', 'id'], filterOutZapId || '']]}
      />
      <Layer
        type="fill"
        id={LAYERS_IDS.zoneActionPosteAmendeeStripes}
        source-layer={OBJECTS_LAYERS.actionZonePoste}
        paint={{
          'fill-pattern': 'stripes',
          'fill-opacity': ['case',
            ['in', lineFilter, ['get', 'codes_ligne']], 0.1,
            ['in', ['get', 'id'], ['literal', hoveredObjectsIds]], 0.1,
            0.1 * (opacity || 1)],
        }}
        layout={{
          visibility: visibility?.zoneActionPosteAmendee ? 'visible' : 'none',
        }}
        filter={['all', ['==', ['get', 'modifiable'], true], ['!=', ['get', 'id'], filterOutZapId || '']]}
        minzoom={11}
      />
      <Layer
        type="line"
        id={LAYERS_IDS.zoneActionPosteOutline}
        source-layer={OBJECTS_LAYERS.actionZonePoste}
        paint={{
          'line-color': '#CC0000',
          'line-width': 2,
          'line-opacity': ['case',
            ['in', lineFilter, ['get', 'codes_ligne']], 1,
            ['in', ['get', 'id'], ['literal', hoveredObjectsIds]], 1,
            opacity],
        }}
        filter={['in', ['get', 'id'], ['literal', hoveredObjectsIds]]}
        minzoom={11}
      />
    </Source>
  )
}

ActionZonePosteLayer.defaultProps = {
  filterZapId: undefined,
  filterOutZapId: undefined,
  opacity: 1,
  lineFilter: '',
  visibility: {
    zoneActionPoste: true,
    zoneActionPosteAmendee: true,
  },
  params: {},
}

export default ActionZonePosteLayer
