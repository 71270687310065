import { Search } from '@mui/icons-material'
import { useDispatch, useSelector } from 'react-redux'
import { useCallback, useEffect, useRef, useState } from 'react'
import './ObjectSearch.scss'
import { searchAllObjects } from 'store/objectSearch/objectSearch.thunk'
import { RootState } from 'store'
import terms from 'common/terms'
import { resetSearchResults } from 'store/objectSearch/objectSearch'
import { UserStudyPerimeterDetailsWithId } from 'store/userFavorite/types'
import { Tooltip } from '@mui/material'
import { DexcartoLayerKey } from 'components/Layers/common'
import { debounce } from 'lodash'
import { MapTheme } from 'components/Toolbar/ThemeMenu/const'
import { SearchResult } from 'utils/search/types'
import SearchResults from './SearchResults'
import './PerimeterSearch.scss'

interface Props {
  setHoveredObjectsIds: (ids: string[]) => void
  activeLayers: DexcartoLayerKey[]
  handleClickResult: (result: SearchResult) => () => void
  activePerimeter: UserStudyPerimeterDetailsWithId
}

const PerimeterSearch = ({ setHoveredObjectsIds, activeLayers, handleClickResult, activePerimeter }:Props) => {
  const dispatch = useDispatch()
  const {
    searchResults, searchPending, searchError,
    dateRangeStart, dateRangeEnd, lineFilter, lineFilterActive,
  } = useSelector((state: RootState) => state.objectSearch)
  const [searchValue, setSearchValue] = useState<string>('')
  const [displayResults, setDisplayResults] = useState(false)
  const inputRef = useRef<HTMLInputElement>(null)
  const componentRef = useRef<HTMLDivElement>(null)

  const debouncedSearch = useCallback(debounce((value: string) => {
    dispatch(searchAllObjects({
      activeLayers: activeLayers as DexcartoLayerKey[],
      query: value,
      bbox: activePerimeter.geometry,
      params: {
        dateRangeStart, dateRangeEnd, lineFilter, lineFilterActive,
      },
      theme: MapTheme.perimeter,
    }))
  }, 500), [activeLayers, dateRangeEnd, dateRangeStart, lineFilter, lineFilterActive])

  const handleClose = () => {
    setSearchValue('')
    setDisplayResults(false)
    dispatch(resetSearchResults())
  }

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (componentRef.current && !componentRef.current.contains(event.target as Node)) {
        handleClose()
      }
    }
    document.addEventListener('mousedown', handleClickOutside)
    return () => document.removeEventListener('mousedown', handleClickOutside)
  }, [componentRef])

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value)
    if (e.target.value === '') {
      setDisplayResults(false)
    } else {
      setDisplayResults(true)
      debouncedSearch(e.target.value)
    }
  }

  const handleResultHover = (id: string) => {
    setHoveredObjectsIds([id])
  }

  const handleClick = (result: SearchResult) => () => {
    handleClickResult(result)()
    handleClose()
  }

  return (
    <div className="perimeter-search" ref={componentRef}>
      <div className={`input ${searchError ? 'error' : ''}`}>
        <Tooltip title={terms.tooltips.searchObject}>
          <Search />
        </Tooltip>
        <input
          type="text"
          ref={inputRef}
          value={searchValue}
          onChange={handleChange}
          placeholder={terms.objectSearch.placeholder}
        />
      </div>
      <SearchResults
        results={searchResults}
        displayResults={displayResults}
        handleHover={handleResultHover}
        pending={searchPending}
        handleClick={handleClick}
        error={searchError}
      />
    </div>
  )
}

export default PerimeterSearch
