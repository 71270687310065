import { OBJECTS_LAYERS, LAYERS_IDS, hoverColor, LayerProperties } from '../common'

const getMnemo = ['get', 'type_installation_fixe_id_mnemo']
const getPictoImage: mapboxgl.Expression = ['case',
  ['in', getMnemo, ['literal', ['CARRE', 'CARRE (CH)']]], 'signal_lumineux_carre_bis',
  ['==', getMnemo, 'CV'], 'signal_lumineux_carre_violet_bis',
  ['in', getMnemo, ['literal', ['S', 'S (CH)']]], 'semaphore',
  ['==', getMnemo, 'D'], 'signal_lumineux_disque_vitesse_limite',
  ['in', getMnemo, ['literal', ['A', 'A (CH)']]], 'signal_lumineux_avertissement_vitesse_limite',
  ['==', getMnemo, 'GA'], 'signal_guidon_arret',
  ['==', getMnemo, 'SG HEURT'], 'heurtoir',
  'cercle2',
]

const getPictoOffset: mapboxgl.Expression = ['case',
  ['in', getMnemo, ['literal', ['CARRE', 'CARRE (CH)', 'S', 'S (CH)']]],
  ['case', ['==', ['get', 'lrs_sens'], 'C'], ['literal', [-50, -150]], ['literal', [50, -150]]],
  ['==', getMnemo, 'GA'],
  ['case', ['==', ['get', 'lrs_sens'], 'C'], ['literal', [-50, -60]], ['literal', [50, -60]]],
  ['case', ['==', ['get', 'lrs_sens'], 'C'], ['literal', [-50, -100]], ['literal', [50, -100]]],
]

const getTextOffset: mapboxgl.Expression = ['case',
  ['in', getMnemo, ['literal', ['CARRE', 'CARRE (CH)', 'S', 'S (CH)']]],
  ['case', ['==', ['get', 'lrs_sens'], 'C'], ['literal', [-2, -7]], ['literal', [2, -7]]],
  ['==', getMnemo, 'GA'],
  ['case', ['==', ['get', 'lrs_sens'], 'C'], ['literal', [-1.75, -3.25]], ['literal', [1.75, -3.25]]],
  ['case', ['==', ['get', 'lrs_sens'], 'C'], ['literal', [-2, -5.5]], ['literal', [2, -5.5]]],
]

export const getTextField = (hoveredObjectsIds: string[]): mapboxgl.Expression => (['case',
  ['==', getMnemo, 'SG HEURT'],
  ['format', ''],
  [
    'format',
    ['get', 'libelle'],
    {},
    '\n',
    {},
    ['get', 'lrs_pk'],
    {
      'text-color': hoverColor('#0088ce', hoveredObjectsIds),
    },
  ],
])

const SignalLayerProps: LayerProperties = {
  signalCircle: {
    id: LAYERS_IDS.signalCircle,
    type: 'circle',
    paint: {
      'circle-radius': 3,
    },
    'source-layer': OBJECTS_LAYERS.signal,
    minzoom: 9,
  },
  signalHighlight: {
    id: LAYERS_IDS.signalHighlight,
    type: 'circle',
    paint: {
      'circle-radius': 6,
      'circle-stroke-color': '#ff0000',
      'circle-stroke-width': 2,
      'circle-color': 'transparent',
    },
    layout: {},
    'source-layer': OBJECTS_LAYERS.signal,
    minzoom: 9,
  },
  signalMat: {
    id: LAYERS_IDS.signalMat,
    type: 'symbol',
    layout: {
      'icon-image': ['case', ['==', ['get', 'lrs_sens'], 'C'], 'mat_gauche', 'mat_droite'],
      'icon-allow-overlap': true,
      'icon-size': 0.8,
      'icon-rotation-alignment': 'map',
    },
    paint: {},
    'source-layer': OBJECTS_LAYERS.signal,
    minzoom: 14,
  },
  signalPicto: {
    id: LAYERS_IDS.signalPicto,
    type: 'symbol',
    layout: {
      'icon-image': getPictoImage,
      'icon-allow-overlap': true,
      'icon-size': 0.4,
      'icon-rotation-alignment': 'map',
      'icon-offset': getPictoOffset,
      'text-font': ['Open Sans Regular'],
      'text-size': 13,
      'text-rotation-alignment': 'map',
      'text-offset': getTextOffset,
      'text-allow-overlap': true,
    },
    paint: {},
    'source-layer': OBJECTS_LAYERS.signal,
    minzoom: 14,
  },
  signalLabel: {
    id: LAYERS_IDS.signalLabel,
    type: 'symbol',
    layout: {
      'text-field': ['format', 'S', '\n', ['get', 'libelle']],
      'text-font': ['Open Sans Regular'],
      'text-size': 13,
      'text-allow-overlap': false,
      'text-offset': ['case', ['==', ['get', 'lrs_sens'], 'C'], ['literal', [1, 0]], ['literal', [-1, 0]]],
      'text-variable-anchor': ['left', 'right', 'bottom-left', 'bottom-right', 'top-left', 'top-right'],
    },
    paint: {},
    'source-layer': OBJECTS_LAYERS.signal,
    minzoom: 14,
  },
}

export default SignalLayerProps
