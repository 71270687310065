import { Feature } from 'geojson'
import { RENDER_STATE, SHAPE } from 'react-map-gl-draw'

const CIRCLE_RADIUS = 8

const SELECTED_STYLE = {
  stroke: 'rgb(38, 181, 242)',
  strokeWidth: 2,
  fill: 'rgb(189,189,189)',
  fillOpacity: 0.3,
  cursor: 'pointer',
}

const SELECTED_HANDLE_STYLE = {
  stroke: 'rgb(0, 0, 0)',
  strokeWidth: 1,
  fill: 'rgb(255, 11, 22)',
  cursor: 'pointer',
}

const HOVERED_STYLE = {
  strokeWidth: 2,
  fill: 'rgb(122,202,67)',
  fillOpacity: 0.3,
  cursor: 'pointer',
}

const UNCOMMITTED_STYLE = {
  stroke: 'rgb(189,189,189)',
  strokeDasharray: '4,2',
  strokeWidth: 2,
  fill: 'rgb(189,189,189)',
  fillOpacity: 0.1,
}

const INACTIVE_STYLE = UNCOMMITTED_STYLE

const DEFAULT_STYLE = {
  stroke: '#000000',
  strokeWidth: 2,
  fill: '#a9a9a9',
  fillOpacity: 0.1,
}

type Style = {
  stroke?: string
  strokeWidth?: number
  fill?: string
  fillOpacity?: number
  r?: number
  strokeDasharray?: string
  x?: number
  y?: number
  height?: number
  width?: number
  cursor?: string
}

interface EditorFeatureStyleProps {
  feature: Feature
  state: RENDER_STATE
}

export function editorFeatureStyle({ feature, state }: EditorFeatureStyleProps) {
  const type = feature.properties?.shape || feature.geometry.type
  let style: Style = {}

  switch (state) {
    case RENDER_STATE.SELECTED:
      style = { ...SELECTED_STYLE }
      break

    case RENDER_STATE.HOVERED:
      style = { ...HOVERED_STYLE }
      break

    case RENDER_STATE.UNCOMMITTED:
    case RENDER_STATE.CLOSING:
      style = { ...UNCOMMITTED_STYLE }
      break

    case RENDER_STATE.INACTIVE:
      style = { ...INACTIVE_STYLE }
      break

    default:
      style = {
        ...style,
        cursor: 'pointer',
      }
  }

  switch (type) {
    case SHAPE.POINT:
      style.r = CIRCLE_RADIUS
      break
    case SHAPE.LINE_STRING:
      style.fill = 'none'
      break
    case SHAPE.POLYGON:
      if (state === RENDER_STATE.CLOSING) {
        style.strokeDasharray = '4,2'
      }

      break
    case SHAPE.RECTANGLE:
      if (state === RENDER_STATE.UNCOMMITTED) {
        style.strokeDasharray = '4,2'
      }

      break
    default:
  }

  return style
}

interface EditHandleStyleProps {
  feature: Feature
  shape: 'circle' | 'rect'
  index: number
  state: RENDER_STATE
}

export function editHandleStyle({ shape, index, state }: EditHandleStyleProps) {
  let style: Style = {}
  switch (state) {
    case RENDER_STATE.SELECTED:
      style = { ...SELECTED_HANDLE_STYLE }
      break

    case RENDER_STATE.HOVERED:
      style = { ...HOVERED_STYLE }
      break

    case RENDER_STATE.UNCOMMITTED:
    case RENDER_STATE.CLOSING:
      style = { ...UNCOMMITTED_STYLE }
      break

    case RENDER_STATE.INACTIVE:
      style = { ...INACTIVE_STYLE }
      break

    default:
      style = { ...DEFAULT_STYLE }
  }

  if (index === -1) {
    style = { ...HOVERED_STYLE }
  }

  switch (shape) {
    case 'circle':
      style.r = CIRCLE_RADIUS
      break
    case 'rect':
      style = {
        ...style,
        x: -6,
        y: -6,
        height: 12,
        width: 12,
      }
      break
    default:
  }

  return style
}

export type OnSelectEvent = {
  selectedFeature: Feature | null
  selectedFeatureIndex: number | null
  selectedEditHandleIndex: number | null
  selectedEditHandleIndexes: number[]
  screenCoords: number[]
  mapCoords: number[]
}

export const EMPTY_ON_SELECT_EVENT: OnSelectEvent = {
  selectedFeature: null,
  selectedFeatureIndex: null,
  selectedEditHandleIndex: null,
  selectedEditHandleIndexes: [],
  screenCoords: [],
  mapCoords: [],
}

export default {
  editorFeatureStyle,
  editHandleStyle,
  EMPTY_ON_SELECT_EVENT,
}
