import { ReactElement, memo } from 'react'
import { Checkbox, FormControlLabel, Typography } from '@mui/material'
import terms from 'common/terms'
import VisibilityAccordion from '../VisibilityAccordion/VisibilityAccordion'
import INFRA_LAYERS, { INFRA_LAYERS_KEYS } from './const'
import { MapTheme } from '../ThemeMenu/const'

interface Props {
  isLayerActive: (layer: string) => boolean
  updateLayerVisibility: (layer: string, visible: boolean) => void
  theme: MapTheme
}

const LayerMenu = ({ isLayerActive, theme, updateLayerVisibility }:Props) :ReactElement => {
  const LAYERS = INFRA_LAYERS_KEYS.filter(layer => layer.themes.includes(theme)).map(layer => layer.layer)
  const allSelected = LAYERS.every(layer => isLayerActive(layer))
  const handleSelectAll = () => {
    if (allSelected) {
      LAYERS.forEach(layer => updateLayerVisibility(layer, false))
    } else {
      LAYERS.forEach(layer => updateLayerVisibility(layer, true))
    }
  }
  return (
    <>
      <FormControlLabel
        label={(
          <Typography>
            {allSelected ? terms.menu.layers.chantiers.unselectAll : terms.menu.layers.chantiers.selectAll}
          </Typography>
        )}
        control={(
          <Checkbox
            checked={allSelected}
            indeterminate={!allSelected && LAYERS.some(layer => isLayerActive(layer))}
            onClick={handleSelectAll}
          />
        )}
      />
      <VisibilityAccordion
        layerList={INFRA_LAYERS}
        updateLayerVisibility={updateLayerVisibility}
        isLayerActive={isLayerActive}
        theme={theme}
      />
    </>
  )
}

export default memo(LayerMenu)
