import { ReactElement } from 'react'
import CircularProgress from '@mui/material/CircularProgress'

import './Loader.scss'

type Props = {
  message?: string | undefined;
}

const defaultProps: Props = {
  message: undefined,
}

export default function Loader({ message }: Props): ReactElement {
  return (
    <div className="loader flex-column-center">
      <CircularProgress />
      {message && <div className="m-2">{`${message}...`}</div>}
    </div>
  )
}

Loader.defaultProps = defaultProps
