import { createAsyncThunk } from '@reduxjs/toolkit'
import { deleteRequest, get, post } from '@osrdata/app_core/dist/requests'
import { GEOGRAPHIC_VIEWS, MAIN_SOURCES, SCHEMATIC_VIEWS } from 'components/Layers/common'
import { Feature } from 'geojson'
import { MapTheme } from 'components/Toolbar/ThemeMenu/const'
import {
  UserFavoriteObject, UserFavoriteObjectComment, UserFavoriteObjectWithId,
  UserStudyPerimeterDetails,
  UserStudyPerimeterSimple, UserView, UserViewWithId,
} from './types'

const getUserViews = createAsyncThunk(
  'get/userViews',
  async (_, thunkApi) => {
    try {
      const response: UserViewWithId[] = await get('/dexcarto/user-view/')
      return response.map(userView => ({
        ...userView,
        view: userView.view && Object.values(MapTheme).includes(userView.view) ? userView.view : MapTheme.schematic,
      }))
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const postUserView = createAsyncThunk(
  'post/userView',
  async (params: UserView, thunkApi) => {
    try {
      const response = await post('/dexcarto/user-view/', params)
      return response
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const deleteUserView = createAsyncThunk(
  'delete/userView',
  async (id: number, thunkApi) => {
    try {
      return await deleteRequest(`/dexcarto/user-view/${id}/`)
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const getUserFavoriteObjects = createAsyncThunk(
  'get/userFavoriteObjects',
  async (_, thunkApi) => {
    try {
      const response: UserFavoriteObjectWithId[] = await get('/dexcarto/user-favorite-object/')
      return response
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const getUserFavoriteObjectsDetails = createAsyncThunk(
  'get/userFavoriteObjectsDetails',
  async (theme: MapTheme, thunkApi) => {
    try {
      const response: UserFavoriteObjectWithId[] = await get('/dexcarto/user-favorite-object/')

      const objects = await Promise.allSettled(response.map(async object => {
        const source = Object.entries(MAIN_SOURCES).find(([key]) => key === object.layer_slug)?.[1]
        let views = {}
        switch (theme) {
          case MapTheme.geographic:
            views = GEOGRAPHIC_VIEWS
            break
          case MapTheme.schematic:
            views = SCHEMATIC_VIEWS
            break
          default:
            views = {}
            break
        }
        const view = Object.entries(views).find(([key]) => key === source)?.[1]
        if (!source || !view) throw new Error('Source or view not found')
        const url = `/chartis/v2/layer/${object.layer_slug}/geojson_feature/${view}/`
        const geojson: Feature = await get(url, { [object.layer_id_field]: object.object_id })
        return { ...object, properties: geojson.properties, geometry: geojson.geometry }
      }))
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      return objects.filter(o => o.status === 'fulfilled').map((o : any) => o.value)
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const postUserFavoriteObject = createAsyncThunk(
  'post/userFavoriteObject',
  async (params: UserFavoriteObject, thunkApi) => {
    try {
      const response = await post('/dexcarto/user-favorite-object/', params)
      return response
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const deleteUserFavoriteObject = createAsyncThunk(
  'delete/userFavoriteObject',
  async (id: number, thunkApi) => {
    try {
      return await deleteRequest(`/dexcarto/user-favorite-object/${id}/`)
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const postUserStudyPerimeter = createAsyncThunk(
  'post/userStufyPerimeter',
  async (params: UserStudyPerimeterDetails, thunkApi) => {
    try {
      const response = await post('/dexcarto/user-study-perimeter/', params)
      return response
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const deleteUserStudyPerimeter = createAsyncThunk(
  'delete/userStufyPerimeter',
  async (id: number, thunkApi) => {
    try {
      return await deleteRequest(`/dexcarto/user-study-perimeter/${id}/`)
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const getUserStudyPerimeters = createAsyncThunk(
  'get/userStudyPerimeters',
  async (_, thunkApi) => {
    try {
      const response: UserStudyPerimeterSimple[] = await get('/dexcarto/user-study-perimeter/')
      return response
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const getObjectComments = createAsyncThunk(
  'get/objectComments',
  async (_, thunkApi) => {
    try {
      const response = await get('/dexcarto/infrastructure-object-comment/')
      return response
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const postObjectComment = createAsyncThunk(
  'post/objectComment',
  async (object: Omit<UserFavoriteObjectComment, 'id'>, thunkApi) => {
    try {
      const response = await post('/dexcarto/infrastructure-object-comment/', object)
      return response
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

export {
  getUserViews,
  postUserView,
  deleteUserView,
  getUserFavoriteObjects,
  getUserFavoriteObjectsDetails,
  postUserFavoriteObject,
  deleteUserFavoriteObject,
  postUserStudyPerimeter,
  deleteUserStudyPerimeter,
  getUserStudyPerimeters,
  getObjectComments,
  postObjectComment,
}
