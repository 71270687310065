import { ReactElement } from 'react'
import { Source, Layer } from 'react-map-gl'
import { LAYERS_IDS, OBJECTS_LAYERS, SOURCES_IDS, hoverColor } from '../common'
import { MapURLParams, useMapURL } from '../hooks'

interface Props {
  hoveredObjectsIds: string[]
  view: string
  params: MapURLParams
  opacity?: number
  lineFilter?: string
  visibility: boolean
}

const BFLayer = ({ hoveredObjectsIds, view, params, opacity, lineFilter, visibility }: Props): ReactElement => {
  const url = useMapURL(
    OBJECTS_LAYERS.bf,
    view,
    SOURCES_IDS.bf,
    params,
  )

  return (
    <Source
      id={SOURCES_IDS.bf}
      type="vector"
      url={url}
    >
      <Layer
        type="circle"
        paint={{
          'circle-radius': 3,
          'circle-color': hoverColor('#9d15cf', hoveredObjectsIds),
          'circle-opacity': ['case',
            ['in', lineFilter, ['get', 'lrs_ligne']], 1,
            ['in', ['get', 'id'], ['literal', hoveredObjectsIds]], 1,
            opacity],
        }}
        id={LAYERS_IDS.bfCircle}
        source-layer={OBJECTS_LAYERS.bf}
        layout={{ visibility: visibility ? 'visible' : 'none' }}
        minzoom={9}
      />
      <Layer
        type="circle"
        id={LAYERS_IDS.bfHighlight}
        source-layer={OBJECTS_LAYERS.bf}
        layout={{ visibility: visibility ? 'visible' : 'none' }}
        paint={{
          'circle-radius': 6,
          'circle-stroke-color': '#ff0000',
          'circle-stroke-width': 2,
          'circle-color': 'transparent',
        }}
        filter={['in', ['get', 'id'], ['literal', hoveredObjectsIds]]}
        minzoom={9}
      />
      <Layer
        type="symbol"
        id={LAYERS_IDS.bfLabel}
        paint={{
          'text-color': hoverColor('#000000', hoveredObjectsIds),
          'text-opacity': ['case',
            ['in', lineFilter, ['get', 'lrs_ligne']], 1,
            ['in', ['get', 'id'], ['literal', hoveredObjectsIds]], 1,
            opacity],
          'text-halo-color': ['case',
            ['in', ['get', 'id'], ['literal', hoveredObjectsIds]], '#ff0000',
            'transparent'],
          'text-halo-width': ['case',
            ['in', ['get', 'id'], ['literal', hoveredObjectsIds]], 0.5,
            0.1],
        }}
        source-layer={OBJECTS_LAYERS.bf}
        layout={{
          'text-field': ['format', 'Bifurcation', '\n', ['get', 'libelle']],
          'text-font': ['Open Sans Regular'],
          'text-size': 13,
          'text-anchor': ['case', ['==', ['get', 'lrs_sens'], 'C'], 'right', 'left'],
          'text-offset': ['case', ['==', ['get', 'lrs_sens'], 'C'], ['literal', [-1, 0]], ['literal', [1, 0]]],
          visibility: visibility ? 'visible' : 'none',
        }}
        minzoom={12}
      />
    </Source>
  )
}

BFLayer.defaultProps = {
  opacity: 1,
  lineFilter: '',
}

export default BFLayer
