import { Refresh, Search } from '@mui/icons-material'
import { Tooltip } from '@mui/material'
import { memo, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { get, post } from '@osrdata/app_core/dist/requests'
import { sendError } from 'store/feedback'
import terms from 'common/terms'
import { FeatureCollection } from 'geojson'
import { GeoJSONLayerDataSignal } from 'components/Layers/GeoJSON/GeoJSONLayer'
import HistoryTable from './HistoryTable'
import './HistoryMenu.scss'
import HistoryDetails from './HistoryDetails'
import { HistoryItem, ZapModificationDetails, CerbereUser, ZapModification, DetailsField } from './types'

const GEOM_FIELDS = ['geom_rgi_track_sch_flat', 'geom_rgi_track_geo',
  'libelle_geom_rgi_track_sch_flat', 'libelle_geom_rgi_track_geo']

interface Props {
  handleGoToZone: (zoneId: number) => () => void
}

const HistoryMenu = ({ handleGoToZone }: Props) => {
  const dispatch = useDispatch()
  const [history, setHistory] = useState<HistoryItem[]>([])
  const [viewDetails, setViewDetails] = useState<boolean>(false)
  const [detailsId, setDetailsId] = useState<{id: number, name: string} | null>(null)
  const [details, setDetails] = useState<ZapModificationDetails[]>([])
  const [shownOldGeometries, setShownOldGeometries] = useState<string[]>([])
  const [shownNewGeometries, setShownNewGeometries] = useState<string[]>([])
  const [searchValue, setSearchValue] = useState<string>('')

  const getHistory = (search: string) => {
    get('/dexcarto/modification', { search }).then(response => {
      const userIds = new Set<string>()
      response.results.forEach((item: HistoryItem) => {
        if (item.user_sub) userIds.add(item.user_sub)
      })
      post('/cerbere/users/simple', [...userIds]).then((usersResponse: CerbereUser[]) => {
        const data = response.results.map((item: HistoryItem) => {
          const user = usersResponse.find((u: CerbereUser) => u.id === item.user_sub)
          return { ...item, user_sub: user ? `${user.firstName} ${user.lastName}` : terms.HistoryMenu.unknownUser }
        })
        setHistory(data)
      }).catch(() => {
        dispatch(sendError(terms.HistoryMenu.errorGetHistory))
      })
    }).catch(() => {
      dispatch(sendError(terms.HistoryMenu.errorGetHistory))
    })
  }

  const getDetails = (zoneId: number) => {
    get(`/dexcarto/zone-action-poste/${zoneId}/modifications`).then(response => {
      const { results } = response as { results: ZapModification[] }
      const zapDetails: ZapModificationDetails[] = results.map(modif => {
        const modifiedFields: DetailsField[] = modif.modified_fields.map(field => {
          if (GEOM_FIELDS.includes(field.field)) {
            return {
              field: field.field,
              isGeom: true,
              shown_old: false,
              shown_new: false,
              old_value: field.old_value,
              new_value: field.new_value,
            }
          }
          return { field: field.field, isGeom: false, old_value: field.old_value, new_value: field.new_value }
        })
        return { ...modif, modified_fields: modifiedFields }
      })
      setDetails(zapDetails)
    }).catch(() => {
      dispatch(sendError(terms.HistoryMenu.errorGetDetails))
    })
  }

  useEffect(() => {
    if (!viewDetails) {
      getHistory(searchValue)
    }
  }, [searchValue])

  useEffect(() => {
    const featureCol: FeatureCollection = {
      type: 'FeatureCollection',
      features: [],
    }
    const newDetails = details.map(detail => ({
      ...detail,
      modified_fields: detail.modified_fields.map(field => {
        if (field.isGeom) {
          return {
            ...field,
            shown_old: shownOldGeometries.includes(`${detail.id}-${field.field}-old`),
            shown_new: shownNewGeometries.includes(`${detail.id}-${field.field}-new`),
          }
        }
        return field
      }),
    }))
    setDetails(newDetails)
    newDetails.forEach(detail => {
      try {
        detail.modified_fields.forEach(field => {
          if (field.isGeom) {
            if (field.shown_old) {
              featureCol.features.push({
                type: 'Feature',
                geometry: JSON.parse(field.old_value.replaceAll("'", '"')),
                properties: { modifId: detail.id, field: field.field, old: true },
              })
            }
            if (field.shown_new) {
              featureCol.features.push({
                type: 'Feature',
                geometry: JSON.parse(field.new_value.replaceAll("'", '"')),
                properties: { modifId: detail.id, field: field.field, old: false },
              })
            }
          }
        })
      } catch (e) {
        dispatch(sendError(terms.HistoryMenu.errorShowGeometry))
      }
    })
    GeoJSONLayerDataSignal.value = featureCol
  }, [shownOldGeometries, shownNewGeometries])

  const handleGoToDetails = (item: HistoryItem) => () => {
    getDetails(item.zap)
    setViewDetails(true)
    setDetailsId({ id: item.zap, name: item.zap_libelle })
  }

  const handleGoBack = () => {
    setViewDetails(false)
    setDetailsId(null)
    setDetails([])
    setShownNewGeometries([])
    setShownOldGeometries([])
    getHistory('')
    setSearchValue('')
  }

  const handleRefresh = () => {
    if (viewDetails && detailsId) {
      getDetails(detailsId.id)
    } else {
      getHistory(searchValue)
    }
  }

  const handleShowGeometry = (field: DetailsField, modifId: number, old: boolean) => () => {
    if (!field.isGeom) return
    const geomId = `${modifId}-${field.field}-${old ? 'old' : 'new'}`
    if (old && field.shown_old) {
      setShownOldGeometries(shownOldGeometries.filter(id => id !== geomId))
    } else if (old && !field.shown_old) {
      setShownOldGeometries([...shownOldGeometries, geomId])
    } else if (!old && field.shown_new) {
      setShownNewGeometries(shownNewGeometries.filter(id => id !== geomId))
    } else if (!old && !field.shown_new) {
      setShownNewGeometries([...shownNewGeometries, geomId])
    }
  }

  return (
    <div className="history-menu">
      <div className="header">
        <div className="title">
          {terms.HistoryMenu.title}
        </div>
        <div className="actions">
          {!viewDetails && (
          <div className="input">
            <Search />
            <input
              type="text"
              value={searchValue}
              onChange={e => setSearchValue(e.target.value)}
              placeholder={terms.HistoryMenu.search}
            />
          </div>
          )}
          <Tooltip title={terms.HistoryMenu.refresh}>
            <button type="button" className="refresh" onClick={handleRefresh}>
              <Refresh />
            </button>
          </Tooltip>
        </div>
      </div>
      {viewDetails ? (
        <HistoryDetails
          handleGoBack={handleGoBack}
          itemLabel={detailsId?.name || ''}
          details={details}
          handleShowGeometry={handleShowGeometry}
        />
      ) : (
        <HistoryTable
          handleGoToZone={handleGoToZone}
          handleGoToDetails={handleGoToDetails}
          items={history}
        />
      )}
    </div>
  )
}

export default memo(HistoryMenu)
