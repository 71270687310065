import { MouseEvent, useState, SyntheticEvent } from 'react'
import { Circle, ExpandMore } from '@mui/icons-material'
import {
  Accordion, AccordionDetails, AccordionSummary, Checkbox, FormControlLabel, Radio, Tooltip, Typography,
} from '@mui/material'
import { SubcategoryLayer } from '../types'
import { MapTheme } from '../ThemeMenu/const'

interface Props {
  subcategory: SubcategoryLayer
  updateLayerVisibility: (layer: string, checked: boolean) => void
  defaultExpanded: boolean
  isLayerActive: (layer: string) => boolean
  theme: MapTheme
  mutuallyExclusive?: boolean
  handleClickMutuallyExclusive?: (layerName: string) => () => void
  handleClickRadio?: (layerName: string) => (e: MouseEvent<HTMLButtonElement> | SyntheticEvent<Element>) => void
}

const getLayerKeys = (subcategory: SubcategoryLayer): string[] => {
  const layerKeys: string[] = []
  subcategory.layers.forEach(layer => {
    if (layer.subcategory) {
      layerKeys.push(...getLayerKeys(layer as SubcategoryLayer))
    } else {
      layerKeys.push(layer.layer)
    }
  })
  return layerKeys
}

const SubCategoryAccordion = ({
  subcategory, updateLayerVisibility, defaultExpanded, isLayerActive, theme, mutuallyExclusive,
  handleClickMutuallyExclusive, handleClickRadio,
}: Props) => {
  const [expanded, setExpanded] = useState<boolean>(defaultExpanded)
  const handleExpand = () => setExpanded(!expanded)
  const layerKeys = getLayerKeys(subcategory)
  const isCategoryChecked = () => layerKeys.every(layer => isLayerActive(layer))
  const isCategoryIndeterminate = () => layerKeys.some(layer => isLayerActive(layer))
   && !isCategoryChecked()

  const handleCheckSubcategory = () => {
    const isSubcategoryChecked = isCategoryChecked()
    layerKeys.forEach(layer => { updateLayerVisibility(layer, !isSubcategoryChecked) })
  }
  return (
    <Accordion
      key={subcategory.name}
      className="subcategory-accordion"
      expanded={expanded}
      disableGutters
      elevation={0}
    >
      <AccordionSummary expandIcon={<ExpandMore onClick={handleExpand} />}>
        {mutuallyExclusive ? (
          <Radio
            checked={isCategoryChecked()}
            onChange={handleClickMutuallyExclusive
              ? handleClickMutuallyExclusive(subcategory.name) : handleCheckSubcategory}
            disabled={!subcategory.themes.includes(theme)}
            onClick={handleClickRadio ? handleClickRadio(subcategory.name) : undefined}
          />
        ) : (
          <Checkbox
            checked={isCategoryChecked()}
            indeterminate={isCategoryIndeterminate()}
            onClick={handleCheckSubcategory}
            disabled={!subcategory.themes.includes(theme)}
          />
        )}

        <Typography
          className={`subcategory-name${!subcategory.themes.includes(theme) ? ' disabled' : ''}`}
          onClick={handleExpand}
        >
          {subcategory.name}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        {subcategory.layers.map(sublayer => (sublayer.subcategory ? (
          <SubCategoryAccordion
            key={sublayer.name}
            subcategory={sublayer}
            updateLayerVisibility={updateLayerVisibility}
            defaultExpanded={false}
            isLayerActive={isLayerActive}
            theme={theme}
          />
        ) : (
          <Tooltip title={sublayer.label} placement="right" key={sublayer.name}>
            <FormControlLabel
              sx={{ ml: 2 }}
              label={(
                <>
                  {sublayer.color && (<Circle className="layer-color" style={{ color: sublayer.color }} />)}
                  <span>{sublayer.name}</span>
                </>
              )}
              control={(
                <Checkbox
                  checked={isLayerActive(sublayer.layer)}
                  onChange={(_e, checked) => updateLayerVisibility(sublayer.layer, checked)}
                  disabled={!sublayer.themes.includes(theme)}
                />
              )}
            />
          </Tooltip>

        )))}
      </AccordionDetails>
    </Accordion>
  )
}

SubCategoryAccordion.defaultProps = {
  mutuallyExclusive: false,
  handleClickMutuallyExclusive: undefined,
  handleClickRadio: undefined,
}

export default SubCategoryAccordion
