// import terms from 'common/terms'
import terms from 'common/terms'
import { LAYERS_KEYS } from 'components/Layers/common'
import { HideableLayers, Layer } from '../types'
import { MapTheme } from '../ThemeMenu/const'

const INFRA_LAYERS: HideableLayers[] = [
  {
    category: terms.menu.categories.posteActionZone,
    expanded: false,
    layers: [
      {
        subcategory: false,
        name: terms.menu.layers.posteActionZone,
        layer: LAYERS_KEYS.zoneActionPoste,
        themes: [MapTheme.schematic, MapTheme.geographic, MapTheme.perimeter],
      },
      {
        subcategory: false,
        name: terms.menu.layers.editablePosteActionZone,
        layer: LAYERS_KEYS.zoneActionPosteAmendee,
        themes: [MapTheme.schematic, MapTheme.geographic, MapTheme.perimeter],
      },
      {
        subcategory: false,
        name: terms.menu.layers.balZone,
        layer: LAYERS_KEYS.balZone,
        themes: [MapTheme.schematic, MapTheme.geographic, MapTheme.perimeter],
      },
      {
        subcategory: false,
        name: terms.menu.layers.ihmZone,
        layer: LAYERS_KEYS.ihmZone,
        themes: [MapTheme.schematic, MapTheme.geographic, MapTheme.perimeter],
      },
      {
        subcategory: false,
        name: terms.menu.layers.ihmZoneAmendee,
        layer: LAYERS_KEYS.ihmZoneAmendee,
        themes: [MapTheme.schematic, MapTheme.geographic, MapTheme.perimeter],
      },
    ],
  },
  {
    category: terms.menu.categories.collectionStatus,
    expanded: false,
    mutuallyExclusive: true,
    layers: [
      {
        subcategory: false,
        name: terms.menu.layers.collectionStatus.study,
        layer: LAYERS_KEYS.collectionStatusStudy,
        themes: [MapTheme.schematic, MapTheme.geographic, MapTheme.perimeter],
      },
      {
        subcategory: false,
        name: terms.menu.layers.collectionStatus.waiting,
        layer: LAYERS_KEYS.collectionStatusWaiting,
        themes: [MapTheme.schematic, MapTheme.geographic, MapTheme.perimeter],
      },
      {
        subcategory: true,
        expanded: true,
        name: terms.menu.layers.collectionStatus.received,
        themes: [MapTheme.schematic, MapTheme.geographic, MapTheme.perimeter],
        layers: [
          {
            subcategory: false,
            name: terms.menu.layers.collectionStatus.notStarted,
            layer: LAYERS_KEYS.collectionStatusNotStarted,
            themes: [MapTheme.schematic, MapTheme.geographic, MapTheme.perimeter],
          },
          {
            subcategory: false,
            name: terms.menu.layers.collectionStatus.blocked,
            layer: LAYERS_KEYS.collectionStatusBlocked,
            themes: [MapTheme.schematic, MapTheme.geographic, MapTheme.perimeter],
          },
          {
            subcategory: false,
            name: terms.menu.layers.collectionStatus.notFinished,
            layer: LAYERS_KEYS.collectionStatusNotDone,
            themes: [MapTheme.schematic, MapTheme.geographic, MapTheme.perimeter],
          },
        ],
      },
    ],
  },
  {
    category: terms.menu.categories.signals,
    expanded: false,
    layers: [
      {
        subcategory: true,
        expanded: false,
        name: terms.menu.layers.signals.names,
        themes: [MapTheme.schematic, MapTheme.geographic, MapTheme.perimeter],
        layers: [
          {
            subcategory: false,
            name: terms.menu.layers.signals.signalProtection,
            layer: LAYERS_KEYS.signalProtection,
            themes: [MapTheme.schematic, MapTheme.geographic, MapTheme.perimeter],
          },
          {
            subcategory: false,
            name: terms.menu.layers.signals.signalAA,
            layer: LAYERS_KEYS.signalAA,
            themes: [MapTheme.schematic, MapTheme.geographic, MapTheme.perimeter],
          },
          {
            subcategory: false,
            name: terms.menu.layers.signals.signalLDV,
            layer: LAYERS_KEYS.signalLDV,
            themes: [MapTheme.schematic, MapTheme.geographic, MapTheme.perimeter],
          },
          {
            subcategory: false,
            name: terms.menu.layers.signals.signalManoeuvre,
            layer: LAYERS_KEYS.signalManoeuvre,
            themes: [MapTheme.schematic, MapTheme.geographic, MapTheme.perimeter],
          },
          {
            subcategory: false,
            name: terms.menu.layers.signals.signalID,
            layer: LAYERS_KEYS.signalID,
            themes: [MapTheme.schematic, MapTheme.geographic, MapTheme.perimeter],
          },
          {
            subcategory: false,
            name: terms.menu.layers.signals.signalDT,
            layer: LAYERS_KEYS.signalDT,
            themes: [MapTheme.schematic, MapTheme.geographic, MapTheme.perimeter],
          },
          {
            subcategory: false,
            name: terms.menu.layers.signals.signalGVC,
            layer: LAYERS_KEYS.signalGVC,
            themes: [MapTheme.schematic, MapTheme.geographic, MapTheme.perimeter],
          },
          {
            subcategory: false,
            name: terms.menu.layers.signals.signalTGV,
            layer: LAYERS_KEYS.signalTGV,
            themes: [MapTheme.schematic, MapTheme.geographic, MapTheme.perimeter],
          },
          {
            subcategory: false,
            name: terms.menu.layers.signals.signalTE,
            layer: LAYERS_KEYS.signalTE,
            themes: [MapTheme.schematic, MapTheme.geographic, MapTheme.perimeter],
          },
          {
            subcategory: false,
            name: terms.menu.layers.signals.signalTP,
            layer: LAYERS_KEYS.signalTP,
            themes: [MapTheme.schematic, MapTheme.geographic, MapTheme.perimeter],
          },
        ],
      },
      {
        subcategory: false,
        name: terms.menu.layers.adv,
        layer: LAYERS_KEYS.adv,
        themes: [MapTheme.schematic, MapTheme.geographic, MapTheme.perimeter],
      },
      {
        subcategory: false,
        name: terms.menu.layers.cdv,
        layer: LAYERS_KEYS.cdv,
        themes: [MapTheme.schematic, MapTheme.geographic, MapTheme.perimeter],
      },
      {
        subcategory: false,
        name: terms.menu.layers.jdz,
        layer: LAYERS_KEYS.jdz,
        themes: [MapTheme.schematic, MapTheme.geographic, MapTheme.perimeter],
      },
      {
        subcategory: false,
        name: terms.menu.layers.dbc,
        layer: LAYERS_KEYS.dbc,
        themes: [MapTheme.schematic, MapTheme.geographic, MapTheme.perimeter],
      },
      {
        subcategory: false,
        name: terms.menu.layers.localisateur,
        layer: LAYERS_KEYS.localisateur,
        themes: [MapTheme.schematic, MapTheme.geographic, MapTheme.perimeter],
      },
      {
        subcategory: false,
        name: terms.menu.layers.passageNiveau,
        layer: LAYERS_KEYS.passageNiveau,
        themes: [MapTheme.schematic, MapTheme.geographic, MapTheme.perimeter],
      },
      {
        subcategory: false,
        name: terms.menu.layers.pedale,
        layer: LAYERS_KEYS.pedale,
        themes: [MapTheme.schematic, MapTheme.geographic, MapTheme.perimeter],
      },
    ],
  },
  {
    category: terms.menu.categories.rct,
    expanded: false,
    layers: [
      {
        subcategory: false,
        name: terms.menu.layers.sousStation,
        layer: LAYERS_KEYS.sousStation,
        themes: [MapTheme.schematic, MapTheme.geographic, MapTheme.perimeter],
      },
    ],
  },
  {
    category: terms.menu.categories.building,
    expanded: false,
    layers: [
      {
        subcategory: false,
        name: terms.menu.layers.station,
        layer: LAYERS_KEYS.station,
        themes: [MapTheme.schematic, MapTheme.geographic, MapTheme.perimeter],
      },
      {
        subcategory: false,
        name: terms.menu.layers.acces,
        layer: LAYERS_KEYS.acces,
        themes: [MapTheme.schematic, MapTheme.geographic, MapTheme.perimeter],
      },
      {
        subcategory: false,
        name: terms.menu.layers.centraleSousStation,
        layer: LAYERS_KEYS.css,
        themes: [MapTheme.schematic, MapTheme.geographic, MapTheme.perimeter],
      },
      {
        subcategory: true,
        expanded: false,
        name: terms.menu.layers.postes.names,
        themes: [MapTheme.schematic, MapTheme.geographic, MapTheme.perimeter],
        layers: [
          {
            subcategory: false,
            name: terms.menu.layers.postes.cc,
            layer: LAYERS_KEYS.ccPoste,
            label: terms.menu.layers.labelPostes.cc,
            themes: [MapTheme.schematic, MapTheme.geographic, MapTheme.perimeter],
          },
          {
            subcategory: true,
            expanded: false,
            name: terms.menu.layers.postes.categoryInfo,
            themes: [MapTheme.schematic, MapTheme.geographic, MapTheme.perimeter],
            layers: [
              {
                subcategory: false,
                name: terms.menu.layers.postes.argos,
                layer: LAYERS_KEYS.argosPoste,
                label: terms.menu.layers.labelPostes.argos,
                themes: [MapTheme.schematic, MapTheme.geographic, MapTheme.perimeter],
              },
              {
                subcategory: false,
                name: terms.menu.layers.postes.pai,
                layer: LAYERS_KEYS.paiPoste,
                label: terms.menu.layers.labelPostes.pai,
                themes: [MapTheme.schematic, MapTheme.geographic, MapTheme.perimeter],
              },
              {
                subcategory: false,
                name: terms.menu.layers.postes.pipc,
                layer: LAYERS_KEYS.pipcPoste,
                label: terms.menu.layers.labelPostes.pipc,
                themes: [MapTheme.schematic, MapTheme.geographic, MapTheme.perimeter],
              },
            ],
          },
          {
            subcategory: true,
            expanded: false,
            name: terms.menu.layers.postes.categoryRelais,
            themes: [MapTheme.schematic, MapTheme.geographic, MapTheme.perimeter],
            layers: [
              {
                subcategory: false,
                name: terms.menu.layers.postes.prci,
                layer: LAYERS_KEYS.prciPoste,
                label: terms.menu.layers.labelPostes.prci,
                themes: [MapTheme.schematic, MapTheme.geographic, MapTheme.perimeter],
              },
              {
                subcategory: false,
                name: terms.menu.layers.postes.prs,
                layer: LAYERS_KEYS.prsPoste,
                label: terms.menu.layers.labelPostes.prs,
                themes: [MapTheme.schematic, MapTheme.geographic, MapTheme.perimeter],
              },
              {
                subcategory: false,
                name: terms.menu.layers.postes.prg,
                layer: LAYERS_KEYS.prgPoste,
                label: terms.menu.layers.labelPostes.prg,
                themes: [MapTheme.schematic, MapTheme.geographic, MapTheme.perimeter],
              },
              {
                subcategory: false,
                name: terms.menu.layers.postes.prmi,
                layer: LAYERS_KEYS.prmiPoste,
                label: terms.menu.layers.labelPostes.prmi,
                themes: [MapTheme.schematic, MapTheme.geographic, MapTheme.perimeter],
              },
            ],
          },
          {
            subcategory: true,
            expanded: false,
            name: terms.menu.layers.postes.categoryElec,
            themes: [MapTheme.schematic, MapTheme.geographic, MapTheme.perimeter],
            layers: [
              {
                subcategory: false,
                name: terms.menu.layers.postes.plit,
                layer: LAYERS_KEYS.plitPoste,
                label: terms.menu.layers.labelPostes.plit,
                themes: [MapTheme.schematic, MapTheme.geographic, MapTheme.perimeter],
              },
              {
                subcategory: false,
                name: terms.menu.layers.postes.peli,
                layer: LAYERS_KEYS.peliPoste,
                label: terms.menu.layers.labelPostes.peli,
                themes: [MapTheme.schematic, MapTheme.geographic, MapTheme.perimeter],
              },
              {
                subcategory: false,
                name: terms.menu.layers.postes.pelir,
                layer: LAYERS_KEYS.pelirPoste,
                label: terms.menu.layers.labelPostes.pelir,
                themes: [MapTheme.schematic, MapTheme.geographic, MapTheme.perimeter],
              },
              {
                subcategory: false,
                name: terms.menu.layers.postes.pml,
                layer: LAYERS_KEYS.pmlPoste,
                label: terms.menu.layers.labelPostes.pml,
                themes: [MapTheme.schematic, MapTheme.geographic, MapTheme.perimeter],
              },
            ],
          },
          {
            subcategory: true,
            expanded: false,
            name: terms.menu.layers.postes.categoryMeca,
            themes: [MapTheme.schematic, MapTheme.geographic, MapTheme.perimeter],
            layers: [
              {
                subcategory: false,
                name: terms.menu.layers.postes.emu,
                layer: LAYERS_KEYS.emuPoste,
                label: terms.menu.layers.labelPostes.emu,
                themes: [MapTheme.schematic, MapTheme.geographic, MapTheme.perimeter],
              },
              {
                subcategory: false,
                name: terms.menu.layers.postes.mu45,
                layer: LAYERS_KEYS.mu45Poste,
                label: terms.menu.layers.labelPostes.mu45,
                themes: [MapTheme.schematic, MapTheme.geographic, MapTheme.perimeter],
              },
              {
                subcategory: false,
                name: terms.menu.layers.postes.mrsaxby,
                layer: LAYERS_KEYS.mrsaxbyPoste,
                label: terms.menu.layers.labelPostes.mrsaxby,
                themes: [MapTheme.schematic, MapTheme.geographic, MapTheme.perimeter],
              },
              {
                subcategory: false,
                name: terms.menu.layers.postes.mrlevdr,
                layer: LAYERS_KEYS.mrlevdrPoste,
                label: terms.menu.layers.labelPostes.mrlevdr,
                themes: [MapTheme.schematic, MapTheme.geographic, MapTheme.perimeter],
              },
              {
                subcategory: false,
                name: terms.menu.layers.postes.mrvignty,
                layer: LAYERS_KEYS.mrvigntyPoste,
                label: terms.menu.layers.labelPostes.mrvignty,
                themes: [MapTheme.schematic, MapTheme.geographic, MapTheme.perimeter],
              },
              {
                subcategory: false,
                name: terms.menu.layers.postes.mrexal,
                layer: LAYERS_KEYS.mrexalPoste,
                label: terms.menu.layers.labelPostes.mrexal,
                themes: [MapTheme.schematic, MapTheme.geographic, MapTheme.perimeter],
              },
              {
                subcategory: false,
                name: terms.menu.layers.postes.ssdv,
                layer: LAYERS_KEYS.ssdvPoste,
                label: terms.menu.layers.labelPostes.ssdv,
                themes: [MapTheme.schematic, MapTheme.geographic, MapTheme.perimeter],
              },
              {
                subcategory: false,
                name: terms.menu.layers.postes.ssvuvb,
                layer: LAYERS_KEYS.ssvuvbPoste,
                label: terms.menu.layers.labelPostes.ssvuvb,
                themes: [MapTheme.schematic, MapTheme.geographic, MapTheme.perimeter],
              },
            ],
          },
          {
            subcategory: true,
            expanded: false,
            name: terms.menu.layers.postes.categoryPVS,
            themes: [MapTheme.schematic, MapTheme.geographic, MapTheme.perimeter],
            layers: [
              {
                subcategory: false,
                name: terms.menu.layers.postes.pvstri,
                layer: LAYERS_KEYS.pvstriPoste,
                label: terms.menu.layers.labelPostes.pvstri,
                themes: [MapTheme.schematic, MapTheme.geographic, MapTheme.perimeter],
              },
              {
                subcategory: false,
                name: terms.menu.layers.postes.pvselec,
                layer: LAYERS_KEYS.pvselecPoste,
                label: terms.menu.layers.labelPostes.pvselec,
                themes: [MapTheme.schematic, MapTheme.geographic, MapTheme.perimeter],
              },
              {
                subcategory: false,
                name: terms.menu.layers.postes.pvsei,
                layer: LAYERS_KEYS.pvseiPoste,
                label: terms.menu.layers.labelPostes.pvsei,
                themes: [MapTheme.schematic, MapTheme.geographic, MapTheme.perimeter],
              },
              {
                subcategory: false,
                name: terms.menu.layers.postes.pvsci,
                layer: LAYERS_KEYS.pvsciPoste,
                label: terms.menu.layers.labelPostes.pvsci,
                themes: [MapTheme.schematic, MapTheme.geographic, MapTheme.perimeter],
              },
              {
                subcategory: false,
                name: terms.menu.layers.postes.pvsmec,
                layer: LAYERS_KEYS.pvsmecPoste,
                label: terms.menu.layers.labelPostes.pvsmec,
                themes: [MapTheme.schematic, MapTheme.geographic, MapTheme.perimeter],
              },
            ],
          },
        ],
      },
      {
        subcategory: true,
        expanded: false,
        name: terms.menu.layers.postes.categoryOther,
        themes: [MapTheme.schematic, MapTheme.geographic, MapTheme.perimeter],
        layers: [
          {
            subcategory: false,
            name: terms.menu.layers.postes.point,
            layer: LAYERS_KEYS.pointPoste,
            label: terms.menu.layers.labelPostes.point,
            themes: [MapTheme.schematic, MapTheme.geographic, MapTheme.perimeter],
          },
          {
            subcategory: false,
            name: terms.menu.layers.postes.levier,
            layer: LAYERS_KEYS.levierPoste,
            label: terms.menu.layers.labelPostes.levier,
            themes: [MapTheme.schematic, MapTheme.geographic, MapTheme.perimeter],
          },
          {
            subcategory: false,
            name: terms.menu.layers.postes.p10,
            layer: LAYERS_KEYS.p10Poste,
            label: terms.menu.layers.labelPostes.p10,
            themes: [MapTheme.schematic, MapTheme.geographic, MapTheme.perimeter],
          },
          {
            subcategory: false,
            name: terms.menu.layers.postes.autres,
            layer: LAYERS_KEYS.autresPoste,
            label: terms.menu.layers.labelPostes.autres,
            themes: [MapTheme.schematic, MapTheme.geographic, MapTheme.perimeter],
          },
        ],
      },
      {
        subcategory: false,
        name: terms.menu.layers.center,
        layer: LAYERS_KEYS.center,
        themes: [MapTheme.schematic, MapTheme.geographic, MapTheme.perimeter],
      },
      {
        subcategory: false,
        name: terms.menu.layers.centerArmen,
        layer: LAYERS_KEYS.centerArmen,
        themes: [MapTheme.schematic, MapTheme.geographic, MapTheme.perimeter],
      },
    ],
  },
  {
    category: terms.menu.categories.exploitationMode,
    expanded: false,
    layers: [
      {
        subcategory: false,
        name: terms.menu.layers.bf,
        layer: LAYERS_KEYS.bf,
        themes: [MapTheme.schematic, MapTheme.geographic, MapTheme.perimeter],
      },
      {
        subcategory: false,
        name: terms.menu.layers.rac,
        layer: LAYERS_KEYS.rac,
        themes: [MapTheme.schematic, MapTheme.geographic, MapTheme.perimeter],
      },
      {
        subcategory: false,
        name: terms.menu.layers.emUnique,
        layer: LAYERS_KEYS.emUnique,
        themes: [MapTheme.schematic, MapTheme.geographic, MapTheme.perimeter],
      },
      {
        subcategory: false,
        name: terms.menu.layers.emBanalisee,
        layer: LAYERS_KEYS.emBanalisee,
        themes: [MapTheme.schematic, MapTheme.geographic, MapTheme.perimeter],
      },
      {
        subcategory: false,
        name: terms.menu.layers.emIpcs,
        layer: LAYERS_KEYS.emIPCS,
        themes: [MapTheme.schematic, MapTheme.geographic, MapTheme.perimeter],
      },
    ],
  },
  {
    category: terms.menu.categories.borders,
    expanded: false,
    layers: [
      {
        subcategory: false,
        name: terms.menu.layers.pri,
        layer: LAYERS_KEYS.pri,
        themes: [MapTheme.schematic],
      },
      {
        subcategory: false,
        name: terms.menu.layers.infrapole,
        layer: LAYERS_KEYS.infrapole,
        themes: [MapTheme.schematic],
      },
      {
        subcategory: false,
        name: terms.menu.layers.utm,
        layer: LAYERS_KEYS.utm,
        themes: [MapTheme.schematic],
      },
      {
        subcategory: false,
        name: terms.menu.layers.zp,
        layer: LAYERS_KEYS.zp,
        themes: [MapTheme.schematic],
      },
    ],
  },
]

const getLayerKeys = (layer: Layer) :{layer: string, themes: MapTheme[]}[] => {
  if (layer.subcategory) {
    return [...layer.layers.flatMap(getLayerKeys)]
  }
  return [{ layer: layer.layer, themes: layer.themes }]
}

export const INFRA_LAYERS_KEYS = INFRA_LAYERS.flatMap(layer => layer.layers.flatMap(getLayerKeys))

export default INFRA_LAYERS
