import { MapTheme } from 'components/Toolbar/ThemeMenu/const'
import { get } from '@osrdata/app_core/dist/requests'
import { Polygon } from 'geojson'
import { SEARCH_LAYER_SOURCES, SearchLayers } from './const'
import {
  CassiniSearchResult, CassiniWithTypeSearchResult, SearchParams, SearchResults,
} from './types'

/* Layers that:
  -have the columns id,libelle,lrs_pk,lrs_voie,lrs_ligne, date_debut_activite, date_fin_activite
  -do not need any other filter
  -have 'full_rgi_track_sch_flat' view for schematic search and 'full_rgi_track_geo' view for geographic search
  Thus they can be searched with one chartis request
*/
const CASSINI_LAYERS: SearchLayers[] = [
  SearchLayers.adv, SearchLayers.cdv, SearchLayers.jdz, SearchLayers.dbc, SearchLayers.localisateur,
  SearchLayers.passageNiveau, SearchLayers.pedale, SearchLayers.station, SearchLayers.acces, SearchLayers.css,
  SearchLayers.sousStation,
]

export const getCassiniParams = (params: SearchParams) => ({
  ...(params.dateRangeStart && params.dateRangeEnd && {
    date_fin_activite__gte_or_null: params.dateRangeStart.format('YYYY-MM-DD'),
    date_debut_activite__lte_or_null: params.dateRangeEnd.format('YYYY-MM-DD'),
  }),
  ...(params.lineFilterActive && params.lineFilter !== '' && { lrs_ligne__ilike: `%${params.lineFilter}%` }),
})

const getCassiniLayers = (layers: SearchLayers[]) => layers.filter(layer => CASSINI_LAYERS.includes(layer))
  .map(layer => SEARCH_LAYER_SOURCES[layer])

export const computeSearchResults = (cassiniResponse: CassiniSearchResult) => cassiniResponse.features.reduce((
  acc: SearchResults, feature,
) => {
  const layer = acc[feature.properties.layer_slug] || []
  return {
    ...acc,
    [feature.properties.layer_slug]: [
      ...layer,
      {
        type: 'cassini' as const,
        id: feature.properties.id,
        libelle: feature.properties.libelle,
        pk: feature.properties.lrs_pk,
        trackName: feature.properties.lrs_voie,
        lineNumber: feature.properties.lrs_ligne,
        schGeom: feature.properties.geom_rgi_track_sch_flat,
        geoGeom: feature.properties.geom_rgi_track_geo,
      },
    ],
  }
}, {})

export const computeSearchResultsWithType = (cassiniResponse: CassiniWithTypeSearchResult) => cassiniResponse
  .features.reduce((
    acc: SearchResults, feature,
  ) => {
    const layer = acc[feature.properties.layer_slug] || []
    return {
      ...acc,
      [feature.properties.layer_slug]: [
        ...layer,
        {
          type: 'cassiniWithType' as const,
          id: feature.properties.id,
          libelle: feature.properties.libelle,
          pk: feature.properties.lrs_pk,
          trackName: feature.properties.lrs_voie,
          lineNumber: feature.properties.lrs_ligne,
          type_installation_fixe_id_mnemo: feature.properties.type_installation_fixe_id_mnemo,
          schGeom: feature.properties.geom_rgi_track_sch_flat,
          geoGeom: feature.properties.geom_rgi_track_geo,
        },
      ],
    }
  }, {})

export const getCassiniObjects = async (
  layers: SearchLayers[], theme: MapTheme, bbox: Polygon,
  query: string, params: SearchParams,
) => {
  const cassiniLayers = getCassiniLayers(layers)
  if (cassiniLayers.length > 0) {
    const mainLayer = cassiniLayers[0]
    const additionalLayers = cassiniLayers.slice(1)
    const cassiniParams = getCassiniParams(params)
    const view = theme === MapTheme.geographic ? 'full_rgi_track_geo' : 'full_rgi_track_sch_flat'
    const bboxParam = theme === MapTheme.perimeter ? 'geom_rgi_track_sch_flat__bpolygon' : 'bbox'
    const cassiniResponse: CassiniSearchResult = await get(`/chartis/v2/layer/${mainLayer}/search/${view}/`, {
      columns: 'id,libelle,lrs_pk,lrs_voie,lrs_ligne,geom_rgi_track_sch_flat,geom_rgi_track_geo',
      [bboxParam]: bbox,
      additional_layers: additionalLayers.join(','),
      size_per_layer: 100,
      libelle__ilike: `%${query.trim()}%`,
      ...cassiniParams,
    })
    return computeSearchResults(cassiniResponse)
  }
  return null
}
