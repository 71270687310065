export const SOURCES_IDS = {
  acces: 'source-access',
  actionZone: 'source-action-zone',
  actionZoneLabel: 'source-action-zone-label',
  adv: 'source-adv',
  advLine: 'source-adv-line',
  css: 'source-css',
  dbc: 'source-dbc',
  jdz: 'source-jdz',
  localisateur: 'source-localisateur',
  passageNiveau: 'source-passage-niveau',
  pedale: 'source-pedale',
  poste: 'source-poste',
  zoneEditorPolygon: 'source-zone-editor-polygon',
  signal: 'source-signal',
  station: 'source-station',
  sousStation: 'source-sous-station',
  track: 'source-track',
  pri: 'source-pri',
  priLabel: 'source-pri-label',
  utm: 'source-utm',
  utmLabel: 'source-utm-label',
  zpRegion: 'source-zp-region',
  zpLabel: 'source-zp-label',
  infrapoleLabel: 'source-infrapole-label',
  chantier: 'source-chantier',
  balZone: 'source-bal-zone',
  center: 'source-center',
  ihmZone: 'source-ihm-zone',
  ihmZoneLabel: 'source-ihm-zone-label',
  cdv: 'source-cdv',
  exploitationMode: 'source-exploitation-mode',
  bf: 'source-bf',
  rac: 'source-rac',
  centerArmen: 'source-center-armen',
} as const

export const FEATURES_SOURCES = [
  SOURCES_IDS.signal,
  SOURCES_IDS.poste,
  SOURCES_IDS.jdz,
  SOURCES_IDS.adv,
  SOURCES_IDS.advLine,
  SOURCES_IDS.acces,
  SOURCES_IDS.sousStation,
  SOURCES_IDS.css,
  SOURCES_IDS.localisateur,
  SOURCES_IDS.dbc,
  SOURCES_IDS.pedale,
  SOURCES_IDS.station,
  SOURCES_IDS.passageNiveau,
  SOURCES_IDS.actionZone,
  SOURCES_IDS.actionZoneLabel,
  SOURCES_IDS.chantier,
  SOURCES_IDS.balZone,
  SOURCES_IDS.center,
  SOURCES_IDS.ihmZone,
  SOURCES_IDS.ihmZoneLabel,
  SOURCES_IDS.cdv,
  SOURCES_IDS.bf,
  SOURCES_IDS.rac,
  SOURCES_IDS.centerArmen,
]
