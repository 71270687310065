import { LAYERS_IDS, LayerProperties } from '../common'

const PosteLayerProps: LayerProperties = {
  posteCircle: {
    id: LAYERS_IDS.posteCircle,
    type: 'circle',
    paint: {
      'circle-color': '#ffb612',
      'circle-stroke-color': '#3492da',
      'circle-stroke-width': 1,
      'circle-radius': 5,
    },
  },
  poste: {
    id: LAYERS_IDS.poste,
    type: 'symbol',
    paint: {
      'text-color': 'black',
      'text-halo-color': '#ffb612',
      'text-halo-width': 0.1,
    },
    layout: {
      'text-font': ['Open Sans Regular'],
      'text-size': 14,
      'text-anchor': 'left',
      'symbol-placement': 'point',
      'text-allow-overlap': false,
      'text-offset': [1, 0],
      'text-justify': 'auto',
      'text-field': [
        'format',
        ['get', 'gare_libelle'],
        '\n',
        ['get', 'libelle_court'],
        '\n',
        {},
        ['get', 'type_installation_fixe_id_mnemo'],
      ],
    },
  },
  selectionOutline: {
    type: 'line',
    paint: {
      'line-color': 'grey',
      'line-dasharray': [2, 2],
      'line-width': 2,
    },
  },
  selectionFill: {
    type: 'fill',
    paint: {
      'fill-color': 'grey',
      'fill-opacity': 0.15,
    },
  },
}

export default PosteLayerProps
