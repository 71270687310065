import {
  Box, FormControl, InputLabel, MenuItem, Modal, Select,
  TextField,
} from '@mui/material'
import terms from 'common/terms'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { UserFavoriteObjectComment, UserFavoriteObjectCommentType } from 'store/userFavorite/types'
import { postObjectComment } from 'store/userFavorite/userFavorite.thunk'
import './ObjectCommentModal.scss'

const style = {
  position: 'absolute' as const,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  height: '50vh',
  bgcolor: 'background.paper',
  boxShadow: 24,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',
  borderRadius: '4px',
  overflow: 'auto',
}

interface Props {
  onModal: boolean,
  handleClose: () => void,
  objectId: string,
  objectLibelle: string,
  layerIdField: string,
  layerSlug: string,
}

const ObjectCommentModal = ({
  onModal, handleClose, objectId, objectLibelle, layerIdField, layerSlug,
}: Props) => {
  const dispatch = useDispatch()
  const [newObjectComment, setNewObjectComment] = useState<Omit<UserFavoriteObjectComment, 'id'>>({
    type: UserFavoriteObjectCommentType.Commentaire,
    content: '',
    object_id: objectId,
    object_libelle: objectLibelle,
    layer_id_field: layerIdField,
    layer_slug: layerSlug,
  })
  const handleValidate = () => {
    dispatch(postObjectComment(newObjectComment))
    handleClose()
    setNewObjectComment({
      type: UserFavoriteObjectCommentType.Commentaire,
      content: '',
      object_id: objectId,
      object_libelle: objectLibelle,
      layer_id_field: layerIdField,
      layer_slug: layerSlug,
    })
  }

  return (
    <Modal
      open={onModal}
      onClose={handleClose}
    >
      <Box sx={style} className="object-comment-modal">
        <div className="content">
          <h2 className="title">
            {terms.ObjectCommentModal.title}
          </h2>
          <FormControl fullWidth>
            <InputLabel id="comment-type-label">
              {terms.ObjectCommentModal.type}
            </InputLabel>
            <Select
              labelId="comment-type-label"
              value={newObjectComment.type}
              label={terms.ObjectCommentModal.type}
              onChange={e => setNewObjectComment({
                ...newObjectComment,
                type: e.target.value as UserFavoriteObjectCommentType,
              })}
            >
              {Object.values(UserFavoriteObjectCommentType).map(type => (
                <MenuItem key={type} value={type}>
                  {type}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth>
            <TextField
              label={terms.ObjectCommentModal.content}
              multiline
              rows={4}
              value={newObjectComment.content}
              onChange={e => setNewObjectComment({
                ...newObjectComment,
                content: e.target.value,
              })}
            />
          </FormControl>
        </div>
        <div className="actions">
          <button className="validate" type="button" onClick={handleValidate}>
            {terms.CreateUserView.save}
          </button>
          <button className="close" type="button" onClick={handleClose}>
            {terms.ObjectCommentModal.close}
          </button>
        </div>
      </Box>
    </Modal>
  )
}

export default ObjectCommentModal
