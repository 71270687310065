import { Box, Modal } from '@mui/material'
import { useState } from 'react'
import terms from 'common/terms'
import { useDispatch, useSelector } from 'react-redux'
import './CreatePerimeterModal.scss'
import { postUserStudyPerimeter } from 'store/userFavorite/userFavorite.thunk'
import { RootState } from 'store'
import { UserStudyPerimeterDetails } from 'store/userFavorite/types'
import { useHistory } from 'react-router-dom'
import PerimeterLVPTable from '../PerimeterLVPTable'

const style = {
  position: 'absolute' as const,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  maxHeight: '90vh',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '4px',
}

interface Props {
  onModal: boolean,
  handleClose: () => void,
  addedPerimeter: UserStudyPerimeterDetails | null
}

const CreatePerimeterModal = ({
  onModal,
  handleClose,
  addedPerimeter,
}: Props) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { zonePolygons } = useSelector((state: RootState) => state.zoneEditor)
  const [inputValue, setInputValue] = useState('')

  const handleValidate = () => {
    if (zonePolygons.length === 0) return
    dispatch(postUserStudyPerimeter({
      type: 'Feature',
      properties: {
        libelle: inputValue,
        split_ratio: 0.5,
      },
      geometry: zonePolygons[0].geometry,
    }))
  }
  return (
    <Modal
      open={onModal}
      onClose={handleClose}
    >
      {addedPerimeter ? (
        <Box className="modal-added-perimeter" sx={style}>
          <h2 className="title">
            {`${terms.CreateUserPerimeter.addedTitle}: ${addedPerimeter.properties.libelle}`}
          </h2>
          <PerimeterLVPTable perimeter={addedPerimeter} />
          <div className="actions">
            <button className="validate" type="button" onClick={() => history.push(`/perimeter/${addedPerimeter.id}`)}>
              {terms.CreateUserPerimeter.viewPerimeter}
            </button>
            <button className="cancel" type="button" onClick={handleClose}>
              {terms.CreateUserPerimeter.cancel}
            </button>
          </div>
        </Box>
      ) : (
        <Box className="modal-create-perimeter" sx={style}>
          <h2 className="title">
            {terms.CreateUserPerimeter.title}
          </h2>
          <input
            type="text"
            value={inputValue}
            onChange={e => setInputValue(e.target.value)}
            placeholder={terms.CreateUserPerimeter.placeholder}
          />
          <div className="actions">
            <button className="validate" type="button" onClick={handleValidate} disabled={inputValue === ''}>
              {terms.CreateUserPerimeter.save}
            </button>
            <button className="cancel" type="button" onClick={handleClose}>
              {terms.CreateUserPerimeter.cancel}
            </button>
          </div>
        </Box>
      )}
    </Modal>
  )
}

export default CreatePerimeterModal
