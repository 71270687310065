import { _useMapControl as useMapControl } from 'react-map-gl'
import { CircularProgress, Tooltip } from '@mui/material'
import './EditorPosteSelection.scss'
import { useState } from 'react'
import { Search } from '@mui/icons-material'
import terms from 'common/terms'

interface Props<FeatureType> {
  features: FeatureType[],
  loading: boolean
  getLabel: (feature: FeatureType) => string
  getId: (feature: FeatureType) => string
  handleFeatureClick: (feature: FeatureType) => () => void
}

function EditorPosteSelection<T>({
  features, loading, getLabel, getId, handleFeatureClick,
}: Props<T>) {
  const { containerRef } = useMapControl({
    captureDrag: true, captureClick: true, captureDoubleClick: true, captureScroll: true, capturePointerMove: true,
  })
  const [searchValue, setSearchValue] = useState('')
  const filteredFeatures = features.filter(f => getLabel(f).toLowerCase().includes(searchValue.toLowerCase()))

  return (
    <div ref={containerRef} className="editor-poste-selection">
      <div className="objects-infos expanded">
        <div className="header">
          <h3>
            {terms.EditorPosteSelection.title}
          </h3>
          {loading && (
          <CircularProgress size={20} />
          )}
        </div>
        <div className="search">
          <Search />
          <input
            type="text"
            placeholder={terms.EditorPosteSelection.searchPlaceholder}
            value={searchValue}
            onChange={e => setSearchValue(e.target.value)}
          />
        </div>
        <div className="item-list">
          {filteredFeatures.map(feature => (
            <button
              type="button"
              key={getId(feature)}
              className="item"
              onClick={handleFeatureClick(feature)}
            >
              <Tooltip
                title={<div style={{ whiteSpace: 'pre-line' }}>{getLabel(feature)}</div>}
                placement="right"
              >
                <span>{getLabel(feature)}</span>
              </Tooltip>
            </button>
          ))}
          {filteredFeatures.length === 0 && (
            <span className="no-match">
              {terms.EditorPosteSelection.noMatch}
            </span>
          )}
        </div>
      </div>
    </div>
  )
}

export default EditorPosteSelection
