import { signal } from '@preact/signals-react'
import { Ref, useEffect, useRef } from 'react'
import ReactMapGL, { MapRef, ViewportProps } from 'react-map-gl'
import { UserStudyPerimeterDetailsWithId } from 'store/userFavorite/types'
import { transformRequest } from 'utils'
import { Map } from 'assets'
import { GEO_DEFAULT_VIEWPORT } from 'views/Map'
import PerimeterMapMenu from 'components/MapMenu/PerimeterMapMenu'
import { addedImagesSignal, loadMissingImage, loadStaticImages } from 'views/Map/utils'
import {
  INTERACTIVE_LAYERS_IDS, LAYERS_KEYS, OBJECTS_LAYERS, SCHEMATIC_VIEWS, SOURCES_IDS,
} from 'components/Layers/common'
import { MapPopup } from 'components'
import MultiFeaturesPopup from 'components/MultiFeaturesPopup/MultiFeaturesPopup'
import { MapTheme } from 'components/Toolbar/ThemeMenu/const'
import PerimeterGeoLayers from 'components/Layers/PerimeterGeoLayers'
import { reprojectPoints } from 'utils/cassini'
import { calculateGeometryViewport } from 'components/utils'
import { get } from '@osrdata/app_core/dist/requests'
import { Feature } from 'geojson'
import { useFeatureClick, useHoverEvent } from './hooks'
import { schPerimeterViewportSignal } from './PerimeterSchMap'
import { perimeterActiveLayersSignal, perimeterActiveViewsSignal, perimeterHoveredIdSignal } from './PerimeterView'

export const geoPerimeterViewportSignal = signal<ViewportProps>(GEO_DEFAULT_VIEWPORT)

interface Props {
  activePerimeter: UserStudyPerimeterDetailsWithId
  setSelectedObjectsIds: (ids: string[]) => void
  setHoveredObjectsIds: (ids: string[]) => void
  defaultViewport: ViewportProps
}

const PerimeterGeoMap = ({ activePerimeter, setSelectedObjectsIds, setHoveredObjectsIds, defaultViewport }: Props) => {
  const mapRef = useRef<MapRef | undefined >(undefined)
  const [onHover] = useHoverEvent()
  const [
    selectedFeatures, onFeatureClick, handlePopupClose, handleMultiPopupClose, handleClickFeature,
  ] = useFeatureClick(MapTheme.geographic, setSelectedObjectsIds)

  const onViewportChange = (newViewport: ViewportProps) => {
    geoPerimeterViewportSignal.value = { ...newViewport }
  }

  useEffect(() => {
    addedImagesSignal.value = new Set<string>()
    const map = mapRef?.current?.getMap()
    loadStaticImages(mapRef)
    const onStyleImageMissing = (e: {id: string}) => { loadMissingImage(mapRef, e.id) }
    map.on('styleimagemissing', onStyleImageMissing)
  }, [])

  const handleResetNorth = () => {
    geoPerimeterViewportSignal.value = { ...geoPerimeterViewportSignal.value, bearing: 0, pitch: 0 }
  }
  const handleZoomOutClick = () => { geoPerimeterViewportSignal.value = defaultViewport }

  const handleClickPoste = (geom: GeoJSON.Geometry, zapId: string, isModifiable: boolean) => () => {
    const { latitude, longitude, zoom } = calculateGeometryViewport(geom, geoPerimeterViewportSignal.value)
    if (!perimeterActiveLayersSignal.value.includes(LAYERS_KEYS.zoneActionPosteAmendee) && isModifiable) {
      perimeterActiveLayersSignal.value = [...perimeterActiveLayersSignal.value, LAYERS_KEYS.zoneActionPosteAmendee]
    }
    if (!perimeterActiveLayersSignal.value.includes(LAYERS_KEYS.zoneActionPoste) && !isModifiable) {
      perimeterActiveLayersSignal.value = [...perimeterActiveLayersSignal.value, LAYERS_KEYS.zoneActionPoste]
    }
    setSelectedObjectsIds([zapId])
    geoPerimeterViewportSignal.value = ({
      ...geoPerimeterViewportSignal.value, latitude, longitude, zoom, transitionDuration: 500,
    })
  }

  const handleHoverPoste = (zapId: string) => () => {
    perimeterHoveredIdSignal.value = [+zapId as unknown as string]
  }

  const handleClickZap = (zapId: string) => async () => {
    // eslint-disable-next-line max-len
    const clickedZap: Feature = await get(`/chartis/v2/layer/${OBJECTS_LAYERS.actionZonePoste}/geojson_feature/${SCHEMATIC_VIEWS[SOURCES_IDS.actionZone]}/`,
      { id: zapId })
    handleClickPoste(clickedZap.geometry, zapId, clickedZap.properties?.modifiable)()
  }

  const handleHoverBal = (balId: string) => () => {
    perimeterHoveredIdSignal.value = [balId]
  }

  const handleClickBal = (balId: string) => async () => {
    // eslint-disable-next-line max-len
    const clickedBal: Feature = await get(`/chartis/v2/layer/${OBJECTS_LAYERS.balZone}/geojson_feature/${SCHEMATIC_VIEWS[SOURCES_IDS.balZone]}/`,
      { id: balId })
    const { latitude, longitude, zoom } = calculateGeometryViewport(
      clickedBal.geometry, geoPerimeterViewportSignal.value,
    )
    if (!perimeterActiveLayersSignal.value.includes(LAYERS_KEYS.balZone)) {
      perimeterActiveLayersSignal.value = [...perimeterActiveLayersSignal.value, LAYERS_KEYS.balZone]
    }
    setSelectedObjectsIds([balId])
    geoPerimeterViewportSignal.value = ({
      ...geoPerimeterViewportSignal.value, latitude, longitude, zoom, transitionDuration: 500,
    })
  }

  const handleSyncViewport = async () => {
    const { latitude, longitude, zoom } = schPerimeterViewportSignal.value
    if (!latitude || !longitude) return
    const projectedPoints = await reprojectPoints(
      [{ type: 'Point', coordinates: [longitude, latitude] }],
      'rgi_track_sch_flat',
      'rgi_track_geo',
    )
    if (projectedPoints.length === 0) return
    const [newLongitude, newLatitude] = projectedPoints[0].coordinates
    geoPerimeterViewportSignal.value = ({
      ...geoPerimeterViewportSignal.value,
      latitude: newLatitude,
      longitude: newLongitude,
      zoom,
      transitionDuration: 500,
    })
  }

  return (
    <ReactMapGL
      {...geoPerimeterViewportSignal.value}
      width="100%"
      height="100%"
      onViewportChange={onViewportChange}
      clickRadius={2}
      transformRequest={transformRequest}
      interactiveLayerIds={INTERACTIVE_LAYERS_IDS.geo}
      mapStyle={Map.fullStyle}
      ref={mapRef as Ref<MapRef>}
      onHover={onHover}
      onClick={onFeatureClick}
    >
      <PerimeterMapMenu
        handleResetNorth={handleResetNorth}
        handleZoomOutClick={handleZoomOutClick}
        handleSyncViewport={handleSyncViewport}
        disableSyncViewport={!perimeterActiveViewsSignal.value.sch}
      />
      <PerimeterGeoLayers activePerimeter={activePerimeter} />

      <MapPopup
        longitude={selectedFeatures.longitude}
        latitude={selectedFeatures.latitude}
        feature={selectedFeatures.features?.[0] || null}
        featureData={selectedFeatures.featureData}
        showPopup={selectedFeatures.showPopup}
        handleClose={handlePopupClose}
        handleClickPoste={handleClickPoste}
        handleHoverPoste={handleHoverPoste}
        handleClickZap={handleClickZap}
        handleClickBal={handleClickBal}
        handleHoverBal={handleHoverBal}
        handleHoverZap={handleHoverPoste}
      />

      <MultiFeaturesPopup
        longitude={selectedFeatures.longitude}
        latitude={selectedFeatures.latitude}
        features={selectedFeatures.features}
        showPopup={selectedFeatures.showMultiPopup}
        handleClose={handleMultiPopupClose}
        setHoveredId={setHoveredObjectsIds}
        handleClickFeature={handleClickFeature}
      />
    </ReactMapGL>
  )
}

export default PerimeterGeoMap
