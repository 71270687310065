import { ReactElement } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'store'
import { updateTheme } from 'store/map'
import { reprojectPoints } from 'utils'
import { GEO_DEFAULT_VIEWPORT, SCH_DEFAULT_VIEWPORT, mapViewportSignal } from 'views/Map'
import { MapTheme } from 'components/Toolbar/ThemeMenu/const'
import { MapStyles } from './const'
import './ThemeMenu.scss'

const ThemeMenu = () : ReactElement => {
  const dispatch = useDispatch()
  const { currentOsmTheme } = useSelector((state: RootState) => state.map)

  const switchViewMode = (theme: MapTheme) => async () => {
    dispatch(updateTheme(theme))
    const defaultViewport = theme === MapTheme.schematic ? SCH_DEFAULT_VIEWPORT : GEO_DEFAULT_VIEWPORT
    const actualView = theme === MapTheme.schematic ? 'rgi_track_sch_flat' : 'rgi_track_geo'
    const newView = theme === MapTheme.schematic ? 'rgi_track_geo' : 'rgi_track_sch_flat'

    const { latitude, longitude, zoom } = mapViewportSignal.value
    if (!latitude || !longitude) return
    const projectedPoints = await reprojectPoints(
      [{ type: 'Point', coordinates: [longitude, latitude] }],
      newView,
      actualView,
    )
    if (projectedPoints.length === 0) {
      mapViewportSignal.value = defaultViewport
      return
    }
    const [newLongitude, newLatitude] = projectedPoints[0].coordinates
    mapViewportSignal.value = ({
      ...mapViewportSignal.value,
      latitude: newLatitude,
      longitude: newLongitude,
      zoom,
      transitionDuration: 0,
    })
  }

  return (
    <div className="theme-menu">
      {MapStyles.map(theme => (
        <div
          className={`theme-select-button${currentOsmTheme === theme.value ? ' active' : ''}`}
          key={theme.title}
          role="presentation"
          onClick={switchViewMode(theme.value)}
        >
          <div className="title">
            {theme.title}
          </div>
          <img src={theme.icon} alt={theme.alt} />
        </div>
      ))}
    </div>
  )
}

export default ThemeMenu
