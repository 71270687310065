import terms from 'common/terms'
import { LAYERS_IDS } from 'components/Layers/common'
import { FeatureLayer } from 'components/MapPopup/utils'
import { store } from 'store'
import { editZAPProperties, handleEditGeometry, openModal } from 'store/zoneEditor/zoneEditor'
import { deletePosteZone } from 'store/zoneEditor/zoneEditor.thunk'

export enum ContextMenuAction {
  editProperties = 'editProperties',
  editGeometry = 'editGeometry',
  delete = 'delete',
}

type ContextMenuItemType = {
  key: ContextMenuAction
  label: string
  onClick: () => void
  show?: boolean
}

const CONTEXT_MENU_ITEMS = (object: FeatureLayer | undefined): ContextMenuItemType[] => [
  {
    key: ContextMenuAction.delete,
    label: terms.contextMenu.delete,
    onClick: () => store.dispatch(openModal({
      message: terms.contextMenu.deleteConfirmation,
      callback: () => object?.properties?.id && store.dispatch(deletePosteZone(object.properties.id)),
    })),
    show: object && object.layer.id === LAYERS_IDS.zoneActionPosteAmendee,
  },
  {
    key: ContextMenuAction.editProperties,
    label: terms.contextMenu.editProperties,
    onClick: () => object && store.dispatch(editZAPProperties(object)),
    show: object && object.layer.id === LAYERS_IDS.zoneActionPosteAmendee,
  },
  {
    key: ContextMenuAction.editGeometry,
    label: terms.contextMenu.editGeometry,
    onClick: () => object && handleEditGeometry(object),
    show: object && object.layer.id === LAYERS_IDS.zoneActionPosteAmendee,
  },
]

export default CONTEXT_MENU_ITEMS
