import terms from 'common/terms'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'store'
import './ObjectTab.scss'
import { CircularProgress, Tooltip } from '@mui/material'
import { Delete, Visibility } from '@mui/icons-material'
import { deleteUserFavoriteObject } from 'store/userFavorite/userFavorite.thunk'
import { UserFavoriteObjectDetails } from 'store/userFavorite/types'
import { calculateGeometryViewport } from 'components/utils'
import { mapViewportSignal } from 'views/Map'
import { closeFavoriteModal } from 'store/userFavorite/userFavorite'
import { getObjectLabel } from './utils'

interface Props {
  setSelectedObjectsIds: (ids: string[]) => void
}

const ObjectTab = ({ setSelectedObjectsIds }: Props) => {
  const dispatch = useDispatch()
  const { favoriteDetails, detailsLoading } = useSelector((state: RootState) => state.userFavorite)

  const handleDeleteObject = (id: number) => () => {
    dispatch(deleteUserFavoriteObject(id))
  }

  const handleViewObject = (object : UserFavoriteObjectDetails) => () => {
    const { latitude, longitude, zoom } = calculateGeometryViewport(object.geometry, mapViewportSignal.value)
    mapViewportSignal.value = ({ ...mapViewportSignal.value, latitude, longitude, zoom, transitionDuration: 500 })
    dispatch(closeFavoriteModal())
    setSelectedObjectsIds([object.object_id])
  }

  if (detailsLoading) {
    return <CircularProgress />
  }
  return (
    <div className="favorite-object-tab">
      {favoriteDetails.length === 0 ? (
        <div className="no-objects">
          {terms.FavoriteModal.noFavoriteObject}
        </div>
      ) : (
        <>
          {favoriteDetails.map(object => (
            <div key={object.id} className="object-details">
              <div className="infos">
                <strong className="title">{getObjectLabel(object)}</strong>
                <div className="description">
                  <strong>{`${terms.featuresPopup.properties.lrs_ligne} : `}</strong>
                  <span>{object.properties?.lrs_ligne || 'NC'}</span>
                </div>
                <div className="description">
                  <strong>{`${terms.featuresPopup.properties.lrs_voie} : `}</strong>
                  <span>{object.properties?.lrs_voie || 'NC'}</span>
                </div>
                <div className="description">
                  <strong>{`${terms.featuresPopup.properties.lrs_pk} : `}</strong>
                  <span>{object.properties?.lrs_pk || 'NC'}</span>
                </div>
              </div>
              <div className="actions">
                <Tooltip title={terms.FavoriteModal.see}>
                  <button type="button" className="view" onClick={handleViewObject(object)}>
                    <Visibility />
                  </button>
                </Tooltip>
                <Tooltip title={terms.FavoriteModal.delete}>
                  <button type="button" className="delete" onClick={handleDeleteObject(object.id)}>
                    <Delete />
                  </button>
                </Tooltip>
              </div>
            </div>
          ))}
        </>
      )}
    </div>
  )
}

export default ObjectTab
