import { signal } from '@preact/signals-react'
import { Ref, useEffect, useRef } from 'react'
import ReactMapGL, { MapRef, ViewportProps } from 'react-map-gl'
import { UserStudyPerimeterDetailsWithId } from 'store/userFavorite/types'
import { transformRequest } from 'utils'
import { Map } from 'assets'
import { SCH_DEFAULT_VIEWPORT } from 'views/Map'
import { calculateGeometryViewport } from 'components/utils'
import PerimeterMapMenu from 'components/MapMenu/PerimeterMapMenu'
import { addedImagesSignal, loadMissingImage, loadStaticImages, refreshTiles } from 'views/Map/utils'
import PerimeterSchLayers from 'components/Layers/PerimeterSchLayers'
import {
  INTERACTIVE_LAYERS_IDS, LAYERS_KEYS, OBJECTS_LAYERS, SCHEMATIC_VIEWS, SOURCES_IDS,
} from 'components/Layers/common'
import { MapPopup } from 'components'
import MultiFeaturesPopup from 'components/MultiFeaturesPopup/MultiFeaturesPopup'
import { MapTheme } from 'components/Toolbar/ThemeMenu/const'
import { reprojectPoints } from 'utils/cassini'
import { get } from '@osrdata/app_core/dist/requests'
import { Feature, Polygon } from 'geojson'
import { polygon } from '@turf/helpers'
import { RootState } from 'store'
import { useSelector } from 'react-redux'
import { perimeterActiveLayersSignal, perimeterActiveViewsSignal, perimeterHoveredIdSignal } from './PerimeterView'
import { useFeatureClick, useHoverEvent } from './hooks'
import { geoPerimeterViewportSignal } from './PerimeterGeoMap'

export const schPerimeterViewportSignal = signal<ViewportProps>(SCH_DEFAULT_VIEWPORT)
export const schPerimeterBboxSignal = signal<Polygon | null>(null)

interface Props {
  activePerimeter: UserStudyPerimeterDetailsWithId
  setSelectedObjectsIds: (ids: string[]) => void
  setHoveredObjectsIds: (ids: string[]) => void
  defaultViewport: ViewportProps
}

const PerimeterSchMap = ({ activePerimeter, setSelectedObjectsIds, setHoveredObjectsIds, defaultViewport }: Props) => {
  const mapRef = useRef<MapRef | undefined >(undefined)
  const { sourcesToUpdate } = useSelector((state: RootState) => state.map)
  const [onHover] = useHoverEvent()
  const [
    selectedFeatures, onFeatureClick, handlePopupClose, handleMultiPopupClose, handleClickFeature,
  ] = useFeatureClick(MapTheme.schematic, setSelectedObjectsIds)

  const onViewportChange = (newViewport: ViewportProps) => {
    schPerimeterViewportSignal.value = { ...newViewport }
  }

  useEffect(() => {
    addedImagesSignal.value = new Set<string>()
    const map = mapRef?.current?.getMap()
    loadStaticImages(mapRef)
    const onStyleImageMissing = (e: {id: string}) => { loadMissingImage(mapRef, e.id) }
    map.on('styleimagemissing', onStyleImageMissing)
  }, [])

  useEffect(() => {
    const bounds = mapRef.current?.getMap().getBounds()
    schPerimeterBboxSignal.value = polygon([[
      [bounds.getNorthWest().lng, bounds.getNorthWest().lat],
      [bounds.getNorthEast().lng, bounds.getNorthEast().lat],
      [bounds.getSouthEast().lng, bounds.getSouthEast().lat],
      [bounds.getSouthWest().lng, bounds.getSouthWest().lat],
      [bounds.getNorthWest().lng, bounds.getNorthWest().lat],
    ]]).geometry
  }, [schPerimeterViewportSignal.value])

  useEffect(() => {
    refreshTiles(mapRef)
  }, [sourcesToUpdate])

  const handleResetNorth = () => {
    schPerimeterViewportSignal.value = { ...schPerimeterViewportSignal.value, bearing: 0, pitch: 0 }
  }
  const handleZoomOutClick = () => { schPerimeterViewportSignal.value = defaultViewport }

  const handleClickPoste = (geom: GeoJSON.Geometry, zapId: string, isModifiable: boolean) => () => {
    const { latitude, longitude, zoom } = calculateGeometryViewport(geom, schPerimeterViewportSignal.value)
    if (!perimeterActiveLayersSignal.value.includes(LAYERS_KEYS.zoneActionPosteAmendee) && isModifiable) {
      perimeterActiveLayersSignal.value = [...perimeterActiveLayersSignal.value, LAYERS_KEYS.zoneActionPosteAmendee]
    }
    if (!perimeterActiveLayersSignal.value.includes(LAYERS_KEYS.zoneActionPoste) && !isModifiable) {
      perimeterActiveLayersSignal.value = [...perimeterActiveLayersSignal.value, LAYERS_KEYS.zoneActionPoste]
    }
    setSelectedObjectsIds([zapId])
    schPerimeterViewportSignal.value = ({
      ...schPerimeterViewportSignal.value, latitude, longitude, zoom, transitionDuration: 500,
    })
  }

  const handleHoverPoste = (zapId: string) => () => {
    perimeterHoveredIdSignal.value = [+zapId as unknown as string]
  }

  const handleClickZap = (zapId: string) => async () => {
    // eslint-disable-next-line max-len
    const clickedZap: Feature = await get(`/chartis/v2/layer/${OBJECTS_LAYERS.actionZonePoste}/geojson_feature/${SCHEMATIC_VIEWS[SOURCES_IDS.actionZone]}/`, { id: zapId })
    handleClickPoste(clickedZap.geometry, zapId, clickedZap.properties?.modifiable)()
  }

  const handleHoverBal = (balId: string) => () => {
    perimeterHoveredIdSignal.value = [balId]
  }

  const handleClickBal = (balId: string) => async () => {
    // eslint-disable-next-line max-len
    const clickedBal: Feature = await get(`/chartis/v2/layer/${OBJECTS_LAYERS.balZone}/geojson_feature/${SCHEMATIC_VIEWS[SOURCES_IDS.balZone]}/`, { id: balId })
    const { latitude, longitude, zoom } = calculateGeometryViewport(
      clickedBal.geometry, schPerimeterViewportSignal.value,
    )
    if (!perimeterActiveLayersSignal.value.includes(LAYERS_KEYS.balZone)) {
      perimeterActiveLayersSignal.value = [...perimeterActiveLayersSignal.value, LAYERS_KEYS.balZone]
    }
    setSelectedObjectsIds([balId])
    schPerimeterViewportSignal.value = ({
      ...schPerimeterViewportSignal.value, latitude, longitude, zoom, transitionDuration: 500,
    })
  }

  const handleSyncViewport = async () => {
    const { latitude, longitude, zoom } = geoPerimeterViewportSignal.value
    if (!latitude || !longitude) return
    const projectedPoints = await reprojectPoints(
      [{ type: 'Point', coordinates: [longitude, latitude] }],
      'rgi_track_geo',
      'rgi_track_sch_flat',
    )
    if (projectedPoints.length === 0) return
    const [newLongitude, newLatitude] = projectedPoints[0].coordinates
    schPerimeterViewportSignal.value = ({
      ...schPerimeterViewportSignal.value,
      latitude: newLatitude,
      longitude: newLongitude,
      zoom,
      transitionDuration: 500,
    })
  }

  return (
    <ReactMapGL
      {...schPerimeterViewportSignal.value}
      width="100%"
      height="100%"
      onViewportChange={onViewportChange}
      clickRadius={2}
      transformRequest={transformRequest}
      interactiveLayerIds={INTERACTIVE_LAYERS_IDS.schPerimeter}
      mapStyle={Map.emptyStyle}
      ref={mapRef as Ref<MapRef>}
      onHover={onHover}
      onClick={onFeatureClick}
    >
      <PerimeterMapMenu
        handleResetNorth={handleResetNorth}
        handleZoomOutClick={handleZoomOutClick}
        handleSyncViewport={handleSyncViewport}
        disableSyncViewport={!perimeterActiveViewsSignal.value.geo}
      />
      <PerimeterSchLayers activePerimeter={activePerimeter} />

      <MapPopup
        longitude={selectedFeatures.longitude}
        latitude={selectedFeatures.latitude}
        feature={selectedFeatures.features?.[0] || null}
        featureData={selectedFeatures.featureData}
        showPopup={selectedFeatures.showPopup}
        handleClose={handlePopupClose}
        handleClickPoste={handleClickPoste}
        handleHoverPoste={handleHoverPoste}
        handleClickZap={handleClickZap}
        handleClickBal={handleClickBal}
        handleHoverBal={handleHoverBal}
        handleHoverZap={handleHoverPoste}
      />

      <MultiFeaturesPopup
        longitude={selectedFeatures.longitude}
        latitude={selectedFeatures.latitude}
        features={selectedFeatures.features}
        showPopup={selectedFeatures.showMultiPopup}
        handleClose={handleMultiPopupClose}
        setHoveredId={setHoveredObjectsIds}
        handleClickFeature={handleClickFeature}
      />
    </ReactMapGL>
  )
}

export default PerimeterSchMap
