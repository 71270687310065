import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import {
  Accordion, AccordionDetails, AccordionSummary, Checkbox, FormControlLabel, Radio, Typography,
} from '@mui/material'
import { MouseEvent, ReactElement, SyntheticEvent } from 'react'
import './VisibilityAccordion.scss'
import { HideableLayers, Layer, SubcategoryLayer } from '../types'
import SubCategoryAccordion from './SubCategoryAccordion'
import { MapTheme } from '../ThemeMenu/const'

const getLayerKeys = (subcategory: Layer): string[] => {
  const layerKeys: string[] = []
  if (!subcategory.subcategory) return [subcategory.layer]
  subcategory.layers.forEach(layer => {
    if (layer.subcategory) {
      layerKeys.push(...getLayerKeys(layer as SubcategoryLayer))
    } else {
      layerKeys.push(layer.layer)
    }
  })
  // console.log(subcategory.name, layerKeys)
  return layerKeys
}

const getMutuallyExclusiveLayers = (layers: HideableLayers, layerName: string) => {
  const clickedLayer = layers.layers.find(layer => layer.name === layerName)
  let layerToEnable: string[] = []
  if (clickedLayer) {
    layerToEnable = [...getLayerKeys(clickedLayer)]
  }
  const layerToDisable = layers.layers.filter(layer => layer.name !== layerName).flatMap(layer => getLayerKeys(layer))
  return { layerToEnable, layerToDisable }
}

type Props = {
  layerList: HideableLayers[]
  updateLayerVisibility: (layer: string, checked: boolean) => void
  isLayerActive: (layer: string) => boolean
  theme: MapTheme
}
export default function VisibilityAccordion({
  layerList, updateLayerVisibility, isLayerActive, theme,
}: Props): ReactElement {
  const handleClickMutuallyExclusive = (category: HideableLayers) => (layerName: string) => () => {
    const { layerToEnable, layerToDisable } = getMutuallyExclusiveLayers(category, layerName)
    layerToEnable.forEach(layerKey => updateLayerVisibility(layerKey, true))
    layerToDisable.forEach(layerKey => updateLayerVisibility(layerKey, false))
  }

  const handleClickRadio = (category: HideableLayers) => (layerName: string) => (
    e: MouseEvent<HTMLButtonElement, globalThis.MouseEvent> | SyntheticEvent<Element, Event>,
  ) => {
    const { checked } = e.target as HTMLInputElement
    if (!checked) return
    const { layerToEnable, layerToDisable } = getMutuallyExclusiveLayers(category, layerName)
    layerToEnable.forEach(layerKey => updateLayerVisibility(layerKey, false))
    layerToDisable.forEach(layerKey => updateLayerVisibility(layerKey, false))
  }

  const handleCheckMutuallyExclusive = (category: HideableLayers, layerName: string) => (
    layer: string, checked: boolean,
  ) => {
    const { layerToDisable } = getMutuallyExclusiveLayers(category, layerName)
    if (checked) {
      layerToDisable.forEach(layerKey => updateLayerVisibility(layerKey, false))
    }
    updateLayerVisibility(layer, checked)
  }
  return (
    <>
      {layerList.map(category => (
        <Accordion
          className="accordion"
          disableGutters
          elevation={0}
          key={category.category}
          defaultExpanded={category.expanded}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon color="inherit" />} key={category.category}>
            <Typography>
              {category.category}
            </Typography>
          </AccordionSummary>
          <AccordionDetails className="p-0">
            {category.layers.map(layer => (layer.subcategory ? (
              <SubCategoryAccordion
                subcategory={layer}
                updateLayerVisibility={category.mutuallyExclusive
                  ? handleCheckMutuallyExclusive(category, layer.name) : updateLayerVisibility}
                defaultExpanded={layer.expanded}
                key={layer.name}
                isLayerActive={isLayerActive}
                theme={theme}
                mutuallyExclusive={category.mutuallyExclusive}
                handleClickMutuallyExclusive={handleClickMutuallyExclusive(category)}
                handleClickRadio={handleClickRadio(category)}
              />
            ) : (
              <FormControlLabel
                sx={{ ml: 2 }}
                key={layer.name}
                label={(
                  <Typography
                    className={`layer-name${!layer.themes.includes(theme) ? ' disabled' : ''}`}
                  >
                    {layer.name}
                  </Typography>
                )}
                control={category.mutuallyExclusive ? (
                  <Radio
                    checked={isLayerActive(layer.layer)}
                    onChange={handleClickMutuallyExclusive(category)(layer.name)}
                    onClick={handleClickRadio(category)(layer.name)}
                    disabled={!layer.themes.includes(theme)}
                  />
                ) : (
                  <Checkbox
                    checked={isLayerActive(layer.layer)}
                    onChange={(_e, checked) => updateLayerVisibility(layer.layer, checked)}
                    disabled={!layer.themes.includes(theme)}
                  />
                )}
              />
            )))}
          </AccordionDetails>
        </Accordion>
      ))}
    </>
  )
}
