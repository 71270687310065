import { ReactElement } from 'react'
import { Source, Layer } from 'react-map-gl'
import { LAYERS_IDS, OBJECTS_LAYERS, SOURCES_IDS, hoverColor } from '../common'
import { MapURLParams, useMapURL } from '../hooks'

interface Props {
  hoveredObjectsIds: string[]
  view: string
  params: MapURLParams
  opacity?: number
  lineFilter?: string
  visibility: boolean
}

const CDVLayer = ({
  hoveredObjectsIds, view, params, opacity, lineFilter, visibility,
}: Props): ReactElement => {
  const url = useMapURL(
    OBJECTS_LAYERS.cdv,
    view,
    SOURCES_IDS.cdv,
    params,
  )

  return (
    <Source
      id={SOURCES_IDS.cdv}
      type="vector"
      url={url}
    >
      <Layer
        type="line"
        paint={{
          'line-width': 3,
          'line-color': hoverColor('#038f03', hoveredObjectsIds),
          'line-opacity': ['case',
            ['in', lineFilter, ['get', 'lrs_ligne']], 1,
            ['in', ['get', 'id'], ['literal', hoveredObjectsIds]], 1,
            opacity],
        }}
        id={LAYERS_IDS.cdv}
        source-layer={OBJECTS_LAYERS.cdv}
        layout={{ visibility: visibility ? 'visible' : 'none' }}
        minzoom={9}
      />
      <Layer
        type="symbol"
        id={LAYERS_IDS.cdvLabel}
        source-layer={OBJECTS_LAYERS.cdv}
        paint={{
          'text-color': '#ffffff',
          'text-halo-color': hoverColor('#038f03', hoveredObjectsIds),
          'text-halo-width': 1.5,
        }}
        layout={{
          'text-font': ['Open Sans Regular'],
          'symbol-placement': 'line',
          'text-field': '{libelle}',
          'text-size': 12,
          'text-allow-overlap': false,
          'symbol-spacing': 1000,
          visibility: visibility ? 'visible' : 'none',
        }}
        minzoom={12}
      />
    </Source>
  )
}

CDVLayer.defaultProps = {
  opacity: 1,
  lineFilter: '',
}

export default CDVLayer
