import { store } from 'store'
import { addActiveLayer, removeActiveLayer } from 'store/map'

const isLayerVisible = (layer: string) => (store.getState().map.activeLayers.includes(layer))

const handleVisiblityChange = (layer: string, checked: boolean) => {
  if (checked) {
    store.dispatch(addActiveLayer(layer))
  } else {
    store.dispatch(removeActiveLayer(layer))
  }
}

export {
  isLayerVisible, handleVisiblityChange,
}
