import { MapTheme } from 'components/Toolbar/ThemeMenu/const'
import { Polygon } from 'geojson'
import {
  CHANTIER_PHASES_KEYS,
  CHANTIER_PHASES_MAP,
  CHANTIER_YEARS_KEYS,
  CHANTIER_YEARS_MAP,
  DexcartoLayerKey, GEOGRAPHIC_VIEWS, LAYERS_KEYS, OBJECTS_LAYERS,
  SCHEMATIC_VIEWS, SOURCES_IDS,
} from 'components/Layers/common'
import { get } from '@osrdata/app_core/dist/requests'
import { CassiniSearchResult, SearchResults } from './types'

const SEARCH_FIELDS = [
  'libelle', 'reference_emergence', 'numero_astre', 'seism_project_id', 'cle_banque_travaux', 'num_compte_geremi',
]

const validLayers = (activeLayers: DexcartoLayerKey[]) => {
  if (!CHANTIER_PHASES_KEYS.some(phase => activeLayers.includes(phase))) return false
  if (!CHANTIER_YEARS_KEYS.some(year => activeLayers.includes(year))) return false
  return true
}

const getChantierParams = (activeLayers: DexcartoLayerKey[]) => {
  const activeYears = Object.entries(CHANTIER_YEARS_MAP)
    .filter(([key]) => activeLayers.includes(key)).map(([, value]) => value)
  const activePhases = Object.entries(CHANTIER_PHASES_MAP)
    .filter(([key]) => activeLayers.includes(key)).flatMap(([, value]) => value)
  const filterASTRE = activeLayers.includes(LAYERS_KEYS.chantierASTRE)
  const filterSEISM = activeLayers.includes(LAYERS_KEYS.chantierSEISM)

  return {
    annee__in: activeYears.join(','),
    phase__in: activePhases.join(','),
    ...(filterASTRE && { numero_astre__isnull: false }),
    ...(filterSEISM && { seism_project_id__isnull: false }),
  }
}

// eslint-disable-next-line import/prefer-default-export
export const getChantierObjects = async (
  activeLayers: DexcartoLayerKey[], theme: MapTheme, bbox: Polygon, query: string,
) => {
  if (!validLayers(activeLayers)) return null
  const idSet = new Set()
  const view = theme === MapTheme.geographic ? GEOGRAPHIC_VIEWS[SOURCES_IDS.chantier]
    : SCHEMATIC_VIEWS[SOURCES_IDS.chantier]
  const bboxParam = theme === MapTheme.perimeter ? 'geom_rgi_track_sch_flat__bpolygon' : 'bpolygon'
  const url = `/chartis/v2/layer/${OBJECTS_LAYERS.chantier}/search/${view}`
  const params = getChantierParams(activeLayers)

  const chantiers = await Promise.all(SEARCH_FIELDS.map(async field => get(url, {
    columns: 'id,libelle,lrs_pk,lrs_voie,lrs_ligne,geom_rgi_track_sch_flat,geom_rgi_track_geo',
    [`${field}__ilike`]: `%${query.trim()}%`,
    [bboxParam]: bbox,
    page_size: 100,
    ...params,
  }))) as CassiniSearchResult[]

  const responseFeatures = chantiers.flatMap(featureCol => featureCol.features)
  return responseFeatures.reduce((acc: SearchResults, feature) => {
    if (idSet.has(feature.properties.id)) return acc
    idSet.add(feature.properties.id)
    return {
      ...acc,
      [OBJECTS_LAYERS.chantier]: [
        ...(acc[OBJECTS_LAYERS.chantier] || []),
        {
          type: 'cassini' as const,
          id: feature.properties.id,
          libelle: feature.properties.libelle,
          pk: feature.properties.lrs_pk,
          trackName: feature.properties.lrs_voie,
          lineNumber: feature.properties.lrs_ligne,
          schGeom: feature.properties.geom_rgi_track_sch_flat,
          geoGeom: feature.properties.geom_rgi_track_geo,
        },
      ],
    }
  }, {})
}
