import terms from 'common/terms'
import { ChantierType } from 'components/types'
import { Feature } from 'geojson'

/* eslint-disable camelcase */
// eslint-disable-next-line max-len
export const getPosteLabel = (feature: Feature) => `${feature.properties?.libelle ?? terms.objectsInfos.unknownPoste}
${feature.properties?.type_installation_fixe_id_mnemo}${!feature.properties?.has_zap ? '*' : ''}`

export const getChantierLabel = (feature: ChantierType) => {
  const {
    num_compte_geremi = '', famille = '', libelle = '', lrs_ligne = '', lrs_pk = '', numero_astre,
  } = feature || {}
  if (numero_astre && num_compte_geremi) {
    return `${num_compte_geremi} - ${numero_astre} - ${libelle} - ${famille} ${lrs_ligne} - ${lrs_pk}`
  } if (numero_astre) {
    return `${numero_astre} - ${libelle} - ${famille} ${lrs_ligne} - ${lrs_pk}`
  } if (num_compte_geremi) {
    return `${num_compte_geremi} - ${libelle} - ${famille} ${lrs_ligne} - ${lrs_pk}`
  }
  return `${libelle} - ${famille} ${lrs_ligne} - ${lrs_pk}`
}

export const getChantierTooltip = (feature: ChantierType) => {
  const {
    cle_banque_travaux = '', famille = '', libelle = '', lrs_ligne = '', lrs_pk = '',
    numero_astre, num_compte_geremi,
  } = feature || {}
  return `${terms.featuresPopup.properties.chantier.account}: ${num_compte_geremi || 'NC'}
  ${terms.featuresPopup.properties.chantier.astre}: ${numero_astre || 'NC'}
  ${terms.featuresPopup.properties.chantier.key}: ${cle_banque_travaux || 'NC'}
  ${terms.featuresPopup.properties.chantier.label}: ${libelle}
  ${terms.featuresPopup.properties.chantier.family}: ${famille}
  ${terms.featuresPopup.properties.chantier.line}: ${lrs_ligne}
  ${terms.featuresPopup.properties.chantier.pk}: ${lrs_pk}`
}

export const getBalZoneLabel = (feature: Feature) => `${feature.properties?.libelle} - 
${feature.properties?.lrs_ligne} - ${feature.properties?.lrs_pk}`
