import { get } from '@osrdata/app_core/dist/requests'
import { createAsyncThunk } from '@reduxjs/toolkit'

const getCollections = createAsyncThunk(
  'get/collections',
  async (params: {page: number, search: string, ordering: string}, thunkApi) => {
    try {
      const response = await get('/dexcarto/ouranos-element', {
        page_size: 50,
        page: params.page,
        search: params.search,
        ordering: params.ordering,
      })
      return response
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const getPerimeterCollections = createAsyncThunk(
  'get/perimeterCollections',
  async (params: {perimeterId: number, page: number, search: string, ordering: string}, thunkApi) => {
    try {
      const response = await get(`/dexcarto/user-study-perimeter/${params.perimeterId}/ouranos-element/`, {
        page_size: 50,
        page: params.page,
        search: params.search,
        ordering: params.ordering,
      })
      return response
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },

)

// eslint-disable-next-line import/prefer-default-export
export { getCollections, getPerimeterCollections }
