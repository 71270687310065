import { useEffect, useState } from 'react'
import { MapMenu, Toolbar, ObjectInfos, BordersInfos } from 'components'
import EditorOverlay from 'components/Layers/Editor/EditorOverlay'
import { useBalZoneFeatures, useChantierFeatures, usePostesFeatures } from 'components/hooks'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'store'
import {
  CHANTIER_YEARS_KEYS, CHANTIER_PHASES_KEYS,
  LAYERS_KEYS, OBJECTS_LAYERS, POSTES_LAYER_KEYS, GEOGRAPHIC_VIEWS, SOURCES_IDS, SCHEMATIC_VIEWS,
} from 'components/Layers/common'
import { isLayerVisible } from 'components/Toolbar/utils'
import terms from 'common/terms'
import { EditorLayer } from 'components/Layers'
import './index.scss'
import { Feature } from 'geojson'
import { ChantierType } from 'components/types'
import { get } from '@osrdata/app_core/dist/requests'
import { calculateGeometryViewport } from 'components/utils'
import { addActiveLayer } from 'store/map'
import { sendError } from 'store/feedback'
import { MapTheme } from 'components/Toolbar/ThemeMenu/const'
import { getBalZoneLabel, getChantierLabel, getChantierTooltip, getPosteLabel } from './utils'
import { mapBboxSignal, mapViewportSignal } from '..'

interface Props {
  setHoveredObjectsIds: (ids: string[]) => void
  setSelectedObjectsIds: (ids: string[]) => void
  viewportZP: string | null
  viewportInfra: string | null
  viewportPRI: string | null
}

const MapOverlay = ({
  setHoveredObjectsIds, setSelectedObjectsIds, viewportInfra, viewportPRI, viewportZP,
}: Props) => {
  const dispatch = useDispatch()
  const { isSelectingPoste } = useSelector((state: RootState) => state.zoneEditor)
  const { activeLayers, currentOsmTheme } = useSelector((state: RootState) => state.map)
  const {
    lineFilter, lineFilterActive, dateRangeEnd, dateRangeStart,
  } = useSelector((state: RootState) => state.objectSearch)
  const [posteExpanded, setPosteExpanded] = useState(false)
  const [posteVisible, setPosteVisible] = useState(false)
  const [chantierExpanded, setChantierExpanded] = useState(false)
  const [chantierVisible, setChantierVisible] = useState(false)
  const [balZoneExpanded, setBalZoneExpanded] = useState(false)
  const [balZoneVisible, setBalZoneVisible] = useState(false)
  const [activePostes, setActivePostes] = useState<string[]>([])

  useEffect(() => {
    setPosteVisible(!isSelectingPoste && Object.values(POSTES_LAYER_KEYS).some(key => isLayerVisible(key)))
  }, [isSelectingPoste, activeLayers])

  useEffect(() => {
    setChantierVisible(
      CHANTIER_YEARS_KEYS.some(key => isLayerVisible(key))
      && CHANTIER_PHASES_KEYS.some(key => isLayerVisible(key)),
    )
    setBalZoneVisible(isLayerVisible(LAYERS_KEYS.balZone))

    const visiblePostes = Object.entries(POSTES_LAYER_KEYS)
      .filter(([, value]) => isLayerVisible(value)).map(([key]) => key)
    setActivePostes(visiblePostes)
  }, [activeLayers])

  const postesFeatures = usePostesFeatures(
    (posteVisible && posteExpanded) || isSelectingPoste,
    currentOsmTheme,
    mapViewportSignal,
    mapBboxSignal,
    isSelectingPoste ? undefined : activePostes,
    { lineFilter, lineFilterActive, dateRangeEnd, dateRangeStart },
  )
  const chantierFeatures = useChantierFeatures(
    chantierVisible && chantierExpanded,
    currentOsmTheme,
    mapViewportSignal,
    mapBboxSignal,
  )
  const balZoneFeatures = useBalZoneFeatures(
    balZoneVisible && balZoneExpanded,
    currentOsmTheme,
    mapViewportSignal,
    mapBboxSignal,
  )

  const handleClickChantier = (feature: ChantierType) => () => {
    setSelectedObjectsIds([feature.id])
  }
  const handleHoverChantier = (feature: ChantierType) => () => setHoveredObjectsIds([feature.id])

  const handleClickPoste = (feature: Feature) => async () => {
    try {
      const view = currentOsmTheme === MapTheme.schematic
        ? SCHEMATIC_VIEWS[SOURCES_IDS.actionZone] : GEOGRAPHIC_VIEWS[SOURCES_IDS.actionZone]

      const chartisResponse: Feature = await get(
        `/chartis/v2/layer/${OBJECTS_LAYERS.actionZonePoste}/geojson_feature/${view}/`,
        { poste_gaia_id: feature.properties?.id, modifiable: true },
      )
      const { latitude, longitude, zoom } = calculateGeometryViewport(chartisResponse.geometry, mapViewportSignal.value)
      mapViewportSignal.value = { ...mapViewportSignal.value, latitude, longitude, zoom, transitionDuration: 500 }
      if (!isLayerVisible(LAYERS_KEYS.zoneActionPosteAmendee)) {
        dispatch(addActiveLayer(LAYERS_KEYS.zoneActionPosteAmendee))
      }
      setSelectedObjectsIds([chartisResponse.properties?.id, feature.properties?.id])
    } catch (error) {
      dispatch(sendError(`${terms.feedback.noZap1} ${feature.properties?.libelle} ${terms.feedback.noZap2}`))
      setSelectedObjectsIds([feature.properties?.id])
    }
  }
  const handleHoverPoste = (feature: Feature) => () => setHoveredObjectsIds([feature.properties?.id])

  const handleClickBalZone = (feature: Feature) => () => {
    setSelectedObjectsIds([feature.properties?.id])
  }
  const handleHoverBalZone = (feature: Feature) => () => setHoveredObjectsIds([feature.properties?.id])

  return (
    <>
      <MapMenu setHoveredObjectsIds={setSelectedObjectsIds} />
      {currentOsmTheme === MapTheme.schematic && (
        <>
          <EditorOverlay posteFeatures={postesFeatures} />
          <EditorLayer />
        </>
      )}
      <div className="bottom-left-infos">
        <div className="list-objects-infos">
          <ObjectInfos
            features={postesFeatures.features}
            loading={postesFeatures.loading}
            visible={posteVisible}
            title={terms.objectsInfos.postes}
            tooltip={terms.tooltips.posteList}
            getLabel={getPosteLabel}
            getId={f => f.properties?.id}
            getFeatureTooltip={getPosteLabel}
            expanded={posteExpanded}
            setExpanded={setPosteExpanded}
            handleClick={handleClickPoste}
            handleHover={handleHoverPoste}
            windowTooltip={terms.objectsInfos.posteTooltip}
            disableFeature={f => !f.properties?.has_zap}
          />
          <ObjectInfos
            features={chantierFeatures.features}
            loading={chantierFeatures.loading}
            visible={chantierVisible}
            title={terms.objectsInfos.chantiers}
            tooltip={terms.tooltips.chantierList}
            getLabel={getChantierLabel}
            getFeatureTooltip={getChantierTooltip}
            getId={f => f.id}
            expanded={chantierExpanded}
            setExpanded={setChantierExpanded}
            handleClick={handleClickChantier}
            handleHover={handleHoverChantier}
          />
          <ObjectInfos
            features={balZoneFeatures.features}
            loading={balZoneFeatures.loading}
            visible={balZoneVisible}
            title={terms.objectsInfos.balZone}
            tooltip={terms.tooltips.balZoneList}
            getLabel={getBalZoneLabel}
            getFeatureTooltip={getBalZoneLabel}
            getId={f => f.properties?.id}
            expanded={balZoneExpanded}
            setExpanded={setBalZoneExpanded}
            handleClick={handleClickBalZone}
            handleHover={handleHoverBalZone}
          />
        </div>
        { currentOsmTheme === MapTheme.schematic && (
          <BordersInfos
            zp={viewportZP}
            infra={viewportInfra}
            pri={viewportPRI}
          />
        )}
      </div>
      <Toolbar setSelectedObjectsIds={setSelectedObjectsIds} />
    </>
  )
}

export default MapOverlay
