import { DrawOutlined, ZoomOutMap } from '@mui/icons-material'
import { ReactElement } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import './MapMenu.scss'
import { setOpenCreateZoneModal } from 'store/zoneEditor/zoneEditor'
import terms from 'common/terms'
import { RootState } from 'store'
import ObjectSearch from 'components/ObjectSearch/ObjectSearch'
import { NavigationControl, _useMapControl as useMapControl } from 'react-map-gl'
import { Tooltip } from '@mui/material'
import DateRangeFilter from 'components/DateRangeFilter/DateRangeFilter'
import { SCH_DEFAULT_VIEWPORT, GEO_DEFAULT_VIEWPORT, mapViewportSignal } from 'views/Map'
import CreateView from 'components/Layers/CreateView/CreateView'
import { MapTheme } from 'components/Toolbar/ThemeMenu/const'
import { SearchResult } from 'utils/search/types'
import { calculateGeometryViewport } from 'components/utils'

interface Props {
  setHoveredObjectsIds: (ids: string[]) => void
}

const MapMenu = ({ setHoveredObjectsIds }: Props): ReactElement => {
  const dispatch = useDispatch()
  const { containerRef } = useMapControl({
    captureDrag: true, captureClick: true, captureDoubleClick: true, captureScroll: true, capturePointerMove: true,
  })
  const { editorStep } = useSelector((state: RootState) => state.zoneEditor)
  const { currentOsmTheme, activeLayers } = useSelector((state: RootState) => state.map)
  const DEFAULT_VIEWPORT = currentOsmTheme === MapTheme.schematic ? SCH_DEFAULT_VIEWPORT : GEO_DEFAULT_VIEWPORT
  const handleCreateClick = () => dispatch(setOpenCreateZoneModal(true))
  const handleZoomOutClick = () => {
    mapViewportSignal.value = { ...mapViewportSignal.value, ...DEFAULT_VIEWPORT, transitionDuration: 0 }
  }

  const handleResetNorth = () => {
    mapViewportSignal.value = { ...mapViewportSignal.value, bearing: 0, pitch: 0, transitionDuration: 250 }
  }

  const handleClickResult = (result: SearchResult) => () => {
    const geometry = currentOsmTheme === MapTheme.schematic ? result.schGeom : result.geoGeom
    const { latitude, longitude, zoom } = calculateGeometryViewport(geometry, mapViewportSignal.value)
    mapViewportSignal.value = {
      ...mapViewportSignal.value,
      latitude,
      longitude,
      zoom,
      transitionDuration: 500,
    }
  }

  return (
    <div className="map-menu" ref={containerRef}>
      <DateRangeFilter />
      {!editorStep && currentOsmTheme === MapTheme.schematic && (
      <button type="button" className="create-zone" onClick={handleCreateClick}>
        <DrawOutlined />
        <span>{terms.zoneEditor.createZone}</span>
      </button>
      )}
      <Tooltip title={terms.tooltips.zoomOut}>
        <button type="button" onClick={handleZoomOutClick}>
          <ZoomOutMap />
        </button>
      </Tooltip>
      <Tooltip title={terms.tooltips.resetNorth}>
        <div role="button" tabIndex={0} onClick={handleResetNorth}>
          <NavigationControl
            showZoom={false}
            className="navigation-control"
            style={{ position: 'static' }}
            compassLabel={terms.tooltips.resetNorth}
          />
        </div>
      </Tooltip>
      <CreateView />
      {!editorStep && (
        <ObjectSearch
          setHoveredObjectsIds={setHoveredObjectsIds}
          theme={currentOsmTheme}
          activeLayers={activeLayers}
          handleClickResult={handleClickResult}
        />
      )}
    </div>
  )
}

export default MapMenu
