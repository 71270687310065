import { useCallback, useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { debounce } from 'lodash'
import { RootState } from 'store'
import { getCollections } from 'store/collections/collections.thunk'
import { collectionDetailsSignal } from './details/CollectionDetails'
import CollectionsPage from './page/CollectionsPage'

export const CollectionsView = () => {
  const location = useLocation()
  const history = useHistory()
  const dispatch = useDispatch()
  const { collections, loading, totalPage } = useSelector((state: RootState) => state.collections)
  const [searchValue, setSearchValue] = useState('')
  const [searchPage, setSearchPage] = useState(1)
  const [orderFerroloc, setOrderFerroloc] = useState<'asc' | 'desc' | null>(null)
  const [selectedCollectionId, setSelectedCollectionId] = useState<number | null>(null)

  const debouncedSearch = useCallback(debounce((search: string, page: number, ordering: string) => {
    dispatch(getCollections({ search, page, ordering }))
    let urlParams = {}
    if (search) urlParams = { ...urlParams, search }
    if (page > 1) urlParams = { ...urlParams, page }
    if (ordering) urlParams = { ...urlParams, ordering }
    history.push(`/collections?${new URLSearchParams(urlParams).toString()}`)
  }, 500), [])

  useEffect(() => {
    let ordering = ''
    if (orderFerroloc === 'asc') ordering = 'ligne_id,kp'
    if (orderFerroloc === 'desc') ordering = '-ligne_id,-kp'
    debouncedSearch(searchValue, searchPage, ordering)
  }, [searchValue, searchPage, orderFerroloc])

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search)
    if (urlParams.has('search')) setSearchValue(urlParams.get('search') || '')
    if (urlParams.has('page')) setSearchPage(parseInt(urlParams.get('page') || '1', 10))
    if (urlParams.has('ordering')) {
      const ordering = urlParams.get('ordering')
      if (ordering === 'ligne_id,kp') setOrderFerroloc('asc')
      else if (ordering === '-ligne_id,-kp') setOrderFerroloc('desc')
      else setOrderFerroloc(null)
    }
    if (urlParams.has('details')) setSelectedCollectionId(parseInt(urlParams.get('details') || '1', 10))
  }, [location.search])

  const handleSelectCollection = (collectionId: number) => () => {
    setSelectedCollectionId(collectionId)
    history.push(`/collections?details=${collectionId}`)
  }

  const handleGoBack = () => {
    setSelectedCollectionId(null)
    let urlParams = {}
    if (searchValue) urlParams = { ...urlParams, search: searchValue }
    if (searchPage > 1) urlParams = { ...urlParams, page: searchPage }
    history.push(`/collections?${new URLSearchParams(urlParams).toString()}`)
    collectionDetailsSignal.value = null
  }

  return (
    <CollectionsPage
      collections={collections}
      selectedCollectionId={selectedCollectionId}
      orderFerroloc={orderFerroloc}
      setOrderFerroloc={setOrderFerroloc}
      setSearchValue={setSearchValue}
      setSearchPage={setSearchPage}
      handleGoBack={handleGoBack}
      handleSelectCollection={handleSelectCollection}
      loading={loading}
      searchValue={searchValue}
      searchPage={searchPage}
      totalPage={totalPage}
    />
  )
}

export default CollectionsView
