import bbox from '@turf/bbox'
import { Geometry } from 'geojson'
import { ViewportProps, WebMercatorViewport } from 'react-map-gl'

export const calculateGeometryViewport = (
  geometry: Geometry, viewport: ViewportProps, maxZoom = 13, defaultZoom = 15,
) => {
  if (geometry.type === 'Point') {
    return {
      latitude: geometry.coordinates[1],
      longitude: geometry.coordinates[0],
      zoom: defaultZoom,
    }
  }
  const geomBbox = bbox({ type: 'Feature', geometry, properties: {} })
  const { longitude, latitude, zoom } = new WebMercatorViewport(viewport as { width: number, height: number })
    .fitBounds([[geomBbox[0], geomBbox[1]], [geomBbox[2], geomBbox[3]]], { padding: 20 })
  return {
    latitude,
    longitude,
    zoom: Math.min(zoom, maxZoom),
  }
}

export default { calculateGeometryViewport }
