import { DexCartoObjectLayerKey, DexCartoSourceKey } from '../type'
import { SOURCES_IDS } from './sources'

export const OBJECTS_LAYERS = {
  trackGeo: 'cassini_v2_rgi_track_geo',
  trackSch: 'cassini_v2_rgi_track_sch_flat',
  signal: 'cassini_v2_gaia_signal',
  poste: 'dexcarto_gaia_poste',
  station: 'cassini_v2_gaia_gare',
  jdz: 'cassini_v2_gaia_joint_de_zone',
  adv: 'cassini_v2_gaia_appareil_de_voie',
  advLine: 'cassini_v2_gaia_appareil_de_voie_lineaire_20',
  acces: 'cassini_v2_gaia_acces',
  sousStation: 'cassini_v2_gaia_sous_station',
  centraleSousStation: 'cassini_v2_gaia_central_sous_station',
  localisateur: 'cassini_v2_gaia_localisateur',
  dbc: 'cassini_v2_gaia_detecteur_boite_chaude',
  pedale: 'cassini_v2_gaia_pedale',
  passageNiveau: 'cassini_v2_gaia_passage_a_niveau',
  actionZonePoste: 'dexcarto_zone_action_poste',
  pri: 'dexcarto_ouranos_habillage_region',
  utm: 'dexcarto_ouranos_habillage_structure',
  zpRegion: 'dexcarto_gaia_structure',
  chantier: 'dexcarto_chantier',
  balZone: 'dexcarto_zone_bal',
  center: 'dexcarto_centre_technique',
  centerArmen: 'dexcarto_centre_technique_armen',
  ihmZone: 'dexcarto_zone_ihm',
  cdv: 'cassini_v2_gaia_circuit_de_voie',
  exploitationMode: 'cassini_v2_gaia_habillage_valeur_margin_100',
  bf: 'cassini_v2_gaia_point_remarquable',
  rac: 'cassini_v2_gaia_ligne',
} as const

export const MAIN_SOURCES: Record<DexCartoObjectLayerKey, DexCartoSourceKey> = {
  [OBJECTS_LAYERS.trackGeo]: SOURCES_IDS.track,
  [OBJECTS_LAYERS.trackSch]: SOURCES_IDS.track,
  [OBJECTS_LAYERS.signal]: SOURCES_IDS.signal,
  [OBJECTS_LAYERS.poste]: SOURCES_IDS.poste,
  [OBJECTS_LAYERS.station]: SOURCES_IDS.station,
  [OBJECTS_LAYERS.jdz]: SOURCES_IDS.jdz,
  [OBJECTS_LAYERS.adv]: SOURCES_IDS.adv,
  [OBJECTS_LAYERS.advLine]: SOURCES_IDS.advLine,
  [OBJECTS_LAYERS.acces]: SOURCES_IDS.acces,
  [OBJECTS_LAYERS.sousStation]: SOURCES_IDS.sousStation,
  [OBJECTS_LAYERS.localisateur]: SOURCES_IDS.localisateur,
  [OBJECTS_LAYERS.dbc]: SOURCES_IDS.dbc,
  [OBJECTS_LAYERS.pedale]: SOURCES_IDS.pedale,
  [OBJECTS_LAYERS.passageNiveau]: SOURCES_IDS.passageNiveau,
  [OBJECTS_LAYERS.pri]: SOURCES_IDS.pri,
  [OBJECTS_LAYERS.utm]: SOURCES_IDS.utm,
  [OBJECTS_LAYERS.zpRegion]: SOURCES_IDS.zpRegion,
  [OBJECTS_LAYERS.chantier]: SOURCES_IDS.chantier,
  [OBJECTS_LAYERS.balZone]: SOURCES_IDS.balZone,
  [OBJECTS_LAYERS.center]: SOURCES_IDS.center,
  [OBJECTS_LAYERS.ihmZone]: SOURCES_IDS.ihmZone,
  [OBJECTS_LAYERS.cdv]: SOURCES_IDS.cdv,
  [OBJECTS_LAYERS.exploitationMode]: SOURCES_IDS.exploitationMode,
  [OBJECTS_LAYERS.actionZonePoste]: SOURCES_IDS.actionZone,
  [OBJECTS_LAYERS.centraleSousStation]: SOURCES_IDS.css,
  [OBJECTS_LAYERS.bf]: SOURCES_IDS.bf,
  [OBJECTS_LAYERS.rac]: SOURCES_IDS.rac,
  [OBJECTS_LAYERS.centerArmen]: SOURCES_IDS.centerArmen,
}

export default OBJECTS_LAYERS
