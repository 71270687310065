import { Collection } from 'store/collections/types'
import './CollectionsTable.scss'
import { CircularProgress, Tooltip } from '@mui/material'
import terms from 'common/terms'
import { Fragment } from 'react'
import { VisibilityOutlined } from '@mui/icons-material'

interface Props {
  collections: Collection[]
  loading: boolean
  handleSelectCollection: (collectionId: number) => () => void
  ferrolocOrder: 'asc' | 'desc' | null
  handleChangeOrder: () => void
  handleHoverCollection?: (collectionId: string | null) => () => void
}

const getFerrolocTooltip = (localisation: Collection['localisation']) => {
  if (localisation.pk) return `PK: ${localisation.pk}`
  if (localisation.pk_debut && localisation.pk_fin) {
    return `PK: ${localisation.pk_debut} à ${localisation.pk_fin}`
  }
  return ''
}

const CollectionsTable = ({
  collections, loading, handleSelectCollection, ferrolocOrder, handleChangeOrder, handleHoverCollection,
}: Props) => (
  <div className="collections-table">
    <table>
      <thead>
        <tr>
          <th>{terms.CollectionsView.name}</th>
          <th>{terms.CollectionsView.type}</th>
          <th>
            <button type="button" onClick={handleChangeOrder}>
              {terms.CollectionsView.ferroloc}
              {ferrolocOrder && (
              <span className="order">
                {ferrolocOrder === 'asc' ? '↑' : '↓'}
              </span>
              )}
            </button>
          </th>
          <th>{}</th>
        </tr>
      </thead>
      {!loading && (
        <tbody>
          {collections.map(collection => (
            <Fragment key={collection.id}>
              <tr
                onMouseEnter={handleHoverCollection && handleHoverCollection(collection?.zaps?.[0]?.id)}
              >
                <td>{collection.libelle}</td>
                <td>{collection.type}</td>
                <td className="ferroloc">
                  {collection.ligne_ids.map(ligneId => (
                    <Tooltip key={ligneId} title={getFerrolocTooltip(collection.localisation)} placement="right">
                      <span className="id-ligne">{ligneId}</span>
                    </Tooltip>
                  ))}
                </td>
                <td>
                  <button type="button" className="see-details" onClick={handleSelectCollection(collection.id)}>
                    <VisibilityOutlined />
                    <span>{terms.CollectionsView.seeDetails}</span>
                  </button>
                </td>
              </tr>
            </Fragment>
          ))}
        </tbody>
      )}
    </table>

    {loading && (
    <div className="loader">
      <CircularProgress />
      <span>{terms.CollectionsView.loading}</span>
    </div>
    )}
    {collections.length === 0 && !loading && (
    <div className="empty">
      {terms.CollectionsView.noCollection}
    </div>
    )}
  </div>
)

CollectionsTable.defaultProps = {
  handleHoverCollection: undefined,
}

export default CollectionsTable
