import LAYERS_KEYS from './menuKeys'

export const CHANTIER_LAYERS_KEYS = [
  LAYERS_KEYS.chantier2024,
  LAYERS_KEYS.chantier2025,
  LAYERS_KEYS.chantier2026,
  LAYERS_KEYS.chantier2027,
  LAYERS_KEYS.chantier2028,
  LAYERS_KEYS.chantier2029,
  LAYERS_KEYS.chantier2030Plus,
  LAYERS_KEYS.chantierPhaseREA,
  LAYERS_KEYS.chantierPhaseTVX,
  LAYERS_KEYS.chantierPhaseTVXP,
  LAYERS_KEYS.chantierPhaseREA_TVXP,
  LAYERS_KEYS.chantierPhaseREA_TVX,
  LAYERS_KEYS.chantierPhaseAPO_REA,
  LAYERS_KEYS.chantierPhaseAPO_TVX,
  LAYERS_KEYS.chantierPhaseAPO_REA_TVX,
  LAYERS_KEYS.chantierPhasePRO_REA,
  LAYERS_KEYS.chantierASTRE,
  LAYERS_KEYS.chantierSEISM,
] as const

export const CHANTIER_YEARS_KEYS = [
  LAYERS_KEYS.chantier2024, LAYERS_KEYS.chantier2025, LAYERS_KEYS.chantier2026, LAYERS_KEYS.chantier2027,
  LAYERS_KEYS.chantier2028, LAYERS_KEYS.chantier2029, LAYERS_KEYS.chantier2030Plus,
]

export const CHANTIER_PHASES_KEYS = [
  LAYERS_KEYS.chantierPhaseREA,
  LAYERS_KEYS.chantierPhaseTVX,
  LAYERS_KEYS.chantierPhaseTVXP,
  LAYERS_KEYS.chantierPhaseREA_TVXP,
  LAYERS_KEYS.chantierPhaseREA_TVX,
  LAYERS_KEYS.chantierPhaseAPO_REA,
  LAYERS_KEYS.chantierPhaseAPO_TVX,
  LAYERS_KEYS.chantierPhaseAPO_REA_TVX,
  LAYERS_KEYS.chantierPhasePRO_REA,
]

export const CHANTIER_YEARS_MAP = {
  [LAYERS_KEYS.chantier2024]: '2024',
  [LAYERS_KEYS.chantier2025]: '2025',
  [LAYERS_KEYS.chantier2026]: '2026',
  [LAYERS_KEYS.chantier2027]: '2027',
  [LAYERS_KEYS.chantier2028]: '2028',
  [LAYERS_KEYS.chantier2029]: '2029',
  [LAYERS_KEYS.chantier2030Plus]: '2030',
}

export const CHANTIER_PHASES_MAP = {
  [LAYERS_KEYS.chantierPhaseREA]: ['REA'],
  [LAYERS_KEYS.chantierPhaseTVX]: ['TVX'],
  [LAYERS_KEYS.chantierPhaseTVXP]: ['TVXP'],
  [LAYERS_KEYS.chantierPhaseREA_TVXP]: ['REA_TVXP'],
  [LAYERS_KEYS.chantierPhaseREA_TVX]: ['REA_TVX'],
  [LAYERS_KEYS.chantierPhaseAPO_REA]: ['APO_REA'],
  [LAYERS_KEYS.chantierPhaseAPO_TVX]: ['APO_TVX'],
  [LAYERS_KEYS.chantierPhaseAPO_REA_TVX]: ['APO_REA_TVX'],
  [LAYERS_KEYS.chantierPhasePRO_REA]: ['PRO_REA'],
}
