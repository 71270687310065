import { FeatureOf, Point } from '@nebula.gl/edit-modes'
import { ReactElement, useRef } from 'react'
import { Marker } from 'react-map-gl'
import './EditorPolygonMarker.scss'

interface Props {
  labelPosition: FeatureOf<Point> | undefined
  color: string
  handleDragEnd: (e: { lngLat: [number, number] }) => void
  posteLibelle: string
}

const EditorPolygonMarker = ({ labelPosition, color, handleDragEnd, posteLibelle }: Props): ReactElement => {
  const ref = useRef<HTMLDivElement>(null)
  const { width, height } = ref.current?.getBoundingClientRect() || {}

  const markerStyle = {
    ...(width && { left: -width / 2 }),
    ...(height && { top: -height / 2 }),
  }

  const markerContentStyle = {
    backgroundColor: color,
    ...((!width || !height) && { color: 'transparent', backgroundColor: 'transparent' }),
  }

  return (
    labelPosition ? (
      <Marker
        className="editor-label-marker"
        longitude={labelPosition?.geometry.coordinates[0]}
        latitude={labelPosition?.geometry.coordinates[1]}
        draggable
        onDragEnd={handleDragEnd}
        style={markerStyle}
      >
        <div className="editor-label-marker-content" ref={ref} style={markerContentStyle}>
          {posteLibelle}
        </div>
      </Marker>
    ) : <></>)
}

export default EditorPolygonMarker
