import { MapTheme } from 'components/Toolbar/ThemeMenu/const'
import { Polygon } from 'geojson'
import { get } from '@osrdata/app_core/dist/requests'
import { OBJECTS_LAYERS } from 'components/Layers/common'
import { CassiniSearchResult, SearchParams } from './types'
import { SearchLayers } from './const'
import { computeSearchResults } from './cassini'

// eslint-disable-next-line import/prefer-default-export
export const getCenterArmenObjects = async (
  layers: SearchLayers[], theme: MapTheme, bbox: Polygon,
  query: string, params: SearchParams,
) => {
  if (!layers.includes(SearchLayers.centerArmen)) return null
  const view = theme === MapTheme.geographic ? 'full_rgi_track_geo' : 'full_rgi_track_sch_flat'
  const bboxParam = theme === MapTheme.perimeter ? 'geom_rgi_track_sch_flat__bpolygon' : 'bbox'
  const response : CassiniSearchResult = await get(
    `/chartis/v2/layer/${OBJECTS_LAYERS.centerArmen}/search/${view}/`,
    {
      columns: 'id,libelle,lrs_pk,lrs_voie,lrs_ligne,geom_rgi_track_sch_flat,geom_rgi_track_geo',
      [bboxParam]: bbox,
      size_per_layer: 100,
      libelle__ilike: `%${query.trim()}%`,
      ...(params.lineFilter && params.lineFilterActive && { lrs_ligne__ilike: `%${params.lineFilter}%` }),
    },
  )
  return computeSearchResults(response)
}
