import { DexcartoLayerKey } from 'components/Layers/common'
import { MapTheme } from 'components/Toolbar/ThemeMenu/const'
import { Polygon } from 'geojson'
import { LAYERS_SOURCES_MAP, SearchLayers } from './const'
import { getCassiniObjects } from './cassini'
import { SearchParams, SearchResults } from './types'
import { getPosteObjects } from './poste'
import { getSignalObjects } from './signal'
import { getChantierObjects } from './chantier'
import { getZapObjects } from './zap'
import { getBifObjects } from './bif'
import { getRacObjects } from './rac'
import { getBALObjects } from './bal'
import { getIhmObjects } from './ihm'
import { getCenterArmenObjects } from './centerArmen'
import { getCenterObjects } from './center'

// eslint-disable-next-line import/prefer-default-export
export const searchObjects = async (
  activeLayers: DexcartoLayerKey[], query: string, bbox: Polygon, params: SearchParams, theme: MapTheme,
) => {
  const layersToSearch = Object.entries(LAYERS_SOURCES_MAP)
    .filter(([, value]) => value.some(layer => activeLayers.includes(layer)))
    .map(([key]) => key as SearchLayers)

  const response = await Promise.all([
    getCassiniObjects(layersToSearch, theme, bbox, query, params),
    getPosteObjects(activeLayers, layersToSearch, theme, bbox, query, params),
    getSignalObjects(activeLayers, layersToSearch, theme, bbox, query, params),
    getChantierObjects(activeLayers, theme, bbox, query),
    getZapObjects(layersToSearch, theme, bbox, query),
    getBifObjects(layersToSearch, theme, bbox, query, params),
    getRacObjects(layersToSearch, theme, bbox, query),
    getBALObjects(layersToSearch, theme, bbox, query),
    getIhmObjects(layersToSearch, theme, bbox, query),
    getCenterArmenObjects(layersToSearch, theme, bbox, query, params),
    getCenterObjects(layersToSearch, theme, bbox, query, params),
  ])
  const searchResults = response.reduce((acc: SearchResults, result) => {
    if (!result) return acc
    return { ...acc, ...result }
  }, {})
  return searchResults
}
