import { ReactElement, useCallback, useState } from 'react'
import { Feature } from 'geojson'
import { _useMapControl as useMapControl } from 'react-map-gl'
import { ReactComponent as LayersIcon } from 'assets/icons/layers.svg'
import { Tooltip } from '@mui/material'
import { Construction, Settings, History } from '@mui/icons-material'
import terms from 'common/terms'
import { useDispatch, useSelector } from 'react-redux'
import { addActiveLayer, removeActiveLayer } from 'store/map'
import { RootState } from 'store'
import { mapViewportSignal } from 'views/Map'
import { sendError } from 'store/feedback'
import { get } from '@osrdata/app_core/dist/requests'
import { LAYERS_KEYS, OBJECTS_LAYERS, SCHEMATIC_VIEWS, SOURCES_IDS } from 'components/Layers/common'
import { calculateGeometryViewport } from 'components/utils'
import LayerMenu from './LayerMenu/LayerMenu'
import ChantierMenu from './ChantierMenu/ChantierMenu'
import './Toolbar.scss'
import ThemeMenu from './ThemeMenu/ThemeMenu'
import HistoryMenu from './HistoryMenu/HistoryMenu'

enum MenuKeys {
  viewmode = 'viewmode',
  layers = 'layers',
  chantier = 'chantier',
  history = 'history',
}

const MENU_ITEMS = [
  {
    key: MenuKeys.viewmode,
    icon: <Settings />,
    label: terms.tooltips.menuViewmode,
  },
  {
    key: MenuKeys.layers,
    icon: <LayersIcon />,
    label: terms.tooltips.menuLayers,
  },
  {
    key: MenuKeys.chantier,
    icon: <Construction />,
    label: terms.tooltips.menuChantier,
  },
  {
    key: MenuKeys.history,
    icon: <History />,
    label: terms.tooltips.menuHistory,
  },
]

interface Props {
  setSelectedObjectsIds: (ids: string[]) => void
}

const Toolbar = ({ setSelectedObjectsIds }: Props): ReactElement => {
  const dispatch = useDispatch()
  const { containerRef: menuRef } = useMapControl({
    captureDrag: true, captureClick: true, captureDoubleClick: true, captureScroll: true, capturePointerMove: true,
  })
  const { containerRef: tabsRef } = useMapControl({
    captureDrag: true, captureClick: true, captureDoubleClick: true, captureScroll: true, capturePointerMove: true,
  })
  const { editorStep } = useSelector((state: RootState) => state.zoneEditor)
  const { activeLayers, currentOsmTheme } = useSelector((state: RootState) => state.map)
  const [visibleMenu, setVisibleMenu] = useState<MenuKeys>(MenuKeys.viewmode)
  const [expanded, setExpanded] = useState<boolean>(true)
  const toggleDisplayMenu = (key: MenuKeys) => {
    setVisibleMenu(key)
    setExpanded(key === visibleMenu ? !expanded : true)
  }

  const isLayerActive = useCallback((layer: string) => activeLayers.includes(layer), [activeLayers])

  const updateLayerVisibility = useCallback((layer: string, checked: boolean) => {
    if (checked) {
      dispatch(addActiveLayer(layer))
    } else {
      dispatch(removeActiveLayer(layer))
    }
  }, [])

  const handleGoToZone = useCallback((zoneId: number) => () => {
    if (!activeLayers.includes(LAYERS_KEYS.zoneActionPosteAmendee)) {
      dispatch(addActiveLayer(LAYERS_KEYS.zoneActionPosteAmendee))
    }
    // eslint-disable-next-line max-len
    get(`/chartis/v2/layer/${OBJECTS_LAYERS.actionZonePoste}/geojson_feature/${SCHEMATIC_VIEWS[SOURCES_IDS.actionZone]}/`, {
      id: zoneId,
    })
      .then((response: Feature) => {
        const { latitude, longitude, zoom } = calculateGeometryViewport(response.geometry, mapViewportSignal.value)
        mapViewportSignal.value = ({ ...mapViewportSignal.value, latitude, longitude, zoom, transitionDuration: 500 })
        setSelectedObjectsIds([zoneId as unknown as string])
      })
      .catch(() => {
        dispatch(sendError(terms.HistoryMenu.errorGoToZone))
      })
  }, [activeLayers])

  return (
    <div className="toolbar">
      <div
        className={`tabs ${expanded && !editorStep ? 'display-menu' : ''} 
        ${visibleMenu === MenuKeys.history && !editorStep && expanded ? 'display-history' : ''}`}
        ref={tabsRef}
      >
        {!editorStep && MENU_ITEMS.map(item => (
          <Tooltip key={item.key} title={item.label} placement="left">
            <button
              className="icon-wrapper"
              onClick={() => toggleDisplayMenu(item.key)}
              type="button"
              tabIndex={-1}
            >
              {item.icon}
            </button>
          </Tooltip>
        ))}
      </div>

      <div
        className={`content ${expanded && !editorStep ? 'visible' : 'hidden'} 
        ${visibleMenu === MenuKeys.history && !editorStep ? 'history' : ''}`}
        ref={menuRef}
      >
        {visibleMenu === MenuKeys.viewmode && !editorStep && (
          <ThemeMenu />
        )}

        {visibleMenu === MenuKeys.layers && !editorStep && (
          <LayerMenu
            isLayerActive={isLayerActive}
            theme={currentOsmTheme}
            updateLayerVisibility={updateLayerVisibility}
          />
        )}

        {visibleMenu === MenuKeys.chantier && !editorStep && (
          <ChantierMenu
            isLayerActive={isLayerActive}
            theme={currentOsmTheme}
            updateLayerVisibility={updateLayerVisibility}
          />
        )}

        {visibleMenu === MenuKeys.history && !editorStep && (
          <HistoryMenu handleGoToZone={handleGoToZone} />
        )}
      </div>
    </div>
  )
}

export default Toolbar
