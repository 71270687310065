import { UserStudyPerimeterDetailsWithId } from 'store/userFavorite/types'
import { LAYERS_KEYS, POSTES_LAYER_KEYS, SIGNAL_LAYERS_MNEMO } from './common'

export const getChantierFilter = (isLayerActive: (layer: string) => boolean): mapboxgl.Expression => {
  const isYearValid: mapboxgl.Expression = [
    'any',
    ['match',
      ['get', 'annee'],
      2024, isLayerActive(LAYERS_KEYS.chantier2024),
      2025, isLayerActive(LAYERS_KEYS.chantier2025),
      2026, isLayerActive(LAYERS_KEYS.chantier2026),
      2027, isLayerActive(LAYERS_KEYS.chantier2027),
      2028, isLayerActive(LAYERS_KEYS.chantier2028),
      2029, isLayerActive(LAYERS_KEYS.chantier2029),
      false,
    ],
    ['all', ['>=', ['get', 'annee'], 2030], isLayerActive(LAYERS_KEYS.chantier2030Plus)],
  ]

  const isPhaseValid: mapboxgl.Expression = [
    'case',
    ['==', ['get', 'phase'], 'REA'], isLayerActive(LAYERS_KEYS.chantierPhaseREA),
    ['==', ['get', 'phase'], 'TVX'], isLayerActive(LAYERS_KEYS.chantierPhaseTVX),
    ['==', ['get', 'phase'], 'TVXP'], isLayerActive(LAYERS_KEYS.chantierPhaseTVXP),
    ['==', ['get', 'phase'], 'REA_TVXP'], isLayerActive(LAYERS_KEYS.chantierPhaseREA_TVXP),
    ['==', ['get', 'phase'], 'REA_TVX'], isLayerActive(LAYERS_KEYS.chantierPhaseREA_TVX),
    ['==', ['get', 'phase'], 'APO_REA'], isLayerActive(LAYERS_KEYS.chantierPhaseAPO_REA),
    ['==', ['get', 'phase'], 'APO_TVX'], isLayerActive(LAYERS_KEYS.chantierPhaseAPO_TVX),
    ['==', ['get', 'phase'], 'APO_REA_TVX'], isLayerActive(LAYERS_KEYS.chantierPhaseAPO_REA_TVX),
    ['==', ['get', 'phase'], 'PRO_REA'], isLayerActive(LAYERS_KEYS.chantierPhasePRO_REA),
    false,
  ]

  const filterASTRE = isLayerActive(LAYERS_KEYS.chantierASTRE)
  const filterSEISM = isLayerActive(LAYERS_KEYS.chantierSEISM)

  return [
    'all',
    isYearValid,
    isPhaseValid,
    ...(filterASTRE ? [['!=', ['get', 'numero_astre'], null]] : []),
    ...(filterSEISM ? [['!=', ['get', 'seism_project_id'], null]] : []),
  ]
}

export const getPosteFilter = (isLayerActive: (layer: string) => boolean, isSelectingPoste = false) => {
  const posteTypes = Object.entries(POSTES_LAYER_KEYS).filter(([, value]) => isLayerActive(value)).map(([key]) => key)
  const expression: mapboxgl.Expression = ['any',
    ['==', ['boolean', isSelectingPoste], true],
    ['in', ['get', 'type_installation_fixe_id_mnemo'], ['literal', posteTypes]],
  ]
  return expression
}

export const getSignalFilter = (isLayerActive: (layer: string) => boolean) => Object.entries(SIGNAL_LAYERS_MNEMO)
  .filter(([key]) => isLayerActive(key)).flatMap(([, value]) => value)

export const getTrackFilter = (activePerimeter: UserStudyPerimeterDetailsWithId): mapboxgl.Expression | undefined => {
  if (!activePerimeter.properties.lvp_perimeter) return undefined
  if (activePerimeter.properties.lvp_perimeter.length === 0) return undefined
  const filters = activePerimeter.properties.lvp_perimeter.map(lvp => {
    const filter: mapboxgl.Expression = ['all',
      ['==', ['get', 'ligne'], lvp.line_code],
      ['==', ['get', 'voie'], lvp.track_name],
      ['<=',
        ['to-number',
          ['concat',
            ['slice', ['get', 'pk_debut'], 0, -4],
            ['slice', ['get', 'pk_debut'], -3],
          ],
        ],
        Number(lvp.end_pk.replace('+', '')),
      ],
      ['>=',
        ['to-number',
          ['concat',
            ['slice', ['get', 'pk_fin'], 0, -4],
            ['slice', ['get', 'pk_fin'], -3],
          ],
        ],
        Number(lvp.start_pk.replace('+', '')),
      ],
    ]
    return filter
  })
  return ['any', ...filters]
}

export const getCollectionStatusProps = (isLayerActive: (layer: string) => boolean) => {
  const visibility = [
    LAYERS_KEYS.collectionStatusStudy, LAYERS_KEYS.collectionStatusBlocked, LAYERS_KEYS.collectionStatusNotStarted,
    LAYERS_KEYS.collectionStatusWaiting, LAYERS_KEYS.collectionStatusNotDone,
  ].some(layer => isLayerActive(layer))
  const count: mapboxgl.Expression = [
    '+',
    ['case',
      ['boolean', isLayerActive(LAYERS_KEYS.collectionStatusStudy)],
      ['coalesce', ['get', 'nombre_etude_en_cours'], 0], 0,
    ],
    ['case',
      ['boolean', isLayerActive(LAYERS_KEYS.collectionStatusBlocked)],
      ['coalesce', ['get', 'nombre_dc_bloque'], 0], 0,
    ],
    ['case',
      ['boolean', isLayerActive(LAYERS_KEYS.collectionStatusNotStarted)],
      ['coalesce', ['get', 'nombre_dc_non_commence'], 0], 0,
    ],
    ['case',
      ['boolean', isLayerActive(LAYERS_KEYS.collectionStatusWaiting)],
      ['coalesce', ['get', 'nombre_en_attente_rc'], 0], 0,
    ],
    ['case',
      ['boolean', isLayerActive(LAYERS_KEYS.collectionStatusNotDone)],
      ['coalesce', ['get', 'nombre_dc_non_termine'], 0], 0,
    ],
  ]

  const filter: mapboxgl.Expression = [
    'all',
    [
      'any',
      ['!=', ['get', 'nombre_etude_en_cours'], null],
      ['!=', ['get', 'nombre_dc_bloque'], null],
      ['!=', ['get', 'nombre_dc_non_commence'], null],
      ['!=', ['get', 'nombre_en_attente_rc'], null],
    ],
    ['get', 'modifiable'],
  ]
  return { visibility, count, filter }
}
