import { RefObject } from 'react'
import { MapEvent, MapRef } from 'react-map-gl'
import { isLayerVisible } from 'components/Toolbar/utils'
import { LAYERS_IDS, LAYERS_KEYS } from '../common'
import PRILayer from './PRILayer'
import UTMLayer from './UTMLayer'
import PRILabelLayer from './PRILabelLayer'
import UTMLabelLayer from './UTMLabelLayer'
import StructureLayer from './StructureLayer'
import ZPLabelLayer from './ZPLabelLayer'
import InfrapoleLabelLayer from './InfrapoleLabelLayer'

interface Props {
  mapRef: RefObject<MapRef | undefined>
  selectedBorder: MapEvent | undefined
  setViewportZP: (zp: string | null) => void
  setViewportInfra: (infra: string | null) => void
  setViewportPRI: (pri: string | null) => void
}

const BordersLayer = ({ mapRef, selectedBorder, setViewportInfra, setViewportPRI, setViewportZP }: Props) => {
  const principalLayer = () => {
    if (isLayerVisible(LAYERS_KEYS.utm)) return LAYERS_KEYS.utm
    if (isLayerVisible(LAYERS_KEYS.pri)) return LAYERS_KEYS.pri
    if (isLayerVisible(LAYERS_KEYS.infrapole)) return LAYERS_KEYS.infrapole
    if (isLayerVisible(LAYERS_KEYS.zp)) return LAYERS_KEYS.zp
    return null
  }

  const selectedBorderFeature = selectedBorder?.features?.find(feature => [
    LAYERS_IDS.priLabel, LAYERS_IDS.zpLabel, LAYERS_IDS.infrapoleLabel, LAYERS_IDS.utmLabel,
  ].includes(feature.layer.id))

  return (
    <>
      <StructureLayer
        visibleZP={isLayerVisible(LAYERS_KEYS.zp)}
        visibleInfra={isLayerVisible(LAYERS_KEYS.infrapole)}
        principalLayer={principalLayer()}
        selectedBorderLabel={selectedBorderFeature?.properties?.libelle
          || selectedBorderFeature?.properties?.zone_de_production_libelle || ''}
      />
      <PRILayer
        visible={isLayerVisible(LAYERS_KEYS.pri)}
        principalLayer={principalLayer()}
        selectedBorderLabel={selectedBorderFeature?.properties?.region_libelle || ''}
      />
      <UTMLayer
        visible={isLayerVisible(LAYERS_KEYS.utm)}
        principalLayer={principalLayer()}
        selectedBorderLabel={selectedBorderFeature?.properties?.structure_libelle || ''}
      />

      <ZPLabelLayer
        visible={isLayerVisible(LAYERS_KEYS.zp)}
        mapRef={mapRef}
        setViewportZP={setViewportZP}
        principalLayer={principalLayer()}
      />
      <InfrapoleLabelLayer
        visible={isLayerVisible(LAYERS_KEYS.infrapole)}
        mapRef={mapRef}
        setViewportInfra={setViewportInfra}
        principalLayer={principalLayer()}
      />
      <PRILabelLayer
        visible={isLayerVisible(LAYERS_KEYS.pri)}
        mapRef={mapRef}
        setViewportPRI={setViewportPRI}
        principalLayer={principalLayer()}
      />
      <UTMLabelLayer
        visible={isLayerVisible(LAYERS_KEYS.utm)}
        mapRef={mapRef}
        principalLayer={principalLayer()}
      />
    </>

  )
}

export default BordersLayer
