import { ReactElement } from 'react'
import { Source, Layer } from 'react-map-gl'
import SignalLayerProps, { getTextField } from './SignalLayerProps'
import { OBJECTS_LAYERS, SOURCES_IDS, hoverColor } from '../common'
import { MapURLParams, useMapURL } from '../hooks'

interface Props {
  hoveredObjectsIds: string[]
  view: string
  params: MapURLParams
  opacity?: number
  lineFilter?: string
  visibility: boolean
  angle: string
  signalFilter: string[]
}

const SignalLayer = ({
  hoveredObjectsIds, view, params, opacity, lineFilter, visibility, angle, signalFilter,
}: Props): ReactElement => {
  const url = useMapURL(
    OBJECTS_LAYERS.signal,
    view,
    SOURCES_IDS.signal,
    params,
  )

  const { signalCircle, signalMat, signalPicto, signalLabel, signalHighlight } = SignalLayerProps

  const pictoMnemos = ['CARRE', 'CARRE (CH)', 'CV', 'S', 'S (CH)', 'D', 'A', 'A (CH)', 'GA', 'SG HEURT']
  const pictoFilter = signalFilter.filter(mnemo => pictoMnemos.includes(mnemo))
  const noPictoFilter = signalFilter.filter(mnemo => !pictoMnemos.includes(mnemo))

  return (
    <Source
      id={SOURCES_IDS.signal}
      type="vector"
      url={url}
    >
      <Layer
        {...signalCircle}
        paint={{
          ...signalCircle.paint,
          'circle-color': hoverColor('#0088ce', hoveredObjectsIds),
          'circle-opacity': ['case',
            ['in', lineFilter, ['get', 'lrs_ligne']], 1,
            ['in', ['get', 'id'], ['literal', hoveredObjectsIds]], 1,
            opacity],
        }}
        layout={{ visibility: visibility ? 'visible' : 'none' }}
        filter={['in', ['get', 'type_installation_fixe_id_mnemo'], ['literal', signalFilter]]}
      />
      <Layer
        {...signalHighlight}
        filter={['in', ['get', 'id'], ['literal', hoveredObjectsIds]]}
      />
      <Layer
        {...signalMat}
        layout={{
          ...signalMat.layout,
          visibility: visibility ? 'visible' : 'none',
          'icon-rotate': ['coalesce', ['get', angle], 0],
        }}
        paint={{
          ...signalMat.paint,
          'icon-opacity': ['case',
            ['in', lineFilter, ['get', 'lrs_ligne']], 1,
            ['in', ['get', 'id'], ['literal', hoveredObjectsIds]], 1,
            opacity],
        }}
        filter={['in', ['get', 'type_installation_fixe_id_mnemo'], ['literal', pictoFilter]]}
      />
      <Layer
        {...signalPicto}
        layout={{
          ...signalPicto.layout,
          'text-field': getTextField(hoveredObjectsIds),
          visibility: visibility ? 'visible' : 'none',
          'icon-rotate': ['coalesce', ['get', angle], 0],
          'text-rotate': ['coalesce', ['get', angle], 0],
        }}
        paint={{
          'text-color': hoverColor('#000000', hoveredObjectsIds),
          'icon-opacity': ['case',
            ['in', lineFilter, ['get', 'lrs_ligne']], 1,
            ['in', ['get', 'id'], ['literal', hoveredObjectsIds]], 1,
            opacity],
          'text-opacity': ['case',
            ['in', lineFilter, ['get', 'lrs_ligne']], 1,
            ['in', ['get', 'id'], ['literal', hoveredObjectsIds]], 1,
            opacity],
          'text-halo-color': ['case',
            ['in', ['get', 'id'], ['literal', hoveredObjectsIds]], '#ff0000',
            'transparent'],
          'text-halo-width': ['case',
            ['in', ['get', 'id'], ['literal', hoveredObjectsIds]], 0.5,
            0.1],
        }}
        filter={['in', ['get', 'type_installation_fixe_id_mnemo'], ['literal', pictoFilter]]}
      />
      <Layer
        {...signalLabel}
        layout={{
          ...signalLabel.layout,
          visibility: visibility ? 'visible' : 'none',
        }}
        paint={{
          'text-color': hoverColor('#000000', hoveredObjectsIds),
          'text-opacity': ['case',
            ['in', lineFilter, ['get', 'lrs_ligne']], 1,
            ['in', ['get', 'id'], ['literal', hoveredObjectsIds]], 1,
            opacity],
          'text-halo-color': ['case',
            ['in', ['get', 'id'], ['literal', hoveredObjectsIds]], '#ff0000',
            'transparent'],
          'text-halo-width': ['case',
            ['in', ['get', 'id'], ['literal', hoveredObjectsIds]], 0.5,
            0.1],
        }}
        filter={['in', ['get', 'type_installation_fixe_id_mnemo'], ['literal', noPictoFilter]]}
      />
    </Source>
  )
}

SignalLayer.defaultProps = {
  opacity: 1,
  lineFilter: '',
}

export default SignalLayer
