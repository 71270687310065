import { ReactElement } from 'react'
import { Source, Layer } from 'react-map-gl'
import { LAYERS_IDS, OBJECTS_LAYERS, SOURCES_IDS, hoverColor } from '../common'
import { MapURLParams, useMapURL } from '../hooks'

interface Props {
  hoveredObjectsIds: string[]
  view: string
  params: MapURLParams
  opacity?: number
  lineFilter?: string
  visibility: boolean
  angle: string
}

const PassageNiveauLayer = ({
  hoveredObjectsIds, view, params, opacity, lineFilter, visibility, angle,
}:Props): ReactElement => {
  const url = useMapURL(
    OBJECTS_LAYERS.passageNiveau,
    view,
    SOURCES_IDS.passageNiveau,
    params,
  )

  return (
    <Source
      id={SOURCES_IDS.passageNiveau}
      type="vector"
      url={url}
    >
      <Layer
        type="symbol"
        layout={{
          'icon-image': 'pn_no_label',
          'icon-size': 0.5,
          'icon-allow-overlap': true,
          'icon-rotate': ['+', ['coalesce', ['get', angle], 0], -90],
          'icon-rotation-alignment': 'map',
          visibility: visibility ? 'visible' : 'none',
        }}
        paint={{
          'icon-opacity': ['case',
            ['in', lineFilter, ['get', 'lrs_ligne']], 1,
            ['in', ['get', 'id'], ['literal', hoveredObjectsIds]], 1,
            opacity],
        }}
        id={LAYERS_IDS.passageNiveauIcon}
        source-layer={OBJECTS_LAYERS.passageNiveau}
        minzoom={12}
      />
      <Layer
        type="symbol"
        id={LAYERS_IDS.passageNiveauLabel}
        source-layer={OBJECTS_LAYERS.passageNiveau}
        paint={{
          'text-color': hoverColor('#000000', hoveredObjectsIds),
          'text-opacity': ['case',
            ['in', lineFilter, ['get', 'lrs_ligne']], 1,
            ['in', ['get', 'id'], ['literal', hoveredObjectsIds]], 1,
            opacity],
          'text-halo-color': ['case',
            ['in', ['get', 'id'], ['literal', hoveredObjectsIds]], '#ff0000',
            'transparent'],
          'text-halo-width': ['case',
            ['in', ['get', 'id'], ['literal', hoveredObjectsIds]], 0.5,
            0.1],
        }}
        layout={{
          'text-field': ['get', 'libelle'],
          'text-font': ['Open Sans Regular'],
          'text-size': 13,
          'text-offset': [0, -2],
          visibility: visibility ? 'visible' : 'none',
        }}
        minzoom={12}
      />
    </Source>
  )
}

PassageNiveauLayer.defaultProps = {
  opacity: 1,
  lineFilter: '',
}
export default PassageNiveauLayer
