import { useState } from 'react'
import moment from 'moment'
import DatePicker from 'components/DatePicker/DatePicker'
import { CalendarMonth, Close } from '@mui/icons-material'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'store'
import { setDateRange } from 'store/objectSearch/objectSearch'
import terms from 'common/terms'
import { getDateRange } from './utils'
import './DateRangeFilter.scss'

const DateRangeFilter = () => {
  const dispatch = useDispatch()
  const { dateRangeStart, dateRangeEnd } = useSelector((state: RootState) => state.objectSearch)
  const [expanded, setExpanded] = useState(false)
  const [startDate, setStartDate] = useState<moment.Moment>(dateRangeStart)
  const [endDate, setEndDate] = useState<moment.Moment>(dateRangeEnd)

  const handleDateChange = (newStartDate: moment.Moment, newEndDate: moment.Moment) => {
    setStartDate(newStartDate)
    setEndDate(newEndDate)
  }

  const handleCancel = () => {
    setExpanded(false)
    setStartDate(dateRangeStart)
    setEndDate(dateRangeEnd)
  }

  const handleButtonClick = () => {
    if (expanded) {
      handleCancel()
    } else {
      setExpanded(true)
    }
  }

  const handleValidate = () => {
    if (!startDate || !endDate) return
    dispatch(setDateRange({ start: startDate, end: endDate }))
    setExpanded(false)
  }

  const handleClear = () => {
    handleDateChange(moment(new Date()), moment(new Date()))
    dispatch(setDateRange({ start: moment(new Date()), end: moment(new Date()) }))
  }

  return (
    <>
      <button type="button" onClick={handleButtonClick}>
        <CalendarMonth />
        <span>
          {getDateRange(dateRangeStart, dateRangeEnd)}
        </span>
      </button>
      {expanded && (
        <div className="date-range-filter">
          <button type="button" className="close" onClick={handleCancel}>
            <Close />
          </button>
          <div className="pickers">
            <DatePicker
              startDate={startDate}
              onChange={handleDateChange}
              endDate={endDate}
            />
          </div>
          <div className="actions">
            <button type="button" onClick={handleClear}>
              {terms.dateRangeFilter.clear}
            </button>
            <button type="button" onClick={handleValidate} disabled={!startDate || !endDate}>
              {terms.dateRangeFilter.validate}
            </button>
          </div>
        </div>
      )}
    </>
  )
}

export default DateRangeFilter
