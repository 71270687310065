import { Box, Modal } from '@mui/material'
import { ReactElement } from 'react'
import ReactMarkdown from 'react-markdown'

const style = {
  position: 'absolute' as const,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  minWidth: '600px',
  maxWidth: '80vw',
  minHeight: '50vh',
  maxHeight: '80vh',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  borderRadius: '4px',
  overflow: 'auto',
}

interface Props {
  openModal: boolean,
  content: string,
  handleClose: () => void,
}

const HelpModal = ({
  openModal,
  content,
  handleClose,
}: Props): ReactElement => (
  <Modal
    open={openModal}
    onClose={handleClose}
  >
    <Box className="modal-confirmation" sx={style}>
      <ReactMarkdown>{content}</ReactMarkdown>
    </Box>
  </Modal>
)

export default HelpModal
