import { BookmarkBorder } from '@mui/icons-material'
import { Tooltip } from '@mui/material'
import terms from 'common/terms'
import CreateViewModal from 'components/Modal/CreateViewModal/CreateViewModal'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'store'
import { closeCreateViewModal, openCreateViewModal } from 'store/userFavorite/userFavorite'

const CreateView = () => {
  const dispatch = useDispatch()
  const { createViewModal } = useSelector((state: RootState) => state.userFavorite)

  const handleOpenModal = () => dispatch(openCreateViewModal())
  const handleCloseModal = () => dispatch(closeCreateViewModal())
  return (
    <>
      <Tooltip title={terms.tooltips.createView}>
        <button type="button" onClick={handleOpenModal}>
          <BookmarkBorder />
        </button>
      </Tooltip>
      { createViewModal && (
        <CreateViewModal
          onModal={createViewModal}
          handleClose={handleCloseModal}
        />
      )}
    </>
  )
}

export default CreateView
