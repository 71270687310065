import { ReactElement, ReactNode } from 'react'
import { PORTAL_URL } from 'config/config'
import { Images } from 'assets'
import AccountMenu from './AccountMenu'

import './TopBar.scss'

type Props = {
  appName: string;
  innerComponent?: ReactNode;
  hasAccess: boolean;
}

const defaultProps = {
  innerComponent: <div />,
}

export default function TopBar({ appName, innerComponent, hasAccess }: Props): ReactElement {
  return (
    <div className="topbar flex-center">
      <header role="banner" className="flex-center">
        <div className="logo">
          <a href={PORTAL_URL.url}>
            <img
              className="flex-center"
              alt={appName}
              src={Images.dgexLogo}
            />
          </a>
        </div>
        <h1 className="app-name">{appName}</h1>
      </header>
      {hasAccess && innerComponent}
      <AccountMenu />
    </div>
  )
}

TopBar.defaultProps = defaultProps
