import { OBJECTS_LAYERS, GEOGRAPHIC_VIEWS, SOURCES_IDS, SCHEMATIC_VIEWS } from 'components/Layers/common'
import { MapTheme } from 'components/Toolbar/ThemeMenu/const'
import { Polygon } from 'geojson'
import { get } from '@osrdata/app_core/dist/requests'
import { computeSearchResults } from './cassini'
import { SearchLayers } from './const'
import { CassiniSearchResult } from './types'

// eslint-disable-next-line import/prefer-default-export
export const getBALObjects = async (
  layers: SearchLayers[], theme: MapTheme, bbox: Polygon, query: string,
) => {
  if (!layers.includes(SearchLayers.bal)) return null
  const layer = OBJECTS_LAYERS.balZone
  const view = theme === MapTheme.geographic
    ? GEOGRAPHIC_VIEWS[SOURCES_IDS.balZone] : SCHEMATIC_VIEWS[SOURCES_IDS.balZone]
  const bboxParam = theme === MapTheme.perimeter ? 'geom_rgi_track_sch_flat__bpolygon' : 'bbox'
  const requestParams = {
    columns: 'id,libelle,lrs_pk,lrs_voie,lrs_ligne,geom_rgi_track_sch_flat,geom_rgi_track_geo',
    [bboxParam]: bbox,
    size_per_layer: 100,
    libelle__ilike: `%${query.trim()}%`,
  }
  const cassiniResponse: CassiniSearchResult = await get(`/chartis/v2/layer/${layer}/search/${view}/`, requestParams)
  return computeSearchResults(cassiniResponse)
}
