import { ReactElement } from 'react'
import { Source, Layer } from 'react-map-gl'
import { LAYERS_IDS, OBJECTS_LAYERS, SOURCES_IDS, hoverColor } from '../common'
import { MapURLParams, useMapURL } from '../hooks'

interface Props {
  hoveredObjectsIds: string[]
  view: string
  params: MapURLParams
  opacity?: number
  lineFilter?: string
  visibility: boolean
}

const ADVLayer = ({ hoveredObjectsIds, view, params, opacity, lineFilter, visibility }: Props): ReactElement => {
  const url = useMapURL(
    OBJECTS_LAYERS.advLine,
    view,
    SOURCES_IDS.advLine,
    params,
  )
  return (
    <Source
      id={SOURCES_IDS.advLine}
      type="vector"
      url={url}
    >
      <Layer
        type="line"
        paint={{
          'line-width': 3,
          'line-color': hoverColor('#FF9313', hoveredObjectsIds),
          'line-opacity': ['case',
            ['in', lineFilter, ['get', 'lrs_ligne']], 1,
            ['in', ['get', 'id'], ['literal', hoveredObjectsIds]], 1,
            opacity],
        }}
        id={LAYERS_IDS.advLine}
        source-layer={OBJECTS_LAYERS.advLine}
        layout={{ visibility: visibility ? 'visible' : 'none' }}
        minzoom={9}
      />
    </Source>
  )
}

ADVLayer.defaultProps = {
  opacity: 1,
  lineFilter: '',
}

export default ADVLayer
