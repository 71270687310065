import { LayerProperties, LAYERS_IDS } from '../common'

const CentraleSousStationLayerProps: LayerProperties = {
  sch: {
    id: LAYERS_IDS.cssSch,
    type: 'symbol',
    paint: {
      'text-color': '#FFFFFF',
    },
    layout: {
      'icon-image': 'rectanglearrondi-#be0096',
      'icon-text-fit': 'both',
      'icon-allow-overlap': false,
      'text-font': ['Roboto Condensed'],
      'text-size': 12,
      'text-field': [
        'format',
        'CSS',
        {
          'font-scale': 1.2,
        },
        '\n',
        {},
        ['get', 'libelle'],
        '\n',
        {},
        'km ',
        ['get', 'lrs_pk'],
      ],
      'text-max-width': 1000,
      'icon-text-fit-padding': [5, 10, 10, 10],
    },
  },
  geo: {
    id: LAYERS_IDS.cssGeo,
    type: 'circle',
    paint: {
      'circle-color': '#be0096',
      'circle-stroke-color': '#26001e',
      'circle-stroke-width': 1,
      'circle-radius': 5,
    },
  },
}

export default CentraleSousStationLayerProps
