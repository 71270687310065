import { UserStudyPerimeterDetails } from 'store/userFavorite/types'
import nextId from 'react-id-generator'
import terms from 'common/terms'
import './PerimeterLVPTable.scss'

interface Props {
  perimeter: UserStudyPerimeterDetails

}

const PerimeterLVPTable = ({ perimeter }: Props) => (
  <div className="perimeter-lvp-table">
    <table>
      <thead>
        <tr>
          <th>{terms.CreateUserPerimeter.line}</th>
          <th>{terms.CreateUserPerimeter.track}</th>
          <th>{terms.CreateUserPerimeter.pk}</th>
        </tr>
      </thead>
      <tbody>
        {perimeter.properties.lvp_perimeter?.map(peri => (
          <tr key={nextId()} className="lines">
            <td className="line">{peri.line_code}</td>
            <td className="track">{`${terms.CreateUserPerimeter.track}: ${peri.track_name}`}</td>
            <td className="pk">
              {`${peri.start_pk} à ${peri.end_pk}`}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>
)

export default PerimeterLVPTable
