import { Box, Modal } from '@mui/material'
import terms from 'common/terms'
import { UserStudyPerimeterDetailsWithId } from 'store/userFavorite/types'
import './PerimeterInfoModal.scss'
import PerimeterLVPTable from '../PerimeterLVPTable'

const style = {
  position: 'absolute' as const,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  maxHeight: '90vh',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '4px',
}

interface Props {
  handleClose: () => void
  openModal: boolean
  perimeter: UserStudyPerimeterDetailsWithId
}

const PerimeterInfoModal = ({
  handleClose, openModal, perimeter,
}: Props) => (
  <Modal open={openModal} onClose={handleClose}>
    <Box className="modal-info-perimeter" sx={style}>
      <h2 className="title">
        {`${terms.PerimeterView.perimeter}: ${perimeter.properties.libelle}`}
      </h2>
      <PerimeterLVPTable perimeter={perimeter} />
      <div className="actions">
        <button className="close" type="button" onClick={handleClose}>
          {terms.PerimeterView.close}
        </button>
      </div>
    </Box>
  </Modal>
)

export default PerimeterInfoModal
