import terms from 'common/terms'
import ActionSnackbar from 'components/ActionSnackbar/ActionSnackbar'
import PosteSelectionPopup from 'components/PosteSelectionPopup/PosteSelectionPopup'
import { ReactElement, useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'store'
import { resetEditor, setSelectedPoste, validatePerimeterPolygon, validatePolygon } from 'store/zoneEditor/zoneEditor'
import { patchPosteZone, postPosteZone } from 'store/zoneEditor/zoneEditor.thunk'
import { getPosteLabel } from 'views/Map/MapOverlay/utils'
import { Feature } from 'geojson'
import EditorPosteSelection from './EditorPosteSelection/EditorPosteSelection'

interface Props {
  posteFeatures: { features: Feature[]; loading: boolean }
}

const EditorOverlay = ({ posteFeatures }: Props): ReactElement => {
  const dispatch = useDispatch()
  const {
    editorStep, selectedPoste, isSelectingPoste, validZonePolygon,
    zonePolygons, labelPosition, actionZonePatch, zapPatchId,
  } = useSelector((state: RootState) => state.zoneEditor)
  const [showPopup, setShowPopup] = useState<boolean>(false)

  useEffect(() => {
    setShowPopup(!!selectedPoste)
  }, [selectedPoste])

  const handleCancel = () => dispatch(resetEditor())

  const disableValidate = () => {
    switch (editorStep) {
      case 1:
      case 2:
      case 3:
      case 10:
      case 11:
        return isSelectingPoste ? !selectedPoste : !validZonePolygon
      case 5:
        return !selectedPoste
      default:
        return false
    }
  }

  const getProps = () => {
    switch (editorStep) {
      case 1:
        return {
          title: terms.zoneEditor.drawPolygon,
          message: terms.zoneEditor.hintDrawPolygon,
          validateFunction: () => dispatch(validatePolygon()),
        }
      case 2:
        return {
          title: terms.zoneEditor.drawPolygon,
          message: terms.zoneEditor.hintEditPolygon,
          validateFunction: () => dispatch(validatePolygon()),
        }
      case 3:
        return {
          title: terms.zoneEditor.selectPoste,
          message: terms.zoneEditor.hintSelectPoste,
          validateFunction: () => {
            if (zonePolygons && selectedPoste && labelPosition) {
              dispatch(postPosteZone({
                geom_rgi_track_sch_flat: zonePolygons[0].geometry,
                poste_gaia_id: selectedPoste?.id,
                poste_libelle: selectedPoste?.libelle,
                region_libelle: selectedPoste?.region_sncf_id_libelle,
                gare_libelle: selectedPoste?.gare_libelle,
                poste_pks: selectedPoste?.lrs_pk.split(','),
                codes_ligne: selectedPoste?.lrs_ligne.split(','),
                poste_technologie_libelle: selectedPoste?.type_installation_fixe_id_libelle,
                poste_technologie_mnemo: selectedPoste?.type_installation_fixe_id_mnemo,
                libelle_geom_rgi_track_sch_flat: labelPosition.geometry,
                color: labelPosition.properties?.color,
              }))
            }
          },
        }
      case 4:
        return {
          title: terms.zoneEditor.patchPolygon,
          message: terms.zoneEditor.hintPatchPolygon,
          validateFunction: () => {
            if (zonePolygons && labelPosition && actionZonePatch) {
              dispatch(patchPosteZone({
                id: actionZonePatch.id,
                geom_rgi_track_sch_flat: zonePolygons.length === 1 ? zonePolygons[0].geometry : {
                  type: 'MultiPolygon',
                  coordinates: zonePolygons.map(polygon => polygon.geometry.coordinates),
                },
                libelle_geom_rgi_track_sch_flat: labelPosition.geometry,
              }))
            }
          },
        }
      case 5:
        return {
          title: terms.zoneEditor.editZAPProperties,
          message: terms.zoneEditor.hintEditZAPProperties,
          validateFunction: () => {
            if (selectedPoste && zapPatchId) {
              dispatch(patchPosteZone({
                id: zapPatchId,
                poste_gaia_id: selectedPoste?.id,
                poste_libelle: selectedPoste?.libelle,
                region_libelle: selectedPoste?.region_sncf_id_libelle,
                gare_libelle: selectedPoste?.gare_libelle,
                poste_pks: selectedPoste?.lrs_pk.split(','),
                codes_ligne: selectedPoste?.lrs_ligne.split(','),
                poste_technologie_libelle: selectedPoste?.type_installation_fixe_id_libelle,
                poste_technologie_mnemo: selectedPoste?.type_installation_fixe_id_mnemo,
              }))
            }
          },
        }
      case 10:
        return {
          title: terms.zoneEditor.createPerimeter,
          message: terms.zoneEditor.hintDrawPolygon,
          validateFunction: () => dispatch(validatePerimeterPolygon()),
        }
      case 11:
        return {
          title: terms.zoneEditor.editPerimeter,
          message: terms.zoneEditor.hintEditPolygon,
          validateFunction: () => dispatch(validatePerimeterPolygon()),
        }
      default:
        return { title: '', message: '' }
    }
  }

  const handleSelectPoste = (poste: Feature) => () => {
    dispatch(setSelectedPoste(poste.properties))
  }

  return (
    <>
      {!!editorStep && (
        <ActionSnackbar
          title={getProps().title}
          message={getProps().message}
          handleValidate={getProps().validateFunction}
          handleCancel={handleCancel}
          disableValidate={disableValidate()}
        />
      )}
      {(editorStep === 3 || editorStep === 5) && (
        <EditorPosteSelection
          features={posteFeatures.features}
          loading={posteFeatures.loading}
          getId={feature => feature.properties?.id}
          getLabel={getPosteLabel}
          handleFeatureClick={handleSelectPoste}
        />
      )}
      <PosteSelectionPopup poste={selectedPoste} showPopup={showPopup} handleClose={() => setShowPopup(false)} />
    </>
  )
}

export default EditorOverlay
