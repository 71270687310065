import { Layer, Source } from 'react-map-gl'
import { LAYERS_IDS, LAYERS_KEYS, OBJECTS_LAYERS, SOURCES_IDS } from '../common'
import { useLineFilterOpacity, useMapURL } from '../hooks'

interface Props {
  visibleZP: boolean
  visibleInfra: boolean
  principalLayer: string | null
  selectedBorderLabel: string
}

const StructureLayer = ({ visibleZP, visibleInfra, principalLayer, selectedBorderLabel }: Props) => {
  const url = useMapURL(
    OBJECTS_LAYERS.zpRegion,
    'full_rgi_track_sch_flat',
    SOURCES_IDS.zpRegion,
    {},
  )
  const { opacity, lineFilter } = useLineFilterOpacity()
  return (
    <Source
      id={SOURCES_IDS.zpRegion}
      type="vector"
      url={url}
    >
      <Layer
        type="line"
        paint={{
          'line-color': ['case',
            principalLayer === LAYERS_KEYS.zp, ['get', 'zone_de_production_color'],
            'transparent',
          ],
          'line-opacity': [
            'case',
            ['in', lineFilter, ['get', 'code_ligne']], 1,
            opacity,
          ],
          'line-width': 2,
        }}
        id={LAYERS_IDS.zp}
        filter={['has', 'zone_de_production_libelle']}
        source-layer={OBJECTS_LAYERS.zpRegion}
        layout={{
          visibility: visibleZP ? 'visible' : 'none',
        }}
      />
      <Layer
        type="line"
        paint={{
          'line-color': ['case',
            principalLayer === LAYERS_KEYS.infrapole, ['get', 'infrapole_color'],
            'transparent',
          ],
          'line-opacity': [
            'case',
            ['in', lineFilter, ['get', 'code_ligne']], 1,
            opacity,
          ],
          'line-width': 2,
        }}
        id={LAYERS_IDS.infrapole}
        filter={['in', 'Siège INFRAPOLE', ['get', 'libelle']]}
        source-layer={OBJECTS_LAYERS.zpRegion}
        layout={{
          visibility: visibleInfra ? 'visible' : 'none',
        }}
      />
      <Layer
        type="line"
        paint={{
          'line-color': ['get', 'zone_de_production_color'],
          'line-opacity': 0.8,
          'line-width': 5,
        }}
        id={LAYERS_IDS.zpHighlight}
        source-layer={OBJECTS_LAYERS.zpRegion}
        layout={{
          visibility: visibleZP ? 'visible' : 'none',
        }}
        filter={['==', 'zone_de_production_libelle', selectedBorderLabel]}
      />
      <Layer
        type="line"
        paint={{
          'line-color': ['get', 'infrapole_color'],
          'line-opacity': 0.8,
          'line-width': 5,
        }}
        id={LAYERS_IDS.infrapoleHighlight}
        source-layer={OBJECTS_LAYERS.zpRegion}
        layout={{
          visibility: visibleInfra ? 'visible' : 'none',
        }}
        filter={['==', 'libelle', selectedBorderLabel]}
      />
    </Source>
  )
}

export default StructureLayer
