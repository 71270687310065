import { ReactElement } from 'react'
import { Source, Layer } from 'react-map-gl'
import PosteLayerProps from './PosteLayerProps'
import { OBJECTS_LAYERS, SOURCES_IDS, hoverColor } from '../common'
import { MapURLParams, useMapURL } from '../hooks'

interface Props {
  hoveredObjectsIds: string[]
  view: string
  params: MapURLParams
  opacity?: number
  lineFilter?: string
  posteVisibility: boolean
  posteFilter: mapboxgl.Expression
}

const PosteLayer = ({
  hoveredObjectsIds, view, opacity, lineFilter, params, posteVisibility, posteFilter,
}: Props): ReactElement => {
  const url = useMapURL(
    OBJECTS_LAYERS.poste,
    view,
    SOURCES_IDS.poste,
    params,
  )
  const { poste, posteCircle } = PosteLayerProps

  return (
    <Source
      id={SOURCES_IDS.poste}
      type="vector"
      url={url}
    >
      <Layer
        {...posteCircle}
        source-layer={OBJECTS_LAYERS.poste}
        layout={{ visibility: posteVisibility ? 'visible' : 'none' }}
        paint={{
          ...posteCircle.paint,
          'circle-color': hoverColor('#ffb612', hoveredObjectsIds),
          'circle-opacity': ['case',
            ['in', lineFilter, ['get', 'lrs_ligne']], 1,
            ['in', ['get', 'id'], ['literal', hoveredObjectsIds]], 1,
            opacity],
        }}
        filter={posteFilter}
        minzoom={10}
      />
      <Layer
        {...poste}
        source-layer={OBJECTS_LAYERS.poste}
        source={SOURCES_IDS.poste}
        layout={{
          ...poste.layout,
          visibility: posteVisibility ? 'visible' : 'none',
          'symbol-sort-key': ['case', ['in', ['get', 'id'], ['literal', hoveredObjectsIds]], 0, 1],
        }}
        paint={{
          ...poste.paint,
          'text-color': hoverColor('#000000', hoveredObjectsIds),
          'text-opacity': ['case',
            ['in', lineFilter, ['get', 'lrs_ligne']], 1,
            ['in', ['get', 'id'], ['literal', hoveredObjectsIds]], 1,
            opacity],
        }}
        filter={posteFilter}
        minzoom={12}
      />
    </Source>
  )
}

PosteLayer.defaultProps = {
  opacity: 1,
  lineFilter: '',
}

export default PosteLayer
