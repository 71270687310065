import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { FeatureLayer } from 'components/MapPopup/utils'
import { Feature, FeatureCollection, MultiPolygon, Point, Polygon } from 'geojson'
import { get } from '@osrdata/app_core/dist/requests'
import { OBJECTS_LAYERS, SCHEMATIC_VIEWS, SOURCES_IDS } from 'components/Layers/common'
import { store } from 'store'
import { PatchActionZoneData, ZoneEditorState } from '../types'
import { deletePosteZone, patchPosteZone, postPosteZone } from './zoneEditor.thunk'

const initialState: ZoneEditorState = {
  mode: null,
  zoneType: null,
  openCreateZoneModal: false,
  isSelectingPoste: false,
  selectedPoste: null,
  zonePolygons: [],
  editorStep: 0,
  validZonePolygon: false,
  labelPosition: null,
  confirmationModal: {
    open: false,
  },
  actionZonePatch: null,
  zapPatchId: null,
  addPerimeterModal: false,
}

const getLabelPosition = async (actionZoneId: string) => {
  // eslint-disable-next-line max-len
  const url = `/chartis/v2/layer/${OBJECTS_LAYERS.actionZonePoste}/geojson/${SCHEMATIC_VIEWS[SOURCES_IDS.actionZoneLabel]}/`
  const response: FeatureCollection<Point> = await get(url, { id: actionZoneId })
  return response.features?.[0] || null
}

const getZoneGeometry = async (actionZoneId: string) => {
  const url = `/chartis/v2/layer/${OBJECTS_LAYERS.actionZonePoste}/geojson/${SCHEMATIC_VIEWS[SOURCES_IDS.actionZone]}/`
  const response: FeatureCollection<Polygon | MultiPolygon > = await get(url, { id: actionZoneId })
  return response.features?.[0] || null
}

export const zoneEditorSlice = createSlice({
  name: 'zoneEditorSlice',
  initialState,
  reducers: {
    setOpenCreateZoneModal: (state, action: PayloadAction<boolean>) => {
      state.openCreateZoneModal = action.payload
      state.mode = null
      state.zoneType = null
    },
    setSelectedPoste: (state, action) => {
      state.selectedPoste = action.payload
    },
    resetEditor: () => initialState,
    initCreateZonePoste: state => {
      state.mode = 'create'
      state.zoneType = 'poste'
      state.editorStep = 1
      state.validZonePolygon = false
    },
    editPolygon: state => {
      state.mode = 'edit'
      state.validZonePolygon = true
      state.editorStep = 2
    },
    validatePolygon: state => {
      state.mode = null
      state.isSelectingPoste = true
      state.editorStep = 3
    },
    patchGeometry: (state, action:PayloadAction<{
      zonePolygons: Feature<Polygon>[]
      labelPosition: Feature<Point>
      zoneData: PatchActionZoneData
    }>) => {
      state.mode = 'patch'
      state.editorStep = 4
      state.labelPosition = action.payload.labelPosition
      state.zonePolygons = action.payload.zonePolygons
      state.actionZonePatch = action.payload.zoneData
    },
    updateZonePolygons: (state, action) => {
      state.zonePolygons = action.payload
    },
    updateLabelPosition: (state, action) => {
      state.labelPosition = action.payload
    },
    closeModal: state => { state.confirmationModal = initialState.confirmationModal },
    openModal: (state, action: PayloadAction<{message: string, callback: () => void}>) => {
      state.confirmationModal = {
        open: true,
        message: action.payload.message,
        action: action.payload.callback,
      }
    },
    editZAPProperties: (state, action: PayloadAction<FeatureLayer>) => {
      state.editorStep = 5
      state.isSelectingPoste = true
      state.zapPatchId = action.payload.properties?.id
    },
    initCreatePerimeter: state => {
      state.mode = 'create'
      state.zoneType = 'perimeter'
      state.editorStep = 10
      state.validZonePolygon = false
    },
    editPerimeterPolygon: state => {
      state.mode = 'edit'
      state.editorStep = 11
      state.validZonePolygon = true
    },
    validatePerimeterPolygon: state => {
      state.addPerimeterModal = true
    },
  },
  extraReducers: builder => {
    builder.addCase(postPosteZone.fulfilled, () => initialState)
    builder.addCase(deletePosteZone.fulfilled, state => {
      state.confirmationModal = initialState.confirmationModal
    })
    builder.addCase(patchPosteZone.fulfilled, () => initialState)
  },
})

export const {
  setOpenCreateZoneModal, setSelectedPoste, resetEditor, initCreateZonePoste,
  validatePolygon, updateZonePolygons, editPolygon, updateLabelPosition, closeModal, openModal,
  patchGeometry, editZAPProperties, initCreatePerimeter, editPerimeterPolygon, validatePerimeterPolygon,
} = zoneEditorSlice.actions

export const handleEditGeometry = async (object: FeatureLayer | undefined) => {
  if (!object) return
  const labelPosition = await getLabelPosition(object.properties?.id)
  const zonePolygon = await getZoneGeometry(object.properties?.id)
  if (!labelPosition || !zonePolygon) return
  if (zonePolygon.geometry.type === 'Polygon') {
    store.dispatch(patchGeometry({
      zonePolygons: [zonePolygon as Feature<Polygon>],
      labelPosition,
      zoneData: {
        id: object.properties?.id,
        label: object.properties?.poste_libelle,
        color: object.properties?.color,
      },
    }))
  } else if (zonePolygon.geometry.type === 'MultiPolygon') {
    store.dispatch(patchGeometry({
      zonePolygons: zonePolygon.geometry.coordinates.map(coordinates => ({
        type: 'Feature',
        geometry: {
          type: 'Polygon',
          coordinates,
        },
        properties: {
          id: object.properties?.id,
          label: object.properties?.poste_libelle,
          color: object.properties?.color,
        },
      })),
      labelPosition,
      zoneData: {
        id: object.properties?.id,
        label: object.properties?.poste_libelle,
        color: object.properties?.color,
      },
    }))
  }
}

export default zoneEditorSlice.reducer
