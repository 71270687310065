import GeographicThumbnail from 'assets/images/geographic_thumbnail-min.png'
import SchematicThumbnail from 'assets/images/schematic_thumbnail-min.png'

export enum MapTheme {
  schematic = 'schematic',
  geographic = 'geographic',
  perimeter = 'perimeter',
}

export const MapStyles = [
  {
    title: 'Vue schématique',
    value: MapTheme.schematic,
    icon: SchematicThumbnail,
    alt: 'light-theme-icon',
  },
  {
    title: 'Vue géographique',
    value: MapTheme.geographic,
    icon: GeographicThumbnail,
    alt: 'full-theme-icon',
  },
]

export default MapStyles
