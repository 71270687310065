import centroid from '@turf/centroid'
import { Feature, FeatureCollection, Point } from '@turf/helpers'

const getDistinctCentroid = (features: Feature[], labelField: string, colorField: string): FeatureCollection<Point> => {
  const featureGroups = features.reduce((acc: {label: string, color: string, features: Feature[]}[], feature) => {
    const label = feature.properties?.[labelField]
    const color = feature.properties?.[colorField]
    const existingGroup = acc.find(group => group.label === label)
    if (existingGroup) {
      existingGroup.features.push(feature)
    } else {
      acc.push({ label, color, features: [feature] })
    }
    return acc
  }, [])
  return {
    type: 'FeatureCollection',
    features: featureGroups.map(group => centroid({ type: 'FeatureCollection', features: group.features },
      { properties: { [labelField]: group.label, [colorField]: group.color } })),
  }
}

export const getDistinctPRICentroid = (features: Feature[]) => getDistinctCentroid(
  features, 'region_libelle', 'region_color',
)

export const getDistinctUTMCentroid = (features: Feature[]) => getDistinctCentroid(
  features, 'structure_libelle', 'color',
)

export const getDistinctZPCentroid = (features: Feature[]) => getDistinctCentroid(
  features, 'zone_de_production_libelle', 'zone_de_production_color',
)

export const getDistinctInfrapoleCentroid = (features: Feature[]) => getDistinctCentroid(
  features, 'libelle', 'infrapole_color',
)

export default { getDistinctPRICentroid, getDistinctUTMCentroid, getDistinctZPCentroid, getDistinctInfrapoleCentroid }
