import { createSlice } from '@reduxjs/toolkit'
import { userFavoriteState } from './types'
import {
  deleteUserFavoriteObject, deleteUserStudyPerimeter, deleteUserView, getObjectComments, getUserFavoriteObjects,
  getUserFavoriteObjectsDetails, getUserStudyPerimeters, getUserViews, postObjectComment, postUserFavoriteObject,
  postUserStudyPerimeter, postUserView,
} from './userFavorite.thunk'

const initialState: userFavoriteState = {
  views: [],
  favoriteObjects: [],
  objectsComments: [],
  createViewModal: false,
  favoriteModal: false,
  favoriteDetails: [],
  detailsLoading: false,
  perimeters: [],
  addedPerimeter: null,
}

export const userFavoriteSlice = createSlice({
  name: 'userFavoriteSlice',
  initialState,
  reducers: {
    openCreateViewModal: state => {
      state.createViewModal = true
    },
    closeCreateViewModal: state => {
      state.createViewModal = false
    },
    openFavoriteModal: state => {
      state.favoriteModal = true
    },
    closeFavoriteModal: state => {
      state.favoriteModal = false
    },
    closeCreatePerimeterModal: state => {
      state.addedPerimeter = null
    },
  },
  extraReducers: builder => builder
    .addCase(postUserView.fulfilled, state => {
      state.createViewModal = false
    })
    .addCase(getUserViews.fulfilled, (state, action) => {
      state.views = action.payload
    })
    .addCase(deleteUserView.fulfilled, (state, action) => {
      state.views = state.views.filter(view => view.id !== action.meta.arg)
    })
    .addCase(getUserFavoriteObjects.fulfilled, (state, action) => {
      state.favoriteObjects = action.payload
    })
    .addCase(postUserFavoriteObject.fulfilled, (state, action) => {
      state.favoriteObjects = [...state.favoriteObjects, action.payload]
    })
    .addCase(deleteUserFavoriteObject.fulfilled, (state, action) => {
      state.favoriteObjects = state.favoriteObjects.filter(obj => obj.id !== action.meta.arg)
      state.favoriteDetails = state.favoriteDetails.filter(obj => obj.id !== action.meta.arg)
    })
    .addCase(getUserFavoriteObjectsDetails.fulfilled, (state, action) => {
      state.favoriteDetails = action.payload
      state.detailsLoading = false
    })
    .addCase(getUserFavoriteObjectsDetails.pending, state => {
      state.detailsLoading = true
    })
    .addCase(getUserFavoriteObjectsDetails.rejected, state => {
      state.detailsLoading = false
    })
    .addCase(getUserStudyPerimeters.fulfilled, (state, action) => {
      state.perimeters = action.payload
    })
    .addCase(postUserStudyPerimeter.fulfilled, (state, action) => {
      state.perimeters = [...state.perimeters, action.payload]
      state.addedPerimeter = action.payload
    })
    .addCase(deleteUserStudyPerimeter.fulfilled, (state, action) => {
      state.perimeters = state.perimeters.filter(p => p.id !== action.meta.arg)
    })
    .addCase(getObjectComments.fulfilled, (state, action) => {
      state.objectsComments = action.payload.results
    })
    .addCase(postObjectComment.fulfilled, (state, action) => {
      state.objectsComments = [...state.objectsComments, action.payload]
    })
  ,
})

export const {
  openCreateViewModal, closeCreateViewModal, openFavoriteModal, closeFavoriteModal,
  closeCreatePerimeterModal,
} = userFavoriteSlice.actions

export default userFavoriteSlice.reducer
