import { LayerProps } from 'react-map-gl'
import { OBJECTS_LAYERS } from '../common'

const ActionZonePosteLayerProps: LayerProps = {
  type: 'fill',
  paint: {
    'fill-color': ['get', 'color'],
    'fill-opacity': 0.25,
    'fill-outline-color': 'black',
  },
  'source-layer': OBJECTS_LAYERS.actionZonePoste,
  minzoom: 11,
}

export default ActionZonePosteLayerProps
