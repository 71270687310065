import { ReactElement } from 'react'
import terms from 'common/terms'
import { NavigationControl, _useMapControl as useMapControl } from 'react-map-gl'
import { Tooltip } from '@mui/material'
import { SyncAlt, ZoomOutMap } from '@mui/icons-material'
import './MapMenu.scss'

interface Props {
  handleZoomOutClick: () => void
  handleResetNorth: () => void
  handleSyncViewport: () => void
  disableSyncViewport: boolean
}

const PerimeterMapMenu = ({
  handleZoomOutClick, handleResetNorth, handleSyncViewport, disableSyncViewport,
}: Props): ReactElement => {
  const { containerRef } = useMapControl({
    captureDrag: true, captureClick: true, captureDoubleClick: true, captureScroll: true, capturePointerMove: true,
  })

  return (
    <div className="map-menu" ref={containerRef}>
      <Tooltip title={terms.tooltips.zoomOutPerimeter}>
        <button type="button" onClick={handleZoomOutClick}>
          <ZoomOutMap />
        </button>
      </Tooltip>
      <Tooltip title={terms.tooltips.resetNorth}>
        <div role="button" tabIndex={0} onClick={handleResetNorth}>
          <NavigationControl
            showZoom={false}
            className="navigation-control"
            style={{ position: 'static' }}
            compassLabel={terms.tooltips.resetNorth}
          />
        </div>
      </Tooltip>
      <Tooltip title={terms.tooltips.syncViews}>
        <button
          type="button"
          onClick={handleSyncViewport}
          disabled={disableSyncViewport}
        >
          <SyncAlt />
        </button>
      </Tooltip>
    </div>
  )
}

export default PerimeterMapMenu
