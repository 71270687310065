import { ReactElement } from 'react'
import { Source, Layer } from 'react-map-gl'
import { OBJECTS_LAYERS, SOURCES_IDS } from '../common'
import SousStationLayerProps from './SousStationLayerProps'
import { MapURLParams, useMapURL } from '../hooks'

interface Props {
  view: string
  params: MapURLParams
  opacity?: number
  lineFilter?: string
  visibility: boolean
}

const SousStationLayer = ({ view, params, opacity, lineFilter, visibility }: Props): ReactElement => {
  const { sch, geo } = SousStationLayerProps
  const url = useMapURL(
    OBJECTS_LAYERS.sousStation,
    view,
    SOURCES_IDS.sousStation,
    params,
  )

  return (
    <Source
      id={SOURCES_IDS.sousStation}
      type="vector"
      url={url}
    >
      <Layer
        {...sch}
        source={SOURCES_IDS.sousStation}
        source-layer={OBJECTS_LAYERS.sousStation}
        minzoom={10}
        layout={{
          ...sch.layout,
          visibility: visibility ? 'visible' : 'none',
        }}
        paint={{
          ...sch.paint,
          'icon-opacity': ['case',
            ['in', lineFilter, ['get', 'lrs_ligne']], 1,
            opacity],
        }}
      />
      <Layer
        {...geo}
        source={SOURCES_IDS.sousStation}
        source-layer={OBJECTS_LAYERS.sousStation}
        maxzoom={10}
        layout={{
          visibility: visibility ? 'visible' : 'none',
        }}
        paint={{
          ...geo.paint,
          'circle-opacity': ['case',
            ['in', lineFilter, ['get', 'lrs_ligne']], 1,
            opacity],
          'circle-stroke-opacity': ['case',
            ['in', lineFilter, ['get', 'lrs_ligne']], 1,
            opacity],
        }}
      />
    </Source>
  )
}

SousStationLayer.defaultProps = {
  opacity: 1,
  lineFilter: '',
}

export default SousStationLayer
