import { UserStudyPerimeterDetailsWithId } from 'store/userFavorite/types'
import FavoriteModal from 'components/Modal/FavoriteModal/FavoriteModal'
import { useDispatch, useSelector } from 'react-redux'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { RootState } from 'store'
import { closeFavoriteModal } from 'store/userFavorite/userFavorite'
import { hideSnackbar } from 'store/feedback'
import { initCreateZonePoste, resetEditor } from 'store/zoneEditor/zoneEditor'
import { Snackbar } from 'components'
import { signal } from '@preact/signals-react'
import { DexcartoLayerKey } from 'components/Layers/common'
import PerimeterToolbar from 'components/Toolbar/PerimeterToolbar'
import './PerimeterView.scss'
import { calculateGeometryViewport } from 'components/utils'
import { PerimeterInfoModal } from 'components/Modal'
import { GEO_DEFAULT_VIEWPORT, SCH_DEFAULT_VIEWPORT } from 'views/Map'
import { ViewportProps } from 'react-map-gl'
import { useHistory } from 'react-router-dom'
import { ArrowBack, DrawOutlined, Info } from '@mui/icons-material'
import terms from 'common/terms'
import { reprojectPoints } from 'utils/cassini'
import { Tooltip } from '@mui/material'
import PerimeterSearch from 'components/ObjectSearch/PerimeterSearch'
import { SearchResult } from 'utils/search/types'
import Resizer from 'components/Resizer/Resizer'
import { debounce } from 'lodash'
import { patch } from '@osrdata/app_core/dist/requests'
import PerimeterSchMap, { schPerimeterViewportSignal } from './PerimeterSchMap'
import PerimeterGeoMap, { geoPerimeterViewportSignal } from './PerimeterGeoMap'
import PerimeterTable from './PerimeterTable'

interface Props {
  activePerimeter: UserStudyPerimeterDetailsWithId
}

export const perimeterSelectedIdSignal = signal<string[]>([])
export const perimeterHoveredIdSignal = signal<string[]>([])
export const perimeterHighlightedIdSignal = signal<string[]>([])

export const perimeterActiveLayersSignal = signal<DexcartoLayerKey[]>([])

export const perimeterActiveViewsSignal = signal<{geo: boolean, sch: boolean, table: boolean}>(
  { geo: true, sch: true, table: false },
)

const setSelectedObjectsIds = (ids: string[]) => {
  perimeterSelectedIdSignal.value = ids
}
const setHoveredObjectsIds = (ids: string[]) => {
  perimeterHoveredIdSignal.value = ids
}

const PerimeterView = ({ activePerimeter }: Props) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const wrapperLeftRef = useRef<HTMLDivElement>(null)
  const wrapperRightRef = useRef<HTMLDivElement>(null)
  const { snackbarDisplay, snackbarMessage, snackbarSeverity } = useSelector((state: RootState) => state.feedback)
  const { favoriteModal } = useSelector((state: RootState) => state.userFavorite)
  const [defaultSchViewport, setDefaultSchViewport] = useState<ViewportProps>(SCH_DEFAULT_VIEWPORT)
  const [defaultGeoViewport, setDefaultGeoViewport] = useState<ViewportProps>(GEO_DEFAULT_VIEWPORT)
  const [openPerimeterInfoModal, setOpenPerimeterInfoModal] = useState(false)
  const [splitRatio, setSplitRatio] = useState(activePerimeter.properties?.split_ratio || 0.5)

  useEffect(() => {
    perimeterHighlightedIdSignal.value = [...perimeterHoveredIdSignal.value, ...perimeterSelectedIdSignal.value]
  }, [perimeterHoveredIdSignal.value, perimeterSelectedIdSignal.value])

  useEffect(() => {
    if (wrapperLeftRef.current && perimeterActiveViewsSignal.value.sch) {
      const { width, height } = wrapperLeftRef.current.getBoundingClientRect()
      const { latitude, longitude, zoom } = calculateGeometryViewport(
        activePerimeter.geometry, { width, height },
      )
      const schPerimeterViewport = {
        ...schPerimeterViewportSignal.value,
        latitude,
        longitude,
        zoom,
        transitionDuration: 0,
      }
      setDefaultSchViewport(schPerimeterViewport)
      if (activePerimeter.properties.x && activePerimeter.properties.y && activePerimeter.properties.zoom) {
        const schViewport = {
          ...schPerimeterViewportSignal.value,
          latitude: activePerimeter.properties.x,
          longitude: activePerimeter.properties.y,
          zoom: activePerimeter.properties.zoom,
          bearing: activePerimeter.properties?.bearing || 0,
          pitch: activePerimeter.properties?.pitch || 0,
          transitionDuration: 0,
        }
        schPerimeterViewportSignal.value = schViewport
      } else {
        schPerimeterViewportSignal.value = schPerimeterViewport
      }

      // Set Default Geo Viewport
      reprojectPoints(
        [{ type: 'Point', coordinates: [longitude, latitude] }],
        'rgi_track_sch_flat',
        'rgi_track_geo',
      ).then(response => {
        if (response.length > 0) {
          const [newLongitude, newLatitude] = response[0].coordinates
          const newGeoViewport = ({
            ...geoPerimeterViewportSignal.value,
            latitude: newLatitude,
            longitude: newLongitude,
            zoom,
            transitionDuration: 500,
          })
          setDefaultGeoViewport(newGeoViewport)
        }
      })

      // Synx Geo Viewport with Sch Viewport
      if (schPerimeterViewportSignal.value.latitude && schPerimeterViewportSignal.value.longitude) {
        reprojectPoints(
          [{ type: 'Point',
            coordinates: [
              schPerimeterViewportSignal.value.longitude,
              schPerimeterViewportSignal.value.latitude,
            ] }],
          'rgi_track_sch_flat',
          'rgi_track_geo',
        ).then(response => {
          if (response.length > 0) {
            const [newLongitude, newLatitude] = response[0].coordinates
            geoPerimeterViewportSignal.value = ({
              ...geoPerimeterViewportSignal.value,
              latitude: newLatitude,
              longitude: newLongitude,
              zoom: schPerimeterViewportSignal.value.zoom,
              transitionDuration: 0,
            })
          }
        })
      }
    }
    if (activePerimeter.properties.displayed_layers) {
      perimeterActiveLayersSignal.value = activePerimeter.properties.displayed_layers
    }

    if (activePerimeter.properties.split_ratio) {
      const { split_ratio: ratio } = activePerimeter.properties
      if (wrapperLeftRef.current && wrapperRightRef.current) {
        wrapperLeftRef.current.style.flexBasis = `${ratio * 100}%`
        wrapperRightRef.current.style.flexBasis = `${(1 - ratio) * 100}%`
      }
    }
  }, [activePerimeter])

  const debouncedUpdatePerimeter = useCallback(debounce((
    x: number, y: number, bearing: number, pitch: number, zoom: number, activeLayers: string[], ratio: number,
  ) => {
    patch(`/dexcarto/user-study-perimeter/${activePerimeter.id}/`, {
      type: 'Feature',
      geometry: activePerimeter.geometry,
      properties: {
        x,
        y,
        bearing,
        pitch,
        zoom,
        displayed_layers: activeLayers,
        split_ratio: ratio,
      },
    })
  }, 500), [activePerimeter])

  useEffect(() => {
    const {
      latitude: x, longitude: y, bearing, pitch, zoom,
    } = schPerimeterViewportSignal.value
    if (x && y && bearing !== undefined && pitch !== undefined && zoom) {
      debouncedUpdatePerimeter(x, y, bearing, pitch, zoom, perimeterActiveLayersSignal.value, splitRatio)
    }
  }, [schPerimeterViewportSignal.value, perimeterActiveLayersSignal.value])

  const handleClosePerimeterInfoModal = () => { setOpenPerimeterInfoModal(false) }
  const handleQuitPerimeter = () => {
    dispatch(resetEditor())
    history.push('/')
  }
  const handleCreateClick = () => {
    if (!perimeterActiveViewsSignal.value.sch) {
      perimeterActiveViewsSignal.value = { ...perimeterActiveViewsSignal.value, sch: true }
    }
    dispatch(initCreateZonePoste())
  }

  const handleClickSearchResult = (result: SearchResult) => () => {
    perimeterSelectedIdSignal.value = [result.id]
    if (perimeterActiveViewsSignal.value.sch) {
      const { latitude, longitude, zoom } = calculateGeometryViewport(result.schGeom, schPerimeterViewportSignal.value)
      schPerimeterViewportSignal.value = {
        ...schPerimeterViewportSignal.value,
        latitude,
        longitude,
        zoom,
        transitionDuration: 500,
      }
    }
    if (perimeterActiveViewsSignal.value.geo) {
      const { latitude, longitude, zoom } = calculateGeometryViewport(result.geoGeom, geoPerimeterViewportSignal.value)
      geoPerimeterViewportSignal.value = {
        ...geoPerimeterViewportSignal.value,
        latitude,
        longitude,
        zoom,
        transitionDuration: 500,
      }
    }
  }

  const ELEMENTS = {
    sch: (
      <PerimeterSchMap
        activePerimeter={activePerimeter}
        setSelectedObjectsIds={setSelectedObjectsIds}
        setHoveredObjectsIds={setHoveredObjectsIds}
        defaultViewport={defaultSchViewport}
      />
    ),
    geo: (
      <PerimeterGeoMap
        activePerimeter={activePerimeter}
        setSelectedObjectsIds={setSelectedObjectsIds}
        setHoveredObjectsIds={setHoveredObjectsIds}
        defaultViewport={defaultGeoViewport}
      />
    ),
    table: (
      <PerimeterTable activePerimeter={activePerimeter} />
    ),
  }

  const elementsToRender = useMemo(() => [
    ...(perimeterActiveViewsSignal.value.sch ? [ELEMENTS.sch] : []),
    ...(perimeterActiveViewsSignal.value.geo ? [ELEMENTS.geo] : []),
    ...(perimeterActiveViewsSignal.value.table ? [ELEMENTS.table] : []),
  ], [perimeterActiveViewsSignal.value, activePerimeter, defaultSchViewport, defaultGeoViewport])

  return (
    <div className="perimeter-view">
      <div className="perimeter-topbar">
        <button type="button" className="back-button" onClick={handleQuitPerimeter}>
          <ArrowBack />
          <span>{terms.PerimeterView.exit}</span>
        </button>

        <Tooltip title={terms.PerimeterView.info}>
          <button type="button" className="info-button" onClick={() => setOpenPerimeterInfoModal(true)}>
            <Info />
          </button>
        </Tooltip>
        <button type="button" className="create-zone" onClick={handleCreateClick}>
          <DrawOutlined />
          <span>{terms.zoneEditor.createZone}</span>
        </button>
        <PerimeterSearch
          setHoveredObjectsIds={(ids: string[]) => { perimeterHoveredIdSignal.value = ids }}
          activeLayers={perimeterActiveLayersSignal.value}
          handleClickResult={handleClickSearchResult}
          activePerimeter={activePerimeter}
        />
      </div>
      <div className="views-wrapper">
        {elementsToRender.length > 0 && (
          <div className="perimeter-left-wrapper" ref={wrapperLeftRef}>
            {elementsToRender[0]}
          </div>
        )}
        {elementsToRender.length > 1 && (
          <>
            <Resizer
              leftElementRef={wrapperLeftRef}
              rightElementRef={wrapperRightRef}
              minWidth={250}
              splitRatio={splitRatio}
              setSplitRatio={setSplitRatio}
            />
            <div className="perimeter-right-wrapper" ref={wrapperRightRef}>
              {elementsToRender[1]}
            </div>
          </>
        )}
        {!perimeterActiveViewsSignal.value.geo && !perimeterActiveViewsSignal.value.sch && (
          <div className="no-view">
            <span>{terms.PerimeterView.noViewSelected}</span>
          </div>
        )}
      </div>

      <FavoriteModal
        onModal={favoriteModal}
        handleClose={() => dispatch(closeFavoriteModal())}
        setSelectedObjectsIds={setSelectedObjectsIds}
      />
      <Snackbar
        message={snackbarMessage}
        displaySnackbar={snackbarDisplay}
        severity={snackbarSeverity}
        handleClose={() => dispatch(hideSnackbar())}
      />
      <PerimeterToolbar />

      <PerimeterInfoModal
        openModal={openPerimeterInfoModal}
        handleClose={handleClosePerimeterInfoModal}
        perimeter={activePerimeter}
      />
    </div>
  )
}

export default PerimeterView
