import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface AppState {
  error: null | undefined,
  fullscreen: boolean,
  bugModal: boolean,
  suggestionModal: boolean
  helpModal: boolean
}

const initialState: AppState = {
  error: null,
  fullscreen: false,
  bugModal: false,
  suggestionModal: false,
  helpModal: false,
}

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    toggleFullscreen: state => {
      state.fullscreen = !state.fullscreen
    },
    getError: (state, action: PayloadAction<null>) => {
      state.error = action.payload
    },
    openBugModal: state => {
      state.bugModal = true
    },
    closeBugModal: state => {
      state.bugModal = false
    },
    openSuggestionModal: state => {
      state.suggestionModal = true
    },
    closeSuggestionModal: state => {
      state.suggestionModal = false
    },
    openHelpModal: state => {
      state.helpModal = true
    },
    closeHelpModal: state => {
      state.helpModal = false
    },
  },
})

export const {
  toggleFullscreen, getError, openBugModal, closeBugModal,
  openSuggestionModal, closeSuggestionModal, openHelpModal, closeHelpModal,
} = appSlice.actions

export default appSlice.reducer
