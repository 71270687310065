import { CircularProgress, Tooltip } from '@mui/material'
import { get } from '@osrdata/app_core/dist/requests'
import { CollectionDetails } from 'store/collections/types'
import terms from 'common/terms'
import { Fragment, useEffect, useState } from 'react'
import './CollectionDetails.scss'
import nextId from 'react-id-generator'
import { signal } from '@preact/signals-react'
import { CollectionSubfamily, GeoReference, IDFIndices } from 'views/Map/utils'
import moment from 'moment'

interface Props {
  collectionId: number
}

export const collectionDetailsSignal = signal<CollectionDetails | null>(null)

const renderFerroloc = (details: CollectionDetails | null) => {
  if (!details) return <></>
  return (
    <>
      {Object.entries(details.localisation).map(([key, value]) => (
        <span key={key} className="value" style={{ marginLeft: '10px' }}>
          {`${key.toUpperCase()}: ${value}`}
        </span>
      ))}
    </>
  )
}

const CollectionDetailsView = ({ collectionId }: Props) => {
  const [loading, setLoading] = useState(true)
  const [subfamilies, setSubfamilies] = useState<CollectionSubfamily[]>([])
  const [fiches, setFiches] = useState<GeoReference['fiches'][]>([])
  const [fichesIDF, setFichesIDF] = useState<IDFIndices[]>([])

  useEffect(() => {
    collectionDetailsSignal.value = null
    setLoading(true)
    get(`/dexcarto/ouranos-element/${collectionId}`)
      .then(response => {
        collectionDetailsSignal.value = response
        setLoading(false)
      })
  }, [collectionId])

  useEffect(() => {
    if (collectionDetailsSignal.value) {
      const newSubfamilies = Object.values(collectionDetailsSignal.value.documents).flatMap(documents => (
        Object.entries(documents).flatMap(([name, document]) => {
          if (name === 'ligne_ids') return []
          if (name === 'IDF') return []
          if (name === 'referenceGeographiques') {
            setFiches((document as GeoReference[]).map(d => d.fiches).filter(f => !!f))
            return []
          }
          return document as CollectionSubfamily[]
        })
      ))
      setSubfamilies(newSubfamilies)
      const newFicheIDF = Object.values(collectionDetailsSignal.value.documents).map(d => d?.IDF || {})
      const newIndicesIDF = newFicheIDF.flatMap(f => Object.values(f).flatMap(v => v.indices))
      setFichesIDF(newIndicesIDF.sort((
        a, b,
      ) => moment(b['Collection_DEX[DateEditionTravaux]']).diff(a['Collection_DEX[DateEditionTravaux]'])))
    } else {
      setSubfamilies([])
      setFiches([])
      setFichesIDF([])
    }
  }, [collectionDetailsSignal.value])

  return (
    <div className="collection-details">
      {loading && (
        <div className="loader">
          <CircularProgress />
          <span>{terms.CollectionsView.details.loading}</span>
        </div>
      )}
      {collectionDetailsSignal.value && !loading && (
        <>
          <h4 className="name">{collectionDetailsSignal.value.libelle}</h4>
          <div className="infos">
            <div className="panel">
              <strong className="panel-title">
                {terms.CollectionsView.details.generalInfos}
              </strong>
              <div className="panel-items">
                <div className="item">
                  <span className="label">{terms.CollectionsView.details.labelPoste}</span>
                  <span className="value">
                    {collectionDetailsSignal.value.zaps?.[0]?.libelle || terms.CollectionsView.details.unknown}
                  </span>
                </div>
                <div className="item">
                  <span className="label">{terms.CollectionsView.details.labelType}</span>
                  <span className="value">{collectionDetailsSignal.value.type}</span>
                </div>
                <div className="item">
                  <span className="label">{terms.CollectionsView.details.labelStation}</span>
                  <span className="value">
                    {collectionDetailsSignal.value.zaps?.[0]?.gare_libelle || terms.CollectionsView.details.unknown}
                  </span>
                </div>
                <div className="item">
                  <span className="label">{terms.CollectionsView.details.labelTechnology}</span>
                  <span className="value">
                    {collectionDetailsSignal.value.zaps?.[0]?.poste_technologie_mnemo
                    || terms.CollectionsView.details.unknown}
                  </span>
                </div>
                <div className="item">
                  <span className="label">{terms.CollectionsView.details.labelLoc}</span>
                  {collectionDetailsSignal.value.ligne_ids.map(ligneId => (
                    <Fragment key={ligneId}>
                      <span className="value">{ligneId}</span>
                      {renderFerroloc(collectionDetailsSignal.value)}
                    </Fragment>
                  ))}
                </div>
              </div>
            </div>
            <div className="panel">
              <strong className="panel-title">
                {terms.CollectionsView.details.borders}
              </strong>
              <div className="panel-items">
                <div className="item">
                  <span className="label">{terms.CollectionsView.details.labelIHM}</span>
                  {collectionDetailsSignal.value.ihms.map(ihm => (
                    <span key={ihm.id} className="value">{ihm.libelle}</span>
                  ))}
                  {collectionDetailsSignal.value.ihms.length === 0 && (
                  <span className="value">{terms.CollectionsView.details.unknown}</span>
                  )}
                </div>
                <div className="item">
                  <span className="label">{terms.CollectionsView.details.labelCollections}</span>
                  {collectionDetailsSignal.value.elements_encadrants.map(element => (
                    <span key={element.id} className="value">{element.libelle}</span>
                  ))}
                  {collectionDetailsSignal.value.elements_encadrants.length === 0 && (
                  <span className="value">{terms.CollectionsView.details.unknown}</span>
                  )}
                </div>
              </div>
            </div>
            <div className="panel table">
              <strong className="panel-title">
                {terms.CollectionsView.details.tableTitle}
              </strong>
              <table className="status-table">
                <thead>
                  <tr>
                    <th>{terms.CollectionsView.details.indice}</th>
                    <th>{terms.CollectionsView.details.status}</th>
                    <th>{terms.CollectionsView.details.work}</th>
                  </tr>
                </thead>
                <tbody>
                  {subfamilies.map(document => (
                    <tr key={nextId()}>
                      <td>{document?.indice_gep}</td>
                      <td>{document?.categorie}</td>
                      <td>{document?.objet || '-'}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
              {fiches.length > 0 && (
                <>
                  <strong className="panel-title">
                    {terms.CollectionsView.details.ficheTitle}
                  </strong>
                  <table className="fiche-table">
                    <thead>
                      <tr>
                        <th>{terms.CollectionsView.details.ficheType}</th>
                        <th>{terms.CollectionsView.details.ficheKey}</th>
                        <th>Indice</th>
                        <th>{terms.CollectionsView.details.ficheStatus}</th>
                        <th>{terms.CollectionsView.details.ficheSummary}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {fiches.map(f => f && (Object.entries(f).map(([key, value]) => (Object.values(value).map(v => (
                        <tr key={nextId()}>
                          <td className="cap">
                            <span>
                              {key}
                            </span>
                          </td>
                          <td>
                            <span>
                              {v.fiche_key}
                            </span>
                          </td>
                          <td>
                            <span>
                              {v.indice_gep}
                            </span>
                          </td>
                          <td>
                            <span>
                              {v.fiche_status_name}
                            </span>
                          </td>
                          <td className="long">
                            <Tooltip title={v.fiche_summary}>
                              <span>{v.fiche_summary}</span>
                            </Tooltip>
                          </td>
                        </tr>
                      ))))))}
                    </tbody>
                  </table>
                </>
              )}
              {fichesIDF.length > 0 && (
                <>
                  <strong className="panel-title">
                    Statuts IDF
                  </strong>
                  <table className="fiche-table">
                    <thead>
                      <tr>
                        <th>Indice</th>
                        <th>Etat</th>
                        <th>Date Edition</th>
                        <th>Libellé</th>
                      </tr>
                    </thead>
                    <tbody>
                      {fichesIDF.map(f => (
                        <tr key={nextId()}>
                          <td>
                            <span>
                              {f['Collection_DEX[Indice]'] || '-'}
                            </span>
                          </td>
                          <td>
                            <span>
                              {f['Collection_DEX[EtatDeLaCollection]'] || '-'}
                            </span>
                          </td>

                          <td>
                            <span>
                              {moment(f['Collection_DEX[DateEditionTravaux]']).format('DD/MM/YYYY') || '-'}
                            </span>
                          </td>
                          <td className="long">
                            <Tooltip title={f['Collection_DEX[LibelleOperation]']}>
                              <span>{f['Collection_DEX[LibelleOperation]'] || '-'}</span>
                            </Tooltip>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </>
              )}
            </div>
          </div>

        </>
      )}
    </div>
  )
}

export default CollectionDetailsView
