import { _useMapControl as useMapControl } from 'react-map-gl'
import { Delete } from '@mui/icons-material'
import './EditorMenu.scss'
import { Tooltip } from '@mui/material'
import terms from 'common/terms'

interface Props {
  selectedPoints: number
  selectedPolygon: boolean
  canDelete: boolean
  handleDelete: () => void
}

const EditorMenu = ({
  selectedPoints, selectedPolygon, canDelete, handleDelete,
}: Props) => {
  const { containerRef } = useMapControl({
    captureDrag: true, captureClick: true, captureDoubleClick: true, captureScroll: true, capturePointerMove: true,
  })

  return (
    <div className="editor-menu" ref={containerRef}>
      {selectedPolygon && (
      <div className="selected-points">
        {selectedPoints > 0 ? (
          <span>{`${selectedPoints} ${terms.menu.editorMenu.selectedPoints}`}</span>
        ) : (
          <span>{terms.menu.editorMenu.noSelection}</span>
        )}
      </div>
      )}
      <Tooltip title={terms.menu.editorMenu.deleteTooltip}>
        <div>
          <button
            type="button"
            className="delete"
            disabled={!canDelete}
            onClick={handleDelete}
          >
            <Delete />
          </button>
        </div>
      </Tooltip>
    </div>
  )
}

export default EditorMenu
