import { Box, Modal, Tab, Tabs } from '@mui/material'
import { SyntheticEvent, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  getUserFavoriteObjectsDetails, getUserStudyPerimeters, getUserViews,
} from 'store/userFavorite/userFavorite.thunk'
import { Close } from '@mui/icons-material'
import terms from 'common/terms'
import { RootState } from 'store'
import ViewTab from './ViewTab'
import './FavoriteModal.scss'
import ObjectTab from './ObjectTab'
import PerimeterTab from './PerimeterTab'

const style = {
  position: 'absolute' as const,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  height: '75vh',
  bgcolor: 'background.paper',
  boxShadow: 24,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',
  borderRadius: '4px',
  overflow: 'auto',
}

interface Props {
  onModal: boolean,
  handleClose: () => void,
  setSelectedObjectsIds: (ids: string[]) => void,
}

const FavoriteModal = ({ onModal, handleClose, setSelectedObjectsIds }: Props) => {
  const dispatch = useDispatch()
  const { currentOsmTheme } = useSelector((state: RootState) => state.map)
  const [value, setValue] = useState(0)

  const handleChange = (event: SyntheticEvent, newValue: number) => {
    setValue(newValue)
  }

  useEffect(() => {
    if (onModal) {
      dispatch(getUserViews())
      dispatch(getUserFavoriteObjectsDetails(currentOsmTheme))
      dispatch(getUserStudyPerimeters())
    }
  }, [onModal])

  return (
    <Modal
      open={onModal}
      onClose={handleClose}
      className="modal-favorite"
    >
      <Box sx={style}>
        <div className="modal-header">
          <h2 className="title">{terms.FavoriteModal.favorites}</h2>
          <button type="button" className="close" onClick={handleClose}>
            <Close />
          </button>
        </div>
        <Tabs
          value={value}
          onChange={handleChange}
        >
          <Tab value={0} label="Vues" />
          <Tab value={1} label="Objets" />
          <Tab value={2} label="Périmètres" />
        </Tabs>
        <div className="tab-wrapper">
          {value === 0 && (<ViewTab />)}
          {value === 1 && (<ObjectTab setSelectedObjectsIds={setSelectedObjectsIds} />)}
          {value === 2 && (<PerimeterTab handleClose={handleClose} />)}
        </div>
      </Box>
    </Modal>
  )
}

export default FavoriteModal
