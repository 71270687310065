import { MapTheme } from 'components/Toolbar/ThemeMenu/const'
import { Polygon } from 'geojson'
import { get } from '@osrdata/app_core/dist/requests'
import { OBJECTS_LAYERS } from 'components/Layers/common'
import { CenterSearchResult, SearchParams, SearchResults } from './types'
import { SearchLayers } from './const'

const computeSearchResults = (cassiniResponse: CenterSearchResult) => cassiniResponse.features.reduce((
  acc: SearchResults, feature,
) => {
  const layer = acc[feature.properties.layer_slug] || []
  return {
    ...acc,
    [feature.properties.layer_slug]: [
      ...layer,
      {
        type: 'center' as const,
        id: feature.properties.id,
        libelle: feature.properties.libelle,
        schGeom: feature.properties.geom_rgi_track_sch_flat,
        geoGeom: feature.properties.geom_rgi_track_geo,
      },
    ],
  }
}, {})

// eslint-disable-next-line import/prefer-default-export
export const getCenterObjects = async (
  layers: SearchLayers[], theme: MapTheme, bbox: Polygon,
  query: string, params: SearchParams,
) => {
  if (!layers.includes(SearchLayers.center)) return null
  const view = theme === MapTheme.geographic ? 'full_rgi_track_geo' : 'full_rgi_track_sch_flat'
  const bboxParam = theme === MapTheme.perimeter ? 'geom_rgi_track_sch_flat__bpolygon' : 'bbox'
  const response : CenterSearchResult = await get(
    `/chartis/v2/layer/${OBJECTS_LAYERS.center}/search/${view}/`,
    {
      columns: 'id,libelle,geom_rgi_track_sch_flat,geom_rgi_track_geo',
      [bboxParam]: bbox,
      size_per_layer: 100,
      libelle__ilike: `%${query.trim()}%`,
      ...(params.lineFilter && params.lineFilterActive && { lrs_ligne__ilike: `%${params.lineFilter}%` }),
    },
  )
  return computeSearchResults(response)
}
