import { useSelector } from 'react-redux'
import { RootState } from 'store'
import { MapTheme } from 'components/Toolbar/ThemeMenu/const'
import { MapEvent, MapRef } from 'react-map-gl'
import { RefObject } from 'react'
import {
  SignalLayer, StationLayer, PosteLayer, JDZLayer, AccesLayer, SousStationLayer, ExploitationModeLayer,
  CentraleSousStationLayer, LocalisateurLayer, DBCLayer, PedaleLayer, PassageNiveauLayer, TrackLayer,
  ADVLayer, ActionZonePosteLayer, ActionZonePosteLibelleLayer, BordersLayer, PosteSelectionLayer, BFLayer, RacLayer,
  TrackLabelLayer, ChantierLayer, ADVLineLayer, BALZoneLayer, CenterLayer, IHMZoneLayer, IHMZoneLabelLayer, CDVLayer,
  GeoJSONLayer,
  CenterArmenLayer,
} from '.'
import { useDateFilterParams, useLineFilterOpacity } from './hooks'
import {
  DexCartoSourceKey, GEOGRAPHIC_VIEWS, LAYERS_KEYS, OBJECTS_LAYERS, POSTES_LAYER_KEYS, SCHEMATIC_VIEWS,
  SIGNAL_LAYERS_KEYS, SOURCES_IDS,
} from './common'
import { getChantierFilter, getCollectionStatusProps, getPosteFilter, getSignalFilter } from './utils'

interface Props {
  mapRef: RefObject<MapRef | undefined>
  selectedBorder: MapEvent | undefined
  setViewportInfra: (infra: string | null) => void
  setViewportPRI: (pri: string | null) => void
  setViewportZP: (zp: string | null) => void
  highlightedObjectsIds: string[]
}

const MapLayers = ({
  mapRef, selectedBorder, highlightedObjectsIds, setViewportInfra, setViewportPRI, setViewportZP,
}: Props) => {
  const { currentOsmTheme, activeLayers } = useSelector((state: RootState) => state.map)
  const { actionZonePatch, zapPatchId, isSelectingPoste } = useSelector((state: RootState) => state.zoneEditor)
  const dateParams = useDateFilterParams()
  const { opacity, lineFilter } = useLineFilterOpacity()

  const getView = (sourceId: DexCartoSourceKey) => {
    let response
    if (currentOsmTheme === MapTheme.schematic) {
      response = SCHEMATIC_VIEWS[sourceId]
    } else if (currentOsmTheme === MapTheme.geographic) {
      response = GEOGRAPHIC_VIEWS[sourceId]
    }
    return response ?? ''
  }

  const isLayerActive = (layer: string) => activeLayers.includes(layer)
  const signalFilter = getSignalFilter(isLayerActive)
  const posteFilter = getPosteFilter(isLayerActive, isSelectingPoste)
  const chantierFilter = getChantierFilter(isLayerActive)
  const collectionStatusProps = getCollectionStatusProps(isLayerActive)

  return (
    <>
      <ExploitationModeLayer activeLayers={activeLayers} view={getView(SOURCES_IDS.exploitationMode)} />
      <TrackLayer
        sourceLayer={currentOsmTheme === MapTheme.schematic ? OBJECTS_LAYERS.trackSch : OBJECTS_LAYERS.trackGeo}
        opacity={opacity}
        lineFilter={lineFilter}
      />
      <JDZLayer
        hoveredObjectsIds={highlightedObjectsIds}
        view={getView(SOURCES_IDS.jdz)}
        params={dateParams}
        opacity={opacity}
        lineFilter={lineFilter}
        visibility={activeLayers.includes(LAYERS_KEYS.jdz)}
        angle={currentOsmTheme === MapTheme.schematic ? 'angle_rgi_track_sch_flat' : 'angle_rgi_track_geo'}
      />
      <BALZoneLayer
        hoveredObjectsIds={highlightedObjectsIds}
        view={getView(SOURCES_IDS.balZone)}
        lineFilter={lineFilter}
        opacity={opacity}
        visibility={activeLayers.includes(LAYERS_KEYS.balZone)}
      />
      <ActionZonePosteLayer
        hoveredObjectsIds={highlightedObjectsIds}
        view={getView(SOURCES_IDS.actionZone)}
        filterZapId={zapPatchId}
        filterOutZapId={actionZonePatch?.id}
        opacity={opacity}
        lineFilter={lineFilter}
        visibility={{
          zoneActionPoste: activeLayers.includes(LAYERS_KEYS.zoneActionPoste),
          zoneActionPosteAmendee: activeLayers.includes(LAYERS_KEYS.zoneActionPosteAmendee),
        }}
      />
      <IHMZoneLayer
        hoveredObjectsIds={highlightedObjectsIds}
        view={getView(SOURCES_IDS.ihmZone)}
        visibility={{
          ihmZone: activeLayers.includes(LAYERS_KEYS.ihmZone),
          ihmZoneAmendee: activeLayers.includes(LAYERS_KEYS.ihmZoneAmendee),
        }}
      />
      <ChantierLayer
        hoveredObjectsIds={highlightedObjectsIds}
        view={getView(SOURCES_IDS.chantier)}
        opacity={opacity}
        lineFilter={lineFilter}
        chantierFilter={chantierFilter}
      />
      {currentOsmTheme === MapTheme.schematic && (
        <BordersLayer
          mapRef={mapRef}
          selectedBorder={selectedBorder}
          setViewportInfra={setViewportInfra}
          setViewportPRI={setViewportPRI}
          setViewportZP={setViewportZP}
        />
      )}
      <ADVLineLayer
        hoveredObjectsIds={highlightedObjectsIds}
        view={getView(SOURCES_IDS.advLine)}
        params={dateParams}
        opacity={opacity}
        lineFilter={lineFilter}
        visibility={activeLayers.includes(LAYERS_KEYS.adv)}
      />
      <TrackLabelLayer
        sourceLayer={currentOsmTheme === MapTheme.schematic ? OBJECTS_LAYERS.trackSch : OBJECTS_LAYERS.trackGeo}
      />
      <RacLayer
        hoveredObjectsIds={highlightedObjectsIds}
        view={getView(SOURCES_IDS.rac)}
        opacity={opacity}
        lineFilter={lineFilter}
        visibility={activeLayers.includes(LAYERS_KEYS.rac)}
      />
      <ADVLayer
        hoveredObjectsIds={highlightedObjectsIds}
        view={getView(SOURCES_IDS.adv)}
        params={dateParams}
        opacity={opacity}
        lineFilter={lineFilter}
        visibility={activeLayers.includes(LAYERS_KEYS.adv)}
      />
      <CDVLayer
        hoveredObjectsIds={highlightedObjectsIds}
        view={getView(SOURCES_IDS.cdv)}
        params={dateParams}
        opacity={opacity}
        lineFilter={lineFilter}
        visibility={activeLayers.includes(LAYERS_KEYS.cdv)}
      />
      <SignalLayer
        hoveredObjectsIds={highlightedObjectsIds}
        view={getView(SOURCES_IDS.signal)}
        params={dateParams}
        opacity={opacity}
        lineFilter={lineFilter}
        angle={currentOsmTheme === MapTheme.schematic ? 'angle_rgi_track_sch_flat' : 'angle_rgi_track_geo'}
        signalFilter={signalFilter}
        visibility={SIGNAL_LAYERS_KEYS.some(key => activeLayers.includes(key))}
      />
      <StationLayer
        hoveredObjectsIds={highlightedObjectsIds}
        view={getView(SOURCES_IDS.station)}
        params={dateParams}
        opacity={opacity}
        lineFilter={lineFilter}
        visibility={activeLayers.includes(LAYERS_KEYS.station)}
      />
      <PosteSelectionLayer />
      <PosteLayer
        hoveredObjectsIds={highlightedObjectsIds}
        view={getView(SOURCES_IDS.poste)}
        params={dateParams}
        opacity={opacity}
        lineFilter={lineFilter}
        posteVisibility={isSelectingPoste || Object.values(POSTES_LAYER_KEYS).some(key => activeLayers.includes(key))}
        posteFilter={posteFilter}
      />
      <AccesLayer
        hoveredObjectsIds={highlightedObjectsIds}
        view={getView(SOURCES_IDS.acces)}
        params={dateParams}
        opacity={opacity}
        lineFilter={lineFilter}
        visibility={activeLayers.includes(LAYERS_KEYS.acces)}
      />
      <BFLayer
        hoveredObjectsIds={highlightedObjectsIds}
        view={getView(SOURCES_IDS.bf)}
        params={{ ...dateParams, code_ch: 'BF' }}
        opacity={opacity}
        lineFilter={lineFilter}
        visibility={activeLayers.includes(LAYERS_KEYS.bf)}
      />
      <SousStationLayer
        view={getView(SOURCES_IDS.sousStation)}
        params={dateParams}
        opacity={opacity}
        lineFilter={lineFilter}
        visibility={activeLayers.includes(LAYERS_KEYS.sousStation)}
      />
      <CentraleSousStationLayer
        view={getView(SOURCES_IDS.css)}
        params={dateParams}
        opacity={opacity}
        lineFilter={lineFilter}
        visibility={activeLayers.includes(LAYERS_KEYS.css)}
      />
      <LocalisateurLayer
        hoveredObjectsIds={highlightedObjectsIds}
        view={getView(SOURCES_IDS.localisateur)}
        params={dateParams}
        opacity={opacity}
        lineFilter={lineFilter}
        visibility={activeLayers.includes(LAYERS_KEYS.localisateur)}
      />
      <DBCLayer
        hoveredObjectsIds={highlightedObjectsIds}
        view={getView(SOURCES_IDS.dbc)}
        params={dateParams}
        opacity={opacity}
        lineFilter={lineFilter}
        visibility={activeLayers.includes(LAYERS_KEYS.dbc)}
        angle={currentOsmTheme === MapTheme.schematic ? 'angle_rgi_track_sch_flat' : 'angle_rgi_track_geo'}
      />
      <PedaleLayer
        hoveredObjectsIds={highlightedObjectsIds}
        view={getView(SOURCES_IDS.pedale)}
        params={dateParams}
        opacity={opacity}
        lineFilter={lineFilter}
        visibility={activeLayers.includes(LAYERS_KEYS.pedale)}
      />
      <PassageNiveauLayer
        hoveredObjectsIds={highlightedObjectsIds}
        view={getView(SOURCES_IDS.passageNiveau)}
        params={dateParams}
        opacity={opacity}
        lineFilter={lineFilter}
        visibility={activeLayers.includes(LAYERS_KEYS.passageNiveau)}
        angle={currentOsmTheme === MapTheme.schematic ? 'angle_rgi_track_sch_flat' : 'angle_rgi_track_geo'}
      />
      <CenterLayer
        hoveredObjectsIds={highlightedObjectsIds}
        view={getView(SOURCES_IDS.center)}
        opacity={opacity}
        lineFilter={lineFilter}
        visibility={activeLayers.includes(LAYERS_KEYS.center)}
      />
      <CenterArmenLayer
        hoveredObjectsIds={highlightedObjectsIds}
        view={getView(SOURCES_IDS.centerArmen)}
        opacity={opacity}
        lineFilter={lineFilter}
        visibility={activeLayers.includes(LAYERS_KEYS.centerArmen)}
      />
      <ActionZonePosteLibelleLayer
        hoveredObjectsIds={highlightedObjectsIds}
        view={getView(SOURCES_IDS.actionZoneLabel)}
        filterOutZapId={zapPatchId}
        filterZapId={actionZonePatch?.id}
        opacity={opacity}
        lineFilter={lineFilter}
        visibility={{
          zoneActionPoste: activeLayers.includes(LAYERS_KEYS.zoneActionPoste),
          zoneActionPosteAmendee: activeLayers.includes(LAYERS_KEYS.zoneActionPosteAmendee),
        }}
        collectionStatusProps={collectionStatusProps}
      />
      <IHMZoneLabelLayer
        hoveredObjectsIds={highlightedObjectsIds}
        view={getView(SOURCES_IDS.ihmZoneLabel)}
        visibility={{
          ihmZone: activeLayers.includes(LAYERS_KEYS.ihmZone),
          ihmZoneAmendee: activeLayers.includes(LAYERS_KEYS.ihmZoneAmendee),
        }}
      />
      <GeoJSONLayer />
    </>
  )
}

export default MapLayers
