import LAYERS_KEYS from './menuKeys'

const POSTES_LAYER_KEYS = {
  ARGOS: LAYERS_KEYS.argosPoste,
  PAI: LAYERS_KEYS.paiPoste,
  PRCI: LAYERS_KEYS.prciPoste,
  PRS: LAYERS_KEYS.prsPoste,
  PRG: LAYERS_KEYS.prgPoste,
  PRMI: LAYERS_KEYS.prmiPoste,
  CC: LAYERS_KEYS.ccPoste,
  PLIT: LAYERS_KEYS.plitPoste,
  PELI: LAYERS_KEYS.peliPoste,
  'PELI-R': LAYERS_KEYS.pelirPoste,
  PML: LAYERS_KEYS.pmlPoste,
  EMU: LAYERS_KEYS.emuPoste,
  MU45: LAYERS_KEYS.mu45Poste,
  'MR-SAXBY': LAYERS_KEYS.mrsaxbyPoste,
  'MR-LEV-DR': LAYERS_KEYS.mrlevdrPoste,
  'MR-VIGNTY': LAYERS_KEYS.mrvigntyPoste,
  'MR-EX-AL': LAYERS_KEYS.mrexalPoste,
  'SS-DV': LAYERS_KEYS.ssdvPoste,
  'SS-VUVB': LAYERS_KEYS.ssvuvbPoste,
  'PVS-TRI': LAYERS_KEYS.pvstriPoste,
  'PVS-ELEC': LAYERS_KEYS.pvselecPoste,
  'PVS-EI': LAYERS_KEYS.pvseiPoste,
  'PVS-CI': LAYERS_KEYS.pvsciPoste,
  'PVS-MEC': LAYERS_KEYS.pvsmecPoste,
  PIPC: LAYERS_KEYS.pipcPoste,
  POINT: LAYERS_KEYS.pointPoste,
  LEVIER: LAYERS_KEYS.levierPoste,
  'P-10': LAYERS_KEYS.p10Poste,
  Autres: LAYERS_KEYS.autresPoste,
} as const

export const MAJOR_POSTES_LAYER_KEYS = {
  ARGOS: LAYERS_KEYS.argosPoste,
  PAI: LAYERS_KEYS.paiPoste,
  PRCI: LAYERS_KEYS.prciPoste,
  PRS: LAYERS_KEYS.prsPoste,
  PRG: LAYERS_KEYS.prgPoste,
  PRMI: LAYERS_KEYS.prmiPoste,
  CC: LAYERS_KEYS.ccPoste,
  PLIT: LAYERS_KEYS.plitPoste,
  PELI: LAYERS_KEYS.peliPoste,
  'PELI-R': LAYERS_KEYS.pelirPoste,
  PML: LAYERS_KEYS.pmlPoste,
  EMU: LAYERS_KEYS.emuPoste,
  MU45: LAYERS_KEYS.mu45Poste,
  'MR-SAXBY': LAYERS_KEYS.mrsaxbyPoste,
  'MR-LEV-DR': LAYERS_KEYS.mrlevdrPoste,
  'MR-VIGNTY': LAYERS_KEYS.mrvigntyPoste,
  'MR-EX-AL': LAYERS_KEYS.mrexalPoste,
  'SS-DV': LAYERS_KEYS.ssdvPoste,
  'SS-VUVB': LAYERS_KEYS.ssvuvbPoste,
  'PVS-TRI': LAYERS_KEYS.pvstriPoste,
  'PVS-ELEC': LAYERS_KEYS.pvselecPoste,
  'PVS-EI': LAYERS_KEYS.pvseiPoste,
  'PVS-CI': LAYERS_KEYS.pvsciPoste,
  'PVS-MEC': LAYERS_KEYS.pvsmecPoste,
  PIPC: LAYERS_KEYS.pipcPoste,
} as const

export const MINOR_POSTES_LAYER_KEYS = {
  POINT: LAYERS_KEYS.pointPoste,
  LEVIER: LAYERS_KEYS.levierPoste,
  'P-10': LAYERS_KEYS.p10Poste,
  Autres: LAYERS_KEYS.autresPoste,
} as const

export default POSTES_LAYER_KEYS
