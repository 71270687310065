import { ReactElement } from 'react'
import { Source, Layer } from 'react-map-gl'
import { LAYERS_IDS, OBJECTS_LAYERS, SOURCES_IDS } from '../common'
import { MapURLParams, useMapURL } from '../hooks'

interface Props {
  hoveredObjectsIds: string[]
  view: string
  opacity?: number
  lineFilter?: string
  visibility?: boolean
  params?: MapURLParams
}

const BALZoneLayer = ({ hoveredObjectsIds, view, opacity, lineFilter, visibility, params }: Props): ReactElement => {
  const url = useMapURL(
    OBJECTS_LAYERS.balZone,
    view,
    SOURCES_IDS.balZone,
    params,
  )
  return (
    <Source
      id={SOURCES_IDS.balZone}
      type="vector"
      url={url}
    >
      <Layer
        id={LAYERS_IDS.zoneBAL}
        type="fill"
        layout={{
          visibility: visibility ? 'visible' : 'none',
        }}
        paint={{
          'fill-color': ['coalesce', ['get', 'color'], '#000000'],
          'fill-opacity': ['case',
            ['in', lineFilter, ['get', 'lrs_ligne']], 0.25,
            ['in', ['get', 'id'], ['literal', hoveredObjectsIds]], 0.25,
            0.25 * (opacity || 1)],
        }}
        minzoom={7}
        source-layer={OBJECTS_LAYERS.balZone}
      />
      <Layer
        type="fill"
        id={LAYERS_IDS.zoneBALDotted}
        source-layer={OBJECTS_LAYERS.balZone}
        paint={{
          'fill-pattern': 'dot',
          'fill-opacity': ['case',
            ['in', lineFilter, ['get', 'lrs_ligne']], 0.1,
            ['in', ['get', 'id'], ['literal', hoveredObjectsIds]], 0.1,
            0.1 * (opacity || 1)],
        }}
        layout={{
          visibility: visibility ? 'visible' : 'none',
        }}
        minzoom={7}
      />
      <Layer
        type="line"
        id={LAYERS_IDS.zoneBALOutline}
        source-layer={OBJECTS_LAYERS.balZone}
        paint={{
          'line-color': '#CC0000',
          'line-width': 2,
          'line-opacity': ['case',
            ['in', lineFilter, ['get', 'lrs_ligne']], 1,
            ['in', ['get', 'id'], ['literal', hoveredObjectsIds]], 1,
            opacity],
        }}
        filter={['in', ['get', 'id'], ['literal', hoveredObjectsIds]]}
        layout={{
          visibility: visibility ? 'visible' : 'none',
        }}
        minzoom={7}
      />
    </Source>
  )
}

BALZoneLayer.defaultProps = {
  opacity: 1,
  lineFilter: '',
  visibility: true,
  params: {},
}

export default BALZoneLayer
