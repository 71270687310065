import { Layer, Source } from 'react-map-gl'
import { MapURLParams, useMapURL } from '../hooks'
import { LAYERS_IDS, OBJECTS_LAYERS, SOURCES_IDS, hoverColor } from '../common'

interface Props {
  hoveredObjectsIds: string[]
  visibility?: {
    ihmZone: boolean
    ihmZoneAmendee: boolean
  }
  params?: MapURLParams
  view: string
}

const IHMZoneLayer = ({ hoveredObjectsIds, visibility, params, view }:Props) => {
  const url = useMapURL(
    OBJECTS_LAYERS.ihmZone,
    view,
    SOURCES_IDS.ihmZone,
    params,
  )

  return (
    <Source
      id={SOURCES_IDS.ihmZone}
      type="vector"
      url={url}
    >
      <Layer
        id={LAYERS_IDS.ihmZoneOutline}
        type="line"
        paint={{
          'line-width': ['step', ['zoom'], 2, 13, 4],
          'line-pattern': [
            'case',
            ['in', ['get', 'id'], ['literal', hoveredObjectsIds]], 'ihm_line_red',
            'ihm_line',
          ],
        }}
        layout={{
          visibility: visibility?.ihmZone ? 'visible' : 'none',
          'line-cap': 'square',
          'line-join': 'miter',
        }}
        minzoom={11}
        source-layer={OBJECTS_LAYERS.ihmZone}
        filter={['==', ['get', 'modifiable'], false]}
      />
      <Layer
        id={LAYERS_IDS.ihmZoneFill}
        type="fill"
        paint={{
          'fill-color': hoverColor('transparent', hoveredObjectsIds),
          'fill-opacity': 0.2,
        }}
        layout={{
          visibility: visibility?.ihmZone ? 'visible' : 'none',
        }}
        minzoom={11}
        source-layer={OBJECTS_LAYERS.ihmZone}
        filter={['==', ['get', 'modifiable'], false]}
      />
      <Layer
        id={LAYERS_IDS.ihmZoneAmendeeOutline}
        type="line"
        paint={{
          'line-width': ['step', ['zoom'], 2, 13, 4],
          'line-pattern': [
            'case',
            ['in', ['get', 'id'], ['literal', hoveredObjectsIds]], 'ihm_line_red',
            'ihm_line',
          ],
        }}
        layout={{
          visibility: visibility?.ihmZoneAmendee ? 'visible' : 'none',
          'line-cap': 'square',
          'line-join': 'miter',
        }}
        minzoom={11}
        source-layer={OBJECTS_LAYERS.ihmZone}
        filter={['==', ['get', 'modifiable'], true]}
      />
      <Layer
        id={LAYERS_IDS.ihmZoneAmendeeFill}
        type="fill"
        paint={{
          'fill-color': hoverColor('transparent', hoveredObjectsIds),
          'fill-opacity': 0.2,
        }}
        layout={{
          visibility: visibility?.ihmZoneAmendee ? 'visible' : 'none',
        }}
        minzoom={11}
        source-layer={OBJECTS_LAYERS.ihmZone}
        filter={['==', ['get', 'modifiable'], true]}
      />
    </Source>
  )
}

IHMZoneLayer.defaultProps = {
  visibility: {
    ihmZone: true,
    ihmZoneAmendee: true,
  },
  params: {},
}

export default IHMZoneLayer
