import { ReactElement } from 'react'
import { Source, Layer } from 'react-map-gl'
import { LAYERS_IDS, OBJECTS_LAYERS, SOURCES_IDS } from '../common'
import { hoverColor } from '../common/commons'
import { MapURLParams, useMapURL } from '../hooks'

interface Props {
  hoveredObjectsIds: string[]
  view: string
  params: MapURLParams
  opacity?: number
  lineFilter?: string
  visibility: boolean
}

const StationLayer = ({ hoveredObjectsIds, view, params, opacity, lineFilter, visibility }: Props): ReactElement => {
  const url = useMapURL(
    OBJECTS_LAYERS.station, view, SOURCES_IDS.station, params,
  )

  return (
    <Source
      id={SOURCES_IDS.station}
      type="vector"
      url={url}
    >
      <Layer
        type="symbol"
        paint={{
          'icon-opacity': ['case',
            ['in', lineFilter, ['get', 'lrs_ligne']], 1,
            ['in', ['get', 'id'], ['literal', hoveredObjectsIds]], 1,
            opacity],
        }}
        id={LAYERS_IDS.station}
        source-layer={OBJECTS_LAYERS.station}
        layout={{
          'icon-image': ['case',
            ['in', ['get', 'id'], ['literal', hoveredObjectsIds]], 'station_red',
            'station'],
          'icon-size': 0.5,
          'icon-allow-overlap': false,
          visibility: visibility ? 'visible' : 'none',
        }}
        minzoom={9}
      />
      <Layer
        type="symbol"
        minzoom={10}
        paint={{
          'text-color': hoverColor('#1c37fc', hoveredObjectsIds),
          'text-halo-color': '#FFFFFF',
          'text-halo-width': 1,
          'text-opacity': ['case',
            ['in', lineFilter, ['get', 'lrs_ligne']], 1,
            ['in', ['get', 'id'], ['literal', hoveredObjectsIds]], 1,
            opacity],
        }}
        id={LAYERS_IDS.stationLabel}
        source-layer={OBJECTS_LAYERS.station}
        layout={{
          'text-font': ['Open Sans Regular'],
          'text-size': 14,
          'text-anchor': 'left',
          'symbol-placement': 'point',
          'text-allow-overlap': false,
          'text-offset': [1.5, 0],
          'text-field': '{libelle}',
          visibility: visibility ? 'visible' : 'none',
        }}
      />
    </Source>
  )
}

StationLayer.defaultProps = {
  opacity: 1,
  lineFilter: '',
}

export default StationLayer
