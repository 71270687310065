/* eslint-disable camelcase */
import { throttle } from 'lodash'
import { useCallback, useEffect, useRef, useState } from 'react'
import { get } from '@osrdata/app_core/dist/requests'
import { useSelector } from 'react-redux'
import { RootState } from 'store'
import { FeatureCollection, Polygon } from 'geojson'
import nextId from 'react-id-generator'
import moment from 'moment'
import { ViewportProps } from 'react-map-gl'
import { Signal } from '@preact/signals-react'
import {
  CHANTIER_PHASES_MAP, CHANTIER_YEARS_MAP, GEOGRAPHIC_VIEWS, LAYERS_KEYS,
  OBJECTS_LAYERS, SCHEMATIC_VIEWS, SOURCES_IDS,
} from './Layers/common'
import { ChantierType, ChartisJSONFeatures } from './types'
import { MapTheme } from './Toolbar/ThemeMenu/const'

export function usePrevious<T>(value: T) {
  const ref = useRef<T>()
  useEffect(() => {
    ref.current = value
  }, [value])
  return ref.current
}

export const useBorderInfos = () => {
  const [viewportZP, setViewportZP] = useState<string | null>(null)
  const [viewportInfra, setViewportInfra] = useState<string | null>(null)
  const [viewportPRI, setViewportPRI] = useState<string | null>(null)

  return {
    viewportZP,
    setViewportZP,
    viewportInfra,
    setViewportInfra,
    viewportPRI,
    setViewportPRI,
  }
}

function useQueryChartisFeatures<T, R>(
  layer_slug: string,
  layer_format: string,
  layer_view: string,
  viewportSignal: Signal<Partial<ViewportProps>>,
  bboxSignal: Signal<Polygon | null>,
  bboxParam: string,
  filterParams: object,
  visible: boolean,
  minzoom: number,
  getResults: (response: R) => T[],
  dependencies: unknown[] = [],
) {
  const [features, setFeatures] = useState<T[]>([])
  const [loading, setLoading] = useState(false)
  const [requestIds, setRequestIds] = useState<string[]>([])
  const viewport = viewportSignal.value
  const bbox = bboxSignal.value

  const queryJSONFeatures = useCallback(throttle(async (boundingBox: Polygon | null) => {
    if (!viewport || !boundingBox) return
    const id = nextId()
    setRequestIds(prev => [...prev, id])
    const response: R = await get(`/chartis/v2/layer/${layer_slug}/${layer_format}/${layer_view}/`, {
      [bboxParam]: boundingBox,
      ...filterParams,
    })
    setFeatures(getResults(response))
    setRequestIds(prev => prev.filter(reqId => reqId !== id))
  }, 1000), [...dependencies])

  useEffect(() => {
    if (minzoom && viewport.zoom && viewport.zoom < minzoom) {
      setFeatures([])
      return
    }
    if (!visible) {
      setFeatures([])
      return
    }
    queryJSONFeatures(bbox)
  }, [viewport, visible, ...dependencies])

  useEffect(() => {
    setLoading(requestIds.length > 0)
  }, [requestIds])

  return {
    features,
    loading,
  }
}

export const usePostesFeatures = (
  visible: boolean,
  theme: MapTheme,
  viewportSignal: Signal<Partial<ViewportProps>>,
  bboxSignal: Signal<Polygon | null>,
  activePostes?: string[],
  searchParams?: {
    lineFilter: string, lineFilterActive: boolean, dateRangeEnd: moment.Moment, dateRangeStart: moment.Moment
  },
  perimeterGeom?: Polygon,
) => {
  const {
    lineFilter, lineFilterActive, dateRangeEnd, dateRangeStart,
  } = searchParams || {}
  const view = theme === MapTheme.schematic ? SCHEMATIC_VIEWS[SOURCES_IDS.poste] : GEOGRAPHIC_VIEWS[SOURCES_IDS.poste]

  const chartisFeatures = useQueryChartisFeatures(
    OBJECTS_LAYERS.poste,
    'search',
    view as string,
    viewportSignal,
    bboxSignal,
    'bbox',
    {
      // eslint-disable-next-line max-len
      columns: 'id,libelle,lrs_ligne,lrs_pk,region_sncf_id_libelle,gare_libelle,type_installation_fixe_id_libelle,type_installation_fixe_id_mnemo,type_mode_fonctionnement_id_libelle,has_zap',
      size_per_layer: 100,
      ...(activePostes && activePostes.length > 0
        ? { type_installation_fixe_id_mnemo__in: activePostes.join(',') } : {}),
      ...(dateRangeStart && { date_fin_activite__gte_or_null: dateRangeStart.format('YYYY-MM-DD') }),
      ...(dateRangeEnd && { date_debut_activite__lte_or_null: dateRangeEnd.format('YYYY-MM-DD') }),
      ...(lineFilterActive && lineFilter !== '' ? { lrs_ligne__ilike: `%${lineFilter}%` } : {}),
      ...(perimeterGeom && { bpolygon: perimeterGeom }),
    },
    visible,
    10,
    (response: FeatureCollection) => response.features,
    [activePostes, lineFilterActive, lineFilter, dateRangeEnd, dateRangeStart],
  )
  return chartisFeatures
}

export const useChantierFeatures = (
  visible: boolean,
  theme: MapTheme,
  viewportSignal: Signal<Partial<ViewportProps>>,
  bboxSignal: Signal<Polygon | null>,
) => {
  const { activeLayers } = useSelector((state: RootState) => state.map)
  const { lineFilter, lineFilterActive } = useSelector((state: RootState) => state.objectSearch)
  const [activeYears, setActiveYears] = useState<string[]>([])
  const [activePhases, setActivePhases] = useState<string[]>([])
  const [filterASTRE, setFilterASTRE] = useState(false)
  const [filterSEISM, setFilterSEISM] = useState(false)
  const bboxParam = theme === MapTheme.schematic ? 'geom_rgi_track_sch_flat__bpolygon' : 'geom_rgi_track_geo__bpolygon'

  useEffect(() => {
    const visibleYears = Object.entries(CHANTIER_YEARS_MAP)
      .filter(([key]) => activeLayers.includes(key)).map(([, value]) => value)
    setActiveYears(visibleYears)

    const visiblePhases = Object.entries(CHANTIER_PHASES_MAP)
      .filter(([key]) => activeLayers.includes(key)).flatMap(([, value]) => value)
    setActivePhases(visiblePhases)

    setFilterASTRE(activeLayers.includes(LAYERS_KEYS.chantierASTRE))
    setFilterSEISM(activeLayers.includes(LAYERS_KEYS.chantierSEISM))
  }, [activeLayers])

  const chartisFeatures = useQueryChartisFeatures(
    OBJECTS_LAYERS.chantier,
    'json',
    'scrolling_list',
    viewportSignal,
    bboxSignal,
    bboxParam,
    {
      annee__in: activeYears.join(','),
      phase__in: activePhases.join(','),
      ...(filterASTRE && { numero_astre__isnull: false }),
      ...(filterSEISM && { seism_project_id__isnull: false }),
      page_size: 100,
      ...(lineFilterActive && lineFilter !== '' ? { lrs_ligne__ilike: `%${lineFilter}%` } : {}),
    },
    visible,
    10,
    (response : ChartisJSONFeatures<ChantierType>) => response.results,
    [activeYears, activePhases, lineFilterActive, lineFilter],
  )
  return chartisFeatures
}

export const useBalZoneFeatures = (
  visible: boolean,
  theme: MapTheme,
  viewportSignal: Signal<Partial<ViewportProps>>,
  bboxSignal: Signal<Polygon | null>,
) => {
  const { activeLayers } = useSelector((state: RootState) => state.map)
  const { lineFilter, lineFilterActive } = useSelector((state: RootState) => state.objectSearch)
  const [activeZone, setActiveZone] = useState(false)
  const view = theme === MapTheme.schematic
    ? SCHEMATIC_VIEWS[SOURCES_IDS.balZone] : GEOGRAPHIC_VIEWS[SOURCES_IDS.balZone]

  useEffect(() => {
    setActiveZone(activeLayers.includes(LAYERS_KEYS.balZone))
  }, [activeLayers])

  const chartisFeatures = useQueryChartisFeatures(
    OBJECTS_LAYERS.balZone,
    'search',
    view as string,
    viewportSignal,
    bboxSignal,
    'bbox',
    {
      columns: 'id,libelle,lrs_ligne,lrs_pk',
      size_per_layer: 100,
      ...(lineFilterActive && lineFilter !== '' ? { lrs_ligne__ilike: `%${lineFilter}%` } : {}),
    },
    visible,
    10,
    (response : FeatureCollection) => response.features,
    [activeZone, lineFilterActive, lineFilter],
  )
  return chartisFeatures
}

export default {
  useBorderInfos,
  usePostesFeatures,
  useChantierFeatures,
  useBalZoneFeatures,
}
