/* eslint-disable camelcase */
import { MapTheme } from 'components/Toolbar/ThemeMenu/const'
import MAIN_API from 'config/config'
import { isEqual } from 'lodash'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'store'
import { addSourceToUpdate } from 'store/map'
import { DexCartoSourceKey, GEOGRAPHIC_VIEWS, SCHEMATIC_VIEWS } from './common'

export type MapURLParams = {
  [key: string]: string
}

export const useMapURL = (
  layer: string,
  type: string,
  sourceId: string,
  urlParams?: MapURLParams,
) => {
  const dispatch = useDispatch()
  const [url, setUrl] = useState<string>(`${MAIN_API.proxy}/chartis/v2/layer/${layer}/mvt/${type}/`)
  const [params, setParams] = useState<MapURLParams>(urlParams || {})

  useEffect(() => {
    if (urlParams && !isEqual(urlParams, params)) {
      setParams(urlParams)
      dispatch(addSourceToUpdate({
        name: layer,
        sourceId,
        type,
        params: urlParams,
      }))
    }
  }, [urlParams])

  useEffect(() => {
    setUrl(`${MAIN_API.proxy}/chartis/v2/layer/${layer}/mvt/${type}/?${new URLSearchParams(params)}`)
  }, [params])

  return url
}

export const useDateFilterParams = () => {
  const { dateRangeEnd, dateRangeStart } = useSelector((state: RootState) => state.objectSearch)
  const [params, setParams] = useState<MapURLParams>({})

  useEffect(() => {
    const newParams = {
      date_fin_activite__gte_or_null: dateRangeStart.format('YYYY-MM-DD'),
      date_debut_activite__lte_or_null: dateRangeEnd.format('YYYY-MM-DD'),
    }
    setParams(newParams)
  }, [dateRangeStart, dateRangeEnd])

  return params
}

export const useLineFilterOpacity = () => {
  const { lineFilterActive, lineFilter } = useSelector((state: RootState) => state.objectSearch)
  const [opacity, setOpacity] = useState<number>(1)

  useEffect(() => {
    if (lineFilterActive && lineFilter !== '') {
      setOpacity(0.15)
    } else {
      setOpacity(1)
    }
  }, [lineFilterActive, lineFilter])

  return { opacity, lineFilter }
}

export const useMapView = (
  sourceId: DexCartoSourceKey,
) => {
  const { currentOsmTheme } = useSelector((state: RootState) => state.map)
  let response
  switch (currentOsmTheme) {
    case MapTheme.geographic:
      response = GEOGRAPHIC_VIEWS[sourceId]
      break
    case MapTheme.schematic:
      response = SCHEMATIC_VIEWS[sourceId]
      break
    default:
      response = ''
  }
  return response ?? ''
}
