import { RefObject, useEffect } from 'react'
import { Layer, Source, MapRef } from 'react-map-gl'
import { mapViewportSignal } from 'views/Map'
import { LAYERS_IDS, LAYERS_KEYS, SOURCES_IDS } from '../common'
import { getDistinctInfrapoleCentroid } from './utils'
import { useCalculateLabelPositions } from './hooks'

interface Props {
  visible: boolean
  mapRef: RefObject<MapRef | undefined>
  setViewportInfra: (infrapoles: string | null) => void
  principalLayer: string | null
}

const InfrapoleLabelLayer = ({ visible, mapRef, setViewportInfra, principalLayer }: Props) => {
  const labelPositions = useCalculateLabelPositions(
    mapRef, visible, LAYERS_IDS.infrapole, getDistinctInfrapoleCentroid, mapViewportSignal.value,
  )

  useEffect(() => {
    if (labelPositions.features.length >= 1 && mapViewportSignal.value.zoom && mapViewportSignal.value.zoom >= 12) {
      setViewportInfra(labelPositions.features.map(
        feature => feature.properties?.libelle.replace('Siège INFRAPOLE ', ''),
      ).sort((a, b) => a.localeCompare(b)).join(', '))
    } else {
      setViewportInfra(null)
    }
  }, [labelPositions.features])

  return (
    <Source
      id={SOURCES_IDS.infrapoleLabel}
      type="geojson"
      data={labelPositions}
    >
      <Layer
        type="symbol"
        id={LAYERS_IDS.infrapoleLabel}
        source={SOURCES_IDS.infrapoleLabel}
        layout={{
          'text-font': ['Open Sans Regular'],
          'text-field': '{libelle}',
          'text-size': 16,
          'text-variable-anchor': ['top', 'bottom', 'left', 'right'],
          'text-offset': [0, 1],
          visibility: visible ? 'visible' : 'none',
        }}
        paint={{
          'text-color': ['get', 'infrapole_color'],
          'text-halo-color': '#000000',
          'text-halo-width': 0.1,
        }}
        maxzoom={principalLayer === LAYERS_KEYS.infrapole ? 24 : 12}
      />
    </Source>
  )
}

export default InfrapoleLabelLayer
