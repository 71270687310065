import { createSlice, isAnyOf, PayloadAction } from '@reduxjs/toolkit'
import { MapTheme } from 'components/Toolbar/ThemeMenu/const'
import { LAYERS_KEYS, OBJECTS_LAYERS, SCHEMATIC_VIEWS, SOURCES_IDS } from 'components/Layers/common'
import { GEO_LAYER_KEYS, SCH_LAYER_KEYS } from 'components/Layers/common/const/menuKeys'
import { MapState, SourceWithType } from './types'
import { deletePosteZone, patchPosteZone, postPosteZone } from './zoneEditor/zoneEditor.thunk'

const initialLayers = [
  LAYERS_KEYS.track, LAYERS_KEYS.station, LAYERS_KEYS.ccPoste, LAYERS_KEYS.argosPoste, LAYERS_KEYS.paiPoste,
  LAYERS_KEYS.pipcPoste, LAYERS_KEYS.prciPoste, LAYERS_KEYS.prsPoste, LAYERS_KEYS.prgPoste, LAYERS_KEYS.prmiPoste,
  LAYERS_KEYS.plitPoste, LAYERS_KEYS.peliPoste, LAYERS_KEYS.pelirPoste, LAYERS_KEYS.pmlPoste,
  LAYERS_KEYS.emuPoste, LAYERS_KEYS.mu45Poste, LAYERS_KEYS.mrsaxbyPoste, LAYERS_KEYS.mrlevdrPoste,
  LAYERS_KEYS.mrexalPoste, LAYERS_KEYS.mrvigntyPoste, LAYERS_KEYS.ssdvPoste, LAYERS_KEYS.ssvuvbPoste,
  LAYERS_KEYS.pvstriPoste, LAYERS_KEYS.pvselecPoste, LAYERS_KEYS.pvseiPoste, LAYERS_KEYS.pvsciPoste,
  LAYERS_KEYS.pvsmecPoste, LAYERS_KEYS.zoneActionPoste, LAYERS_KEYS.ihmZone, LAYERS_KEYS.balZone,
]

const initialState: MapState = {
  currentOsmTheme: MapTheme.schematic,
  currentBorderType: null,
  activeLayers: initialLayers,
  sourcesToUpdate: [],
}

export const mapSlice = createSlice({
  name: 'mapSlice',
  initialState,
  reducers: {
    updateTheme: (state, action: PayloadAction<MapTheme>) => {
      state.currentOsmTheme = action.payload
      if (action.payload === MapTheme.schematic) {
        state.activeLayers = state.activeLayers.filter(layer => Object.values(SCH_LAYER_KEYS).includes(layer))
      } else if (action.payload === MapTheme.geographic) {
        state.activeLayers = state.activeLayers.filter(layer => Object.values(GEO_LAYER_KEYS).includes(layer))
      }
    },
    addActiveLayer: (state, action: PayloadAction<string>) => {
      state.activeLayers.push(action.payload)
    },
    removeActiveLayer: (state, action: PayloadAction<string>) => {
      state.activeLayers = state.activeLayers.filter(layer => layer !== action.payload)
    },
    setActiveLayers: (state, action: PayloadAction<string[]>) => {
      state.activeLayers = action.payload
    },
    setSourcesToUpdate: (state, action: PayloadAction<SourceWithType[]>) => {
      state.sourcesToUpdate = action.payload
    },
    addSourceToUpdate: (state, action: PayloadAction<SourceWithType>) => {
      state.sourcesToUpdate = [...state.sourcesToUpdate, action.payload]
    },
    removeSourcesToUpdate: (state, action: PayloadAction<SourceWithType[]>) => {
      const sourcesId = action.payload.map(source => source.sourceId)
      state.sourcesToUpdate = state.sourcesToUpdate.filter(source => !sourcesId.includes(source.sourceId))
    },
  },
  extraReducers: builder => {
    builder.addMatcher(isAnyOf(postPosteZone.fulfilled, deletePosteZone.fulfilled, patchPosteZone.fulfilled), state => {
      state.sourcesToUpdate = [...state.sourcesToUpdate, {
        name: OBJECTS_LAYERS.actionZonePoste,
        sourceId: SOURCES_IDS.actionZone,
        type: SCHEMATIC_VIEWS[SOURCES_IDS.actionZone] || '',
        params: {},
      },
      {
        name: OBJECTS_LAYERS.actionZonePoste,
        sourceId: SOURCES_IDS.actionZoneLabel,
        type: SCHEMATIC_VIEWS[SOURCES_IDS.actionZoneLabel] || '',
        params: {},
      },
      {
        name: OBJECTS_LAYERS.ihmZone,
        sourceId: SOURCES_IDS.ihmZone,
        type: SCHEMATIC_VIEWS[SOURCES_IDS.ihmZone] || '',
        params: {},
      },
      {
        name: OBJECTS_LAYERS.ihmZone,
        sourceId: SOURCES_IDS.ihmZoneLabel,
        type: SCHEMATIC_VIEWS[SOURCES_IDS.ihmZoneLabel] || '',
        params: {},
      },
      ]
    })
  },
})

export const {
  updateTheme, addActiveLayer, removeActiveLayer, setActiveLayers,
  setSourcesToUpdate, addSourceToUpdate, removeSourcesToUpdate,
} = mapSlice.actions

export default mapSlice.reducer
