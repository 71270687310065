import { Train } from '@mui/icons-material'
import { Autocomplete, TextField, Tooltip } from '@mui/material'
import terms from 'common/terms'
import { MapTheme } from 'components/Toolbar/ThemeMenu/const'
import { SyntheticEvent, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'store'
import { setLineFilter } from 'store/objectSearch/objectSearch'
import { searchLineObjects } from 'store/objectSearch/objectSearch.thunk'
import { GaiaLine } from 'store/objectSearch/types'
import { GEO_DEFAULT_VIEWPORT, SCH_DEFAULT_VIEWPORT, mapViewportSignal } from 'views/Map'

const LineSearch = () => {
  const dispatch = useDispatch()
  const { lineSearch: text } = terms.objectSearch
  const { lineSearchResults, lineSearchPending } = useSelector((state: RootState) => state.objectSearch)
  const { currentOsmTheme } = useSelector((state: RootState) => state.map)
  const DEFAULT_VIEWPORT = currentOsmTheme === MapTheme.schematic ? SCH_DEFAULT_VIEWPORT : GEO_DEFAULT_VIEWPORT
  const [active, setActive] = useState(false)
  const [value, setValue] = useState<GaiaLine | null>(null)
  const [inputValue, setInputValue] = useState('')
  const [options, setOptions] = useState<GaiaLine[]>([])

  useEffect(() => {
    if (inputValue.length >= 3) {
      dispatch(searchLineObjects(inputValue))
    } else {
      setOptions([])
    }
  }, [inputValue])

  useEffect(() => {
    setOptions(lineSearchResults)
  }, [lineSearchResults])

  useEffect(() => {
    dispatch(setLineFilter({
      line: value?.code || '',
      active,
    }))
  }, [active, value])

  const handleInputChange = (_:SyntheticEvent, newValue: string) => {
    if (newValue.length <= 6) {
      setInputValue(newValue)
    }
  }

  const handleSelectLine = (_: SyntheticEvent, newValue: GaiaLine | null) => {
    setValue(newValue)
    setActive(!!newValue)
    if (newValue) {
      mapViewportSignal.value = { ...DEFAULT_VIEWPORT, transitionDuration: 0 }
    }
  }

  return (
    <div className="line-filter">
      <Tooltip title={text.filterLine}>
        <button
          type="button"
          className={`line-button ${active ? 'active' : ''}`}
          onClick={() => setActive(prev => !prev)}
        >
          <Train />
        </button>
      </Tooltip>
      <Autocomplete
        disablePortal
        options={options}
        filterOptions={x => x}
        sx={{
          width: 110,
          '& button.MuiButtonBase-root': {
            visibility: 'visible',
          },
        }}
        value={value}
        inputValue={inputValue}
        size="small"
        loading={lineSearchPending}
        loadingText={text.loading}
        noOptionsText={inputValue.length >= 3 ? text.noResult : text.tooSmall}
        onChange={handleSelectLine}
        onInputChange={handleInputChange}
        renderInput={params => (<TextField {...params} label={text.line} fullWidth />)}
        renderOption={(props, option) => (
          <li {...props} key={option.id}>
            <Tooltip title={option.libelle} placement="right">
              <span>
                {`${text.line} ${option.code} - ${text.pkFrom} ${option.pk_debut} ${text.pkTo} ${option.pk_fin}`}
              </span>
            </Tooltip>
          </li>
        )}
        getOptionLabel={option => option.code}
        isOptionEqualToValue={(opt, val) => opt.id === val.id}
      />
    </div>
  )
}

export default LineSearch
