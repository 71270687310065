import { perimeterActiveLayersSignal, perimeterHighlightedIdSignal } from 'views/Perimeter/PerimeterView/PerimeterView'
import { UserStudyPerimeterDetailsWithId } from 'store/userFavorite/types'
import { useMemo } from 'react'
import {
  DexCartoSourceKey, DexcartoLayerKey, GEOGRAPHIC_VIEWS, LAYERS_KEYS, OBJECTS_LAYERS, POSTES_LAYER_KEYS,
  SIGNAL_LAYERS_KEYS, SOURCES_IDS,
} from './common'
import { getChantierFilter, getCollectionStatusProps, getPosteFilter, getSignalFilter, getTrackFilter } from './utils'
import {
  ADVLayer, ADVLineLayer, AccesLayer, BFLayer, CDVLayer, DBCLayer, JDZLayer, LocalisateurLayer, PassageNiveauLayer,
  PedaleLayer, RacLayer, SignalLayer, SousStationLayer, StationLayer, TrackLabelLayer, TrackLayer,
  CentraleSousStationLayer,
  PosteLayer,
  CenterLayer,
  BALZoneLayer,
  ActionZonePosteLayer,
  IHMZoneLayer,
  ActionZonePosteLibelleLayer,
  IHMZoneLabelLayer,
  ChantierLayer,
  CenterArmenLayer,
  ExploitationModeLayer,
} from '.'

const getView = (sourceId: DexCartoSourceKey) => GEOGRAPHIC_VIEWS[sourceId] ?? ''

interface Props {
  activePerimeter: UserStudyPerimeterDetailsWithId
}

const PerimeterGeoLayers = ({ activePerimeter }: Props) => {
  const getVisibility = (layerKey: DexcartoLayerKey) => perimeterActiveLayersSignal.value.includes(layerKey)

  const isLayerActive = (layer: string) => perimeterActiveLayersSignal.value.includes(layer)
  const signalFilter = getSignalFilter(isLayerActive)
  const posteFilter = getPosteFilter(isLayerActive)
  const chantierFilter = getChantierFilter(isLayerActive)
  const collectionStatusProps = getCollectionStatusProps(isLayerActive)

  const trackFilter = useMemo(() => getTrackFilter(activePerimeter), [activePerimeter])

  const bpolygonParam = { geom_rgi_track_sch_flat__bpolygon: JSON.stringify(activePerimeter.geometry) }

  return (
    <>
      <ExploitationModeLayer
        activeLayers={perimeterActiveLayersSignal.value}
        view={getView(SOURCES_IDS.exploitationMode)}
        params={bpolygonParam}
      />
      <TrackLayer
        sourceLayer={OBJECTS_LAYERS.trackGeo}
        trackFilter={trackFilter}
      />
      <JDZLayer
        hoveredObjectsIds={perimeterHighlightedIdSignal.value}
        view={getView(SOURCES_IDS.jdz)}
        params={bpolygonParam}
        angle="angle_rgi_track_geo"
        visibility={getVisibility(LAYERS_KEYS.jdz)}
      />
      <BALZoneLayer
        hoveredObjectsIds={perimeterHighlightedIdSignal.value}
        view={getView(SOURCES_IDS.balZone)}
        params={bpolygonParam}
        visibility={getVisibility(LAYERS_KEYS.balZone)}
      />
      <ActionZonePosteLayer
        hoveredObjectsIds={perimeterHighlightedIdSignal.value}
        view={getView(SOURCES_IDS.actionZone)}
        params={bpolygonParam}
        visibility={{
          zoneActionPoste: getVisibility(LAYERS_KEYS.zoneActionPoste),
          zoneActionPosteAmendee: getVisibility(LAYERS_KEYS.zoneActionPosteAmendee),
        }}
      />
      <IHMZoneLayer
        hoveredObjectsIds={perimeterHighlightedIdSignal.value}
        view={getView(SOURCES_IDS.ihmZone)}
        params={bpolygonParam}
        visibility={{
          ihmZone: getVisibility(LAYERS_KEYS.ihmZone),
          ihmZoneAmendee: getVisibility(LAYERS_KEYS.ihmZoneAmendee),
        }}
      />
      <ChantierLayer
        view={getView(SOURCES_IDS.chantier)}
        hoveredObjectsIds={perimeterHighlightedIdSignal.value}
        params={bpolygonParam}
        chantierFilter={chantierFilter}
      />
      <ADVLineLayer
        hoveredObjectsIds={perimeterHighlightedIdSignal.value}
        view={getView(SOURCES_IDS.advLine)}
        params={bpolygonParam}
        visibility={getVisibility(LAYERS_KEYS.adv)}
      />
      <TrackLabelLayer sourceLayer={OBJECTS_LAYERS.trackGeo} trackFilter={trackFilter} />
      <RacLayer
        hoveredObjectsIds={perimeterHighlightedIdSignal.value}
        view={getView(SOURCES_IDS.rac)}
        params={bpolygonParam}
        visibility={getVisibility(LAYERS_KEYS.rac)}
      />
      <ADVLayer
        hoveredObjectsIds={perimeterHighlightedIdSignal.value}
        view={getView(SOURCES_IDS.adv)}
        params={bpolygonParam}
        visibility={getVisibility(LAYERS_KEYS.adv)}
      />
      <CDVLayer
        hoveredObjectsIds={perimeterHighlightedIdSignal.value}
        view={getView(SOURCES_IDS.cdv)}
        params={bpolygonParam}
        visibility={getVisibility(LAYERS_KEYS.cdv)}
      />
      <SignalLayer
        hoveredObjectsIds={perimeterHighlightedIdSignal.value}
        view={getView(SOURCES_IDS.signal)}
        params={bpolygonParam}
        angle="angle_rgi_track_geo"
        signalFilter={signalFilter}
        visibility={SIGNAL_LAYERS_KEYS.some(key => getVisibility(key))}
      />
      <StationLayer
        hoveredObjectsIds={perimeterHighlightedIdSignal.value}
        view={getView(SOURCES_IDS.station)}
        params={bpolygonParam}
        visibility={getVisibility(LAYERS_KEYS.station)}
      />
      <PosteLayer
        hoveredObjectsIds={perimeterHighlightedIdSignal.value}
        view={getView(SOURCES_IDS.poste)}
        params={{ geom_rgi_track_sch_flat_centroid__bpolygon: JSON.stringify(activePerimeter.geometry) }}
        posteFilter={posteFilter}
        posteVisibility={Object.values(POSTES_LAYER_KEYS).some(key => getVisibility(key))}
      />
      <AccesLayer
        hoveredObjectsIds={perimeterHighlightedIdSignal.value}
        view={getView(SOURCES_IDS.acces)}
        params={bpolygonParam}
        visibility={getVisibility(LAYERS_KEYS.acces)}
      />
      <BFLayer
        hoveredObjectsIds={perimeterHighlightedIdSignal.value}
        view={getView(SOURCES_IDS.bf)}
        params={{ ...bpolygonParam, code_ch: 'BF' }}
        visibility={getVisibility(LAYERS_KEYS.bf)}
      />
      <SousStationLayer
        view={getView(SOURCES_IDS.sousStation)}
        params={bpolygonParam}
        visibility={getVisibility(LAYERS_KEYS.sousStation)}
      />
      <CentraleSousStationLayer
        view={getView(SOURCES_IDS.css)}
        params={bpolygonParam}
        visibility={getVisibility(LAYERS_KEYS.css)}
      />
      <LocalisateurLayer
        hoveredObjectsIds={perimeterHighlightedIdSignal.value}
        view={getView(SOURCES_IDS.localisateur)}
        params={bpolygonParam}
        visibility={getVisibility(LAYERS_KEYS.localisateur)}
      />
      <DBCLayer
        hoveredObjectsIds={perimeterHighlightedIdSignal.value}
        view={getView(SOURCES_IDS.dbc)}
        params={bpolygonParam}
        visibility={getVisibility(LAYERS_KEYS.dbc)}
        angle="angle_rgi_track_geo"
      />
      <PedaleLayer
        hoveredObjectsIds={perimeterHighlightedIdSignal.value}
        view={getView(SOURCES_IDS.pedale)}
        params={bpolygonParam}
        visibility={getVisibility(LAYERS_KEYS.pedale)}
      />
      <PassageNiveauLayer
        hoveredObjectsIds={perimeterHighlightedIdSignal.value}
        view={getView(SOURCES_IDS.passageNiveau)}
        params={bpolygonParam}
        visibility={getVisibility(LAYERS_KEYS.passageNiveau)}
        angle="angle_rgi_track_geo"
      />
      <CenterLayer
        hoveredObjectsIds={perimeterHighlightedIdSignal.value}
        view={getView(SOURCES_IDS.center)}
        params={bpolygonParam}
        visibility={getVisibility(LAYERS_KEYS.center)}
      />
      <CenterArmenLayer
        hoveredObjectsIds={perimeterHighlightedIdSignal.value}
        view={getView(SOURCES_IDS.centerArmen)}
        params={bpolygonParam}
        visibility={getVisibility(LAYERS_KEYS.centerArmen)}
      />
      <ActionZonePosteLibelleLayer
        hoveredObjectsIds={perimeterHighlightedIdSignal.value}
        view={getView(SOURCES_IDS.actionZoneLabel)}
        params={{ geom_rgi_track_sch_flat__bpolygon: JSON.stringify(activePerimeter.geometry) }}
        visibility={{
          zoneActionPoste: getVisibility(LAYERS_KEYS.zoneActionPoste),
          zoneActionPosteAmendee: getVisibility(LAYERS_KEYS.zoneActionPosteAmendee),
        }}
        collectionStatusProps={collectionStatusProps}
      />
      <IHMZoneLabelLayer
        hoveredObjectsIds={perimeterHighlightedIdSignal.value}
        view={getView(SOURCES_IDS.ihmZoneLabel)}
        params={{ geom_rgi_track_sch_flat__bpolygon: JSON.stringify(activePerimeter.geometry) }}
        visibility={{
          ihmZone: getVisibility(LAYERS_KEYS.ihmZone),
          ihmZoneAmendee: getVisibility(LAYERS_KEYS.ihmZoneAmendee),
        }}
      />
    </>
  )
}

export default PerimeterGeoLayers
