import { InfoOutlined } from '@mui/icons-material'
import terms from 'common/terms'
import { ReactElement } from 'react'
import './ActionSnackbar.scss'

interface Props {
  title: string
  message: string
  handleCancel: () => void
  handleValidate?: () => void
  disableValidate?: boolean
}

const ActionSnackbar = ({ title, message, handleValidate, handleCancel, disableValidate }: Props): ReactElement => (
  <div className="action-snackbar">
    <InfoOutlined className="info-icon" />
    <div className="message">
      <strong>{title}</strong>
      <span>{message}</span>
    </div>
    <div className="actions">
      {handleValidate && (
      <button type="button" className="validate" disabled={disableValidate} onClick={handleValidate}>
        {terms.zoneEditor.validate}
      </button>
      )}
      <button type="button" className="cancel" onClick={handleCancel}>
        {terms.zoneEditor.cancel}
      </button>
    </div>
  </div>
)

ActionSnackbar.defaultProps = {
  handleValidate: undefined,

  disableValidate: false,
}

export default ActionSnackbar
