import { FormControl, TextField } from '@mui/material'
import './UserFeedback.scss'
import terms from 'common/terms'

interface Props {
  typeDescription: string
  description: string
  inputContent: string
  setInputContent: (value: string) => void
  inputObject: string
  setInputObject: (value: string) => void
}

const UserFeedBack = ({
  typeDescription, description, inputContent, setInputContent, inputObject, setInputObject,
}: Props) => (
  <div className="user-feedback">
    <p id="parent-modal-description">
      {typeDescription}
    </p>
    <div className="inputs">
      <input
        type="text"
        value={inputObject}
        onChange={e => setInputObject(e.target.value)}
        style={{ marginBottom: '30px' }}
      />
      <p id="parent-modal-description">
        {description}
      </p>
      <FormControl fullWidth>
        <TextField
          id="outlined-controlled"
          fullWidth
          multiline
          rows={5}
          value={inputContent}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setInputContent(event.target.value)
          }}
        />
      </FormControl>
    </div>
    <div className={`count ${
      inputContent.length > parseInt('1500', 10)
        ? 'feedback-warning'
        : ''
    }`}
    >
      <span>{`${inputContent.length}/${terms.UserFeedback.maxChar}`}</span>
    </div>
  </div>
)

export default UserFeedBack
