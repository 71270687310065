import { FEATURES_SOURCES, SOURCES_IDS } from 'components/Layers/common'
import { FeatureLayer } from 'components/MapPopup/utils'
import { MapTheme } from 'components/Toolbar/ThemeMenu/const'
import { useCallback, useState } from 'react'
import { MapEvent } from 'react-map-gl'
import { FeatureData, getFeatureData } from 'views/Map/utils'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'store'
import { setSelectedPoste } from 'store/zoneEditor/zoneEditor'
import { perimeterHoveredIdSignal } from './PerimeterView'

export const useHoverEvent = () => {
  const [hoveredEvent, setHoveredEvent] = useState<MapEvent | undefined>(undefined)

  const onHover = (e: MapEvent) => {
    if (!e.target.classList.contains('overlays')) return
    if (e.features && e.features.length >= 1) {
      setHoveredEvent(e)
      const featureId = e.features.map(feature => feature.properties.id).find(id => id !== undefined)
      perimeterHoveredIdSignal.value = featureId ? [featureId] : []
    } else if (hoveredEvent || perimeterHoveredIdSignal.value.length > 0) {
      setHoveredEvent(undefined)
      perimeterHoveredIdSignal.value = []
    }
  }

  return [onHover, hoveredEvent, setHoveredEvent] as const
}
 type SelectedFeatures = {
  longitude: number | null
  latitude: number | null
  features: FeatureLayer[]
  featureData: FeatureData
  showPopup: boolean
  showMultiPopup: boolean
}

const initialSelectedFeatures: SelectedFeatures = {
  latitude: null,
  longitude: null,
  features: [],
  featureData: {
    postes: [],
    collections: {},
    history: [],
    neighbouringZones: { zone_action_poste: [], zone_bal: [] },
    rcData: {},
  },
  showPopup: false,
  showMultiPopup: false,
}

export const useFeatureClick = (theme: MapTheme, setSelectedObjectsIds: (ids: string[]) => void) => {
  const [selectedFeatures, setSelectedFeatures] = useState<SelectedFeatures>(initialSelectedFeatures)
  const { isSelectingPoste, editorStep } = useSelector((state: RootState) => state.zoneEditor)
  const dispatch = useDispatch()

  const handleClickFeature = async (feature: FeatureLayer) => {
    await getFeatureData(feature, theme).then(featureData => {
      setSelectedFeatures(prev => ({
        ...prev,
        featureData,
        features: [feature],
        showPopup: true,
        showMultiPopup: false,
      }))
    })
  }
  const onFeatureClick = (e: MapEvent) => {
    if (!e.target.classList.contains('overlays')) return
    setSelectedObjectsIds([])
    if (!(e.srcEvent instanceof TouchEvent) && e.srcEvent.button === 2) return
    if (isSelectingPoste) {
      const posteFeatures = e.features?.filter(feature => feature.layer.source === SOURCES_IDS.poste) || []
      if (posteFeatures[0]) {
        dispatch(setSelectedPoste(posteFeatures[0].properties))
      }
      return
    }
    if (editorStep) return

    const featuresSet = new Set()
    const relevantFeatures = e.features?.reduce((acc, feature) => {
      if (!FEATURES_SOURCES.includes(feature.layer.source)) return acc
      const featureId = `${feature.properties.id}-${feature.layer.sourceLayer}`
      if (featuresSet.has(featureId)) return acc
      featuresSet.add(featureId)
      return [...acc, feature]
    }, [])
    if (relevantFeatures.length === 0) {
      setSelectedFeatures(initialSelectedFeatures)
      return
    }
    if (relevantFeatures.length === 1) {
      const feature = relevantFeatures[0]
      setSelectedFeatures({
        latitude: e.lngLat[1],
        longitude: e.lngLat[0],
        features: [],
        featureData: {
          postes: [],
          collections: {},
          history: [],
          neighbouringZones: { zone_action_poste: [], zone_bal: [] },
          rcData: {},
        },
        showPopup: false,
        showMultiPopup: false,
      })
      handleClickFeature(feature)
    } else {
      setSelectedFeatures({
        latitude: e.lngLat[1],
        longitude: e.lngLat[0],
        features: relevantFeatures,
        featureData: {
          postes: [],
          collections: {},
          history: [],
          neighbouringZones: { zone_action_poste: [], zone_bal: [] },
          rcData: {},
        },
        showPopup: false,
        showMultiPopup: true,
      })
    }
  }

  const handlePopupClose = useCallback(() => {
    setSelectedFeatures(initialSelectedFeatures)
  }, [])

  const handleMultiPopupClose = useCallback(() => {
    setSelectedFeatures(prev => ({ ...prev, showMultiPopup: false }))
  }, [])

  return [selectedFeatures, onFeatureClick, handlePopupClose, handleMultiPopupClose, handleClickFeature] as const
}
