/* eslint-disable camelcase */
import terms from 'common/terms'
import { LAYERS_IDS, SOURCES_IDS } from 'components/Layers/common'
import moment from 'moment'
import { LayerProps } from 'react-map-gl'
import { FeatureData, GeoReference } from 'views/Map/utils'
import { FeatureProperties } from './types'

export type FeatureLayer = {
  layer: LayerProps
  properties: GeoJSON.Feature<GeoJSON.Geometry>['properties']
  geometry: GeoJSON.Feature<GeoJSON.Geometry>['geometry']
  source: typeof SOURCES_IDS[keyof typeof SOURCES_IDS]
}
// remove '"', {' and '}' from string
const cleanString = (str: string) => str.replace(/"|{|}/g, '')

const getObjectType = (feature: FeatureLayer) => {
  switch (feature.source) {
    case SOURCES_IDS.signal:
    case SOURCES_IDS.localisateur:
    case SOURCES_IDS.passageNiveau:
    case SOURCES_IDS.acces:
      return feature.properties?.type_installation_fixe_id_libelle || 'NC'
    case SOURCES_IDS.dbc:
    case SOURCES_IDS.poste:
    case SOURCES_IDS.pedale:
      return feature.properties?.type_installation_fixe_id_mnemo || 'NC'
    case SOURCES_IDS.sousStation:
      return cleanString(feature.properties?.type_electrifications_id_libelle) || 'NC'
    case SOURCES_IDS.adv:
    case SOURCES_IDS.advLine:
      return feature.properties?.type_appareil_de_voie_id_mnemonique || 'NC'
    default:
      return null
  }
}

export const getTitle = (feature: FeatureLayer) => {
  switch (feature.layer.id) {
    case LAYERS_IDS.signalCircle:
    case LAYERS_IDS.signalMat:
    case LAYERS_IDS.signalPicto:
    case LAYERS_IDS.signalLabel:
    case LAYERS_IDS.signalHighlight:
      return { name: terms.featuresPopup.title.signal, value: '' }
    case LAYERS_IDS.station:
    case LAYERS_IDS.stationLabel:
      return { name: terms.featuresPopup.title.station, value: '' }
    case LAYERS_IDS.poste:
    case LAYERS_IDS.posteCircle:
      return { name: terms.featuresPopup.title.poste, value: '' }
    case LAYERS_IDS.jdz:
      return { name: terms.featuresPopup.title.jdz, value: '' }
    case LAYERS_IDS.accesCircle:
    case LAYERS_IDS.accesLabel:
    case LAYERS_IDS.accesHighlight:
      return { name: terms.featuresPopup.title.acces, value: '' }
    case LAYERS_IDS.sousStationSch:
    case LAYERS_IDS.sousStationGeo:
      return { name: terms.featuresPopup.title.sousStation, value: '' }
    case LAYERS_IDS.cssSch:
    case LAYERS_IDS.cssGeo:
      return { name: terms.featuresPopup.title.centraleSousStation, value: '' }
    case LAYERS_IDS.localisateurCircle:
    case LAYERS_IDS.localisateurLabel:
    case LAYERS_IDS.localisateurHighlight:
      return { name: terms.featuresPopup.title.localisateur, value: '' }
    case LAYERS_IDS.dbc:
      return { name: terms.featuresPopup.title.dbc, value: '' }
    case LAYERS_IDS.pedaleCircle:
    case LAYERS_IDS.pedaleLabel:
    case LAYERS_IDS.pedaleHighlight:
      return { name: terms.featuresPopup.title.pedale, value: '' }
    case LAYERS_IDS.passageNiveauIcon:
    case LAYERS_IDS.passageNiveauLabel:
      return { name: terms.featuresPopup.title.passageNiveau, value: '' }
    case LAYERS_IDS.zoneActionPoste:
    case LAYERS_IDS.zoneActionPosteLabel:
      return { name: terms.featuresPopup.title.zone, value: '' }
    case LAYERS_IDS.zoneActionPosteAmendee:
    case LAYERS_IDS.zoneActionPosteAmendeeLabel:
    case LAYERS_IDS.collectionStatusCircle:
      return { name: terms.featuresPopup.title.zoneAmendee, value: '' }
    case LAYERS_IDS.advCircle:
    case LAYERS_IDS.advLabel:
    case LAYERS_IDS.advLine:
    case LAYERS_IDS.advHighlight:
      return { name: terms.featuresPopup.title.adv, value: '' }
    case LAYERS_IDS.chantierLine:
    case LAYERS_IDS.chantierLineHighlight:
    case LAYERS_IDS.chantierPointHighlight:
    case LAYERS_IDS.chantierPoint:
      return { name: terms.featuresPopup.title.chantier, value: '' }
    case LAYERS_IDS.zoneBAL:
      return { name: terms.featuresPopup.title.balZone, value: '' }
    case LAYERS_IDS.centerCircle:
    case LAYERS_IDS.centerHighlight:
    case LAYERS_IDS.centerLabel:
      return { name: terms.featuresPopup.title.center, value: '' }
    case LAYERS_IDS.centerArmenCircle:
    case LAYERS_IDS.centerArmenHighlight:
    case LAYERS_IDS.centerArmenLabel:
      return { name: terms.featuresPopup.title.centerArmen, value: '' }
    case LAYERS_IDS.ihmZoneFill:
    case LAYERS_IDS.ihmZoneOutline:
    case LAYERS_IDS.ihmZoneLabel:
      return { name: terms.featuresPopup.title.ihmZone, value: '' }
    case LAYERS_IDS.ihmZoneAmendeeFill:
    case LAYERS_IDS.ihmZoneAmendeeOutline:
    case LAYERS_IDS.ihmZoneAmendeeLabel:
      return { name: terms.featuresPopup.title.ihmZoneAmendee, value: '' }
    case LAYERS_IDS.cdv:
    case LAYERS_IDS.cdvLabel:
      return { name: terms.featuresPopup.title.cdv, value: '' }
    case LAYERS_IDS.bfCircle:
    case LAYERS_IDS.bfHighlight:
    case LAYERS_IDS.bfLabel:
      return { name: terms.featuresPopup.title.bf, value: '' }
    case LAYERS_IDS.rac:
    case LAYERS_IDS.racLabel:
      return { name: terms.featuresPopup.title.rac, value: '' }
    default:
      return { name: '', value: '' }
  }
}

const getCassiniProperties = (feature: FeatureLayer, featureData: FeatureData): FeatureProperties => {
  const { libelle = 'NC', lrs_pk, lrs_voie, lrs_ligne } = feature.properties || {}
  const { postes, collections } = featureData
  const type = getObjectType(feature)
  const fields = [
    [
      ...(libelle ? [{ name: terms.featuresPopup.properties.libelle, value: libelle }] : []),
      ...(lrs_ligne ? [{ name: terms.featuresPopup.properties.lrs_ligne, value: lrs_ligne }] : []),
      ...(lrs_voie ? [{ name: terms.featuresPopup.properties.lrs_voie, value: lrs_voie }] : []),
      ...(lrs_pk ? [{ name: terms.featuresPopup.properties.lrs_pk, value: lrs_pk }] : []),
      ...(type ? [{ name: terms.featuresPopup.properties.type, value: type }] : []),
    ],
  ]
  return {
    fields,
    showPostes: true,
    postes,
    collections,
    history: [],
    neighbouringZones: { zone_action_poste: [], zone_bal: [] },
    rcData: {},
  }
}

const getPosteProperties = (
  feature: FeatureLayer, collections: FeatureData['collections'],
  history: FeatureData['history'], neighbouringZones: FeatureData['neighbouringZones'],
): FeatureProperties => {
  const {
    gare_libelle, poste_libelle, poste_technologie_libelle, poste_pks, codes_ligne, ihms_libelles = 'NC',
  } = feature.properties || {}
  const fields = [
    [
      ...(gare_libelle ? [{ name: terms.featuresPopup.properties.gare, value: gare_libelle }] : []),
      ...(poste_libelle ? [{ name: terms.featuresPopup.properties.libelle, value: poste_libelle }] : []),
      ...(poste_technologie_libelle ? [
        { name: terms.featuresPopup.properties.techno_poste, value: poste_technologie_libelle },
      ] : []),
      ...(poste_pks ? [{ name: terms.featuresPopup.properties.lrs_pk, value: poste_pks }] : []),
      ...(codes_ligne ? [{ name: terms.featuresPopup.properties.lrs_ligne, value: codes_ligne }] : []),
      ...(ihms_libelles ? [{ name: terms.featuresPopup.properties.linkedIHM, value: ihms_libelles }] : []),
    ],
  ]
  return {
    fields,
    showPostes: false,
    postes: [],
    collections,
    history,
    neighbouringZones,
    rcData: (
      feature.properties?.nombre_dc_bloque
      || feature.properties?.nombre_dc_non_commence
      || feature.properties?.nombre_dc_non_termine
      || feature.properties?.nombre_en_attente_rc
      || feature.properties?.nombre_etude_en_cours
    ) ? {
        nombre_dc_bloque: feature.properties?.nombre_dc_bloque || 0,
        nombre_dc_non_commence: feature.properties?.nombre_dc_non_commence || 0,
        nombre_dc_non_termine: feature.properties?.nombre_dc_non_termine || 0,
        nombre_en_attente_rc: feature.properties?.nombre_en_attente_rc || 0,
        nombre_etude_en_cours: feature.properties?.nombre_etude_en_cours || 0,
      } : {},
  }
}

const getChantierProperties = (feature: FeatureLayer, collections: FeatureData['collections']): FeatureProperties => {
  const {
    cle_banque_travaux, libelle, famille, infrapole, annee, lrs_ligne, lrs_pk, num_compte_geremi, reference_emergence,
    jv_emergence, date_mes_production, phase, numero_astre = 'NC', seism_project_id = 'NC', programme,
  } = feature.properties || {}

  const collectionsNames = Object.keys(collections).reduce((acc, reference) => {
    const { referenceGeographiques, ligne_ids } = collections[reference]
    return ({
      ...acc,
      [reference]: {
        referenceGeographiques: (referenceGeographiques as GeoReference[]).map(e => ({
          jiraId: e.jiraId, referenceGeographique: e.referenceGeographique,
        })),
        ligne_ids,
      },
    })
  }, {})

  const fields = [
    [
      ...(cle_banque_travaux ? [{ name: terms.featuresPopup.properties.chantier.key, value: cle_banque_travaux }] : []),
      ...(libelle ? [{ name: terms.featuresPopup.properties.chantier.label, value: libelle }] : []),
      ...(famille ? [{ name: terms.featuresPopup.properties.chantier.family, value: famille }] : []),
      ...(programme ? [{ name: terms.featuresPopup.properties.chantier.program, value: programme }] : []),
      ...(infrapole ? [{ name: terms.featuresPopup.properties.chantier.infrapole, value: infrapole }] : []),
    ],
    [
      ...(lrs_ligne ? [{ name: terms.featuresPopup.properties.chantier.line, value: lrs_ligne }] : []),
      ...(lrs_pk ? [{ name: terms.featuresPopup.properties.chantier.pk, value: lrs_pk }] : []),
    ],
    [
      ...(num_compte_geremi ? [{
        name: terms.featuresPopup.properties.chantier.account, value: num_compte_geremi,
      }] : []),
      ...(reference_emergence ? [{
        name: terms.featuresPopup.properties.chantier.reference, value: reference_emergence,
      }] : []),
      ...(jv_emergence ? [{ name: terms.featuresPopup.properties.chantier.jv, value: jv_emergence }] : []),
      ...(numero_astre ? [{ name: terms.featuresPopup.properties.chantier.astre, value: numero_astre }] : []),
      ...(seism_project_id ? [{ name: terms.featuresPopup.properties.chantier.seism, value: seism_project_id }] : []),
    ],
    [
      ...(annee ? [{ name: terms.featuresPopup.properties.chantier.year, value: annee }] : []),
      ...(phase ? [{ name: terms.featuresPopup.properties.chantier.phase, value: phase }] : []),
      ...(date_mes_production ? [{
        name: terms.featuresPopup.properties.chantier.date, value: moment(date_mes_production).format('DD/MM/YYYY'),
      }] : [{ name: terms.featuresPopup.properties.chantier.date, value: 'NC' }]
      ),
    ],
  ]
  return {
    fields,
    showPostes: false,
    postes: [],
    collections: collectionsNames,
    history: [],
    neighbouringZones: { zone_action_poste: [], zone_bal: [] },
    rcData: {},
  }
}

const getBALProperties = (
  feature: FeatureLayer,
  collections: FeatureData['collections'],
  neighbouringZones: FeatureData['neighbouringZones'],
)
: FeatureProperties => {
  const {
    libelle = 'NC', lrs_pk, lrs_voie, lrs_ligne,
  } = feature.properties || {}

  const fields = [
    [
      ...(libelle ? [{ name: terms.featuresPopup.properties.libelle, value: libelle }] : []),
      ...(lrs_ligne ? [{ name: terms.featuresPopup.properties.lrs_ligne, value: lrs_ligne }] : []),
      ...(lrs_voie ? [{ name: terms.featuresPopup.properties.lrs_voie, value: lrs_voie }] : []),
      ...(lrs_pk ? [{ name: terms.featuresPopup.properties.lrs_pk, value: lrs_pk }] : []),
    ],
  ]
  return {
    fields,
    showPostes: false,
    postes: [],
    collections,
    history: [],
    neighbouringZones,
    rcData: (
      feature.properties?.nombre_dc_bloque
      || feature.properties?.nombre_dc_non_commence
      || feature.properties?.nombre_dc_non_termine
      || feature.properties?.nombre_en_attente_rc
      || feature.properties?.nombre_etude_en_cours
    ) ? {
        nombre_dc_bloque: feature.properties?.nombre_dc_bloque || 0,
        nombre_dc_non_commence: feature.properties?.nombre_dc_non_commence || 0,
        nombre_dc_non_termine: feature.properties?.nombre_dc_non_termine || 0,
        nombre_en_attente_rc: feature.properties?.nombre_en_attente_rc || 0,
        nombre_etude_en_cours: feature.properties?.nombre_etude_en_cours || 0,
      } : {},
  }
}

const getCenterProperties = (feature: FeatureLayer, featureData: FeatureData): FeatureProperties => {
  const {
    libelle = 'NC', nom_de_chassis, type_ct_libelle, code_ligne, pk,
  } = feature.properties || {}
  const { postes, collections } = featureData

  const fields = [
    [
      ...(libelle ? [{ name: terms.featuresPopup.properties.libelle, value: libelle }] : []),
      ...(nom_de_chassis ? [{ name: terms.featuresPopup.properties.center.nomChassis, value: nom_de_chassis }] : []),
      ...(type_ct_libelle ? [{ name: terms.featuresPopup.properties.center.type, value: type_ct_libelle }] : []),
      ...(code_ligne ? [{ name: terms.featuresPopup.properties.lrs_ligne, value: code_ligne }] : []),
      ...(pk ? [{ name: terms.featuresPopup.properties.lrs_pk, value: pk }] : []),
    ],
  ]

  return {
    fields,
    showPostes: true,
    postes,
    collections,
    history: [],
    neighbouringZones: { zone_action_poste: [], zone_bal: [] },
    rcData: {},
  }
}

const getIHMProperties = (feature: FeatureLayer, featureData: FeatureData): FeatureProperties => {
  const { libelle = 'NC' } = feature.properties || {}
  const { postes } = featureData

  const fields = [
    [
      ...(libelle ? [{ name: terms.featuresPopup.properties.libelle, value: libelle }] : []),
    ],
  ]
  return {
    fields,
    showPostes: true,
    postes,
    collections: {},
    history: [],
    neighbouringZones: { zone_action_poste: [], zone_bal: [] },
    rcData: {},
  }
}

export const getProperties = (feature: FeatureLayer, featureData: FeatureData): FeatureProperties => {
  switch (feature.source) {
    case SOURCES_IDS.actionZone:
    case SOURCES_IDS.actionZoneLabel:
      return getPosteProperties(feature, featureData.collections, featureData.history, featureData.neighbouringZones)
    case SOURCES_IDS.chantier:
      return getChantierProperties(feature, featureData.collections)
    case SOURCES_IDS.balZone:
      return getBALProperties(feature, featureData.collections, featureData.neighbouringZones)
    case SOURCES_IDS.poste:
      return getCassiniProperties(feature, {
        postes: [],
        collections: featureData.collections,
        history: [],
        neighbouringZones: { zone_action_poste: [], zone_bal: [] },
        rcData: {},
      })
    case SOURCES_IDS.center:
      return getCenterProperties(feature, featureData)
    case SOURCES_IDS.ihmZone:
    case SOURCES_IDS.ihmZoneLabel:
      return getIHMProperties(feature, featureData)
    default:
      return getCassiniProperties(feature, featureData)
  }
}

export const getAllProperties = (feature: FeatureLayer) => {
  const { libelle, lrs_pk, lrs_voie, lrs_ligne, ...otherProperties } = feature.properties || {}
  const mainProperties = [
    ...(libelle ? [{ name: 'libelle', value: libelle }] : []),
    ...(lrs_ligne ? [{ name: 'lrs_ligne', value: lrs_ligne }] : []),
    ...(lrs_voie ? [{ name: 'lrs_voie', value: lrs_voie }] : []),
    ...(lrs_pk ? [{ name: 'lrs_pk', value: lrs_pk }] : []),
  ]

  return [mainProperties, Object.entries(otherProperties || {}).map(([name, value]) => ({ name, value }))]
}
