import { ReactElement } from 'react'
import { terms, Images } from 'assets'
import SimpleButton from 'components/SimpleButton/SimpleButton'
import './AccessDenied.scss'

export default function AccessDenied(): ReactElement {
  return (
    <div id="access-denied" className="flex-column-center">
      <div className="flex-column-center">
        <img src={Images.warning} alt="Warning notice" />
        <span className="main-message">
          {terms.Error.forbidden.appTitle}
        </span>
        {terms.Error.forbidden.appSubtitle}
      </div>
      <div className="button-wrapper">
        <SimpleButton
          title={terms.Error.forbidden.exitButton}
          onClick={() => window.location.replace('https://dgexsol.fr')}
        />
      </div>
    </div>
  )
}
