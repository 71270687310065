import { DexCartoSourceKey } from '../type'
import { SOURCES_IDS } from './sources'

export const SCHEMATIC_VIEWS: Record<DexCartoSourceKey, string | null> = {
  [SOURCES_IDS.track]: 'full',
  [SOURCES_IDS.signal]: 'full_rgi_track_sch_flat',
  [SOURCES_IDS.poste]: 'full_rgi_track_sch_flat',
  [SOURCES_IDS.station]: 'full_rgi_track_sch_flat_centroid',
  [SOURCES_IDS.jdz]: 'full_rgi_track_sch_flat',
  [SOURCES_IDS.adv]: 'full_rgi_track_sch_flat',
  [SOURCES_IDS.advLine]: 'full_rgi_track_sch_flat',
  [SOURCES_IDS.acces]: 'full_rgi_track_sch_flat',
  [SOURCES_IDS.sousStation]: 'full_rgi_track_sch_flat',
  [SOURCES_IDS.css]: 'full_rgi_track_sch_flat',
  [SOURCES_IDS.localisateur]: 'full_rgi_track_sch_flat',
  [SOURCES_IDS.dbc]: 'full_rgi_track_sch_flat',
  [SOURCES_IDS.pedale]: 'full_rgi_track_sch_flat',
  [SOURCES_IDS.passageNiveau]: 'full_rgi_track_sch_flat',
  [SOURCES_IDS.actionZone]: 'full_rgi_track_sch_flat',
  [SOURCES_IDS.actionZoneLabel]: 'full_rgi_track_sch_flat_libelle',
  [SOURCES_IDS.chantier]: 'full_rgi_track_sch_flat',
  [SOURCES_IDS.balZone]: 'full_rgi_track_sch_flat',
  [SOURCES_IDS.center]: 'full_rgi_track_sch_flat',
  [SOURCES_IDS.ihmZone]: 'full_rgi_track_sch_flat',
  [SOURCES_IDS.ihmZoneLabel]: 'full_rgi_track_sch_flat_libelle',
  [SOURCES_IDS.cdv]: 'full_rgi_track_sch_flat',
  [SOURCES_IDS.exploitationMode]: 'full_rgi_track_sch_flat',
  [SOURCES_IDS.pri]: 'full_rgi_track_sch_flat',
  [SOURCES_IDS.priLabel]: null,
  [SOURCES_IDS.utm]: 'full_rgi_track_sch_flat',
  [SOURCES_IDS.utmLabel]: null,
  [SOURCES_IDS.zpRegion]: 'full_rgi_track_sch_flat',
  [SOURCES_IDS.zpLabel]: null,
  [SOURCES_IDS.infrapoleLabel]: null,
  [SOURCES_IDS.zoneEditorPolygon]: null,
  [SOURCES_IDS.bf]: 'full_rgi_track_sch_flat_centroid',
  [SOURCES_IDS.rac]: 'full_rgi_track_sch_flat',
  [SOURCES_IDS.centerArmen]: 'full_rgi_track_sch_flat',
}

export const GEOGRAPHIC_VIEWS: Record<DexCartoSourceKey, string | null> = {
  [SOURCES_IDS.track]: 'full',
  [SOURCES_IDS.signal]: 'full_rgi_track_geo',
  [SOURCES_IDS.poste]: 'full_rgi_track_geo',
  [SOURCES_IDS.station]: 'full_rgi_track_geo_centroid',
  [SOURCES_IDS.jdz]: 'full_rgi_track_geo',
  [SOURCES_IDS.adv]: 'full_rgi_track_geo',
  [SOURCES_IDS.advLine]: 'full_rgi_track_geo',
  [SOURCES_IDS.acces]: 'full_rgi_track_geo',
  [SOURCES_IDS.sousStation]: 'full_rgi_track_geo',
  [SOURCES_IDS.css]: 'full_rgi_track_geo',
  [SOURCES_IDS.localisateur]: 'full_rgi_track_geo',
  [SOURCES_IDS.dbc]: 'full_rgi_track_geo',
  [SOURCES_IDS.pedale]: 'full_rgi_track_geo',
  [SOURCES_IDS.passageNiveau]: 'full_rgi_track_geo',
  [SOURCES_IDS.actionZone]: 'full_rgi_track_geo',
  [SOURCES_IDS.actionZoneLabel]: 'full_rgi_track_geo_libelle',
  [SOURCES_IDS.chantier]: 'full_rgi_track_geo',
  [SOURCES_IDS.balZone]: 'full_rgi_track_geo',
  [SOURCES_IDS.center]: 'full_rgi_track_geo',
  [SOURCES_IDS.ihmZone]: 'full_rgi_track_geo',
  [SOURCES_IDS.ihmZoneLabel]: 'full_rgi_track_geo_libelle',
  [SOURCES_IDS.cdv]: 'full_rgi_track_geo',
  [SOURCES_IDS.exploitationMode]: 'full_rgi_track_geo',
  [SOURCES_IDS.pri]: null,
  [SOURCES_IDS.priLabel]: null,
  [SOURCES_IDS.utm]: null,
  [SOURCES_IDS.utmLabel]: null,
  [SOURCES_IDS.zpRegion]: null,
  [SOURCES_IDS.zpLabel]: null,
  [SOURCES_IDS.infrapoleLabel]: null,
  [SOURCES_IDS.zoneEditorPolygon]: null,
  [SOURCES_IDS.bf]: 'full_rgi_track_geo_centroid',
  [SOURCES_IDS.rac]: 'full_rgi_track_geo',
  [SOURCES_IDS.centerArmen]: 'full_rgi_track_geo',
}
