import { Layer, Source } from 'react-map-gl'
import { OBJECTS_LAYERS, SOURCES_IDS } from '../common'
import { chantierLayerStyles } from './ChantierLayerPaint'
import { MapURLParams, useMapURL } from '../hooks'

interface Props {
  hoveredObjectsIds: string[]
  opacity?: number
  lineFilter?: string
  chantierFilter: mapboxgl.Expression
  params?: MapURLParams
  view: string
}

const ChantierLayer = ({ hoveredObjectsIds, opacity, lineFilter, chantierFilter, params, view }: Props) => {
  const url = useMapURL(
    OBJECTS_LAYERS.chantier,
    view,
    SOURCES_IDS.chantier,
    params,
  )
  const { chantierLine, chantierPoint, chantierLineHighlight, chantierPointHighlight } = chantierLayerStyles

  return (
    <Source
      id={SOURCES_IDS.chantier}
      type="vector"
      url={url}
    >
      <Layer
        {...chantierLine}
        paint={{
          ...chantierLine.paint,
          'line-opacity': ['case',
            ['in', lineFilter, ['get', 'lrs_ligne']], 1,
            opacity],
        }}
        filter={[
          ...chantierFilter,
          ['any', ['==', ['geometry-type'], 'LineString'], ['==', ['geometry-type'], 'MultiLineString']],
        ]}
      />
      <Layer
        {...chantierPoint}
        paint={{
          ...chantierPoint.paint,
          'circle-opacity': ['case',
            ['in', lineFilter, ['get', 'lrs_ligne']], 1,
            opacity],
        }}
        filter={[
          ...chantierFilter,
          ['any', ['==', ['geometry-type'], 'Point'], ['==', ['geometry-type'], 'MultiPoint']],
        ]}
      />
      <Layer
        {...chantierLineHighlight}
        filter={[
          ...chantierFilter,
          ['any', ['==', ['geometry-type'], 'LineString'], ['==', ['geometry-type'], 'MultiLineString']],
          ['in', ['get', 'id'], ['literal', hoveredObjectsIds]],
        ]}
      />
      <Layer
        {...chantierPointHighlight}
        filter={[
          ...chantierFilter,
          ['any', ['==', ['geometry-type'], 'Point'], ['==', ['geometry-type'], 'MultiPoint']],
          ['in', ['get', 'id'], ['literal', hoveredObjectsIds]],
        ]}
      />
    </Source>
  )
}

ChantierLayer.defaultProps = {
  opacity: 1,
  lineFilter: '',
  params: {},
}

export default ChantierLayer
