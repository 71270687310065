import { createSlice } from '@reduxjs/toolkit'
import { CollectionsState } from './types'
import { getCollections, getPerimeterCollections } from './collections.thunk'

const initialState: CollectionsState = {
  collections: [],
  loading: true,
  page: 1,
  totalPage: 1,
  search: '',
  perimeterCollections: [],
  perimeterLoading: true,
  perimeterPage: 1,
  perimeterTotalPage: 1,
  perimeterSearch: '',
}

export const collectionsSlice = createSlice({
  name: 'collections',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getCollections.pending, (state, action) => {
      state.loading = true
      state.page = action.meta.arg.page
      state.search = action.meta.arg.search
    })
    builder.addCase(getCollections.fulfilled, (state, action) => {
      state.collections = action.payload.results
      state.totalPage = Math.ceil(action.payload.count / 50)
      state.loading = false
    })
    builder.addCase(getCollections.rejected, state => {
      state.loading = false
    })
    builder.addCase(getPerimeterCollections.pending, (state, action) => {
      state.perimeterLoading = true
      state.perimeterPage = action.meta.arg.page
      state.perimeterSearch = action.meta.arg.search
    })
    builder.addCase(getPerimeterCollections.fulfilled, (state, action) => {
      state.perimeterCollections = action.payload.results
      state.perimeterTotalPage = Math.ceil(action.payload.count / 50)
      state.perimeterLoading = false
    })
    builder.addCase(getPerimeterCollections.rejected, state => {
      state.perimeterLoading = false
    })
  },
})

export default collectionsSlice.reducer
