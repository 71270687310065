import { useEffect, useState } from 'react'
import { DateRange, Range, RangeKeyDict } from 'react-date-range'
import { fr } from 'date-fns/locale'
import 'react-date-range/dist/styles.css' // main style file
import 'react-date-range/dist/theme/default.css' // theme css file
import moment from 'moment'

import './DatePicker.scss'

interface Props {
  startDate: moment.Moment
  endDate: moment.Moment
  onChange: (startDate: moment.Moment, endDate: moment.Moment) => void
}

const DatePicker = ({ startDate, endDate, onChange }: Props) => {
  const [state, setState] = useState<Range[]>([
    {
      startDate: startDate.toDate(),
      endDate: endDate.toDate(),
      key: 'selection',
    },
  ])

  const onDateRangeChange = (ranges: RangeKeyDict) => {
    setState([ranges.selection])
    const start = moment(ranges.selection.startDate).locale('fr')
    const end = moment(ranges.selection.endDate)
    onChange(start, end)
  }

  useEffect(() => {
    setState([
      {
        startDate: startDate.toDate(),
        endDate: endDate.toDate(),
        key: 'selection',
      },
    ])
  }, [startDate, endDate])

  return (
    <DateRange
      editableDateInputs
      locale={fr}
      onChange={onDateRangeChange}
      moveRangeOnFirstSelection={false}
      ranges={state}
      months={2}
      direction="horizontal"
      scroll={{ enabled: true }}
      showDateDisplay={false}
      color="#00A0E3"
      className="date-range-picker"
    />
  )
}

export default DatePicker
