import { DexcartoLayerKey } from '../type'
import LAYERS_KEYS from './menuKeys'

export const SIGNAL_LAYERS_KEYS: Readonly<DexcartoLayerKey[]> = [
  LAYERS_KEYS.signalProtection,
  LAYERS_KEYS.signalManoeuvre,
  LAYERS_KEYS.signalID,
  LAYERS_KEYS.signalDT,
  LAYERS_KEYS.signalGVC,
  LAYERS_KEYS.signalTGV,
  LAYERS_KEYS.signalTE,
  LAYERS_KEYS.signalTP,
  LAYERS_KEYS.signalAA,
  LAYERS_KEYS.signalLDV,
] as const

export const SIGNAL_LAYERS_MNEMO = {
  [LAYERS_KEYS.signalProtection]: [
    'Ct', 'CARRE', 'CARRE (CH)', 'CV', 'D', 'Ex', 'GA', 'R17', 'R18', 'S', 'S (CH)', 'SG HEURT',
  ],
  [LAYERS_KEYS.signalManoeuvre]: ['LM', 'SLM'],
  [LAYERS_KEYS.signalID]: ['ID', 'TIDD'],
  [LAYERS_KEYS.signalDT]: ['DD', 'SLD'],
  [LAYERS_KEYS.signalGVC]: ['CHEVRON', 'VOIE CONV', 'IDP'],
  [LAYERS_KEYS.signalTGV]: ['CAB E', 'JAL MAN', 'REP TGV', 'CAB S', 'CAB E', 'CAB R'],
  [LAYERS_KEYS.signalTE]: [
    'BIMODE', 'BIMODE A', 'SECT', 'REV', 'BP DIS', 'BP EXE', 'CC EXE', 'BP FIN', 'CC FIN', 'FIN CAT',
  ],
  [LAYERS_KEYS.signalTP]: [
    'CHEVRON', 'FEUXVERTS', 'JAL ARRET', 'LGR', 'ARRET', 'ARRET A', 'ATC', 'CARRE A', 'DESTI', 'DIVERS', 'GARE', 'PN',
    'PN...', 'APPROETSA', 'ARRET VOY', 'SIFFLER', 'STOP', 'STOP A', 'MIBLAN VER', 'PAD', 'MV', 'APPROCHETS',
    'GABARIT', 'TUNNEL', 'LIMITETS', 'RLI', 'SAC', 'Aig M', 'Aig T', 'SIG A TRAM', 'B', 'DEPOT', 'TAB DIVERS',
    'FLECHE ACC', 'G', 'GIVRE', 'HEURT...', 'IMP', 'TLD', 'TECS', 'TSCS',
  ],
  [LAYERS_KEYS.signalAA]: ['A', 'A (CH)', 'A TRAM'],
  [LAYERS_KEYS.signalLDV]: ['L', 'R', 'Z', 'R30', 'RR30', 'REPER VIT', 'P', 'TIV A TRAM', 'TIV E TRAM', 'TIV D FIXE',
    'TIVD B FIX', 'TIVD C FIX', 'TIV D MOB', 'TIV R MOB', 'TIV PENDIS', 'TIV PENEXE', 'TIV PENREP', 'TIV R TRAM',
    'TBFVN TMP', 'TB TMP', 'P TMP', 'TIV D TMP', 'TIV R TMP',
  ],
} as const
