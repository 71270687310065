import { Box, Modal, Typography } from '@mui/material'
import { ReactElement } from 'react'
import InfoIcon from '@mui/icons-material/Info'
import terms from 'common/terms'
import './ConfirmationModal.scss'

const style = {
  position: 'absolute' as const,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '4px',
}

interface Props {
  onModal: boolean,
  content?: string,
  handleClose: () => void,
  handleConfirmClick?: () => void
}

const ConfirmationModal = ({
  onModal,
  content,
  handleClose,
  handleConfirmClick,
}: Props): ReactElement => (
  <Modal
    open={onModal}
    onClose={handleClose}
  >
    <Box className="modal-confirmation" sx={style}>
      <InfoIcon className="icon" />
      <Typography className="title mt-4" variant="h6" component="h1" color="success">
        {terms.confirmationModal.confirmation}
      </Typography>
      <Typography className="text-content" variant="h6" component="h2">
        {content}
      </Typography>
      <button className="validate" type="button" onClick={handleConfirmClick}>
        {terms.confirmationModal.confirm}
      </button>
      <button className="cancel" type="button" onClick={handleClose}>
        {terms.confirmationModal.cancel}
      </button>
    </Box>
  </Modal>
)

ConfirmationModal.defaultProps = {
  content: undefined,
  handleConfirmClick: undefined,
}

export default ConfirmationModal
