import { LayerProps } from 'react-map-gl'
import { OBJECTS_LAYERS } from '../common'

const ActionZonePosteLayerProps: LayerProps = {
  type: 'symbol',
  paint: {
    'text-color': '#fff',
  },
  layout: {
    'icon-image': ['concat', 'rectanglearrondi-', ['get', 'color']],
    'icon-text-fit': 'both',
    'icon-allow-overlap': true,
    'text-font': ['Open Sans Bold'],
    'text-allow-overlap': true,
    'text-size': 12,
    'text-justify': 'left',
    'text-field': [
      'format',
      ['get', 'poste_libelle'],
      '\n',
      ['get', 'gare_libelle'],
    ],
    'text-max-width': 1000,
    'text-anchor': 'right',
  },
  'source-layer': OBJECTS_LAYERS.actionZonePoste,
  minzoom: 12,
}

export default ActionZonePosteLayerProps
