import {
  ReactElement, Ref, useCallback, useEffect, useRef, useState,
} from 'react'
import ReactMapGL, { MapRef, ViewportProps, MapEvent } from 'react-map-gl'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'store'
import { Map } from 'assets'
import { transformRequest } from 'utils'
import { MapTheme } from 'components/Toolbar/ThemeMenu/const'
import { MapPopup, Snackbar } from 'components'
import { MapLayers } from 'components/Layers'
import { CreateZoneModal } from 'components/Modal'
import {
  CONTEXT_MENU_LAYERS_IDS, FEATURES_SOURCES, INTERACTIVE_LAYERS_IDS, LAYERS_IDS, LAYERS_KEYS, OBJECTS_LAYERS,
  SCHEMATIC_VIEWS, SOURCES_IDS,
} from 'components/Layers/common'
import {
  initCreatePerimeter, initCreateZonePoste, setOpenCreateZoneModal, setSelectedPoste,
} from 'store/zoneEditor/zoneEditor'
import { ZoneType } from 'store/types'
import './index.scss'
import { hideSnackbar } from 'store/feedback'
import ContextMenu from 'components/ContextMenu/ContextMenu'
import MultiFeaturesPopup from 'components/MultiFeaturesPopup/MultiFeaturesPopup'
import { useBorderInfos } from 'components/hooks'
import { FeatureLayer } from 'components/MapPopup/utils'
import { signal } from '@preact/signals-react'
import { useHistory, useLocation } from 'react-router-dom'
import { throttle } from 'lodash'
import FavoriteModal from 'components/Modal/FavoriteModal/FavoriteModal'
import { closeFavoriteModal } from 'store/userFavorite/userFavorite'
import { Feature, Polygon } from 'geojson'
import { polygon } from '@turf/helpers'
import { getObjectComments, getUserFavoriteObjects } from 'store/userFavorite/userFavorite.thunk'
import { addActiveLayer, updateTheme } from 'store/map'
import { calculateGeometryViewport } from 'components/utils'
import { get } from '@osrdata/app_core/dist/requests'
import {
  FeatureData, addedImagesSignal, formatLocationURL, getFeatureData,
  loadMissingImage, loadStaticImages, parseLocationURL, refreshTiles,
} from './utils'
import MapOverlay from './MapOverlay'

export const SCH_DEFAULT_VIEWPORT = {
  latitude: 46.3680,
  longitude: 5.3901,
  zoom: 5.4389429465554,
  bearing: 0,
  pitch: 0,
}

export const GEO_DEFAULT_VIEWPORT = {
  latitude: 46.9623,
  longitude: 5.5332,
  zoom: 5.4389429465554,
  bearing: 0,
  pitch: 0,
}

export const mapViewportSignal = signal<ViewportProps>(SCH_DEFAULT_VIEWPORT)

export const mapBboxSignal = signal<Polygon | null>(null)

export default function MapGL(): ReactElement {
  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation()
  const mapRef = useRef<MapRef | undefined >(undefined)
  const [selectedFeature, setSelectedFeature] = useState<FeatureLayer | null>(null)
  const [selectedFeatureLon, setSelectedFeatureLon] = useState<number | null>(null)
  const [selectedFeatureLat, setSelectedFeatureLat] = useState<number | null>(null)
  const [selectedFeaturesData, setSelectedFeaturesData] = useState<FeatureData>(
    {
      postes: [],
      collections: {},
      history: [],
      neighbouringZones: { zone_bal: [], zone_action_poste: [] },
      rcData: {},
    },
  )
  const [showPopup, setShowPopup] = useState<boolean>(false)
  const [showMultiPopup, setShowMultiPopup] = useState<boolean>(false)
  const [selectedFeatureMulti, setSelectedFeatureMulti] = useState<FeatureLayer[]>([])
  const [hoveredEvent, setHoveredEvent] = useState<MapEvent | undefined>(undefined)
  const [contextHoveredEvent, setContextHoveredEvent] = useState<MapEvent | undefined>(undefined)
  const [hoveredObjectsIds, setHoveredObjectsIds] = useState<string[]>([])
  const [selectedObjectsIds, setSelectedObjectsIds] = useState<string[]>([])
  const [highlightedObjectsIds, setHighlightedObjectsIds] = useState<string[]>([])
  const bordersInfos = useBorderInfos()
  const [contextEvent, setContextEvent] = useState<MapEvent | undefined>(undefined)
  const [selectedBorder, setSelectedBorder] = useState<MapEvent | undefined>(undefined)

  const { currentOsmTheme, sourcesToUpdate, activeLayers } = useSelector((state: RootState) => state.map)
  const { openCreateZoneModal, isSelectingPoste, editorStep } = useSelector((state: RootState) => state.zoneEditor)
  const { snackbarDisplay, snackbarMessage, snackbarSeverity } = useSelector((state: RootState) => state.feedback)
  const { favoriteModal } = useSelector((state: RootState) => state.userFavorite)

  const throttledUpdateURL = useCallback(throttle((newViewport: ViewportProps, theme: MapTheme) => {
    history.push(formatLocationURL(newViewport, theme))
  }, 1000, { leading: false }), [])

  const onViewportChange = (newViewport: ViewportProps) => {
    mapViewportSignal.value = { ...newViewport }
  }

  useEffect(() => {
    dispatch(getUserFavoriteObjects())
    dispatch(getObjectComments())
    const params = parseLocationURL(location.pathname)
    if (params) {
      dispatch(updateTheme(params.view === 'geo' ? MapTheme.geographic : MapTheme.schematic))
      mapViewportSignal.value = { ...mapViewportSignal.value, ...params.viewport }
    }
  }, [])

  useEffect(() => {
    throttledUpdateURL(mapViewportSignal.value, currentOsmTheme)
    const bounds = mapRef.current?.getMap().getBounds()
    mapBboxSignal.value = polygon([[
      [bounds.getNorthWest().lng, bounds.getNorthWest().lat],
      [bounds.getNorthEast().lng, bounds.getNorthEast().lat],
      [bounds.getSouthEast().lng, bounds.getSouthEast().lat],
      [bounds.getSouthWest().lng, bounds.getSouthWest().lat],
      [bounds.getNorthWest().lng, bounds.getNorthWest().lat],
    ]]).geometry
  }, [mapViewportSignal.value])

  useEffect(() => {
    const map = mapRef?.current?.getMap()
    const newMapStyle = currentOsmTheme === MapTheme.schematic ? Map.emptyStyle : Map.fullStyle
    map.setStyle(newMapStyle)

    addedImagesSignal.value = new Set<string>()
    loadStaticImages(mapRef)
    const onStyleImageMissing = (e: {id: string}) => { loadMissingImage(mapRef, e.id) }
    map.on('styleimagemissing', onStyleImageMissing)
    return () => { map.off('styleimagemissing', onStyleImageMissing) }
  }, [currentOsmTheme])

  useEffect(() => {
    refreshTiles(mapRef)
  }, [sourcesToUpdate])

  useEffect(() => {
    setHighlightedObjectsIds([...hoveredObjectsIds, ...selectedObjectsIds])
  }, [hoveredObjectsIds, selectedObjectsIds])

  const handlePopupClose = () => {
    setSelectedFeature(null)
    setSelectedFeatureLon(null)
    setSelectedFeatureLat(null)
    setSelectedFeaturesData({
      postes: [],
      collections: {},
      history: [],
      neighbouringZones: { zone_bal: [], zone_action_poste: [] },
      rcData: {},
    })
    setShowPopup(false)
    setShowMultiPopup(false)
    setSelectedFeatureMulti([])
  }

  const handleClickFeature = async (feature: FeatureLayer) => {
    await getFeatureData(feature, currentOsmTheme).then(postes => setSelectedFeaturesData(postes))
    setSelectedFeature(feature)
    setShowPopup(true)
    setSelectedFeatureMulti([])
    setShowMultiPopup(false)
  }

  const onFeatureClick = (e: MapEvent) => {
    if (!e.target.classList.contains('overlays')) return
    setSelectedObjectsIds([])
    if (!(e.srcEvent instanceof TouchEvent) && e.srcEvent.button === 2) return
    setContextEvent(undefined)
    if (isSelectingPoste) {
      const posteFeatures = e.features?.filter(feature => feature.layer.source === SOURCES_IDS.poste) || []
      if (posteFeatures[0]) {
        dispatch(setSelectedPoste(posteFeatures[0].properties))
      }
      return
    }
    if (editorStep) return
    if (e.features?.some(feature => [
      LAYERS_IDS.priLabel, LAYERS_IDS.zpLabel, LAYERS_IDS.infrapoleLabel, LAYERS_IDS.utmLabel,
    ].includes(feature.layer.id))) {
      setSelectedBorder(e)
    } else {
      setSelectedBorder(undefined)
    }

    const featuresSet = new Set()
    const relevantFeatures = e.features?.reduce((acc, feature) => {
      if (!FEATURES_SOURCES.includes(feature.layer.source)) return acc
      const featureId = `${feature.properties.id}-${feature.layer.sourceLayer}`
      if (featuresSet.has(featureId)) return acc
      featuresSet.add(featureId)
      return [...acc, feature]
    }, [])

    setSelectedFeatureLon(e.lngLat[0])
    setSelectedFeatureLat(e.lngLat[1])
    if (relevantFeatures?.length === 1) {
      const feature = relevantFeatures[0]
      handleClickFeature(feature)
    } else if (relevantFeatures && relevantFeatures?.length >= 2) {
      setSelectedFeatureMulti(relevantFeatures)
      setShowMultiPopup(true)
    }
  }

  const handleCreateZone = (type: ZoneType) => () => {
    dispatch(setOpenCreateZoneModal(false))
    if (type === 'poste') {
      dispatch(initCreateZonePoste())
    } else if (type === 'perimeter') {
      dispatch(initCreatePerimeter())
    }
  }

  const onHover = (e: MapEvent) => {
    if (!e.target.classList.contains('overlays')) return
    if (editorStep) {
      setHoveredObjectsIds([])
      setHoveredEvent(undefined)
      return
    }
    if (e.features && e.features.length >= 1) {
      setHoveredEvent(e)
      const featureId = e.features.map(feature => feature.properties.id).find(id => id !== undefined)
      setHoveredObjectsIds(featureId ? [featureId] : [])
    } else if (hoveredEvent || hoveredObjectsIds.length > 0) {
      setHoveredEvent(undefined)
      setHoveredObjectsIds([])
    }
  }

  const onContextMenu = (e: MapEvent) => {
    e.preventDefault()
    if (hoveredEvent === undefined) return
    const hoveredEventLayerIds = hoveredEvent?.features?.map(feature => feature.layer.id) || []
    if (hoveredEventLayerIds.every(layerId => !CONTEXT_MENU_LAYERS_IDS.includes(layerId))) return
    setContextEvent(e)
    setContextHoveredEvent({
      ...hoveredEvent,
      features: hoveredEvent?.features?.filter(feature => CONTEXT_MENU_LAYERS_IDS.includes(feature.layer.id)),
    })
    setHoveredEvent(undefined)
    setSelectedBorder(undefined)
    setShowPopup(false)
  }

  const handleClickPoste = (geom: GeoJSON.Geometry, zapId: string, isModifiable: boolean) => () => {
    const { latitude, longitude, zoom } = calculateGeometryViewport(geom, mapViewportSignal.value)
    if (!activeLayers.includes(LAYERS_KEYS.zoneActionPosteAmendee) && isModifiable) {
      dispatch(addActiveLayer(LAYERS_KEYS.zoneActionPosteAmendee))
    }
    if (!activeLayers.includes(LAYERS_KEYS.zoneActionPoste) && !isModifiable) {
      dispatch(addActiveLayer(LAYERS_KEYS.zoneActionPoste))
    }
    setSelectedObjectsIds([zapId])
    mapViewportSignal.value = ({ ...mapViewportSignal.value, latitude, longitude, zoom, transitionDuration: 500 })
  }

  const handleHoverPoste = (zapId: string) => () => {
    setHoveredObjectsIds([+zapId as unknown as string])
  }

  const handleHoverBal = (balId: string) => () => {
    setHoveredObjectsIds([balId])
  }

  const handleClickZap = (zapId: string) => async () => {
    // eslint-disable-next-line max-len
    const clickedZap: Feature = await get(`/chartis/v2/layer/${OBJECTS_LAYERS.actionZonePoste}/geojson_feature/${SCHEMATIC_VIEWS[SOURCES_IDS.actionZone]}/`, { id: zapId })
    handleClickPoste(clickedZap.geometry, zapId, clickedZap.properties?.modifiable)()
  }

  const handleClickBal = (balId: string) => async () => {
    // eslint-disable-next-line max-len
    const clickedBal: Feature = await get(`/chartis/v2/layer/${OBJECTS_LAYERS.balZone}/geojson_feature/${SCHEMATIC_VIEWS[SOURCES_IDS.balZone]}/`, { id: balId })
    const { latitude, longitude, zoom } = calculateGeometryViewport(clickedBal.geometry, mapViewportSignal.value)
    if (!activeLayers.includes(LAYERS_KEYS.balZone)) {
      dispatch(addActiveLayer(LAYERS_KEYS.balZone))
    }
    setSelectedObjectsIds([balId])
    mapViewportSignal.value = ({ ...mapViewportSignal.value, latitude, longitude, zoom, transitionDuration: 500 })
  }

  return (
    <div className="map-wrapper">
      <ReactMapGL
        {...mapViewportSignal.value}
        width="100%"
        height="100%"
        key={currentOsmTheme}
        onViewportChange={onViewportChange}
        clickRadius={2}
        transformRequest={transformRequest}
        ref={mapRef as Ref<MapRef>}
        onClick={onFeatureClick}
        onContextMenu={onContextMenu}
        onHover={onHover}
        interactiveLayerIds={currentOsmTheme === MapTheme.schematic
          ? INTERACTIVE_LAYERS_IDS.sch : INTERACTIVE_LAYERS_IDS.geo}
      >
        <MapOverlay
          setSelectedObjectsIds={setSelectedObjectsIds}
          setHoveredObjectsIds={setHoveredObjectsIds}
          viewportInfra={bordersInfos.viewportInfra}
          viewportPRI={bordersInfos.viewportPRI}
          viewportZP={bordersInfos.viewportZP}
        />
        <MapLayers
          mapRef={mapRef}
          selectedBorder={selectedBorder}
          highlightedObjectsIds={highlightedObjectsIds}
          setViewportInfra={bordersInfos.setViewportInfra}
          setViewportPRI={bordersInfos.setViewportPRI}
          setViewportZP={bordersInfos.setViewportZP}
        />
        {contextEvent && (
          <ContextMenu
            event={contextEvent}
            reset={() => setContextEvent(undefined)}
            hoveredEvent={contextHoveredEvent}
            setMapHover={setHoveredEvent}
            setHoveredId={setHoveredObjectsIds}
          />
        )}

        <MapPopup
          longitude={selectedFeatureLon}
          latitude={selectedFeatureLat}
          feature={selectedFeature}
          featureData={selectedFeaturesData}
          showPopup={showPopup}
          handleClose={handlePopupClose}
          handleClickPoste={handleClickPoste}
          handleHoverPoste={handleHoverPoste}
          handleClickZap={handleClickZap}
          handleClickBal={handleClickBal}
          handleHoverBal={handleHoverBal}
          handleHoverZap={handleHoverPoste}
        />

        <MultiFeaturesPopup
          longitude={selectedFeatureLon}
          latitude={selectedFeatureLat}
          features={selectedFeatureMulti}
          showPopup={showMultiPopup}
          handleClose={() => setShowMultiPopup(false)}
          setHoveredId={setHoveredObjectsIds}
          handleClickFeature={handleClickFeature}
        />
      </ReactMapGL>

      <CreateZoneModal
        open={openCreateZoneModal}
        onClose={() => dispatch(setOpenCreateZoneModal(false))}
        handleClickOption={handleCreateZone}
      />
      <FavoriteModal
        onModal={favoriteModal}
        handleClose={() => dispatch(closeFavoriteModal())}
        setSelectedObjectsIds={setSelectedObjectsIds}
      />
      <Snackbar
        message={snackbarMessage}
        displaySnackbar={snackbarDisplay}
        severity={snackbarSeverity}
        handleClose={() => dispatch(hideSnackbar())}
      />
    </div>
  )
}
