/* eslint-disable react/destructuring-assignment */
import { ReactElement } from 'react'
import { auth } from '@osrdata/app_core'
import { store } from 'store'
import history from 'config/history'
import {
  BookmarkBorder, PowerSettingsNewOutlined, BugReportOutlined, LightbulbOutlined, DynamicFeed,
  HelpOutline,
} from '@mui/icons-material'
import { openFavoriteModal } from 'store/userFavorite/userFavorite'
import { openBugModal, openHelpModal, openSuggestionModal } from 'store/app'

import { resetEditor } from 'store/zoneEditor/zoneEditor'

type MenuItemType = {
  label: string;
  icon?: ReactElement;
  onClick: () => void;
  show: boolean;
}

const ACCOUNT_MENU_ITEMS: MenuItemType[] = [
  {
    label: 'Mes Favoris',
    icon: <BookmarkBorder />,
    onClick: () => {
      store.dispatch(openFavoriteModal())
      store.dispatch(resetEditor())
    },
    show: true,
  },
  {
    label: 'Collections',
    icon: <DynamicFeed />,
    onClick: () => history.push('/collections', { from: history.location.pathname }),
    show: true,
  },
  {
    label: 'Une suggestion ?',
    icon: <LightbulbOutlined />,
    onClick: () => {
      store.dispatch(openSuggestionModal())
    },
    show: true,
  },
  {
    label: 'Un bug ?',
    icon: <BugReportOutlined />,
    onClick: () => {
      store.dispatch(openBugModal())
    },
    show: true,
  },
  {
    label: 'Aide',
    icon: <HelpOutline />,
    onClick: () => {
      store.dispatch(openHelpModal())
    },
    show: true,
  },
  {
    label: 'TopBar.disconnect',
    icon: <PowerSettingsNewOutlined />,
    onClick: () => auth.logout()(store.dispatch),
    show: true,
  },
]

export default ACCOUNT_MENU_ITEMS
