import { post } from '@osrdata/app_core/dist/requests'
import { Point } from 'geojson'

// eslint-disable-next-line import/prefer-default-export
export const reprojectPoints = async (
  points: Point[],
  fromProjection: string,
  toProjection: string,
) => {
  const response: Point[] = await post(
    `/cassini-v2/locate/${fromProjection}/reproject_point/${toProjection}/`,
    points,
  ).catch(error => {
    throw new Error(error)
  })
  return response
}
