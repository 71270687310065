import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import moment from 'moment'
import { ObjectSearchState } from './types'
import { searchAllObjects, searchLineObjects } from './objectSearch.thunk'

const initialState: ObjectSearchState = {
  searchPending: false,
  searchError: false,
  searchResults: {},
  lineSearchResults: [],
  lineSearchPending: false,
  lineSearchError: false,
  lineFilter: '',
  lineFilterActive: false,
  dateRangeEnd: moment(new Date()),
  dateRangeStart: moment(new Date()),

}

export const objectSearchSlice = createSlice({
  name: 'objectSearch',
  initialState,
  reducers: {
    resetSearchResults: state => {
      state.searchResults = {}
    },
    resetLineSearchResults: state => {
      state.lineSearchResults = []
    },
    setLineFilter: (state, action: PayloadAction<{line: string, active: boolean}>) => {
      state.lineFilter = action.payload.line
      state.lineFilterActive = action.payload.active
    },
    setDateRange: (state, action: PayloadAction<{start: moment.Moment, end: moment.Moment}>) => {
      state.dateRangeStart = action.payload.start
      state.dateRangeEnd = action.payload.end
    },
  },
  extraReducers: builder => {
    builder
      .addCase(searchAllObjects.pending, state => {
        state.searchPending = true
        state.searchError = false
      })
      .addCase(searchAllObjects.fulfilled, (state, action) => {
        state.searchPending = false
        state.searchError = false
        state.searchResults = action.payload
      })
      .addCase(searchAllObjects.rejected, state => {
        state.searchPending = false
        state.searchError = true
      })
      .addCase(searchLineObjects.pending, state => {
        state.lineSearchPending = true
        state.lineSearchError = false
      })
      .addCase(searchLineObjects.fulfilled, (state, action) => {
        state.lineSearchPending = false
        state.lineSearchError = false
        state.lineSearchResults = action.payload
      })
      .addCase(searchLineObjects.rejected, state => {
        state.lineSearchPending = false
        state.lineSearchError = true
      })
  },
})

export const { resetSearchResults, resetLineSearchResults, setLineFilter, setDateRange } = objectSearchSlice.actions

export default objectSearchSlice.reducer
